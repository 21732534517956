/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment } from 'react';

import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';
import { sendAudio } from './VoiceOver.action';

import Loader from './VoiceOverLoader/Loader';
import microphoneIcon from '../../../images/microphone.svg';

class VoiceRecognition extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderStatus: 'start',
			microphoneAccessError: null,
			recognitionErrorMessage: '',
			rtcSession: {
				type: 'audio',
				mimeType: 'audio/wav',
				audio: true,
				recorderType: StereoAudioRecorder,
				numberOfAudioChannels: 1,
				disableLogs: true,
			},
		};

		this.recordTimeout = 5000; // 5 seconds
	}

	componentDidMount() {
		this.startRecording();
	}

	componentWillUnmount() {
		this.stopRecorder();

		this.isCancelled = true;
	}

	onRecordStart = () => this.setState({ loaderStatus: 'listening' });

	onRecordEnd = blob => {
		if (!blob) {
			return;
		}

		this.setState({ loaderStatus: 'loading' });

		sendAudio(blob)
			.then(res => {
				if (this.isCancelled) {
					return;
				}

				this.setState({ loaderStatus: 'start' });

				if (res.action === 'navigation') {
					this.redirect(res.fulfillmentText, res.queryText);
				} else {
					this.showRecognitionErrorMessage(res.queryText);
				}
			})
			.catch(() => {
				if (this.isCancelled) {
					return;
				}

				this.setState({ loaderStatus: 'start' });

				this.showRecognitionErrorMessage('Lost connection with the server, please try again later');
			});
	};

	showMicrophoneAccessError = () => {
		const microphoneAccessError = 'For the best experience, please use Safari or Chrome on desktop, Safari Mobile on iOS and Chrome or Firefox on Android.';
		this.setState({ microphoneAccessError });
	};

	showRecognitionErrorMessage = queryText => {
		const text = queryText ? `"${queryText}"` : 'any word';
		this.setState({ recognitionErrorMessage: `Sorry, couldn’t understand ${text}. Please, try again.` });
	};

	hideRecognitionErrorMessage = () => {
		this.setState({ recognitionErrorMessage: '' });
	};

	startRecording = () => {
		this.hideRecognitionErrorMessage();

		this.startRecorder();
	};

	startRecorder = () => {
		const isSupported = navigator.mediaDevices
			&& (window.AudioContext || window.webkitAudioContext);

		if (!isSupported) {
			return;
		}

		const captureAudioMicrophone = async () => {
			const stream
				= await navigator.mediaDevices.getUserMedia({ audio: true });
			this.stream = stream;

			const recorder = new RecordRTCPromisesHandler(this.stream, this.state.rtcSession);
			this.recorder = recorder;

			this.onRecordStart();
			recorder.startRecording();

			const sleep = m => new Promise(r => setTimeout(r, m));
			await sleep(this.recordTimeout);

			await recorder.stopRecording();
			const blob = await this.recorder.getBlob();

			this.stream.getTracks().forEach((track) => track.stop()); // remove red dot
			if (!this.isCancelled) {
				return blob;
			}
			return '';
		};

		captureAudioMicrophone()
			.then(blob => {
				this.onRecordEnd(blob);
			})
			.catch(() => {
				this.showMicrophoneAccessError();
			});
	};

	stopRecorder = () => {
		this.setState({ loaderStatus: 'start' });

		if (this.recorder) {
			this.recorder.stopRecording();
		}
		if (this.stream) {
			this.stream.getTracks().forEach((track) => track.stop()); // remove red dot
		}
	};

	redirect = (page, text) => {
		if (this.checkRedirectURL(page, text)) {
			this.props.onRedirect(page);
		} else {
			this.showRecognitionErrorMessage(text);
		}
	};

	checkRedirectURL = (url) => {
		const intents = this.props.intents.map(intent => intent.link);
		return intents.includes(url);
	};

	render() {
		const { loaderStatus, microphoneAccessError, recognitionErrorMessage } = this.state;

		const loaderMessage = loaderStatus.charAt(0).toUpperCase() + loaderStatus.slice(1);

		return (
			<Fragment>
				<div className="recognition-error">{recognitionErrorMessage}</div>

				{!microphoneAccessError ?
					<Fragment>
						{loaderStatus === 'start' ?
							<div className="header">
								<img src={microphoneIcon} className="microphone" alt="microphone" onClick={this.startRecording} />
							</div>
							: <Loader text={loaderMessage} />}
					</Fragment>
					: <div className="microphone-access-error">{microphoneAccessError}</div>}

			</Fragment>
		);
	}
}

export default VoiceRecognition;
