/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Alert } from 'reactstrap';
import {
	getTrendingHashtags,
	getTrendingHashtagsUrl,
	getTrendingHashtagsImg,
	getQuotes,
} from './ContentGeneration.action';

import Loader from '../../../common/components/LoaderAnimation/Loader';
import CalendarContentPickerHashtagItem from './CalendarContentPickerHashtagItem/CalendarContentPickerHashtagItem';

import './CalendarContentPicker.css';

const CalendarContentPicker = (props) => {
	const [trendingHashtags, setTrendingHashtags] = useState([]);
	const [selectedTrendingHashtags, setSelectedTrendingHashtags] = useState([]);
	const [detectedFromURLHashtags, setDetectedFromURLHashtags] = useState([]);
	const [listOfHashtags, setListOfHashtags] = useState([]);
	const [link, setLink] = useState([]);
	const [linkImg, setLinkImg] = useState('');
	const [warningMassage, changeWarningMassage] = useState('');
	const [loader, setLoader] = useState(true);

	useEffect(() => {
		if (!props.img && (!props.link || props.link.length === 0) && props.mode !== 'img') {
			getTrendingHashtags()
				.then(({ hashtags }) => {
					setTrendingHashtags(hashtags);
					setLoader(false);
				})
				.catch(() => {
					setLoader(false);
				});
		} else if (props.mode === 'img' && props.img) {
			setTrendingHashtags([]);
			setLoader(false);
		}
	}, []);

	useEffect(() => {
		if (props.listOfHashtags) {
			setListOfHashtags(props.listOfHashtags);
		}
	}, [props.listOfHashtags]);

	useEffect(() => {
		if (props.hashtags) {
			setSelectedTrendingHashtags(props.hashtags);
		}
	}, [props.hashtags]);

	const loadHashtagsHandler = (img) => {
		setLoader(true);

		getTrendingHashtagsImg(img)
			.then(({ hashtags }) => setTrendingHashtags(hashtags))
			.catch((err) => err && err.message && changeWarningMassage(err.message))
			.finally(() => setLoader(false));
	};

	useEffect(() => {
		if (props.img || props.img !== linkImg) {
			loadHashtagsHandler(props.img);
			setLinkImg(props.img);
		}
		if (!props.img || props.img !== linkImg) {
			setTrendingHashtags([]);
			setLinkImg('');
		}
	}, [props.img]);

	useEffect(() => {
		const { mode, img, link: propsLink } = props;

		if (mode === 'img' && !img) {
			setLoader(false);
		}

		if (mode === 'link' && propsLink && link.length !== propsLink.length) {
			setLoader(true);
			getQuotes(propsLink)
				.then(({ link: linkReq, quotes }) => {
					if (props.getQuotes) {
						props.getQuotes(quotes);
					}
					if (props.getLink) {
						props.getLink(linkReq);
					}
					Promise.all([getTrendingHashtagsUrl(linkReq)])
						.then(([{ hashtags }]) => {
							setDetectedFromURLHashtags(hashtags);
						})
						.catch((error) =>
							console.log(
								'[CalendarContentPicker getTrendingHashtagsUrl error]:',
								error,
							));
				})
				.catch((error) =>
					console.log('[CalendarContentPicker getQuotes error]:', error))
				.finally(() => setLoader(false));
		}
	}, [props.mode, props.img, props.link]);

	useEffect(() => {
		if (props.link && props.link.length !== 0) {
			setLink(props.link);
		} else {
			setLink([]);
		}
	}, [props.link]);

	useEffect(() => {
		if (props.imageUrl) {
			setLoader(true);
			getTrendingHashtagsImg(props.imageUrl)
				.then(({ hashtags }) => setDetectedFromURLHashtags(hashtags))
				.catch((error) =>
					console.log(
						'[CalendarContentPicker getTrendingHashtagsImg error]:',
						error,
					))
				.finally(() => setLoader(false));
		}
	}, [props.imageUrl]);

	/* const getButton = () => {
		 const {
			 mode, img,
		 } = props;

		 return mode === 'img' && img && img !== linkImg ? (
			 <Button
				 onClick={() => loadHashtagsHandler(img)}
				 className="load-hashtags-btn"
				 color="light"
				 size="sm"
			 >
				 Load hashtags
			 </Button>
		 ) : null;
	 }; */

	const getHashtagListForMapping = () => {
		if (listOfHashtags && listOfHashtags._id) {
			if (listOfHashtags._id === 'trending-hashtags') {
				return trendingHashtags;
			}
			if (listOfHashtags._id === 'url') {
				return detectedFromURLHashtags;
			}
			return props.hashtagsList;
		}
		return detectedFromURLHashtags.length
			? detectedFromURLHashtags
			: trendingHashtags;
	};

	const hashtagList = getHashtagListForMapping().slice(0, 12);

	return (
		<div
			className={cx({
				'new-hashtags-picker': props.newClass,
				'hashtags-picker': !props.newClass,
				'hashtags-picker-centered': props.mode === 'img',
			})}
		>
			<Alert
				toggle={() => changeWarningMassage('')}
				className={cx({
					'hide-warning-wrapper': !warningMassage,
				})}
				color="warning"
			>
				{warningMassage}
			</Alert>
			{/* <div className="load-hashtags-wrapper">{getButton()}</div> */}
			<div className={props.newClass ? 'new-hashtags' : 'hashtags'}>
				{hashtagList.length && !loader
					? hashtagList.map((hashtag) => (
						<CalendarContentPickerHashtagItem
							key={hashtag}
							hashtag={hashtag}
							selectedTrendingHashtags={selectedTrendingHashtags}
							newClass={props.newClass}
							onSelect={props.onSelect}
						/>
					))
					: 'No Hashtags'}
				{loader && <Loader />}
			</div>
		</div>
	);
};

export default CalendarContentPicker;
