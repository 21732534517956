/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { post } from '../../../common/httpRequests';
import { CHAT_GPT_SUMMARY_GENERATION } from '../../../common/apiRouting';

export function summarizeText(text) {
	return post(CHAT_GPT_SUMMARY_GENERATION, { text }, true)
		.then(res => res.json());
}
