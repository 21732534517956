/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { v1 as uuid } from 'uuid';
import { getToken } from '../../../common/auth';
import { post } from '../../../common/httpRequests';
import {
	IMAGE_UPLOADER_URL,
	VIDEO_UPLOADER_URL,
	DETECT_ANNOTATION_FROM_IMAGE_URL,
	AUDIO_UPLOADER_URL,
	GET_TEXT_FROM_IMAGE_URL,
	GENERATE_PRESIGNED_URL,
	// DELETE_FILES_URL,
	CHAT_GPT_POST_FROM_VIDEO_GENERATION,
} from '../../../common/apiRouting';

function ext(url) {
	return (url = url.substr(1 + url.lastIndexOf('/')).split('?')[0]).split('#')[0].substr(url.lastIndexOf('.')).split('.')[1];
}

export function generatePostFromVideoText(videoUrl) {
	return post(CHAT_GPT_POST_FROM_VIDEO_GENERATION, { videoUrl }, true)
		.then(res => res.json());
}

export function deleteFiles() {
	return Promise.resolve();
	// filesList = filesList.filter(file => file);
	// if (!filesList || filesList.length === 0) return Promise.resolve();
	// return fetch(`${DELETE_FILES_URL}`, {
	// 	method: 'POST',
	// 	headers: {
	// 		Authorization: getToken(),
	// 		'Content-type': 'application/json',
	// 	},
	// 	body: JSON.stringify({
	// 		files: filesList,
	// 	}),
	// }).then(res => res.json()).then(() => Promise.resolve()).catch((err) => Promise.reject(err));
}


export function uploadPreSignedImage(image, type) {
	return fetch(`${GENERATE_PRESIGNED_URL}?fileExtension=${type?.split('/').reverse()[0]}&contentType=${image.type}`, {
		headers: {
			Authorization: getToken(),
		},
	})
		.then(res => res.json()).then(response => {
			const urlObj = new URL(response.url);
			return fetch(response.url, {
				method: 'PUT',
				body: image,
				headers: {
					'Content-Type': image.type,
				},
			}).then(() => Promise.resolve({
				id: uuid(),
				image: urlObj.origin + urlObj.pathname,
			}));
		});
}

export function uploadPreSignedPdf(pdf, metadata) {
	return fetch(`${GENERATE_PRESIGNED_URL}?fileExtension=${pdf.name.split('/').reverse()[0]}&contentType=${pdf.type}`, {
		headers: {
			Authorization: getToken(),
		},
	})
		.then(res => res.json()).then(response => {
			const urlObj = new URL(response.url);
			return fetch(response.url, {
				method: 'PUT',
				body: pdf,
				headers: {
					'Content-Type': pdf.type,
				},
			}).then(() => Promise.resolve({
				id: uuid(),
				url: urlObj.origin + urlObj.pathname,
				thumbnail: metadata.thumbnail,
				type: pdf.type,
				duration: metadata.duration,
			}));
		}).catch((err) => Promise.reject(err));
}

export function uploadPreSignedVideo(video, metadata) {
	if (video.url) {
		return Promise.resolve({
			...video,
			thumbnail: metadata.thumbnail,
		});
	}
	return fetch(`${GENERATE_PRESIGNED_URL}?fileExtension=${video.name.split('.').reverse()[0]}&contentType=${video.type}`, {
		headers: {
			Authorization: getToken(),
		},
	})
		.then(res => res.json()).then(response => {
			const urlObj = new URL(response.url);
			return fetch(response.url, {
				method: 'PUT',
				body: video,
				headers: {
					'Content-Type': video.type,
				},
			}).then(() =>
				Promise.resolve({
					id: uuid(),
					audioTrack: `${urlObj.origin + urlObj.pathname.split('.')[0]}.mp3`,
					type: video.type,
					duration: metadata.duration,
					url: urlObj.origin + urlObj.pathname,
					thumbnail: metadata.thumbnail,
					is4KVideo: video.is4KVideo,
					videoHeight: video.videoHeight,
					videoWidth: video.videoWidth,
					// eslint-disable-next-line radix
					videoTimeOffset: parseInt(metadata.videoTimeOffset, 0),
					size: video.size,
				}));
		});
}

export function uploadPreSignedAudio(audio, metadata) {
	return fetch(`${GENERATE_PRESIGNED_URL}?fileExtension=${audio.name.split('.').reverse()[0]}&contentType=${audio.type}`, {
		headers: {
			Authorization: getToken(),
		},
	})
		.then(res => res.json()).then(response => {
			const urlObj = new URL(response.url);
			return fetch(response.url, {
				method: 'PUT',
				body: audio,
				headers: {
					'Content-Type': audio.type,
				},
			}).then(() => Promise.resolve({
				id: uuid(),
				url: urlObj.origin + urlObj.pathname,
				thumbnail: metadata.thumbnail,
				duration: metadata.duration,
			}));
		});
}

export function uploadVideoThumbnailAndGetDuration(mediaFile) {
	return new Promise((resolve, reject) => {
		const videoUrl = URL.createObjectURL(mediaFile);
		const video = document.createElement('video');
		const canvas = document.createElement('canvas');
		let duration = 0;
		video.style.display = 'none';
		canvas.style.display = 'none';
		video.muted = true;
		const reader = new FileReader();
		reader.onload = function () {
			video.src = videoUrl;
			video.play().then(() => {
				// window.URL.revokeObjectURL(video.src);
				duration = video.duration;
				canvas.width = video.videoWidth * 0.5;
				canvas.height = video.videoHeight * 0.5;
				canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
				canvas.toBlob((blob) => {
					if (!blob) return reject('Not able to get thumbnail');
					const thumbnailFile = new File([blob], `${mediaFile.name.split('.')[0]}.jpg`, { type: 'image/jpeg' });
					uploadPreSignedImage(thumbnailFile, '.jpg').then(result => resolve({
						thumbnail: result.image,
						duration,
					})).catch(err => reject(err));
				}, 'image/jpeg');
			});
		};
		reader.readAsDataURL(mediaFile);
	});
}

export function uploadAudioThumbnailAndGetDuration(mediaFile) {
	return new Promise((resolve) => {
		const audio = document.createElement('audio');
		let duration = 0;
		audio.style.display = 'none';
		audio.preload = 'metadata';
		audio.onloadedmetadata = function () {
			window.URL.revokeObjectURL(audio.src);
			duration = audio.duration;
			audio.remove();
			return resolve({
				thumbnail: null,
				duration,
			});
		};

		audio.src = URL.createObjectURL(mediaFile);
	});
}

export function uploadImage(image) {
	const formData = new FormData();
	formData.append('file', image);

	const options = {
		method: 'POST',
		headers: {},
		body: formData,
	};
	options.headers.Authorization = `${getToken()}`;

	return fetch(IMAGE_UPLOADER_URL, options)
		.then(res => res.json());
}

export function uploadVideo(video, socialNetworksSelected) {
	const formData = new FormData();
	formData.append('file', video);

	if (socialNetworksSelected.includes.instagram) {
		formData.append('durationMinLimit', '3');
		formData.append('durationMaxLimit', '60');
	}

	formData.append('socialNetworksSelected', JSON.stringify(socialNetworksSelected));

	const options = {
		method: 'POST',
		headers: {},
		body: formData,
	};
	options.headers.Authorization = `${getToken()}`;

	return fetch(VIDEO_UPLOADER_URL, options).then(res => res.json());
}

export function uploadAudio(audio) {
	const formData = new FormData();
	formData.append('file', audio.file);
	formData.append('durationMinLimit', '3');
	formData.append('durationMaxLimit', '180');

	const options = {
		method: 'POST',
		headers: {},
		body: formData,
	};
	options.headers.Authorization = `${getToken()}`;

	return fetch(AUDIO_UPLOADER_URL, options).then(res => res.json());
}

export function getAnnotationFromImage(imageURL) {
	const url = Array.isArray(imageURL) ? imageURL[0] : imageURL;
	return post(`${DETECT_ANNOTATION_FROM_IMAGE_URL}`, { imageURL: url }, true)
		.then(res => res.json());
}

export function getTextFromImage(imageURL) {
	return post(`${GET_TEXT_FROM_IMAGE_URL}`, { imageURL }, true)
		.then(res => res.json());
}
