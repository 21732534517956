/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, Button, Label, ModalHeader } from 'reactstrap';

import './CalendarSummarizeModal.css';
import MessageEditor from '../../../common/components/MessageEditor/MessageEditor';
import { summarizeText } from './CalendarSummarizeModal.action';
import Loader from '../../../common/components/LoaderAnimation/Loader';
import TextSummariesCounter from '../../../common/components/TextSummariesCounter/TextSummariesCounter';

class CalendarSummarizeModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			textValue: '',
			summary: '',
			countInputWords: 0,
			disabled: true,
		};
	}

	onMessageChange = (textValue) => {
		const minInputLength = 100;
		const regURL = /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-/])*)?/gm;
		const checkURL = regURL.test(textValue);

		console.log(checkURL);

		const countWords = textValue ? textValue.split(/\S*/gm).length - 1 : 0;

		console.log(countWords);
		if (countWords < minInputLength && !checkURL) {
			this.setState({
				countInputWords: countWords,
				disabled: true,
				textValue,
			});
		} else {
			this.setState({
				countInputWords: countWords,
				disabled: false,
				textValue,
			});
		}
	};

	onToggle = () => {
    	if (this.state.loading) return false;

    	this.props.toggle();
    	this.setState({
    		textValue: '',
    		summary: '',
    	});
	};

	getSummarizeText = () => {
    	if (this.state.loading) return false;
    	this.setState({ loading: true });
    	summarizeText(this.state.textValue)
    		.then((text) => {
    			this.setState({
    				summary: text,
    				loading: false,
    			});
    		});
	};

	render() {
    	const {
    		isOpen, onSelect, user, limits,
    	} = this.props;
    	const {
    		loading, textValue, summary, countInputWords, disabled,
    	} = this.state;
    	return (
    		<Modal
    			isOpen={isOpen}
    			toggle={this.onToggle}
    			size="lg"
    			centered
    			autoFocus
    		>
    			<ModalHeader toggle={this.onToggle} style={{ border: 'none' }} />
    			<ModalBody style={{ textAlign: 'left ' }}>
    				<div>
    					<Label style={{ margin: 0 }}>Enter Paragraph or URL to Article to Generate Summaries</Label>
    					<div style={{ position: 'relative' }}>
    						<MessageEditor
    							focused={isOpen}
    							message={textValue}
    							onChange={this.onMessageChange}
    							addEmoji={null}
    							user={user}
    							limits={limits}
    						/>
    						<TextSummariesCounter countWords={countInputWords} />
    					</div>
    					<div className="button-container" style={{ borderBottom: '1px solid rgba(0,0,0,.3)' }}>
    						<Button
    							style={{ margin: '12px 0' }}
    							color="primary"
    							onClick={this.getSummarizeText}
    							disabled={disabled}
    						>
									Summarize it
    						</Button>
    					</div>
    					<div style={{ margin: '12px 0' }}>
    						<Label style={{ marginBottom: '14px' }}>Summary</Label>
    						<div className="editor" style={{ height: 200, padding: '6px 12px', overflow: 'scroll' }}>
    							{loading ? <Loader /> : summary}
    						</div>
    					</div>
    					<div className="button-container">
    						<Button
    							style={{ margin: '12px 0' }}
    							color="primary"
    							onClick={() => {
    								if (this.state.loading) return false;

    								this.onToggle();
    								onSelect(summary);
    							}}
    						>
                                Insert into Social Media Post
    						</Button>
    					</div>
    				</div>
    			</ModalBody>
    			<ModalFooter />
    		</Modal>
    	);
	}
}

export default CalendarSummarizeModal;
