/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import {
	EditorState,
	ContentState,
	SelectionState,
	Modifier,
	RichUtils,
	convertFromRaw,
} from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createHashtagPlugin from 'draft-js-hashtag-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import createVideoPlugin from '@draft-js-plugins/video';
import createLinkPlugin from 'draft-js-anchor-plugin';
import { ResizableBox } from 'react-resizable';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import 'react-resizable/css/styles.css';

import {
	ItalicButton,
	BoldButton,
	UnderlineButton,
	HeadlineOneButton,
	HeadlineTwoButton,
	HeadlineThreeButton,
	UnorderedListButton,
	OrderedListButton,
	CodeBlockButton,
} from 'draft-js-buttons';

import HighlightWord from '../HighlightWord/HighlightWord';
import PredictiveLinguistics from '../PredictiveLinguistics/PredictiveLinguistics';
import chatGptIcon from '../../../icons/chatgpt-icon.svg';
import {
	getSelectionRange,
	getSelectionCoords,
	getHighlightWord,
	getCurrentContentBlockKey,
	getCurrentContentBlockText,
	moveSelectionToEnd,
	replaceHighlightWord,
	getTextHashtagsWithPosition,
	anchorOnHashTag,
} from '../../editorHelper';
import * as selectors from '../../../selectors';
import { getSuccessData } from '../../../helpers/store';
import { uploadEditedImage } from '../../../components/Library/ImageEditModal/ImageEditModal.action';
import AutocompletePlugin from '../../../utils/editor-plugins/autocomplete';

import './MessageEditor.css';
import { UserSubscriptionService } from '../../../components/UserSubscriptions/UserSubscriptionService';

const hashtagPlugin = createHashtagPlugin({ hashtag: 'hashtag' });
const linkifyPlugin = createLinkifyPlugin();
const imagePlugin = createImagePlugin({ theme: { image: 'editorStyleForImage' } });
const videoPlugin = createVideoPlugin();
const autocompletePlugin = new AutocompletePlugin();
const linkPlugin = createLinkPlugin({ placeholder: 'Past link and click Enter' });
const staticToolbarPlugin = createToolbarPlugin();

const { Toolbar } = staticToolbarPlugin;
const { LinkButton } = linkPlugin;

const plugins = [hashtagPlugin, linkifyPlugin, imagePlugin, videoPlugin, linkPlugin, staticToolbarPlugin];

class MessageEditor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editorState: EditorState.createEmpty(),
			inlineToolbar: {
				show: false,
				position: {},
				highlightWord: null,
			},
			hashtagSuggestion: undefined,
			focused: false,
			editableBlockText: '',
			cursorToStart: false,
			cursorToEnd: false,
			scroll: 0,
			isPredictiveLinguisticsVisible: true,

		};
	}

	componentDidMount() {
		this.setState({ cursorToStart: true });
		const {
			addImageToEditor, addVideoToEditor, addEmojiToEditor, addTextToEditorState, changeEditorStyles,
		} = this.props;
		if (addImageToEditor) {
			addImageToEditor(this.loadImageToGoogleCloud);
		}
		if (addVideoToEditor) {
			addVideoToEditor(this.addVideoToState);
		}
		if (addEmojiToEditor) {
			addEmojiToEditor(this.addEmoji);
		}
		if (addTextToEditorState) {
			addTextToEditorState(this.addTextToEditor);
		}
		if (changeEditorStyles) {
			changeEditorStyles(this.toggleEditorInlineStyle);
		}

		if (this.props.message) {
			const editorState = this.setEditorState(this.props.message);
			this.setState({ editorState, cursorToStart: true }, () => this.editor.componentWillMount()); // work around for DraftJs to reload plugins);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.focused !== undefined) {
			this.isCancelled = !nextProps.focused;
		}

		if (nextProps.addToText) {
			this.addTextToEditor(nextProps.addToText);
			return;
		}

		if (nextProps.addEmoji) {
			this.addEmoji(nextProps.addEmoji);
			return;
		}

		if (nextProps.message === this.getMessage()) {
			return;
		}

		const editorState = this.setEditorState(nextProps.message);
		this.setState({
			editorState,
			cursorToEnd: true,
		}, () => this.editor.componentWillMount()); // work around for DraftJs to reload plugins
	}

	componentWillUnmount() {
		this.isCancelled = true;
		this.unmountRefFunctions();
	}

	onChange = (editorState) => {
		if (this.isCancelled) {
			return;
		}

		this.setState({ isPredictiveLinguisticsVisible: true });
		const { isJournalPostEditor } = this.props;

		const currentContentBlockKey = getCurrentContentBlockKey(editorState);
		const contentState = editorState.getCurrentContent();
		const currentContentBlockText =
			getCurrentContentBlockText(currentContentBlockKey, contentState);
		const hashTagsWithPosition = getTextHashtagsWithPosition(currentContentBlockText);
		const currentAnchorPosition = editorState.getSelection().getAnchorOffset();
		const anchorHashTag = anchorOnHashTag(hashTagsWithPosition, currentAnchorPosition);
		this.setState({
			editableBlockText: currentContentBlockText,
		});

		if (!editorState.getSelection().isCollapsed() || (anchorHashTag && editorState.getSelection().hasFocus)) {
			const selectionRange = getSelectionRange();
			if (!selectionRange) {
				return;
			}
			const { inlineToolbar } = this.state;
			if (selectionRange.startOffset === 0 && inlineToolbar.show) {
				this.setState({ inlineToolbar: { show: false } });
			}

			if (this.editor && !isJournalPostEditor) {
				const selectionCoords = getSelectionCoords(selectionRange, this.editor.editor.editor);
				const { offsetTop, offsetLeft } = selectionCoords;
				const highlightWord = anchorHashTag ?
					anchorHashTag.name : getHighlightWord(selectionRange.startContainer.data);

				const remainderOfDivisionInteger = Math.ceil(offsetTop / 200);
				const top = remainderOfDivisionInteger === 1 ? offsetTop - 20 : (offsetTop / remainderOfDivisionInteger) + 55;
				const left = offsetLeft < 0 ? 0 : offsetLeft;

				this.setState({
					hashtagSuggestion: anchorHashTag,
					inlineToolbar: {
						show: !!highlightWord,
						position: {
							top,
							left,
						},
						highlightWord,
					},
				});
			}
		} else {
			this.setState(state => ({
				...state, inlineToolbar: { show: false }, hashtagSuggestion: undefined,
			}));
		}

		// TODO fix in future timeout
		// if (this.state.cursorToStart) {
		// 	setTimeout(() => {
		// 		this.setState({
		// 			cursorToStart: false,
		// 			editorState: EditorState.moveFocusToEnd(editorState),
		// 		}, () => {
		// 			if (this.props.onChange) {
		// 				this.props.onChange(this.getMessage());
		// 			}
		// 		});
		// 	}, 10);
		// 	return;
		// }

		this.setState({
			editorState: this.state.cursorToEnd ? EditorState.forceSelection(
				editorState,
				editorState.getSelection(),
			) : editorState,
			cursorToEnd: false,
		}, () => {
			if (this.props.onChange) {
				this.props.onChange(this.getMessage());
			}
		});
	};

	onBlur = () => {
		// this.isCancelled = true;
		this.setState({
			focused: false,
		});
	};

	onFocus = () => {
		this.isCancelled = false;
		this.setState({ focused: true });
	};

	onScroll = (e) => {
		this.setState({ scroll: e.currentTarget.scrollTop });
	};

	getMessage() {
		const { isJournalPostEditor, changePostMessageContent } = this.props;
		const editorText = this.editor ? this.editor.getEditorState().getCurrentContent().getPlainText() : '';
		if (isJournalPostEditor && changePostMessageContent) {
			changePostMessageContent(this.editor);
		}
		return editorText;
	}

	setEditorState = text => {
		const { isJournalPostEditor } = this.props;
		const { editorState } = this.state;
		try {
			// When opening journal post
			const newVal = convertFromRaw(JSON.parse(text));
			return EditorState.createWithContent(newVal);
		} catch (e) {
			const newEditor = EditorState.createWithContent(ContentState.createFromText(text));
			if (isJournalPostEditor) {
				return EditorState.moveFocusToEnd(newEditor);
			}
			return EditorState.forceSelection(newEditor, editorState.getSelection());
		}
	};

	unmountRefFunctions = () => {
		const {
			addImageToEditor, addVideoToEditor, addEmojiToEditor, addTextToEditorState, changeEditorStyles,
		} = this.props;
		if (addImageToEditor) {
			addImageToEditor(null);
		}
		if (addVideoToEditor) {
			addVideoToEditor(null);
		}
		if (addEmojiToEditor) {
			addEmojiToEditor(null);
		}
		if (addTextToEditorState) {
			addTextToEditorState(null);
		}
		if (changeEditorStyles) {
			changeEditorStyles(null);
		}
	};

	addEmoji = emoji => {
		const { editorState } = this.state;
		const selection = editorState.getSelection();
		const contentState = editorState.getCurrentContent();
		const isNeedToPastedToStart = selection.getStartOffset() === 0;
		const pastedContent = isNeedToPastedToStart ? emoji : ` ${emoji} `;

		const newState = selection.isCollapsed() ?
			Modifier.insertText(contentState, selection, pastedContent)
			: Modifier.replaceText(contentState, selection, emoji);

		const newEditorState = EditorState.push(
			editorState,
			newState,
			'insert-characters',
		);
		this.setState({
			editorState: newEditorState,
			cursorToStart: false,
		}, () => {
			this.setState({
				editorState: EditorState.forceSelection(
					newEditorState,
					newEditorState.getSelection(),
				),
			});
			this.props.onChange(this.getMessage());
		});
	};

	focus = () => {
		this.isCancelled = false;
		this.editor.focus();
		this.setState({ focused: true });
	};

	replaceMessage = (message) => {
		const { editorState } = this.state;

		let contentState = editorState.getCurrentContent();

		const currentContentBlockKey = getCurrentContentBlockKey(editorState);
		const currentContentBlockText
			= getCurrentContentBlockText(currentContentBlockKey, contentState);

		const blockSelection = SelectionState
			.createEmpty(currentContentBlockKey)
			.merge({
				anchorOffset: 0,
				focusOffset: currentContentBlockText.length,
			});

		contentState = Modifier.replaceText(
			contentState,
			blockSelection,
			message,
		);

		const updatedEditorState = EditorState.push(editorState, contentState);

		this.setState({
			editorState: moveSelectionToEnd(updatedEditorState, currentContentBlockKey, message),
			editableBlockText: message,
		});
	};


	addTextToEditor = (word) => {
		const { editorState } = this.state;
		const selection = editorState.getSelection();
		const contentState = editorState.getCurrentContent();

		const anchorKey = selection.getAnchorKey();
		const currentContent = editorState.getCurrentContent();
		const currentBlock = currentContent.getBlockForKey(anchorKey);

		const start = selection.getStartOffset();
		const end = selection.getEndOffset();
		const isNeedSpaceBefore = (!currentBlock.getText().slice(start - 1, end) && !currentBlock.getText().slice(start - 2, end)) || currentBlock.getText().slice(start - 1, end);
		const isNeedSpaceAfter = (!currentBlock.getText().slice(start, end + 1) && !currentBlock.getText().slice(start, end + 2)) || currentBlock.getText().slice(start, end + 1);
		const newState = selection.isCollapsed() ?
			Modifier.insertText(contentState, selection, `${isNeedSpaceBefore && isNeedSpaceBefore !== ' ' ? ' ' : ''}${word}${isNeedSpaceAfter && isNeedSpaceAfter !== ' ' ? ' ' : ''}`)
			: Modifier.replaceText(contentState, selection, word);


		const newEditorState = EditorState.push(
			editorState,
			newState,
			'insert-characters',
		);
		this.setState({
			editorState: newEditorState,
			cursorToStart: false,
		}, () => {
			this.setState({
				editorState: EditorState.forceSelection(
					newEditorState,
					newEditorState.getSelection(),
				),
			});

			const { clearAddToText, onChange, changeLoaderVisibility } = this.props;
			if (clearAddToText) {
				clearAddToText();
			}
			onChange(this.getMessage());

			if (changeLoaderVisibility) {
				changeLoaderVisibility(false);
			}
		});
	};

	replaceWord = (word, isSelection) => {
		const { editorState } = this.state;
		const newText = replaceHighlightWord(
			editorState,
			editorState.getSelection().getAnchorKey(),
			word,
			isSelection,
		);
		this.setState({
			editorState: this.setEditorState(newText),
			cursorToEnd: true,
		}, () => {
			if (this.props.clearAddToText) {
				this.props.clearAddToText();
			}
			this.props.onChange(this.getMessage());
		});
	};

	toggleEditorInlineStyle = (newInlineStyle, newBlockStyle) => {
		let newEditorState;
		if (newInlineStyle) {
			newEditorState = RichUtils.toggleInlineStyle(
				this.state.editorState,
				newInlineStyle,
			);
		} else if (newBlockStyle) {
			newEditorState = RichUtils.toggleBlockType(
				this.state.editorState,
				newBlockStyle,
			);
		} else {
			const { editorState } = this.state;
			newEditorState = editorState;
		}
		this.setState({
			editorState: newEditorState,
			isPredictiveLinguisticsVisible: false,
		}, () => {
			const { editorState } = this.state;
			this.onChange(editorState);
		});
	};

	addImageToState = imageUrl => {
		const { editorState } = this.state;
		const newEditorState = imagePlugin.addImage(editorState, imageUrl);
		this.setState(
			{ editorState: EditorState.moveFocusToEnd(newEditorState) },
			() => {
				const { onChange, changeLoaderVisibility } = this.props;
				if (onChange) {
					onChange(this.getMessage());
				}
				changeLoaderVisibility(false);
			},
		);
	};

	loadImageToGoogleCloud = imageURL => {
		const { changeLoaderVisibility } = this.props;
		changeLoaderVisibility(true);
		uploadEditedImage(imageURL)
			.then(({ image }) => {
				this.addImageToState(image);
			})
			.catch(error => {
				changeLoaderVisibility(false);
				console.log('addImageToState:', error);
			});
	};

	addVideoToState = videoURL => {
		const { editorState } = this.state;
		const newEditorState = videoPlugin.addVideo(editorState, { src: videoURL });
		this.setState(
			{ editorState: EditorState.moveFocusToEnd(newEditorState) },
			() => {
				const { onChange, changeLoaderVisibility } = this.props;
				if (onChange) {
					onChange(this.getMessage());
				}
				changeLoaderVisibility(false);
			},
		);
	};

	render() {
		const {
			inlineToolbar,
			editorState,
			editableBlockText,
			scroll,
			hashtagSuggestion,
			isPredictiveLinguisticsVisible,
		} = this.state;

		const {
			user,
			limits,
			settings,
			setEmojiList,
			emojiCount,
			predictiveLinguisticsDisabled = false,
			height = 200,
			maxHeight = 300,
			isJournalPostEditor,
			needToShowResizableBox,
			handleOpenCaptionGenerationModal,
		} = this.props;

		const showPredictiveLinguistics = settings && getSuccessData(settings).predictiveLinguisticsActivated && !predictiveLinguisticsDisabled;
		const isDeviceWidthAllowed = window.innerWidth >= 768;
		const userData = user.response || user;
		const planName = UserSubscriptionService.getUserSubscriptionPlan(userData);
		const { autocompleteAPI } = limits[planName] ? limits[planName] : { autocompleteAPI: false };
		const needToShowPredictiveLinguistic = showPredictiveLinguistics && isPredictiveLinguisticsVisible && isDeviceWidthAllowed;

		if (autocompleteAPI) {
			plugins.push(autocompletePlugin);
		}

		return (
			<ResizableBox
				height={height}
				width="calc(100% - 2rem)"
				axis="y"
				minConstraints={[800, height]}
				maxConstraints={[800, maxHeight]}
				className={needToShowResizableBox ? '' : 'hide-resizable-box'}
			>
				<div className="editor" onClick={this.focus}>
					{handleOpenCaptionGenerationModal && <div
						className="chat-gpt-icon"
						onClick={() => {
							handleOpenCaptionGenerationModal();
						}}
					>
						<img
							src={chatGptIcon}
							alt="chat-gpt-icon"
							height="30px"
							data-for="chat-gpt-caption"
							data-tip=""
						/>

						<ReactTooltip
							id="chat-gpt-caption"
							place="bottom"
							effect="solid"
							backgroundColor="#07a3ed"
							getContent={() => (
								<div>Generate Caption using AI</div>
							)}
							delayHide={300}
						/>
					</div>}
					{needToShowPredictiveLinguistic &&
					<PredictiveLinguistics
						scroll={scroll}
						isVisible={false}
						text={editableBlockText}
						onConfirm={this.replaceMessage}
						editorState={editorState}
						updateSuggestionHashtags={this.props.updateSuggestionHashtags}
						setEmojiList={setEmojiList}
						emojiCount={emojiCount}
					/>
					}
					{inlineToolbar.show ?
						<HighlightWord
							highlightStyle={inlineToolbar.position}
							highlightWord={inlineToolbar.highlightWord}
							replaceWord={this.replaceWord}
							hashtagSuggestion={hashtagSuggestion}
						/> : null}

					<Scrollbars
						style={{
							width: 'calc(100% - 2rem)',
							height: 'calc(100% - 2rem)',
							zIndex: 2,
							margin: '1rem',
						}}
						onScroll={(e) => this.onScroll(e)}
					>
						<Editor
							editorState={editorState}
							onChange={this.onChange}
							handlePastedText={(text, html, currentEditorState) => {
								const blockMap = ContentState.createFromText(text.trim()).blockMap;
								const newState = Modifier.replaceWithFragment(currentEditorState.getCurrentContent(), currentEditorState.getSelection(), blockMap);
								this.onChange(EditorState.push(currentEditorState, newState, 'insert-fragment'));
								return 'handled';
							}}
							onBlur={this.onBlur}
							onFocus={this.onFocus}
							onScroll={this.onScroll}
							plugins={plugins}
							ref={(element) => {
								this.editor = element;
							}}
						/>
					</Scrollbars>
					{isJournalPostEditor &&
					<Toolbar>
						{
							(externalProps) => (
								<Fragment>
									<BoldButton {...externalProps} />
									<ItalicButton {...externalProps} />
									<UnderlineButton {...externalProps} />
									<LinkButton {...externalProps} />
									<HeadlineOneButton {...externalProps} />
									<HeadlineTwoButton {...externalProps} />
									<HeadlineThreeButton {...externalProps} />
									<UnorderedListButton {...externalProps} />
									<OrderedListButton {...externalProps} />
									<CodeBlockButton {...externalProps} />
								</Fragment>
							)
						}
					</Toolbar>}
				</div>
			</ResizableBox>
		);
	}
}

MessageEditor.defaultProps = {
	needToShowResizableBox: true,
};

MessageEditor.propTypes = {
	needToShowResizableBox: PropTypes.bool,
};

const mapStateToProps = state => ({
	settings: selectors.getSettings(state),
});

export default connect(mapStateToProps)(MessageEditor);
