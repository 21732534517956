/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
	Input,
	FormFeedback,
	Alert,
} from 'reactstrap';
import { connect } from 'react-redux';
import './Categories.css';
import * as selectors from '../../../selectors';
import ConfirmModal from '../../../common/components/ConfirmModal/ConfirmModal';
import { checkLibraryCategoryCreateOrDeleteRights } from '../../../helpers/rolesCheck';
import AccessWarningTooltip from '../../AccessWarningTooltip/AccessWarningTooltip';


class CategoriesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			category: {
				id: '',
				text: '',
			},
			showAlertModal: false,
			errorMessage: '',
			message: 'Are you sure that you want to delete this category? It will remove all the posts and rss feeds from this category',
		};

		this.handleChangeCategory = this.handleChangeCategory.bind(this);
		this.addCategory = this.addCategory.bind(this);
	}

	componentDidMount() {
		if (this.props.currentCategory.id) {
			this.setState({
				category: this.props.currentCategory,
			});
		}
	}

	onCloseModal = () => {
		this.setState({ category: { id: '', text: '' }, errorMessage: '' });
		this.props.toggle();
	};

	addCategory(e) {
		e.preventDefault();

		const newNameCategory = this.state.category.text;
		const trimNewNameCategory = newNameCategory.trim();
		const { existingCategories, currentCategory } = this.props;
		const existingCategoriesName = [];
		const currentEditCategoryName = currentCategory && currentCategory.text ? currentCategory.text.toLowerCase() : '';

		existingCategories.forEach(el => existingCategoriesName.push(el.text.toLowerCase()));

		const showErrorMessage = errorMessage => {
			this.setState({
				errorMessage,
			});
		};

		// Category name not changed, and close modal without save
		if (trimNewNameCategory.toLowerCase() === currentEditCategoryName) {
			this.onCloseModal();
			return;
		}

		if (trimNewNameCategory.length < 1) {
			showErrorMessage('Please, provide name of your category');
			return;
		}

		if (trimNewNameCategory.length >= 50) {
			showErrorMessage('Maximum 50 characters exceeded');
			return;
		}

		if (existingCategoriesName.indexOf(trimNewNameCategory.toLowerCase()) !== -1) {
			showErrorMessage('Category with this name already exists');
			return;
		}

		this.props.addCategory(this.state.category);
		this.onCloseModal();
	}

	showAlertModal = (value) => {
		this.setState({
			showAlertModal: value,
		});
	};

	handleChangeCategory(e) {
		const category = e.target.value;
		this.setState(() => ({ category: { id: this.props.currentCategory.id, text: category } }));
	}

	render() {
		const { isOpen, deleteCategory, permissionError } = this.props;

		return (
			<Fragment>
				<div>
					<Modal
						isOpen={isOpen}
						toggle={this.onCloseModal}
						centered
						autoFocus={false}
					>
						<ModalHeader
							className="categories-modal-header"
							toggle={this.onCloseModal}
						>
							<div id="rectangle" />
							{this.props.currentCategory.id ? 'Edit Category' : 'Add Category'}
						</ModalHeader>
						<ModalBody className="discover-categories-modal-body">
							{permissionError && <Alert 	color="danger">{permissionError} <br /></Alert>}
							<Form onSubmit={this.addCategory}>
								<FormGroup className="add-category">
									<Label>Category Name</Label>
									<Input
										type="text"
										value={this.state.category.text}
										onChange={this.handleChangeCategory}
										invalid={!!this.state.errorMessage}
										autoFocus
									/>
									<FormFeedback className="feedback-from">{this.state.errorMessage}</FormFeedback>
								</FormGroup>
							</Form>
						</ModalBody>
						<ModalFooter className="categories-modal-footer">
							{this.props.currentCategory.id ?
								<Button
									className="delete-category-btn"
									color="light"
									onClick={() => checkLibraryCategoryCreateOrDeleteRights(this.props.user.response) && this.showAlertModal(true)}
									data-tip
									data-for="access-warning-tooltip"
								>Delete
								</Button>
								: null}
							<Button
								className="save-category-btn"
								color="primary"
								onClick={this.addCategory}
							>Save
							</Button>
						</ModalFooter>
						{
							!checkLibraryCategoryCreateOrDeleteRights(this.props.user.response) && <AccessWarningTooltip />
						}
					</Modal>
					<ConfirmModal
						toggle={this.showAlertModal}
						isOpen={this.state.showAlertModal}
						onConfirm={() => {
							deleteCategory(this.props.currentCategory.id);
							this.onCloseModal();
						}}
						onClose={() => this.showAlertModal(false)}
						message={this.state.message}
					/>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	user: selectors.getCurrentUser(state),
});

export default connect(mapStateToProps)(CategoriesModal);
