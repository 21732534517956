/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import FetchStoreLifeCycle from '../../../helpers/components/requestLifeCycle/requestLifeCycle';
import CalendarPostModal from '../../Calendar/CalendarPostModal/CalendarPostModal';
import { getCurrentUser } from '../../../actions/user';
import { getAccountsFromDB } from '../../../actions/accounts';
import { getSettings } from '../../../actions/settings';
import * as selectors from '../../../selectors';
import { APP_CMD_CLOSE_MODAL } from '../ChromeExtension';

import './CreateSinglePost.css';
import { UserSubscriptionLimitsContext, UserSubscriptionService } from '../../UserSubscriptions/UserSubscriptionService';
import { getImagesByURL } from '../../../common/components/GetImagesFromUrl/GetImagesFromUrlModal/GetImagesFromUrlModal.action';
import { isJson } from '../../../helpers/isJson';
import CalendarCongratsModal from '../../Calendar/CalendarCongratsModal/CalendarCongratsModal';

class CreateSinglePost extends Component {
	constructor(props) {
		super(props);
		this.state = {
			postModalOpen: false,
			congratsModalOpen: false,
			libraryPost: [],
			eventMessage: false,
			text: '',
			postType: '',
			message: '',
			headerMessage: '',
			loadingImage: false,
			images: [],
			limits: {},
			errMessage: '',
		};
	}

	componentDidMount() {
		this.props.getCurrentUserAction();
		this.props.getAccountsFromDBAction();
		this.props.getSettingsAction();
		UserSubscriptionService.getLimits()
			.then((response) => {
				this.setState({ limits: response.limits });
			});
		window.addEventListener('message', this.handleExtEnter);

		const location = window.location.href.split('parenturl=')[1];
		if (this.state.loadingImage || location === 'about:blank') return;

		this.setState({
			text: location,
			loadingImage: true,
		}, () => {
			getImagesByURL(location)
				.then(imageUrl => {
					if (imageUrl) {
						this.setState({ images: [imageUrl] });
					}
				})
				.catch(error => console.log('[getImagesByURL error]:', error.message))
				.finally(() => this.setState({ postModalOpen: true }));
		});
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.handleExtEnter);
	}

	onPostModalClose = () => this.setState({ postModalOpen: false });

	onSaveModalClose = ({
							   open, message,
							   errMessage,
							   postType, headerMessage = ' ',
						   }) => {
		this.setState({
			postModalOpen: false,
			postType,
			message,
			congratsModalOpen: open,
			headerMessage,
			errMessage,
		}, () => {
			setTimeout(() => {
				this.closeExtModal();
			}, 5000);
		});
	};

	getDocHeight = (doc = document) => {
		const body = doc.body;
		const html = doc.documentElement;

		return Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.offsetHeight,
		);
	};

	handleExtEnter = (event) => {
		if (!isJson(event.data)) {
			return false;
		}
		this.setState({ eventMessage: event });
	};

	closeExtModal = () => {
		const { eventMessage } = this.state;

		if (eventMessage) {
			eventMessage.source.postMessage(JSON.stringify({ action: APP_CMD_CLOSE_MODAL, closeModal: true }), '*');
		}
	};

	render() {
		const { user, accountsList, user: { response: { timezone = '' } = {} } = {} } = this.props;
		const {
			postModalOpen,
			libraryPost,
			text,
			images,
			congratsModalOpen,
			headerMessage,
			message,
			postType,
			errMessage,
		} = this.state;
		return (
			<Fragment>
				<Helmet>
					<body className="with-ext-modal" />
				</Helmet>

				<div id="create-single-post-extension">
					<FetchStoreLifeCycle entities={{ user, accountsList }}>
						{postModalOpen &&
						<UserSubscriptionLimitsContext.Provider value={{
							limits: this.state.limits,
							currentSubscription: user.response ? user.response.subscription : null,
						}}
						>
							<CalendarPostModal
								isOpen={postModalOpen}
								toggle={this.onPostModalClose}
								onSave={this.onSaveModalClose}
								libraryPost={libraryPost}
								timezone={timezone}
								// resizeHeight={() => this.sendDocHeightMsg(eventMessage)}
								closeExtModal={this.closeExtModal}
								initialText={text}
								initialImages={images}
							/>
						</UserSubscriptionLimitsContext.Provider>}
						<CalendarCongratsModal
							isOpen={congratsModalOpen}
							toggle={this.closeExtModal}
							headerMessage={headerMessage}
							message={message}
							url={postType}
							timezone={timezone}
							errMessage={errMessage}
						/>
					</FetchStoreLifeCycle>
				</div>
			</Fragment>
		);
	}
}

const componentWithRouter = withRouter(CreateSinglePost);

const mapStateToProps = state => ({
	user: selectors.getCurrentUser(state),
	accountsList: selectors.getAccounts(state),
	settings: selectors.getSettings(state),
});

const mapDispatchToProps = dispatch => ({
	getCurrentUserAction: bindActionCreators(getCurrentUser, dispatch),
	getAccountsFromDBAction: bindActionCreators(getAccountsFromDB, dispatch),
	getSettingsAction: bindActionCreators(getSettings, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(componentWithRouter);
