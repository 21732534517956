/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import ReactTooltip from 'react-tooltip';

import { Alert, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loader from '../../../../common/components/LoaderAnimation/Loader';

import { getImagesByURL } from './GetImagesFromUrlModal.action';

import downloadFile from '../../../../images/downloadFile.svg';
import getImageFromURLModalIcon from '../../../../images/getImageFromURLModalIcon.svg';
import artBoard from '../../../../images/artboard.png';

import './GetImagesFromUrlModal.css';

const GetImagesFromUrlModal = ({
	isModalOpen, changeGetImagesFromURLModalVisibility, addedImageToPost, linksFromMessage, images, maxFilesCount,
}) => {
	const [inputURLValue, changeInputURLValue] = useState('');
	const [imageSrcList, changeImageSrcList] = useState([]);
	const [errorMessage, changeErrorMessage] = useState('');
	const [isDataLoading, changeDataLoadingValue] = useState(false);
	const [selectedImages, setSelectedImages] = useState([]);
	const [alreadySelectedImageLength, setAlreadySelectedImageLength] = useState();

	const chooseImage = (imgUrl) => {
		changeErrorMessage('');
		const imageURL = typeof imgUrl === 'object' ? imgUrl.image || '' : imgUrl;
		if (imageURL && !selectedImages.includes(imageURL)) {
			setAlreadySelectedImageLength(alreadySelectedImageLength + 1);
			setSelectedImages([...selectedImages, imageURL]);
		} else {
			setAlreadySelectedImageLength(alreadySelectedImageLength - 1);
			setSelectedImages(selectedImages.filter(img => img !== imageURL));
		}
	};

	useEffect(() => {
		if (isModalOpen) {
			setAlreadySelectedImageLength(images.length);
			if (linksFromMessage && linksFromMessage.length > 0) {
				let getImageByUrlPromises = [];
				getImageByUrlPromises = getImageByUrlPromises.concat(linksFromMessage.map(link => getImagesByURL(link)));
				changeDataLoadingValue(true);
				Promise.all(getImageByUrlPromises).then(imgUrls => {
					if (imgUrls && imgUrls.length) {
						imgUrls = imgUrls.filter(img => img);
						changeImageSrcList(imgUrls.map((imgUrl, index) => ({ link: linksFromMessage[index], image: imgUrl })));
						if (images && images.length > 0) {
							const alreadySelectedImages = [];
							images.forEach(imageObj => {
								let imgUrl = '';
								imgUrl = typeof imageObj === 'object' ? imageObj.image : imageObj;
								if (imgUrls.includes(imgUrl)) {
									alreadySelectedImages.push(imgUrl);
								}
							});
							setSelectedImages(alreadySelectedImages);
						}
						changeDataLoadingValue(false);
					}
				})
					.catch(error => console.log('[getImagesByURL error]:', error.message))
					.finally(() => changeDataLoadingValue(false));
			}
		}
	}, [isModalOpen]);

	const closeModal = () => {
		changeInputURLValue('');
		changeErrorMessage('');
		changeImageSrcList([]);
		setSelectedImages([]);
		changeDataLoadingValue(false);
		changeGetImagesFromURLModalVisibility(false);
	};

	const handleGetImagesByURLClick = () => {
		let getImageByUrlPromises = [];
		if (!inputURLValue.trim()) {
			return changeErrorMessage('Please paste url');
		}
		const urlRegex = /(https?:\/\/[^\s]+)/;
		const urlExtensionRegex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/;
		if (!urlRegex.test(inputURLValue)) {
			return changeErrorMessage('Please paste valid url');
		}

		if (urlExtensionRegex.test(inputURLValue)) {
			return changeErrorMessage('Please paste valid html page');
		}

		getImageByUrlPromises.push(getImagesByURL(inputURLValue));
		if (linksFromMessage && linksFromMessage.length && linksFromMessage.length > 0) {
			getImageByUrlPromises = getImageByUrlPromises.concat(linksFromMessage.map(link => getImagesByURL(link)));
		}

		changeDataLoadingValue(true);
		return getImagesByURL(inputURLValue)
			.then(imgUrl => {
				if (!imgUrl) {
					return changeErrorMessage('There are no images for current url. Please try another one');
				}
				return changeImageSrcList([...imageSrcList, { link: inputURLValue, image: imgUrl }]);
			})
			.catch(error => console.log('[getImagesByURL error]:', error.message))
			.finally(() => changeDataLoadingValue(false));
	};

	const addImageToPost = () => {
		if (selectedImages.length + images.length > maxFilesCount) {
			changeErrorMessage(`No. of images selected exceeds maximum of ${maxFilesCount}`);
		} else {
			addedImageToPost(selectedImages);
			closeModal();
		}
	};

	const changeURLValue = (imageUrl) => {
		changeErrorMessage('');
		changeInputURLValue(imageUrl);
	};

	const downloadImage = (imageSrc, name) => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', imageSrc, true);
		xhr.responseType = 'blob';
		xhr.onload = function () {
			const urlCreator = window.URL || window.webkitURL;
			const imageUrl = urlCreator.createObjectURL(this.response);
			const tag = document.createElement('a');
			tag.href = imageUrl;
			tag.target = '_blank';
			tag.download = name;
			document.body.appendChild(tag);
			tag.click();
			document.body.removeChild(tag);
		};
		xhr.onerror = (err) => {
			console.log('Failed to download image', err);
		};
		xhr.send();
	};

	const handleDownloadImages = () => {
		imageSrcList.map((imageObj, index) => downloadImage(imageObj.image, `image_${index + 1}${imageObj.image.indexOf('.') >= 0 ? `.${imageObj.image.split('.').reverse()[0]}` : ''}`));
	};

	return (
		<Modal
			isOpen={isModalOpen}
			toggle={closeModal}
			className="get-images-from-url-modal"
			centered
			size="lg"
			autoFocus={false}
		>
			<ModalHeader
				toggle={closeModal}
				className="get-images-from-url-modal-header"
			>
				<img
					src={getImageFromURLModalIcon}
					alt="get-pick-from-URL-modal-icon"
					className="get-image-from-URL-modal-icon"
				/>
				<span style={{ marginLeft: '2rem', width: '100%' }}>Get Images From URL</span>
				<Input
					type="text"
					placeholder="Post your url to grab image"
					className="get-images-from-url-modal-body-input"
					value={inputURLValue}
					onChange={event => changeURLValue(event.target.value)}
					autoFocus
				/>
			</ModalHeader>
			{isDataLoading && <Loader />}
			<ModalBody
				className="get-images-from-url-modal-body"
				style={isDataLoading ? { opacity: 0.3 } : { opacity: 1 }}
			>
				{errorMessage &&
						<Alert
							color="danger"
							className="get-images-from-url-modal-body-alert"
						>{errorMessage}
						</Alert>}
				<div className="image-list-wrap">
					{!isDataLoading && imageSrcList && imageSrcList.length ?
						imageSrcList.map((imageObj, index) => (
							<Fragment key={imageObj.image}>
								<div
									alt="pick-from-open-graph"
									className={`image-list-item ${selectedImages.includes(imageObj.image) ? 'active' : ''}`}
									style={{ backgroundImage: `url(${imageObj.image})` }}
									onClick={() => chooseImage(imageObj.image)}
									data-tip={imageObj.link}
									data-for={`get-url-img-${index}`}
								/>
								<ReactTooltip
									id={`get-url-img-${index}`}
									place="top"
									effect="solid"
								/>
							</Fragment>
						)) : null}
					{!isDataLoading && imageSrcList && !imageSrcList.length ?
						<div className="no-images">
							<img src={artBoard} width={261} height={131} className="art-board" alt="art-board" />
							Images not found.
						</div> : null}
				</div>
			</ModalBody>
			<ModalFooter className="get-images-from-url-modal-footer">
				<Button
					color="light"
					className="get-images-from-url-modal-footer-button"
					onClick={handleGetImagesByURLClick}
					disabled={!inputURLValue || !!errorMessage || isDataLoading}
				>
					Get Images
				</Button>
				<Button
					color="light"
					className="get-images-from-url-modal-footer-button"
					onClick={addImageToPost}
					disabled={!!errorMessage || isDataLoading || selectedImages.length <= 0}
				>
					Select Images ({alreadySelectedImageLength})
				</Button>
				{imageSrcList && imageSrcList.length > 0 &&
				<Fragment>
					<img
						src={downloadFile}
						alt="download-file"
						className="download-file-button"
						data-tip="Download Image"
						data-for="get-url-img-download"
						onClick={handleDownloadImages}
					/>
					<ReactTooltip
						place="right"
						effect="solid"
						id="get-url-img-download"
					/>
				</Fragment>}
			</ModalFooter>
		</Modal>);
};

GetImagesFromUrlModal.defaultProps = {
	images: [],
	maxFilesCount: 10,
};

GetImagesFromUrlModal.propTypes = {
	isModalOpen: PropTypes.bool.isRequired,
	changeGetImagesFromURLModalVisibility: PropTypes.func.isRequired,
	addedImageToPost: PropTypes.func.isRequired,
	images: PropTypes.arrayOf(PropTypes.string),
	maxFilesCount: PropTypes.number,
};

export default GetImagesFromUrlModal;
