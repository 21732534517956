/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, May 2020
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import './RemindToInstallWoofyAppModal.css';

import appStoreIcon from '../../../images/appStoreIcon.jpg';
import googlePlayIcon from '../../../images/googlePlayIcon.png';

const RemindToInstallWoofyAppModal = ({ isRemindToInstallWoofyAppModalOpen, changeRemindToInstallWoofyAppModalVisibility }) => {
	const handleClickNotNeedToRemindButton = () => {
		const userNotNeedToRemind = localStorage.getItem('needToRemindInstallMobileApp');
		if (!userNotNeedToRemind) {
			localStorage.setItem('needToRemindInstallMobileApp', 'notNeedToRemind');
		}
		changeRemindToInstallWoofyAppModalVisibility(false);
	};

	return (
		<Modal isOpen={isRemindToInstallWoofyAppModalOpen} id="remind-to-install-woofy-app-modal">
			<ModalBody className="remind-to-install-woofy-app-modal-body">
				<span>In order to post to Facebook Profile/Instagram/TikTok/Snapchat/WhatsApp, you’ll need to download the HelloWoofy mobile app.
					You’ll receive reminders here to post as per the terms of service of these social networks.
				</span>
				<p className="remind-to-install-woofy-app-modal-body-link">
					<a
						href="https://apps.apple.com/app/woofy-scheduler/id1460231447"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={appStoreIcon} alt="Download the Woofy Apple app" className="apple-icon" />
					</a>
					<a
						className="upload-button-woofy"
						href="https://play.google.com/store/apps/details?id=com.woofy.hellowoofy&fbclid=IwAR2cNrjyFcHsQcg0EaPWYW9bWZyJSOV-NjElkbRkaQCTQhazqcHflTsmB4Q"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src={googlePlayIcon}
							alt="Download the Woofy Android app"
							className="android-icon"
						/>
					</a>
				</p>
			</ModalBody>
			<ModalFooter className="remind-to-install-woofy-app-modal-footer">
				<Button
					color="light"
					className="remind-to-install-woofy-app-modal-footer-button"
					onClick={handleClickNotNeedToRemindButton}
				>Don&apos;t Show This Again
				</Button>
				<Button
					color="primary"
					className="remind-to-install-woofy-app-modal-footer-button"
					onClick={() => changeRemindToInstallWoofyAppModalVisibility(false)}
				>OK
				</Button>
			</ModalFooter>
		</Modal>
	);
};

RemindToInstallWoofyAppModal.propTypes = {
	isRemindToInstallWoofyAppModalOpen: PropTypes.bool.isRequired,
	changeRemindToInstallWoofyAppModalVisibility: PropTypes.func.isRequired,
};

export default RemindToInstallWoofyAppModal;
