/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, November 2020
 */

import React, { useState, useEffect, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import * as selectors from '../../../../selectors';

import AppCarousel from '../../../../common/components/AppCarousel/AppCarousel';
import UTMParamsForm from '../UTMParamsForm/UTMParamsForm';
import {
	UserSubscriptionLimitsContext,
	UserSubscriptionService,
} from '../../../../components/UserSubscriptions/UserSubscriptionService';

import { getCurrentUser } from '../../../../actions/user';
import { subsErrorReasons } from '../../../../constants/subscriptionValidation';

import './AddUTMParamsToLinkModal.css';

const AddUTMParamsToLinkModal = ({
	isModalOpen, changedAddUTMModalVisibility, arrayOfUrls, changedLinkToLinkWithUTMParams, getCurrentUserAction, user: { response: user } = {},
}) => {
	const { setIsUpgradeModalShown, setUpgradeModalMetadata } = useContext(UserSubscriptionLimitsContext);
	const [isUTMParamsFormVisible, changeUTMParamsFormVisibility] = useState(false);
	const [currentItemIndex, changeCurrentItemIndex] = useState(0);

	useEffect(() => {
		getCurrentUserAction(true);
	}, []);
	const closeModal = () => {
		changedAddUTMModalVisibility(false);
	};

	const cancelButtonClick = () => {
		closeModal();
	};

	const applyButtonClick = () => {
		const isUserHaveSubscription = UserSubscriptionService.isUserHaveSubscription(user);
		if (isUserHaveSubscription) {
			changeUTMParamsFormVisibility(true);
		} else {
			setUpgradeModalMetadata({ reason: subsErrorReasons.no_subscription });
			setIsUpgradeModalShown(true);
		}
	};

	return (
		<Fragment>
			<Modal
				isOpen={isModalOpen}
				className="add-utm-params-modal"
				centered
			>
				<ModalHeader toggle={closeModal}>Select URL for add UTM parameters</ModalHeader>
				<ModalBody className="add-utm-params-modal-body">
					{isUTMParamsFormVisible ?
						<UTMParamsForm
							link={arrayOfUrls[currentItemIndex]}
							changeUTMParamsFormVisibility={changeUTMParamsFormVisibility}
							changedLinkToLinkWithUTMParams={changedLinkToLinkWithUTMParams}
						/> :
						<AppCarousel
							carouselItems={arrayOfUrls}
							changeCurrentItemIndex={changeCurrentItemIndex}
						/>}
				</ModalBody>
				{!isUTMParamsFormVisible &&
			<ModalFooter className="add-utm-params-modal-footer">
				<Button
					color="light"
					onClick={cancelButtonClick}
					className="add-utm-params-modal-footer-button"
				>CANCEL
				</Button>
				<Button
					color="primary"
					onClick={applyButtonClick}
					className="add-utm-params-modal-footer-button"
				>ADD UTM
				</Button>
			</ModalFooter>}
			</Modal>
		</Fragment>
	);
};


AddUTMParamsToLinkModal.propTypes = {
	isModalOpen: PropTypes.bool.isRequired,
	changedAddUTMModalVisibility: PropTypes.func.isRequired,
	arrayOfUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
	changedLinkToLinkWithUTMParams: PropTypes.func.isRequired,
	getCurrentUserAction: PropTypes.func.isRequired,
	user: PropTypes.shape({}).isRequired,
	history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
	user: selectors.getCurrentUser(state),
});

const mapDispatchToProps = dispatch => ({
	getCurrentUserAction: bindActionCreators(getCurrentUser, dispatch),
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(AddUTMParamsToLinkModal);
