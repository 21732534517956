/* eslint-disable jsx-a11y/media-has-caption */
/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

import React, { useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import ImageUploading from 'react-images-uploading';

import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Loader from '../LoaderAnimation/Loader';
import './VideoThumbnailCaptureModal.css';
import { uploadPreSignedImage } from '../../../components/Calendar/CalendarImageUploader/CalendarImageUploader.action';
import crossIcon from '../../../icons/Cross_red_circle.svg';

const VideoThumbnailCaptureModal = ({
	videoUrl,
	imageMaxSize,
	setThumbnailImage,
	changeVideoThumbnailCaptureModalOpenVisibility,
	isVideoThumbnailCaptureModalOpen,
	cancelVideoThumbnailCaptureHandler,
}) => {
	const videoRef = useRef(null);
	const canvasRef = useRef(null);
	const [videoWidth, setVideoWidth] = useState(0);
	const [videoHeight, setVideoHeight] = useState(0);
	const [errorMessage, setErrorMessage] = useState('');
	const [isDataLoading, setDataLoading] = useState(false);
	const [duration, setDuration] = useState(0);
	const [is4KVideo, setIs4KVideo] = useState(false);
	const [chooseVideoThumb, setChooseVideoThumb] = useState(false);
	const [chooseImageThumb, setChooseImageThumb] = useState(false);
	const [images, setImages] = React.useState([]);
	const maxImagesNumber = 1;

	const onImageChange = (imageList) => {
		setErrorMessage('');
		setImages(imageList);
		setChooseImageThumb(true);
	};

	const closeModal = () => {
		changeVideoThumbnailCaptureModalOpenVisibility(false);
	};

	const onCancel = () => cancelVideoThumbnailCaptureHandler();

	const onLoadedMetadata = () => {
		canvasRef.current.width = videoRef.current.videoWidth;
		canvasRef.current.height = videoRef.current.videoHeight;
		setVideoWidth(videoRef.current.videoWidth);
		setVideoHeight(videoRef.current.videoHeight);
		setDuration(videoRef.current.duration);
		setIs4KVideo(videoRef.current.videoWidth > 1920);
	};

	const onCrossClick = () => {
		setImages([]);
		setErrorMessage('');
		setChooseImageThumb(false);
		setChooseVideoThumb(false);
	};

	const setThumbImage = (file, videoTimeOffset) => {
		if (!file) return console.log('Not able to get thumbnail');
		const thumbnailFile = new File([file], `${videoUrl.split('.')[0]}.jpg`, { type: 'image/jpeg' });
		uploadPreSignedImage(thumbnailFile, '.jpg').then(result => {
			setThumbnailImage(result.image, duration, is4KVideo, videoTimeOffset);
			setDataLoading(false);
			closeModal();
		}).catch(() => {
			setErrorMessage('Something went wrong! Please try again!');
			setDataLoading(false);
		});
	};

	const handleUseThumbnailClick = () => {
		setErrorMessage('');
		setDataLoading(true);
		if (chooseVideoThumb) {
			const videoTimeOffset = videoRef.current.currentTime;
			canvasRef.current.getContext('2d').drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
			canvasRef.current.toBlob((blob) => setThumbImage(blob, videoTimeOffset), 'image/jpeg');
		}

		if (chooseImageThumb) {
			return fetch(images[0].data_url)
				.then(res => res.blob())
				.then(blob => setThumbImage(blob));
		}
	};

	const showChooseButtons = !chooseImageThumb && !chooseVideoThumb;
	return (
		<Modal
			isOpen={isVideoThumbnailCaptureModalOpen}
			centered
			backdrop="static"
		>
			<ModalHeader>Choose Thumbnail</ModalHeader>
			<ModalBody className="video-thumbnail-modal-body">
				{showChooseButtons && <div style={{
					display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto', width: '100%',
				}}
				>
					{isDataLoading ? <Loader /> : (<Fragment>
						<video
							id="uploadedVideo"
							ref={videoRef}
							src={videoUrl}
							crossOrigin="anonymous"
							className="capture-thumbnail-video"
							controls
							onLoadedMetadata={onLoadedMetadata}
						/>
						<canvas id="render-thumbnail" style={{ display: 'none' }} ref={canvasRef} />
					</Fragment>)}
					{errorMessage && <Alert color="danger">{errorMessage}</Alert>}
					<div style={{
						display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: 'auto', width: '100%', marginTop: '1rem',
					}}
					>
						<ImageUploading
							value={images}
							onChange={onImageChange}
							onError={(errors) => {
								console.log('Error uploading image: ', errors, videoWidth, videoHeight);
								if (errors.resolution) {
									setErrorMessage('Image Resolution doesn\'t match with video.');
								}
								if (errors.acceptType) {
									setErrorMessage('Image type not supported');
								}
								if (errors.maxNumber) {
									setErrorMessage('Only 1 image can be selected');
								}
								if (errors.maxFileSize) {
									setErrorMessage('Image file size is exceeded.');
								}
								setTimeout(() => setErrorMessage(''), 2000);
								setDataLoading(false);
							}}
							acceptType={['jpg', 'jpeg', 'png']}
							maxNumber={maxImagesNumber}
							maxFileSize={imageMaxSize}
							dataURLKey="data_url"
							resolutionType="ratio"
							resolutionWidth={videoWidth}
							resolutionHeight={videoHeight}
						>
							{({
								onImageUpload,
								dragProps,
							}) => (
								<div className="upload__image-wrapper">
									<Button
										color="light"
										className="use-thumbnail-btn"
										onClick={() => onImageUpload()}
										{...dragProps}
									>
									Choose from file
									</Button>
								</div>
							)}
						</ImageUploading>
						<div>
							<Button
								color="light"
								className="use-thumbnail-btn"
								onClick={() => setChooseVideoThumb(true)}
							>
							Choose from video
							</Button>
						</div>
					</div>
					<span style={{ fontSize: 12, color: 'red', marginTop: '10px' }}>*Custom thumbnail only works for verified YouTube account</span>
				</div>}
				{chooseImageThumb && !chooseVideoThumb &&
					<div style={{ display: 'flex', position: 'relative', minHeight: '350px' }}>
						{!isDataLoading && <img
							onClick={() => {
								onCrossClick();
							}}
							src={crossIcon}
							alt="cross"
							crossOrigin="anonymous"
							style={{
								width: 24,
								height: 24,
								position: 'absolute',
								right: -9,
								top: -9,
								cursor: 'pointer',
							}}
						/>}
						{errorMessage && <Alert color="danger">{errorMessage}</Alert>}
						{isDataLoading ? <Loader /> :
							<div
								style={{
									backgroundImage: `url(${images[0].data_url})`,
									backgroundPosition: 'left',
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									width: 460,
									height: 'auto',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
									overflow: 'hidden',
								}}
								className="image-to-text"
							/>}
					</div>
				}
				{!chooseImageThumb && chooseVideoThumb && <Fragment>
					{!isDataLoading && <img
						onClick={() => {
							onCrossClick();
						}}
						src={crossIcon}
						alt="cross"
						style={{
						  width: 24,
							height: 24,
							position: 'absolute',
							right: 15,
							top: 5,
						  cursor: 'pointer',
						}}
					/>}
					{!isDataLoading && errorMessage && <Alert color="danger">{errorMessage}</Alert>}
					{isDataLoading ? <Loader /> : (<Fragment>
						<video
							id="uploadedVideo"
							ref={videoRef}
							src={videoUrl}
							crossOrigin="anonymous"
							className="capture-thumbnail-video"
							controls
							onLoadedMetadata={onLoadedMetadata}
						/>
						<canvas id="render-thumbnail" style={{ display: 'none' }} ref={canvasRef} />
					</Fragment>)}
				</Fragment>}
			</ModalBody>
			<ModalFooter className="video-thumbnail-modal-footer">
				<Button
					color="light"
					className="use-thumbnail-btn"
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button
					color="primary"
					className="use-thumbnail-btn"
					onClick={handleUseThumbnailClick}
					disabled={isDataLoading || (!chooseVideoThumb && !chooseImageThumb)}
				>
					Use Thumbnail
				</Button>
			</ModalFooter>
		</Modal>);
};


VideoThumbnailCaptureModal.propTypes = {
	videoUrl: PropTypes.string.isRequired,
	isVideoThumbnailCaptureModalOpen: PropTypes.bool.isRequired,
	setThumbnailImage: PropTypes.func.isRequired,
	changeVideoThumbnailCaptureModalOpenVisibility: PropTypes.func.isRequired,
	cancelVideoThumbnailCaptureHandler: PropTypes.func.isRequired,
};

export default VideoThumbnailCaptureModal;
