/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { post, get, put } from '../../httpRequests';
import {
	GET_BANNER_BEAR_USAGE,
	IMAGE_SUGGESTIONS_URL,
	GET_ALL_BANNER_BEAR_TEMPLATES,
	POST_CRETAE_IMAGE_FROM_BANNER_BEAR_TEMPLATE,
	GET_IMAGE_FROM_BANNER_BEAR,
} from '../../apiRouting';

export function getImageSuggestions(text, controller) {
	return post(IMAGE_SUGGESTIONS_URL, text, true, controller)
		.then(res => res.json());
}

export function getAllBannerBearTemplates(search, tag) {
	const url = `${GET_ALL_BANNER_BEAR_TEMPLATES}?name=${search || ''}&tag=${tag || ''}`;
	console.log('tagtagtag1', tag, search);

	return get(url, true)
		.then(res => res.json());
}

export function creatBannerBearImageFromTemplate(data) {
	return post(POST_CRETAE_IMAGE_FROM_BANNER_BEAR_TEMPLATE, data, true)
		.then(res => res.json());
}

export function getImageFromBannerBear(id) {
	return get(`${GET_IMAGE_FROM_BANNER_BEAR}/${id}`, true)
		.then(res => res.json());
}

export function updateBannerBearUsage() {
	return put(GET_BANNER_BEAR_USAGE, {}, true)
		.then(res => res.json());
}

export function getBannerBearUsage() {
	return get(GET_BANNER_BEAR_USAGE, true)
		.then(res => res.json());
}
