import { Grid, IconButton, Modal, Container, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './BannerBearEditModal.css';
import { getBannerBearUsage, updateBannerBearUsage } from '../../common/components/ImageSuggestion/ImageSuggestion.action';


const BannerBearEditModal = (props) => {
	const {
		open, handleClose, template, finalizeBannerBearImage, hanldeGenerateImageFromBannerBear, curruntTemplateImage, handleUploadTemporaryImage,
	} = props;
	const [tempImgLoader, setTempImgLoader] = useState(false);
	const [postLoader, setPostLoader] = useState(false);
	const [curruntTemplate, setCurruntTemplate] = useState({});
	const [modifications, setModifications] = useState([]);
	const [previewImg, setPreviewImg] = useState('');
	const [finalBtn, setFinalBtn] = useState(true);
	const [hoverEffect, setHoverEffect] = useState(false);
	const [showUpdateBtn, setShowUpdateBtn] = useState(false);
	const [showPostBtn, setShowPostBtn] = useState(false);
	const [creditInfo, setCreditInfo] = useState(0);

	useEffect(() => {
		getBannerBearUsage().then(usageData => {
			setCreditInfo(usageData);
		});
	}, []);

	// handle image change on the based on updated image from bannerbear
	useEffect(() => {
		setPreviewImg(curruntTemplateImage);
	}, [curruntTemplateImage]);

	// update the template based on the change of anything in template to preview live image beside
	useEffect(() => {
		setCurruntTemplate(template);
	}, [template]);

	// update the template based on the change of anything in template to preview live image beside
	useEffect(() => {
		setCurruntTemplate(template);
	}, [modifications]);

	const handleHoverEffect = () => {
		setHoverEffect(true);
		setTimeout(() => {
			setHoverEffect(false);
		}, 1000);
	};

	const backToDefault = () => {
		setTempImgLoader(false);
		setPostLoader(false);
		setFinalBtn(true);
		setShowPostBtn(false);
		setShowUpdateBtn(false);
	};

	// handle image to use for post
	const handleUseToPost = () => {
		setPostLoader(true);
		finalizeBannerBearImage();
		setFinalBtn(false);
		setPostLoader(false);
	};

	// updating the modification
	useEffect(() => {
		setModifications(curruntTemplate?.available_modifications?.map(temp => {
			if ('image_url' in temp) {
				return {
					name: temp.name,
					image_url: '',
				};
			}
			return {
				name: temp.name,
				text: '',
			};
		}));
	}, [curruntTemplate]);

	// handling the change in the text for input field type text
	const handleChangeText = (e, name) => {
		const updateModification = modifications?.map(ele => {
			// eslint-disable-next-line no-prototype-builtins
			if (ele.name === name) {
				ele.text = e.target.value;
				return ele;
			}
			return ele;
		});
		setModifications(updateModification);
		setCurruntTemplate(template);
		setShowUpdateBtn(true);
		handleHoverEffect();
	};


	// handle indivisual file uploads
	const handleFile = (e, name) => {
		setShowPostBtn(false);
		setTempImgLoader(true);
		handleUploadTemporaryImage(e.target.files[0])
			.then(response => {
				const updateModification = modifications?.map(ele => {
					// eslint-disable-next-line no-prototype-builtins
					if (ele.name === name) {
						ele.image_url = response;
						return ele;
					}
					return ele;
				});
				setModifications(updateModification);
				setFinalBtn(false);
				setTempImgLoader(false);
				setShowUpdateBtn(true);
				handleHoverEffect();
				setShowPostBtn(true);
			});
	};

	// handle generate image from the bannerbear by passing the modifications
	const handleSubmit = async (e) => {
		setTempImgLoader(true);
		setShowPostBtn(false);
		setFinalBtn(true);
		setShowPostBtn(false);
		const handleEnable = () => {
			setFinalBtn(false);
			setTempImgLoader(false);
			setShowPostBtn(true);
			setShowUpdateBtn(false);
		};
		e.preventDefault();
		hanldeGenerateImageFromBannerBear(curruntTemplate?.uid, modifications, handleEnable);
		updateBannerBearUsage().then(usageData => {
			setCreditInfo(usageData);
		});
	};
	const nameFormat = (name) => name.split('_').join(' ');
	return (
		<Modal
			open={open}
			// handleClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className="bear_modal"
		>
			<div className="bear_modal_inner" >
				<Container>
					<div className="bear_modal_header">
						<div className="bear_title">
							Edit Template and Create Image
						</div>
						<div style={{ marginLeft: '2rem' }}>
							Credits Used This Month: {creditInfo.creditsUsed}/{creditInfo.creditLimit}
						</div>
						<div className="bear_close_icon">
							<IconButton onClick={() => {
								backToDefault();
								handleClose();
							}}
							>
								<CloseIcon />
							</IconButton>
						</div>
					</div>

					<div className="bear_modal_body">
						<Grid container spacing={2} >
							<Grid item xs={4} >
								<div className="bear_input">
									{
										curruntTemplate?.current_defaults?.map(ele => (
											<>
												{
													ele?.type === 'text_container' && <div className="bear_edit_section" >
														<div className="bear_edit_text">
															<div className="banner_label">
																{nameFormat(ele.name)}
															</div>
															<div className="banner_input_sec">
																<input type="text" name={ele.name} onChange={(e) => handleChangeText(e, ele.name)} placeholder={ele.text} />
															</div>
														</div>
													</div>
												}
												{
													ele?.type === 'image_container_circle' && <div className="bear_edit_section" >

														<div className="bear_edit_text">
															<div className="banner_label">
																{nameFormat(ele.name)}
															</div>
															<div className="bear_circle_img_container" style={{ background: `url(${modifications?.find(mod => mod.name === ele.name)?.image_url?.length > 0 ? modifications?.find(mod => mod.name === ele.name)?.image_url : ele?.image_url})` }} />
															<div className="banner_input_sec">
																<input className="banner_input_field" type="file" onChange={(e) => handleFile(e, ele.name)} accept="image/*" id={ele.name} />
															</div>
														</div>
													</div>
												}
												{
													ele?.type === 'image_container_rectangle' && <div className="bear_edit_section" >

														<div className="bear_edit_text">
															<div className="banner_label">
																{nameFormat(ele.name)}
															</div>
															<div className="bear_rect_img_container" style={{ background: `url(${modifications?.find(mod => mod.name === ele.name)?.image_url?.length > 0 ? modifications?.find(mod => mod.name === ele.name)?.image_url : ele?.image_url})` }} />
															<div className="banner_input_sec">
																<input className="banner_input_field" type="file" onChange={(e) => handleFile(e, ele.name)} accept="image/*" id={ele.name} />
															</div>
														</div>
													</div>
												}
											</>
										))
									}

								</div>

							</Grid>
							<Grid item xs={8} >
								<div
									className="bear_preview"
									style={hoverEffect ? { background: 'rgba(128,128,128, 0.3)' } : {}}
								>
									<img src={previewImg} alt={curruntTemplate?.name} />
									<div className="overley_sec" />
								</div>
								<div className="button-sets">
									<div className="button-left">
										<div className="bear_submit">
											{
												tempImgLoader ? (
													<button style={{ paddingBottom: '0.5rem' }}><CircularProgress style={{ padding: 10 }} /></button>
												) : (
													<>{showUpdateBtn && <button
														style={hoverEffect ? { boxShadow: '0 0 20px 5px #48abe0' } : {}}
														onClick={handleSubmit}
													>Update</button>}</>
												)
											}
										</div>
									</div>
									<div className="button-right">
										<div className=" bear_submit bear_submit_footer">
											{
												postLoader ? <button style={{ paddingBottom: '0.5rem' }}><CircularProgress style={{ padding: 10 }} /></button>
													:
													<>{showPostBtn && <button id="post-btn" onClick={handleUseToPost} disabled={finalBtn} >Use this to post</button>}</>
											}
										</div>
									</div>
								</div>
								{ (showUpdateBtn || showPostBtn) && <div style={{
									color: 'red',
									fontSize: 16,
									textAlign: 'center',
    								marginTop: '1rem',
									width: '50%',
									marginInline: 'auto',
								}}
								>*Please note that every click of Update will count towards your monthly credit</div>}
							</Grid>
						</Grid>
					</div>
				</Container>
			</div>
		</Modal>
	);
};

export default BannerBearEditModal;
