import React, { useState } from 'react';
import {
	Container,
	FormGroup,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Grid,
	Alert,
	Checkbox,
} from '@mui/material';
import {
	Button,
} from 'reactstrap';
import './CaptionGenerationModal.css';
import Dialog from '../../componentsNew/Dialog/Dialog';
import { chatGptCaptionGeneration } from '../../components/Calendar/CalendarPostModal/CalendarPostModal.action';


const CaptionGenerationModal = (props) => {
	const {
		open, handleClose, handleAppendCaption, isConfirmButtonLoading = false, isShortPost = true,
	} = props;
	const [errorMessage, setErrorMessage] = useState('');
	const [platform, setPlatform] = useState(isShortPost ? 'instagram' : 'medium');
	const [tone, setTone] = useState('funny');
	const [wordCount, setWordCount] = useState(isShortPost ? '10' : '100');
	const [variant, setVariant] = useState('1');
	const [text, setText] = useState('');
	const [varientResponse, setVarientResponse] = useState([]);
	const [addMidJourneyPrompt, setAddMidJourneyPrompt] = useState(false);
	const handleSelectPlatform = (event) => {
		setPlatform(event.target.value);
	};
	const handleSelectTone = (event) => {
		setTone(event.target.value);
	};

	const handleGenerateCaptionFn = () => {
		const postBody = {
			platform,
			tone,
			wordCount,
			text,
			variant,
			addMidJourneyPrompt,
		};
		setErrorMessage('');
		chatGptCaptionGeneration(postBody)
			.then((response) => {
				setVarientResponse(response);
			})
			.catch((err) => {
				console.log('\n\n\nerr', err);
				setErrorMessage(err.message);
			});
	};

	const handleUseVarient = (textValue) => {
		handleAppendCaption(textValue);
		setPlatform('instagram');
		setTone('funny');
		setWordCount('');
		setVariant('1');
		setText('');
		setVarientResponse([]);
		setAddMidJourneyPrompt(false);
		handleClose();
	};

	let wordCountAllowed = [10, 25, 50, 100];
	let platforms = {
		facebook: 'Facebook',
		instagram: 'Instagram',
		linkedin: 'LinkedIn',
	};
	if (!isShortPost) {
		wordCountAllowed = [100, 250, 500, 750];
		platforms = {
			medium: 'Medium',
			wordpress: 'Wordpress',
			blog: 'Blog',
		};
	}

	return (
		<Dialog
			open={open}
			handleClose={() => {
				if (!isConfirmButtonLoading) {
					handleClose();
				}
			}}
			title="Generate Caption"
			className="caption_generation_modal"
		>
			<div className="caption_generation_section">
				<Container>
					{errorMessage && <Alert severity="error">{errorMessage}</Alert>}
					<div className="caption_form">
						<FormGroup>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={3}>
									<div className="caption_section">
										<FormControl fullWidth>
											<InputLabel id="platform-label">Select Platform</InputLabel>
											<Select
												labelId="platform-label"
												className="form-control"
												id="platform"
												value={platform}
												label="Select Platform"
												onChange={handleSelectPlatform}
											>
												{Object.keys(platforms).map(key => <MenuItem value={key}>{platforms[key]}</MenuItem>)}
											</Select>
										</FormControl>
									</div>
								</Grid>
								<Grid item xs={12} sm={3}>
									<div className="caption_section">
										<FormControl fullWidth>
											<InputLabel id="tone-label">Select Tone</InputLabel>
											<Select
												className="form-control"
												labelId="tone-label"
												id="tone"
												value={tone}
												label="Select Tone"
												onChange={handleSelectTone}
											>
												<MenuItem value="professional">Professional</MenuItem>
												<MenuItem value="funny">Funny</MenuItem>
												<MenuItem value="sad">Sad</MenuItem>
												<MenuItem value="happy">Happy</MenuItem>
												<MenuItem value="fear">Fear</MenuItem>
											</Select>
										</FormControl>
									</div>
								</Grid>
								<Grid item xs={12} sm={3}>
									<div className="caption_section">
										<FormControl fullWidth>
											<InputLabel id="varient-label">Total Variations</InputLabel>
											<Select
												labelId="varient-label"
												id="varient"
												className="form-control"
												value={variant}
												label="Total Variations"
												onChange={(e) => setVariant(e.target.value)}
											>
												<MenuItem value="1">1</MenuItem>
												<MenuItem value="2">2</MenuItem>
												<MenuItem value="3">3</MenuItem>
												<MenuItem value="4">4</MenuItem>
												<MenuItem value="5">5</MenuItem>
											</Select>
										</FormControl>
									</div>
								</Grid>
								<Grid item xs={12} sm={3}>
									<div className="caption_section">
										<FormControl fullWidth>
											<InputLabel id="word-count-label">Words</InputLabel>
											<Select
												labelId="word-count-label"
												id="word-count"
												className="form-control"
												value={wordCount}
												label="Words"
												onChange={(e) => setWordCount(e.target.value)}
											>
												{wordCountAllowed.map(count => <MenuItem value={count}>{count}</MenuItem>)}
											</Select>
										</FormControl>
									</div>
								</Grid>
								<Grid item xs={1} sm={1} xl={1} style={{ padding: 0 }}>
									<div className="caption_section">
										<FormControl fullWidth>
											<Checkbox
												labelId="add-midjourney-prompt-label"
												id="add-midjourney-prompt"
												title="Add MidJourney Prompt?"
												className="form-control"
												checked={addMidJourneyPrompt}
												onChange={() => setAddMidJourneyPrompt(!addMidJourneyPrompt)}
											/>
										</FormControl>
									</div>
								</Grid>
								<Grid
									item
									xs={4}
									sm={4}
									xl={4}
									style={{
										display: 'flex', alignItems: 'center', padding: 0, paddingTop: '0.5rem',
									}}
								>
									<InputLabel id="add-midjourney-prompt-label">Add MidJourney Prompt?</InputLabel>
								</Grid>
								<Grid item xs={12} sm={12}>
									<div className="caption_section">
										<FormControl fullWidth>
											<input
												type="text"
												value={text}
												className="form-control"
												placeholder="Enter Topic"
												onChange={(event) => setText(event.target.value)}
											/>
										</FormControl>
									</div>
								</Grid>
							</Grid>

							<div className="responses_section">
								{varientResponse?.map((ele) => (
									<div className="response_item">
										<div className="response_text">{ele?.message?.content}</div>
										<div className="response_btn">
											<Button
												variant="text"
												color="primary"
												onClick={() => handleUseVarient(ele?.message?.content)}
											>
                    							Use This
											</Button>
										</div>
									</div>
								))}
							</div>

							<div className="submit-buttons">
								<div>
									<Button
										onClick={handleClose}
										text="Cancel"
										variant="text"
										color="light"
										style={{
											margin: '0 16px',
											width: 130,
											height: 40,
										}}
									>Cancel</Button>
								</div>
								<div>
									<Button
										text="Generate"
										variant="text"
										color="primary"
										style={{
											margin: '0 16px',
											width: 130,
											height: 40,
										}}
										onClick={handleGenerateCaptionFn}
									>Generate</Button>
								</div>
							</div>
						</FormGroup>
					</div>
				</Container>
			</div>
		</Dialog>
	);
};

export default CaptionGenerationModal;
