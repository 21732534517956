/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import * as requestActionTypes from '../constants/requestActionTypes';
import { get } from './_common/request';
import { loadingKey, failKey, successKey } from '../helpers/store';

export const getExtensionSettings = (silent = false) => (dispatch) => {
	const requestType = requestActionTypes.GET_EXTENSION_SETTINGS;

	if (!silent) {
		dispatch({ type: loadingKey(requestType.key) });
	}
	get(requestType)
		.then((response) => {
			dispatch({ type: successKey(requestType.key), payload: response });
		})
		.catch((err) => {
			const msg = typeof err === 'string' ? { message: err } : err;
			dispatch({ type: failKey(requestType.key), payload: msg });
		});
};
