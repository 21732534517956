import _ from 'lodash';

import { agencyPlan, smbPlan, proPlan, singlePlan, agencyPlusPlan } from '../constants/subscriptionTypes';
import { subsActions, subsErrorReasons } from '../constants/subscriptionValidation';
import { UserSubscriptionService } from '../components/UserSubscriptions/UserSubscriptionService';

export const isSmbSubscription = plan => smbPlan.includes(plan);
export const isAgencySubscription = plan => agencyPlan.includes(plan);
export const isProSubscription = plan => proPlan.includes(plan);
export const isSingleSubscription = plan => singlePlan.includes(plan);
export const isAgencyPlusSubscription = plan => agencyPlusPlan.includes(plan);

const upgradeToEssentialsOrHigher = 'Upgrade your Plan to Essentials and get more with Woofy higher Subscription plan!';
const upgradeToAdvancedOrHigher = 'Upgrade your Plan to Advanced and get more with Woofy higher Subscription plan!';
const upgradeToBusinessOrHigher = 'Upgrade your Plan to Business and get more with Woofy higher Subscription plan!';

export const getReasonUpgradeMessage = (user, props = {}) => {
	const { action, reason, amount } = props;
	let message = '';
	if (!user) return;
	const currentPlan = UserSubscriptionService.getUserSubscriptionPlan(user);
	const planName = _.capitalize(currentPlan);

	switch (reason) {
	case subsErrorReasons.limit_exceed:
		message = `Due to limits of your current plan, ${planName}, you have reached your limits of {amount} {action}.`;
		break;
	case subsErrorReasons.no_access:
		message = `Due to limits of your current plan, ${planName}, you don't have access to {action}.`;
		break;
	default:
		break;
	}
	switch (action) {
	case subsActions.socialMediaAccounts:
		message = message.replace('{action}', 'social media accounts');
		break;
	case subsActions.calendars:
		message = message.replace('{action}', 'calendars');
		break;
	case subsActions.hashTagList:
		message = message.replace('{action}', 'use hashtag list');
		break;
	case subsActions.importImages:
		message = message.replace('{action}', 'import images');
		break;
	case subsActions.addingVideoToLinked:
		message = message.replace('{action}', 'add video to LinkedIn');
		break;
	case subsActions.advancedArticleSummarizer:
		message = message.replace('{action}', 'advanced article Summarizer');
		break;
	case subsActions.advancedDiscovery:
		message = message.replace('{action}', 'more articles');
		break;
	case subsActions.journalArticlePosts:
		message = message.replace('{action}', 'use journal');
		break;
	case subsActions.pinterestBoard:
		message = message.replace('{action}', 'Pinterest boards');
		break;
	case subsActions.libraryPosts:
		message = message.replace('{action}', 'Library posts');
		break;
	default:
		message = message.replace('{action}', 'this feature');
		break;
	}
	if (amount) {
		message = message.replace('{amount}', amount);
	}

	return message;
};

export const upgradeToPlanMessage = (user, limits, props = {}) => {
	const nextPlan = UserSubscriptionService.getUserNextUpgradeSubscriptionInfo(user);
	const { action } = props;
	let newAmount = 0;

	if (nextPlan) {
		const planName = _.capitalize(nextPlan.planName);
		switch (action) {
		case subsActions.calendars:
			newAmount = UserSubscriptionService.getNewPlanItemLimit(nextPlan.plan, limits, 'calendars');
			return `If you upgrade to ${planName}, you can have up to ${newAmount} calendars`;
		case subsActions.socialMediaAccounts:
			newAmount = UserSubscriptionService.getNewPlanItemLimit(nextPlan.plan, limits, 'socialMediaAccounts');
			return `If you upgrade to ${planName}, you can have up to ${newAmount} social media accounts`;
		case subsActions.addingVideoToLinked:
			return 'If you upgrade to Basic or higher plans, you will be able to add video to linked in';
		case subsActions.advancedArticleSummarizer:
			return upgradeToAdvancedOrHigher;
		case subsActions.googleMB:
			return upgradeToEssentialsOrHigher;
		case subsActions.pinterest:
			return upgradeToEssentialsOrHigher;
		case subsActions.snapchat:
			return upgradeToEssentialsOrHigher;
		case subsActions.wix:
			return upgradeToEssentialsOrHigher;
		case subsActions.squarespace:
			return upgradeToEssentialsOrHigher;
		case subsActions.whatsapp:
			return upgradeToAdvancedOrHigher;
		case subsActions.mailchimp:
			return upgradeToAdvancedOrHigher;
		case subsActions.youtube:
			return upgradeToAdvancedOrHigher;
		case subsActions.bannerBearImagesPerMonth:
			return upgradeToBusinessOrHigher;
		case subsActions.threads:
			return upgradeToAdvancedOrHigher;
		default:
			break;
		}

		return `Upgrade your Plan to ${planName} and get more with Woofy higher Subscription plan!`;
	}
};
