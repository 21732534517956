/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { combineReducers } from 'redux';
import user from './user';
import discount from './discount';
import calendar from './calendar';
import settings from './settings';
import notification from './notification';
import accounts from './accounts';
import config from './config';
import post from './post';
import extensionSettings from './extensionSettings';
import googleService from './googleService';
import journal from './journal';

const reducers = {
	user,
	discount,
	calendar,
	config,
	settings,
	notification,
	accounts,
	post,
	extensionSettings,
	googleService,
	journal,
};

export default combineReducers(reducers);
