/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

import './LibrarySimilarityModal.css';

export default class LibrarySimilarityModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};
		this.onToggle = this.onToggle.bind(this);
	}

	onToggle() {
		this.props.toggle(this.state.isOpen);
	}

	onProceed = () => {
		const { onPostCreate, createdPost } = this.props;
		const complianceEngineRules = {
			complianceEngineRules: {
				ignoreCampaignCheck: false,
				ignoreComplianceCheck: false,
				fixCampaignConflicts: true,
			},
		};
		if (onPostCreate) {
			onPostCreate({ ...createdPost, ...complianceEngineRules });
		}
	};

	getConflictingCampaignsNamesString = (campaignsList = [], campaignsIds = []) => {
		if (!campaignsList.length || !campaignsIds.length) {
			return '';
		}
		const filteredCampaigns = campaignsList.filter((campaign) =>
			campaignsIds.includes(campaign._id));
		const campaignsNames = filteredCampaigns.map((campaign) => campaign.name);
		return campaignsNames.join(', ');
	};

	render() {
		const { libraryPost, conflictingCampaignsIds } = this.props;
		return (
			<Modal
				isOpen={this.props.isOpen}
				toggle={this.onToggle}
				centered
				className="library-similarity-modal"
			>
				<ModalBody>
					{libraryPost && libraryPost.campaigns && conflictingCampaignsIds &&
					<div className="library-similarity-modal-message">
						If you create a single post using this content,	it will not be possible to use this
						content in the campaign(s):
						<br />
						<div>
							{this.getConflictingCampaignsNamesString(
								libraryPost.campaigns,
								conflictingCampaignsIds,
							)}
						</div>
						<p className="library-similarity-modal-suggestion">
							Do you want to proceed anyways and skip this content in the campaign
							or select another content for creating a single post?
						</p>
					</div>}
					<div className="library-similarity-modal-buttons">
						<Button
							className="library-similarity-modal-button library-similarity-modal-cancel"
							color="light"
							onClick={this.onToggle}
						>Select another
						</Button>
						<Button
							className="library-similarity-modal-button library-similarity-modal-proceed"
							color="primary"
							onClick={this.onProceed}
						>Proceed
						</Button>
					</div>
				</ModalBody>
			</Modal>
		);
	}
}
