export function escapeRegExp(text) {
	return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const autocompleteStrategy = (
	contentBlock,
	callback,
) => {
	const text = contentBlock.getText();
	callback(0, text.length);
};

export default autocompleteStrategy;
