/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

export default class ComingSoonModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};
	}

	onToggle = () => {
		this.props.toggle(this.state.isOpen);
	};

	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				toggle={this.onToggle}
				centered
			>
				<ModalBody>
					<div className="coming-soon">
						Coming Soon
					</div>
				</ModalBody>
			</Modal>
		);
	}
}
