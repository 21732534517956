/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, November 2020
 */

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as selectors from '../../../selectors';

import AddUTMParamsToLinkModal from './AddUTMParamsToLinkModal/AddUTMParamsToLinkModal';
import { clickable } from '../../../helpers/makeElementClicable';

import getArrayOfUrlsFromText from '../../../helpers/getArrayOfUrlsFromText';

import { getSettings } from '../../../actions/settings';

import greenRoundCheckMark from '../../../images/greenRoundCheckMark.svg';

import './AddUTMParamsToLink.css';

const alreadyExistingUTMParamsText =
	(<div className="add-utm-to-link-utm-params-detect">
		<img src={greenRoundCheckMark} alt="check-mark" className="check-mark" />
		<span>UTM Parameters Detected.</span>
	 </div>);

const AddUTMParamsToLink = ({
	title, messageText, changedLinkToLinkWithUTMParams, settings, getSettingsAction,
}) => {
	const [needToShowUTM, changedNeedToShowUTMValue] = useState(false);
	const [isAddUTMModalOpen, changedAddUTMModalVisibility] = useState(false);
	const [arrayOfUrls, changedArrayOfUrlsValues] = useState([]);
	const [isUTMParamsExist, changedUTMParamsExistValue] = useState(false);

	const resetState = () => {
		changedNeedToShowUTMValue(false);
		changedAddUTMModalVisibility(false);
		changedUTMParamsExistValue(false);
	};

	useEffect(() => {
		getSettingsAction(true);
	}, []);

	useEffect(() => {
		const { response: { utmDetectionActivated } = {} } = settings;
		if (messageText.trim()) {
			const arrayUrls = getArrayOfUrlsFromText(messageText);
			if (arrayUrls.length) {
				const isUTMAlreadyAdded = arrayUrls.some(url => url.includes('utm_source='));
				if (isUTMAlreadyAdded) {
					changedUTMParamsExistValue(true);
				}
				const arrayOfUrlWithoutUTM = arrayUrls.filter(url => !url.includes('utm_source='));
				if (arrayOfUrlWithoutUTM.length && utmDetectionActivated) {
					changedArrayOfUrlsValues(arrayOfUrlWithoutUTM);
					changedNeedToShowUTMValue(true);
				}
			} else {
				changedNeedToShowUTMValue(false);
			}
		}
		return () => {
			resetState();
		};
	}, [messageText, settings]);

	const addUTMParams = () => {
		if (arrayOfUrls.length) {
			changedAddUTMModalVisibility(true);
		}
	};

	return (
		<Fragment>
			{isUTMParamsExist && alreadyExistingUTMParamsText}
			{needToShowUTM &&
			<div
				className="add-utm-to-link"
				{...clickable(() => addUTMParams())}
			>{title}
			</div>}
			{isAddUTMModalOpen &&
			<AddUTMParamsToLinkModal
				isModalOpen={isAddUTMModalOpen}
				changedAddUTMModalVisibility={changedAddUTMModalVisibility}
				arrayOfUrls={arrayOfUrls}
				changedLinkToLinkWithUTMParams={changedLinkToLinkWithUTMParams}
			/>}
		</Fragment>);
};

AddUTMParamsToLink.defaultProps = {
	title: 'We detected a link, do you want to add UTM parameters?',
};

AddUTMParamsToLink.propTypes = {
	title: PropTypes.string,
	messageText: PropTypes.string.isRequired,
	changedLinkToLinkWithUTMParams: PropTypes.func.isRequired,
	settings: PropTypes.shape({}).isRequired,
	getSettingsAction: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
	settings: selectors.getSettings(state),
});

const mapDispatchToProps = dispatch => ({
	getSettingsAction: bindActionCreators(getSettings, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUTMParamsToLink);
