/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import Loader from '../../../common/components/LoaderAnimation/Loader';

import './style.css';
import { ErrorIcon } from '../../../icons/svgIcons';

const FetchStoreLifeCycle = ({ entities = {}, children }) => {
	const entitiesArray = Object.keys(entities).map(key => ({ key, value: entities[key] }));

	const erroredEntities = entitiesArray.filter(item => item.value.hasError).map(item => item.key);


	if (!entitiesArray.some(entity => typeof entity.value === 'undefined')) {
		if (entitiesArray.some(entity => entity.value.hasError === true)) {
			return (
				<div className="wrapper_section_fetch">
					<ErrorIcon style={{ width: 100, padding: 20 }} />
					<div className="error-block">{`Error on load ${erroredEntities.join(', ')}.`}</div>
				</div>);
		}

		if (entitiesArray.some(entity => entity.value.isLoading === true)) {
			return (
				<div className="wrapper_section_fetch">
					<Loader />
					<div className="loader-text">Loading</div>
				</div>);
		}

		if (entitiesArray.every(entity => entity.value.response)) {
			return children;
		}

		return <div className="wrapper_section_fetch">Unknown error</div>;
	}

	return <div />;
};

export default FetchStoreLifeCycle;
