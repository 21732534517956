/* eslint-disable react/jsx-filename-extension */
/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import 'abortcontroller-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';

import detectCurrentEnvironment from './helpers/detectCurrentEnvironment';

const currentEnvironment = detectCurrentEnvironment();

if (currentEnvironment === 'production') {
	Sentry.init({
		dsn: 'https://fe53d755098c474ebb761318b10d2946@o402824.ingest.sentry.io/5416987',
		integrations: [
			new Integrations.BrowserTracing(),
		],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 0.5,
	});
}

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
