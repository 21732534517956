import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(createTheme({
	typography: {
		allVariants: {
			color: '#3C414C',
			fontFamily: 'Lato',
		},
	},
	components: {
		MuiCircularProgress: {
			styleOverrides: {
				root: {
					color: 'white',
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .Mui-error.Mui-focused': {
						'& > fieldset': {
							borderColor: '#FF4848 !important',
						},
					},
					'& .Mui-error:hover': {
						'& > fieldset': {
							borderColor: '#FF4848 !important',
						},
					},
				  '& .MuiOutlinedInput-root.Mui-focused': {
				    '& > fieldset': {
				      borderColor: '#23A9F4',
				    },
				  },
				  '& .MuiOutlinedInput-root:hover': {
				    '& > fieldset': {
				      borderColor: '#23A9F4',
				    },
				  },
				  '& .MuiOutlinedInput-root': {
				    '& > fieldset': {
				      borderColor: 'white',
				    },
				  },
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '10px !important',
				},
				input: {
					background: '#F6F8FA !important',
					boxShadow: '2.90629px 3.63286px 5.08601px #FFFFFF, inset 2px 2px 3.63286px rgba(114, 120, 134, 0.35)',
					borderRadius: '10px !important',
				},
			},
		},
	},
}));

export default theme;
