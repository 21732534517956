/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { post } from '../../../common/httpRequests';
import { IMAGE_UPLOADER_BY_URL } from '../../../common/apiRouting';

export function uploadEditedImage(imageUrl, needToSetHeaders = true) {
	return post(IMAGE_UPLOADER_BY_URL, { imageUrl, needToSetHeaders }, true)
		.then(res => res.json());
}
