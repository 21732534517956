/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import PropTypes from 'prop-types';

import VideoGuideIframe from '../VideoGuideIframe/VideoGuideIframe';

import paw from '../../images/paw.svg';

import './ShowingVideoGuideButton.css';

const ShowingVideoGuideButton = ({ url }) => (
	<div className="showing-video-guide-container">
		<img src={paw} className="paw-pulse" alt="paw-pulse" />
		<div className="paw-container" onClick={() => VideoGuideIframe.changeVideoGuideIframeVisibility(url)}>
			<img src={paw} alt="paw" className="paw" />
			<span className="question">?</span>
		</div>
	</div>
);

ShowingVideoGuideButton.propTypes = {
	url: PropTypes.string.isRequired,
};

export default ShowingVideoGuideButton;
