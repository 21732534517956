/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, October 2020
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import detectTwitterPostLength from '../../../helpers/detectTwitterPostLength';

import './TextMessageCounter.css';
import { getHashtagsFromText } from '../../textHelper';

const TextMessageCounter = ({ text }) => {
	const [textLength, changeTextLength] = useState(0);
	const [twitterTextLength, changeTwitterTextLength] = useState(0);
	useEffect(() => {
		if (text.trim().length) {
			changeTextLength(text.length);
			detectTwitterPostLength(text)
				.then(twitterLength => changeTwitterTextLength(twitterLength))
				.catch(error => console.log('detectTwitterPostLength error', error.message));
		} else {
			changeTextLength(0);
			changeTwitterTextLength(0);
		}
	}, [text]);

	return (
		<div className="text-length-counter-container">
			<div className="text-length-counter">{textLength}</div>
			<div className="twitter-length-counter">Twitter: {twitterTextLength}</div>
			<div className="twitter-length-counter">Hashtags: {getHashtagsFromText(text).length}</div>
		</div>
	);
};

TextMessageCounter.propTypes = {
	text: PropTypes.string.isRequired,
};

export default TextMessageCounter;
