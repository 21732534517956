/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, November 2020
 */

import React from 'react';
import PropTypes from 'prop-types';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import './AppCarousel.css';

const AppCarousel = ({
	carouselItems, showDots, showThumbs, changeCurrentItemIndex,
}) => {
	const handleCarouselValueChange = selectedItemIndex => changeCurrentItemIndex(selectedItemIndex);
	return (
		<div id="app-carousel">
			<Carousel
				showIndicators={showDots}
				useKeyboardArrows
				dynamicHeight
				onChange={handleCarouselValueChange}
				showThumbs={showThumbs}
			>
				{carouselItems.map((item, index) => <div key={item + index} className="app-carousel-item">{item}</div>)}
			</Carousel>
		</div>
	);
};

AppCarousel.defaultProps = {
	showDots: false,
	showThumbs: false,
};

AppCarousel.propTypes = {
	carouselItems: PropTypes.arrayOf(PropTypes.string).isRequired,
	showDots: PropTypes.bool,
	showThumbs: PropTypes.bool,
	changeCurrentItemIndex: PropTypes.func.isRequired,
};

export default AppCarousel;
