/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { post } from '../../common/httpRequests';
import { SESSION_LOGIN, SESSION_LOGOUT } from '../../common/apiRouting';

export const login = (email, password) => post(SESSION_LOGIN, {
	email,
	password,
}, false)
	.then(res => res.json());

export const logout = () => post(SESSION_LOGOUT, {}, true)
	.then(res => res.json());

export const register = () => {
};
