/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { get, put } from '../../common/httpRequests';
import { EXTENSION_SETTINGS_URL } from '../../common/apiRouting';

export function getExtensionSettingsFromApi() {
	return get(`${EXTENSION_SETTINGS_URL}`, true)
		.then(res => res.json());
}

export function updateExtensionSettingsInApi(data) {
	return put(`${EXTENSION_SETTINGS_URL}`, data, true)
		.then(res => {
			if (res) {
				return res.json();
			}
			return false;
		});
}
