/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import './AiLoader.css';
import { MicIcon } from '../../icons/svgIcons';

export default function AiLoader({ activeVoiceOver }) {
	return 	<MicIcon className="ai-loader-icon" onClick={() => activeVoiceOver()} />;
}

AiLoader.propTypes = {
	activeVoiceOver: PropTypes.func.isRequired
};
