import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
	Dialog, DialogContent, DialogContentText, DialogTitle, IconButton,
} from '@mui/material';

import './Dialog.css';

const FormWrapper = ({
	children, open, handleClose, title, contentStyle = {},
}) => (
	<Dialog
		PaperProps={{
			style: {
				borderRadius: 30,
				maxWidth: 800,
			},
		}}
		open={open}
		onClose={handleClose}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
		fullWidth
	>
		{title && <DialogTitle
			id="alert-dialog-title"
			className="dialog-title"
		>
			{title}
			<IconButton onClick={() => handleClose()}>
				<CloseIcon />
			</IconButton>
		</DialogTitle> }
		<DialogContent style={{
			padding: contentStyle.padding || contentStyle.padding === 0 ? contentStyle.padding : '20px 24px',
			...contentStyle,
		}}
		>
			<DialogContentText id="alert-dialog-description">
				{children}
			</DialogContentText>
		</DialogContent>
	</Dialog>
);

export default FormWrapper;
