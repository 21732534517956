export const SocialIntegrationType = {
	FACEBOOK: 'facebook',
	GOOGLE: 'googleMB',
	TWITTER: 'twitter',
	HOOTSUITE: 'hootsuite',
	INSTAGRAM: 'instagram',
	INSTAGRAM_BUSINESS: 'instagram-business',
	TIKTOK: 'tiktok',
	SNAPCHAT: 'snapchat',
	MEDIUM: 'medium',
	LINKEDIN: 'linkedin',
	SHOPIFY: 'shopify',
	PINTEREST: 'pinterest',
	WORDPRESS: 'wordpress',
	MAILCHIMP: 'mailchimp',
	WHATSAPP: 'whatsapp',
	YOUTUBE: 'youtube',
};
