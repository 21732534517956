/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import ReactTooltip from 'react-tooltip';
import Dropzone from 'react-dropzone';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import CalendarImageImageUploaderItem from './CalendarImageImageUploaderItem/CalendarImageImageUploaderItem';
import TextFromImageConfirmModal from '../../../common/components/TextFromImageConfirmModal/TextFromImageConfirmModal';
import AttachImageMetadataModal from '../../../common/components/AttachImageMetadataModal/AttachImageMetadataModal';
import chatGptIcon from '../../../icons/chatgpt-icon.svg';

import {
	getAnnotationFromImage,
	getTextFromImage,
	// uploadVideo,
	// uploadImage,
	// uploadAudio,
	uploadAudioThumbnailAndGetDuration,
	// uploadVideoThumbnailAndGetDuration,
	uploadPreSignedImage,
	uploadPreSignedVideo,
	uploadPreSignedAudio,
	uploadPreSignedPdf,
	deleteFiles,
	generatePostFromVideoText,
} from './CalendarImageUploader.action';
import { getEmojiAndHashtags } from '../../../common/components/PredictiveLinguistics/PredictiveLinguistics.action';
import { uploadEditedImage } from '../../Library/ImageEditModal/ImageEditModal.action';
import edit from '../../../images/pencil-edit-button.svg';

import Loader from '../../../common/components/LoaderAnimation/Loader';
import ImageEditModal from '../../Library/ImageEditModal/ImageEditModal';
import { getConfig } from '../../Settings/Settings.action';
import { getRandomArray } from '../../../helpers/getRandomArray';

import iconImageSuggestion from '../../../common/components/ImageSuggestion/purpleBulb.svg';
import ImageSuggestion from '../../../common/components/ImageSuggestion/ImageSuggestion';
import GetImagesFromUrl from '../../../common/components/GetImagesFromUrl/GetImagesFromUrl';

import { imagesAcceptedFormats } from '../../../constants/imagesAcceptedFormats';

import './CalendarImageUploader.css';
import PlusIcon from '../../../images/uploads-plus.svg';
import VideoThumbnailCaptureModal from '../../../common/components/VideoThumbnailCaptureModal/VideoThumbnailCaptureModal';

const regExpAudio = RegExp('audio/');
const regExpImage = RegExp('image/*');
const regExpPdf = RegExp('application/pdf');
const regexForHiecFiles = /heic$/;
const regexForGifs = /.gif$/;
const regexForVideos = /.mp4$/;
const validationErrorMessage = 'You can upload up to 10 images or 1 video or 1 GIF, please remove some of them to choose other file';

class CalendarImageUploader extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			files: [],
			errorMessage: '',
			imageEditModalOpen: false,
			maxFilesCount: '',
			imageMaxSize: '',
			videoMaxSize: '',
			pdfMaxSize: '',
			audioMaxSize: 8388608,
			allowedVideoFormats: [],
			acceptedFormats: [],
			imageUrl: '',
			videoUrl: '',
			videoFileObj: null,
			videoFilesArray: [],
			pendingVideosToUpload: false,
			currentVideoIndex: 0,
			textFromUrl: '',
			isTextFromImageModalOpen: false,
			isAttachImageMetadataModalOpen: false,
			isDataLoading: false,
			isFilesLoading: false,
			isVideoThumbnailCaptureModalOpen: false,
			showChatGptWarningModal: false,
			chatGptErrorMessage: `We noticed that you have not uploaded any videos yet.
			Please upload a video and ensure you've added Open AI API Key under settings.`,
		};
	}

	componentDidMount() {
		const {
			discoverPostImage,
			images,
			setImage,
			editImage = [],
			isFeaturedImageToBlogPost,
		} = this.props;
		const { files } = this.state;

		getConfig().then((res) => {
			const {
				acceptedFormats, videoMaxSize, maxFilesCount, imageMaxSize, allowedVideoFormats, pdfMaxSize,
			} = res;

			const allowedFilesFormats = isFeaturedImageToBlogPost ? imagesAcceptedFormats : acceptedFormats;

			this.setState({
				acceptedFormats: allowedFilesFormats,
				videoMaxSize,
				pdfMaxSize,
				maxFilesCount,
				imageMaxSize,
				allowedVideoFormats,
			});
		});
		if (discoverPostImage) {
			this.addDiscoverPostImage(discoverPostImage, images, setImage);
		}
		if (!files.length && images.length) {
			this.setState({
				files: [...images],
			});
		}
		const filesContainEditImg = editImage.some(editImg => ((files.length !== 0) ?
			files.find((fileObj) => this.targetImg(fileObj) === editImg) : false));
		if (editImage && editImage.length > 0 && !filesContainEditImg) {
			for (let i = 0; i <= editImage.length - 1; i++) {
				const id = uuid();
				this.setState(prevState => ({
					files: [...prevState.files, { image: editImage[i], id }],
				}), () => setImage({ image: editImage[i], id }));
			}
		}
	}

	componentWillReceiveProps(props) {
		const { discoverPostImage, images = [], setImage } = props;
		if (images.length) {
			this.setState({ files: [...images] });
		}
		if (discoverPostImage) {
			this.addDiscoverPostImage(discoverPostImage, images, setImage);
		}
	}

	componentDidUpdate(props) {
		if (this.props.images !== props.images) {
			// eslint-disable-next-line
			this.setState({
				files: [...this.props.images],
			});
		}
	}

	onDrop = files => {
		if (files && files.length === 0) {
			// If file with unsupported format is dropped and files array is empty
			const errorMessage = 'Unsupported file format';
			this.updateValidateMessage(errorMessage);
			return false;
		}
		const {
			isBulkUploadImages, isBulkUploadVideos, changedMediaFilesLoaderVisibility, isFeaturedImageToBlogPost,
		} = this.props;
		const { allowedVideoFormats } = this.state;

		const socialAccounts = this.getSocialAccountsSelected();

		const {
			files: fliesFromState, imageMaxSize, audioMaxSize, videoMaxSize, pdfMaxSize,
		} = this.state;

		this.setState({
			errorMessage: '',
		});

		const arrayOfFiles = files.map(file => {
			const { name, type: fileType } = file;
			const heicFile = regexForHiecFiles.test(name.split('.').pop());
			if (!fileType && heicFile) {
				return { file, type: 'image/heic' };
			} return { file, type: fileType };
		});

		const typesDroppedFiles = {
			images: [], video: [], audio: [], pdf: [],
		};

		const validationResult = this.setImagesAndVideosFiles(arrayOfFiles, typesDroppedFiles, regExpImage, allowedVideoFormats);

		if (validationResult.includes(false)) {
			return;
		}

		if (isBulkUploadImages || isBulkUploadVideos) {
			const isValidSizeOfArray = this.bulkUploadValidation(fliesFromState);
			if (!isValidSizeOfArray) {
				return;
			}
		} else if (isFeaturedImageToBlogPost) {
			const isValid = this.featuredImageToBlogPostValidation(files, fliesFromState);
			if (!isValid) {
				return;
			}
		} else {
			const isValid = this.validateImageAndVideoFiles(arrayOfFiles, typesDroppedFiles, fliesFromState);
			if (!isValid) {
				return;
			}
		}

		if (changedMediaFilesLoaderVisibility) {
			changedMediaFilesLoaderVisibility(true);
		} else {
			this.changedLoaderVisibility(true);
		}

		const openVideoModal = () => {
			if (this.state.videoFilesArray.length > 0) {
				this.setState({
					videoFileObj: this.state.videoFilesArray[0],
					videoUrl: URL.createObjectURL(this.state.videoFilesArray[0]),
					pendingVideosToUpload: this.state.videoFilesArray.length > 1,
					currentVideoIndex: 0,
				});
				this.changeVideoThumbnailCaptureModalOpenVisibility(true);
			}
		};

		arrayOfFiles.forEach(file => {
			const { file: mediaFile, type } = file;
			// Handle image file
			const allowedVideoFormat = this.checkVideoFormats(type);
			if (regExpImage.test(type)) {
				if (mediaFile.size > imageMaxSize) {
					this.updateValidateMessage('You cannot upload the photo more than 5 mb. Please try another one.');
				} else {
					this.uploadFiles('image', uploadPreSignedImage(mediaFile, type), mediaFile);
					// this.uploadFiles('image', uploadImage(mediaFile), mediaFile);
				}
			}
			// Handle audio file
			if (regExpAudio.test(file.type)) {
				if (file.size > audioMaxSize) {
					this.updateValidateMessage('You cannot upload the audio more than 8 mb. Please try another one.');
				} else if (!socialAccounts.alexa) {
					this.updateValidateMessage('You can only upload audio when posting to Alexa Voice. Please, select Alexa account first.');
				} else {
					uploadAudioThumbnailAndGetDuration(mediaFile).then(data => {
						this.uploadFiles('audio', uploadPreSignedAudio(mediaFile, data));
					}).catch(err => console.log(err));
					// this.uploadFiles('audio', uploadAudio(mediaFile));
				}
			}

			if (regExpPdf.test(file.type)) {
				if (mediaFile.size > pdfMaxSize) {
					this.updateValidateMessage('You cannot upload the PDF more than 10MB. Please try another one.');
				} else if (!socialAccounts.whatsapp) {
					this.updateValidateMessage('You can only upload PDF when posting to WhatsApp Group. Please, select WhatsApp group first.');
				} else {
					this.uploadFiles('pdf', uploadPreSignedPdf(mediaFile, type), mediaFile);
				}
			}

			// Handle video file
			if (allowedVideoFormat) {
				if (mediaFile.size > videoMaxSize) {
					this.updateValidateMessage('You cannot upload the video more than 150 mb. Please try another one.');
				} else {
					// uploadVideoThumbnailAndGetDuration(mediaFile).then(data => {
					// 	this.uploadFiles('video', uploadPreSignedVideo(mediaFile, data));
					// }).catch(err => console.log(err));
					// this.uploadFiles('video', uploadVideo(mediaFile, socialNetworksSelected));
					this._checkFor4kVideo(mediaFile).then(({ is4KVideo, videoHeight, videoWidth }) => {
						mediaFile.videoHeight = videoHeight;
						mediaFile.videoWidth = videoWidth;
						if (!is4KVideo) {
							this.setState({ videoFilesArray: [...this.state.videoFilesArray, mediaFile] }, openVideoModal);
						} else if (is4KVideo) {
							mediaFile.is4KVideo = is4KVideo;
							this.setState({ videoFilesArray: [...this.state.videoFilesArray, mediaFile] }, openVideoModal);
						}
					});
				}
			}
		});
	};

	_checkFor4kVideo = (file) => new Promise((resolve) => {
		const video = document.createElement('video');
		video.preload = 'metadata';
		video.onloadedmetadata = () => {
			window.URL.revokeObjectURL(video.src);
			const is4KVideo = video.videoWidth > 1920;
			const videoHeight = video.videoHeight;
			const videoWidth = video.videoWidth;
			const data = {
				is4KVideo, videoHeight, videoWidth,
			};
			video.remove();
			return resolve(data);
		};
		video.src = URL.createObjectURL(file);
	});

	getSocialAccountsSelected = () => {
		const { dropZoneTitle } = this.props;
		const result = {
			text: dropZoneTitle,
		};
		this.props.selectedAccounts.forEach(account => {
			if (account.socialMedia === 'instagram') {
				result.instagram = true;
			}

			if (account.socialMedia === 'alexaDevice') {
				result.alexa = true;
				result.text = 'Add video or audio';
			}

			if (account.socialMedia === 'whatsApp') {
				result.whatsapp = true;
				result.text = 'Add photo or video or PDF';
			}
		});

		if ('alexa' in result && 'whatsapp' in result) {
			result.text = 'Add photo or audio or video or PDF';
		}

		return result;
	};

	onHandleRemove = async (removeFile, files = []) => {
		let filesToDelete = [];
		if (removeFile && removeFile.image) {
			filesToDelete.push(removeFile.image);
		} else if ((removeFile && removeFile.type && removeFile.type.indexOf('video') >= 0) ||
		(removeFile && removeFile.url &&
			(removeFile.url.toLowerCase().indexOf('.mp4') >= 0 || removeFile.url.toLowerCase().indexOf('mov') >= 0)
		)) {
			filesToDelete.push(removeFile.url);
			filesToDelete.push(removeFile.thumbnail);
			filesToDelete.push(removeFile.audioTrack);
		} else {
			filesToDelete.push(removeFile && removeFile.url);
		}
		this.props.onRemove(removeFile);
		const newFiles = this.state.files.filter((file) => file.id !== removeFile.id);
		filesToDelete = filesToDelete.map(file => file);
		await deleteFiles(filesToDelete);
		this.setState({
			files: newFiles,
			errorMessage: '',
		}, () => {
			if (files.length) {
				this.onDrop(files);
			}
		});
	};

	onHandleAttachMetadata = (imageUrl) => {
		this.setState({ imageUrl });
		this.changeAttachImageMetadataModalVisibility(true);
	};

	getSocialNetworksFromSelectedAccounts = (selectedAccounts = []) => {
		const socialNetworks = [];

		selectedAccounts.forEach(account => {
			const socialMedia = account.socialMedia;
			if (!socialNetworks.includes(socialMedia)) {
				socialNetworks.push(socialMedia);
			}
		});

		return socialNetworks;
	};

	setImageEditModal = (value) => {
		this.setState({
			imageEditModalOpen: value,
		});
	};

	setImagesAndVideosFiles = (files, typesDroppedFiles) => files.map(file => {
		const { type: fileType } = file;

		const allowedVideoFormat = this.checkVideoFormats(fileType);
		const allowedFormats = regExpImage.test(fileType) || allowedVideoFormat || regExpAudio.test(fileType) || regExpPdf.test(fileType);

		if (!allowedFormats) {
			const errorMessage = `Unsupported format ${fileType}`;
			this.updateValidateMessage(errorMessage);
			return false;
		}
		if (regExpImage.test(fileType)) {
			typesDroppedFiles.images.push(fileType);
		}
		if (allowedVideoFormat) {
			typesDroppedFiles.video.push(fileType);
		}
		if (regExpAudio.test(fileType)) {
			typesDroppedFiles.audio.push(fileType);
		}
		if (regExpPdf.test(fileType)) {
			typesDroppedFiles.pdf.push(fileType);
		}
		return true;
	});

	getEmojiFromImageURL = url => {
		if (this.emojiAndHashtagsAbortController) {
			this.emojiAndHashtagsAbortController.abort();
		}
		const { setEmojiList, emojiCount } = this.props;
		setEmojiList([]);
		this.emojiAndHashtagsAbortController = new window.AbortController();
		this.loadImagURLToGoogleCloud(url)
			.then(urlForGettingAnnotations => getAnnotationFromImage(urlForGettingAnnotations)
				.then(result => {
					if (!result.length) {
						return setEmojiList(null);
					}
					const textForEmojis = result.join(' ');
					return getEmojiAndHashtags(textForEmojis, this.emojiAndHashtagsAbortController)
						.then(emojiAndHashtags => {
							const { emojis } = emojiAndHashtags;
							const randomArray = getRandomArray(emojis, emojiCount);
							setEmojiList(randomArray);
							this.emojiAndHashtagsAbortController = null;
						});
				})
				.catch(error => {
					console.log('error:::', error);
					setEmojiList(null);
				}));
	};

	addDiscoverPostImage = (discoverPostImage, images, setImage) => {
		this.setState({
			files: [...images],
		}, () => {
			const id = uuid();
			if (!images.some(item => item.image === discoverPostImage)) {
				setImage({ image: discoverPostImage, id });
			} else {
				return;
			}
			this.setState(prevState => ({
				files: [...prevState.files, { file: { image: discoverPostImage }, id }],
			}));
		});
	};

	uploadFiles = (fileType, promise, mediaFile) => {
		const { toggleDisableSuggestionImages, changedMediaFilesLoaderVisibility } = this.props;
		if (toggleDisableSuggestionImages) {
			toggleDisableSuggestionImages(true);
		}
		this.changedLoaderVisibility(true);
		promise
			.then(data => {
				this.changedLoaderVisibility(false);
				if (data.status === 400 || data.status === 413) {
					this.updateValidateMessage(data.message);
				} else if (!this.props.unmounted) {
					console.log('mediaFile:', mediaFile);
					if (fileType === 'image') this.props.setImage(data, mediaFile);
					if (fileType === 'video') {
						this.props.setVideo(data);
						if (this.state.pendingVideosToUpload && this.state.currentVideoIndex <= this.state.videoFilesArray.length) {
							const newIndex = this.state.currentVideoIndex + 1;
							this.setState({
								videoFileObj: this.state.videoFilesArray[newIndex],
								videoUrl: URL.createObjectURL(this.state.videoFilesArray[newIndex]),
								pendingVideosToUpload: this.state.videoFilesArray.length > newIndex + 1,
								currentVideoIndex: newIndex,
							});
							this.changeVideoThumbnailCaptureModalOpenVisibility(true);
						} else {
							this.setState({
								videoFileObj: null,
								videoUrl: '',
								videoFilesArray: [],
								currentVideoIndex: 0,
								pendingVideosToUpload: false,
							});
						}
					}
					if (fileType === 'audio') this.props.setAudio(data);
					if (fileType === 'pdf') this.props.setPdf(data);
				}
			})
			.catch(error => {
				this.changedLoaderVisibility(false);
				this.updateValidateMessage(error);
			})
			.finally(() => {
				if (toggleDisableSuggestionImages) {
					toggleDisableSuggestionImages(false);
				}
				if (changedMediaFilesLoaderVisibility) {
					changedMediaFilesLoaderVisibility(false);
				} else {
					this.changedLoaderVisibility(false);
				}
			});
	};

	loadImagURLToGoogleCloud = url => {
		if (!url.startsWith('https://storage.googleapis.com/')) {
			return uploadEditedImage(url)
				.then(({ image }) => image);
		}
		return Promise.resolve(url);
	};

	bulkUploadValidation = fliesFromState => {
		const errorMessage = 'Maximum 30 images or videos can be uploaded, please remove some of them to choose other files.';
		if (fliesFromState.length >= 30) {
			return this.updateValidateMessage(errorMessage);
		}
		return true;
	};

	featuredImageToBlogPostValidation = (newFiles, fliesFromState) => {
		const errorMessage = 'Your can uploaded only one featured image, please remove/unselect image.';
		if (newFiles.length > 1 || fliesFromState.length >= 1) {
			return this.updateValidateMessage(errorMessage);
		}
		return true;
	};

	validateImageAndVideoFiles = (files, typesDroppedFiles, fliesFromState) => {
		const { images, video, audio } = typesDroppedFiles;
		const { maxFilesCount } = this.state;

		if (this.props.video && this.props.video.url) {
			return this.updateValidateMessage(validationErrorMessage);
		}
		if (this.props.audio && this.props.audio.url) {
			const videoAdded = video.length;
			const moreThanOneImageAdded = images.length > 1;
			const imageExists = fliesFromState.length;

			if (videoAdded || moreThanOneImageAdded || imageExists) {
				return this.updateValidateMessage(validationErrorMessage);
			}
		}
		if (fliesFromState.length && video.length) {
			return this.updateValidateMessage(validationErrorMessage);
		}
		if (fliesFromState.length) {
			const isGifFileExist = fliesFromState.find(file => regexForGifs.test(file.image));
			const isNewFileGif = images.find(image => image === 'image/gif');
			if (isGifFileExist || isNewFileGif) {
				return this.updateValidateMessage(validationErrorMessage);
			}
		}
		if (files.length) {
			if (files.length > 10 || fliesFromState.length > 10 || (files.length + fliesFromState.length > 10)) {
				return this.updateValidateMessage(validationErrorMessage);
			}
			const newGifsCount = files.filter(file => regexForGifs.test(file.type)).length;
			const newVideosCount = files.filter(file => regexForVideos.test(file.type)).length;
			if (newGifsCount > 1 || newVideosCount > 1) {
				return this.updateValidateMessage(validationErrorMessage);
			}
			if ((files.length >= 2 && newGifsCount) || (files.length >= 2 && newVideosCount)) {
				return this.updateValidateMessage(validationErrorMessage);
			}
		}
		return this.defaultImageAndVideoFilesValidation(files, maxFilesCount, images, video, audio, fliesFromState, typesDroppedFiles, validationErrorMessage);
	};

	// TODO: finish it
	validateVideo = async file => {
		if (file.size > this.state.videoMaxSize) {
			return Promise.reject('You cannot upload the video more than 150 mb. Please try another one.');
		}

		return Promise.resolve();
	};

	defaultImageAndVideoFilesValidation = (files, maxFilesCount, images, video, audio, fliesFromState) => {
		if (files.length > maxFilesCount || (files.length + fliesFromState.length) > maxFilesCount) {
			this.updateValidateMessage(validationErrorMessage);
		}

		if (video.length > 1 || (images.length && video.length)) {
			this.updateValidateMessage('1 video or 10 images');
		}
		return true;
	};

	updateValidateMessage = (message) => {
		const { changedMediaFilesLoaderVisibility } = this.props;
		if (changedMediaFilesLoaderVisibility) {
			changedMediaFilesLoaderVisibility(false);
		}
		this.setState({
			errorMessage: message,
		});
	};

	checkLimitsForExistingImages = (existingImages) => {
		const isVideoAlreadyExist = existingImages.some(image => regexForVideos.test(image.image));
		const isGifsAlreadyExist = existingImages.some(image => regexForGifs.test(image.image));
		const isImagesLimitReached = existingImages.length >= 10;
		if (isVideoAlreadyExist || isGifsAlreadyExist || isImagesLimitReached) {
			return this.updateValidateMessage(validationErrorMessage);
		}
		return true;
	};

	addedImageToPost = (url) => {
		this.setImageEditModal(false);
		const {
			images, video = {}, isBulkUploadImages, isBulkUploadVideos, isFeaturedImageToBlogPost, setImage,
		} = this.props;
		if (!isBulkUploadVideos && video && Object.keys(video).length && video.url) {
			return this.updateValidateMessage(validationErrorMessage);
		}
		let isAllowedToAddImage;
		if (isBulkUploadImages || isBulkUploadVideos) {
			isAllowedToAddImage = this.bulkUploadValidation(images);
		} else if (isFeaturedImageToBlogPost) {
			isAllowedToAddImage = this.featuredImageToBlogPostValidation([url], images);
		} else {
			isAllowedToAddImage = this.checkLimitsForExistingImages(images);
		}

		if (isAllowedToAddImage) {
			const id = uuid();
			const image = { image: url, id };
			setImage(image);
		}
		return true;
	};

	handleAddMultipleImages = (urls) => {
		const { setImage } = this.props;
		if (urls && urls.length) {
			urls.forEach(url => {
				const id = uuid();
				const image = { image: url, id };
				setImage(image);
			});
		}
	};

	handleEditModalClick = (value) => {
		const { isFilesLoading } = this.state;
		const { isMediaFilesLoading, isBulkUploadImages, isFeaturedImageToBlogPost } = this.props;

		if (isFilesLoading || isMediaFilesLoading) return;

		if (!isBulkUploadImages && !isFeaturedImageToBlogPost) {
			if (this.state.files.length >= this.state.maxFilesCount) {
				this.setState({
					errorMessage: 'Maximum 10 images can be uploaded. Please remove some of them to create and use a new image.',
				});
				return;
			}

			if (this.props.video && Object.keys(this.props.video).length) {
				const { toggleAlertModal } = this.props;
				const message = (
					<div style={{ color: '#e84352' }}>Maximum 10 images or one video can be uploaded, please remove some of
						them to choose other images.
					</div>);
				if (toggleAlertModal) {
					toggleAlertModal(message);
				}
				return;
			}
		}
		this.setImageEditModal(value);
	};

	targetImg = (obj) => (obj.file ? obj.file.image : obj.image);

	strArrayToImagesArray = imagesList => imagesList && imagesList.map((image, index) => ({ image, id: `img-${index}` }));

	checkVideoFormats = fileType => {
		const { allowedVideoFormats } = this.state;
		return allowedVideoFormats.some(format => format === fileType);
	};

	changeTextFromImageModalVisibility = newValue => {
		this.setState({ isTextFromImageModalOpen: newValue, isDataLoading: newValue });
	};

	changeAttachImageMetadataModalVisibility = newValue => {
		this.setState({ isAttachImageMetadataModalOpen: newValue });
	};

	changeVideoThumbnailCaptureModalOpenVisibility = (newValue, url, videoFileObj) => {
		const data = { isVideoThumbnailCaptureModalOpen: newValue };
		if (url) data.videoUrl = url;
		if (videoFileObj) data.videoFileObj = videoFileObj;
		this.setState(data);
	};

	cancelVideoThumbnailCaptureHandler = () => {
		// skip current video
		this.setState({
			videoFileObj: null,
			videoUrl: '',
			videoFilesArray: this.state.videoFilesArray.filter(item => item.name !== this.state.videoFileObj.name),
			isFilesLoading: false,
			isVideoThumbnailCaptureModalOpen: false,
			currentVideoIndex: 0,
		}, () => {
			// Check if more videos are present
			if (this.state.videoFilesArray.length > 0) {
				this.setState({
					videoFileObj: this.state.videoFilesArray[0],
					videoUrl: URL.createObjectURL(this.state.videoFilesArray[0]),
					pendingVideosToUpload: this.state.videoFilesArray.length > 1,
					currentVideoIndex: 0,
				});
				this.changeVideoThumbnailCaptureModalOpenVisibility(true);
			}
		});
		if (this.props.changedMediaFilesLoaderVisibility) {
			this.props.changedMediaFilesLoaderVisibility(false);
		} else {
			this.changedLoaderVisibility(false);
		}
	};

	changePostMessageToTextFromImage = imageUrl => {
		this.setState({ imageUrl });
		this.changeTextFromImageModalVisibility(true);
		return getTextFromImage(imageUrl)
			.then(detectedText => this.setState({ textFromUrl: detectedText }))
			.catch(error => console.log('changePostMessageToTextFromImage error:', error))
			.finally(() => this.setState({ isDataLoading: false }));
	};

	changedLoaderVisibility = newValue => this.setState({ isFilesLoading: newValue });

	handleChatGPTFromVideoClick = () => {
		console.log('video: ', this.props.video && this.props.video.url);
		if (!this.props.video || Object.keys(this.props.video).length === 0) {
			this.setState({ showChatGptWarningModal: !this.state.showChatGptWarningModal });
		}
		generatePostFromVideoText(this.props.video.url)
			.then(response => {
				console.log(response);
				this.props.handleGeneratePostFromVideo(response.postTitle, response.postCaption);
			})
			.catch(err => {
				console.log(err);
				this.setState({
					showChatGptWarningModal: !this.state.showChatGptWarningModal,
					chatGptErrorMessage: err.errorMessage || err.message,
				});
			});
	}

	render() {
		const {
			images = [],
			video,
			videos,
			pdf,
			className,
			isPriorPostedPostView,
			setHashtagsImageUrl,
			isBulkUploadImages,
			imageSuggestion,
			toggleImageSuggestion,
			setImages,
			message,
			toggleAlertModal,
			addTextFromImageToPostMessage,
			isMediaFilesLoading,
			isFeaturedImageToBlogPost,
			audio,
			selectedAccounts,
			socialImages,
			addSocialImage,
			imgRatioValidate,
			mediaMetadata,
			handleAttachMetadata,
			links,
			showImageMetadata,
			showBannerBear,
			setUpgradeModalMetadata,
			setIsUpgradeModalShown,
		} = this.props;
		const {
			errorMessage,
			imageEditModalOpen,
			acceptedFormats,
			isTextFromImageModalOpen,
			isAttachImageMetadataModalOpen,
			isVideoThumbnailCaptureModalOpen,
			imageUrl,
			videoUrl,
			videoFileObj,
			textFromUrl,
			isDataLoading,
			isFilesLoading,
			imageMaxSize,
			maxFilesCount,
			showChatGptWarningModal,
			chatGptErrorMessage,
		} = this.state;
		const socialAccounts = this.getSocialAccountsSelected();

		return (
			<Fragment>
				<div
					className={`calendar-image-uploader ${className || ''}`}
					style={{ pointerEvents: 'auto' }}
				>
					<Dropzone
						accept={acceptedFormats}
						style={{
							width: '100%',
							minHeight: '200px',
							border: '2px dashed #e3e3e6',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							cursor: isFilesLoading || isMediaFilesLoading ? 'not-allowed' : 'auto',
							pointerEvents: isFilesLoading || isMediaFilesLoading ? 'none' : 'inherit',
						}}
						onDrop={(files) => this.onDrop(files)}
						disabled={isFilesLoading || isMediaFilesLoading || isPriorPostedPostView}
					>
						{errorMessage.length ? <div className="no-images">{errorMessage}</div> : null}
						{(isFilesLoading || isMediaFilesLoading) && <Loader />}
						<div
							className="dropzone-uploaded-files"
						>
							{(images && images.length) || (videos && videos.length) || (video && video.url) || (audio && audio.url) || (pdf && pdf.url) ?
							 '' : <img
									id="plus"
									src={PlusIcon}
									alt="add-pick-to-post"
								/> }
							{(images && images.length ? images : this.strArrayToImagesArray(images))
								.map(image => (<CalendarImageImageUploaderItem
									key={image.id}
									file={image}
									onHandleRemove={this.onHandleRemove}
									onHandleAttachMetadata={this.onHandleAttachMetadata}
									addSocialImage={addSocialImage}
									imgRatioValidate={imgRatioValidate}
									socialImages={socialImages}
									isPriorPostedPostView={isPriorPostedPostView}
									setHashtagsImageUrl={setHashtagsImageUrl}
									getEmojiFromImageURL={this.getEmojiFromImageURL}
									needToShowAllButtons={!isBulkUploadImages && !isFeaturedImageToBlogPost}
									changePostMessageToTextFromImage={this.changePostMessageToTextFromImage}
									selectedAccounts={selectedAccounts}
									mediaMetadata={mediaMetadata}
									changeOriginalImg={(files, prevFile) => {
										this.onHandleRemove(prevFile, files);
									}}
									showImageMetadata={showImageMetadata}
								/>))}
							{audio && audio.url ?
								<CalendarImageImageUploaderItem
									file={audio}
									onHandleRemove={this.onHandleRemove}
									onHandleAttachMetadata={this.onHandleAttachMetadata}
									isPriorPostedPostView={isPriorPostedPostView}
									setHashtagsImageUrl={setHashtagsImageUrl}
									getEmojiFromImageURL={this.getEmojiFromImageURL}
									changePostMessageToTextFromImage={this.changePostMessageToTextFromImage}
									addTextFromImageToPostMessage={addTextFromImageToPostMessage}
									mediaMetadata={mediaMetadata}
									isBulkUploadImages // as a hotfix to show only delete for audio
								/> : null}
							{videos && videos.length ? videos.map(videoObj => (<CalendarImageImageUploaderItem
								key={videoObj.url}
								file={videoObj}
								onHandleRemove={this.onHandleRemove}
								onHandleAttachMetadata={this.onHandleAttachMetadata}
								isPriorPostedPostView={isPriorPostedPostView}
								setHashtagsImageUrl={setHashtagsImageUrl}
								getEmojiFromImageURL={this.getEmojiFromImageURL}
								needToShowAllButtons={!isBulkUploadImages && !isFeaturedImageToBlogPost}
								changePostMessageToTextFromImage={this.changePostMessageToTextFromImage}
								toggleVideoThumbnailModal={this.changeVideoThumbnailCaptureModalOpenVisibility}
								mediaMetadata={mediaMetadata}
							/>)) : video && video.url &&
								<CalendarImageImageUploaderItem
									key={video.url}
									file={video}
									onHandleRemove={this.onHandleRemove}
									onHandleAttachMetadata={this.onHandleAttachMetadata}
									isPriorPostedPostView={isPriorPostedPostView}
									setHashtagsImageUrl={setHashtagsImageUrl}
									getEmojiFromImageURL={this.getEmojiFromImageURL}
									needToShowAllButtons={!isBulkUploadImages && !isFeaturedImageToBlogPost}
									changePostMessageToTextFromImage={this.changePostMessageToTextFromImage}
									toggleVideoThumbnailModal={this.changeVideoThumbnailCaptureModalOpenVisibility}
									mediaMetadata={mediaMetadata}
								/>}
							{pdf && pdf.url &&
								<CalendarImageImageUploaderItem
									key={pdf.url}
									file={pdf}
									onHandleRemove={this.onHandleRemove}
									onHandleAttachMetadata={this.onHandleAttachMetadata}
									isPriorPostedPostView={isPriorPostedPostView}
									setHashtagsImageUrl={setHashtagsImageUrl}
									getEmojiFromImageURL={this.getEmojiFromImageURL}
									changePostMessageToTextFromImage={this.changePostMessageToTextFromImage}
									mediaMetadata={mediaMetadata}
									pdfShowButtons
									onHandlePreviewPdf={this.onHandlePreviewPdf}
								/>}
						</div>
						{!isPriorPostedPostView &&
						<div className="dropzone-add-files">
							<span>{socialAccounts.text}</span>
						</div>}
						<Fragment>{!isPriorPostedPostView &&
						<div className="icons-wrapper" onClick={event => event.stopPropagation()}>
							<GetImagesFromUrl addedImageToPost={this.handleAddMultipleImages} links={links} images={images} maxFilesCount={maxFilesCount} />
							<Fragment>
								<div className="image-editing" onClick={() => this.handleEditModalClick(true)}>
									<img src={edit} alt="edit" width="12" height="12" />
								</div>
								{imageEditModalOpen && <ImageEditModal
									isOpen={imageEditModalOpen}
									toggle={this.setImageEditModal}
									setErrorMessage={this.updateValidateMessage}
									transferImageToAddContent={this.addedImageToPost}
								/>}

							</Fragment>
							<Fragment>
								<div className="image-suggestion">
									<img src={iconImageSuggestion} alt="Purple Bulb" onClick={toggleImageSuggestion} />
									{imageSuggestion &&
									<ImageSuggestion
										isOpen={imageSuggestion}
										onToggle={toggleImageSuggestion}
										images={images}
										setImages={setImages}
										message={message}
										imageMaxSize={imageMaxSize}
										isLoadVideo={video && !!Object.keys(video).length}
										toggleAlertModal={toggleAlertModal}
										isBulkUploadImages={isBulkUploadImages}
										isFeaturedImageToBlogPost={isFeaturedImageToBlogPost}
										showBannerBear={showBannerBear}
										setUpgradeModalMetadata={setUpgradeModalMetadata}
										setIsUpgradeModalShown={setIsUpgradeModalShown}
									/>}
								</div>
							</Fragment>
							{!isBulkUploadImages ? (
								<Fragment>
									<img
										src={chatGptIcon}
										alt="chat-gpt-icon"
										style={{
											height: 24,
											width: 24,
											borderRadius: '50%',
											marginLeft: '0.5rem',
											cursor: 'pointer',
										}}
										data-for="chat-gpt-icon"
										onClick={this.handleChatGPTFromVideoClick}
									/>
									<ReactTooltip
										id="chat-gpt-icon"
										place="bottom"
										effect="solid"
										backgroundColor="#07a3ed"
										getContent={() => (
											<div>Create Post from Video using AI</div>
										)}
										delayHide={300}
									/>
									{showChatGptWarningModal && (<Fragment>
										<Modal
											isOpen={showChatGptWarningModal}
											centered
											id="chat-gpt-warning-modal"
										>
											<ModalHeader
												className="chat-gpt-warning-modal-header"
												toggle={() => this.setState({
													showChatGptWarningModal: !this.state.showChatGptWarningModal,
													chatGptErrorMessage: `We noticed that you have not uploaded any videos yet.
													Please upload a video and ensure you've added Open AI API Key under settings.`,
												})}
											>
												Generate Post From Video
											</ModalHeader>
											<ModalBody>
												<div style={{ textAlign: 'center' }}>
													{chatGptErrorMessage}
												</div>
											</ModalBody>
											<ModalFooter className="chat-gpt-warning-modal-footer">
												<Button
													color="light"
													onClick={() => this.setState({
														showChatGptWarningModal: !this.state.showChatGptWarningModal,
														chatGptErrorMessage: `We noticed that you have not uploaded any videos yet.
														Please upload a video and ensure you've added Open AI API Key under settings.`,
													})}
													className="chat-gpt-warning-modal-footer-button"
												>
													Okay
												</Button>
											</ModalFooter>
										</Modal>
									</Fragment>)}
								</Fragment>
							) : null}
						</div>}
						</Fragment>
					</Dropzone>
				</div>
				{isTextFromImageModalOpen &&
				<TextFromImageConfirmModal
					imageUrl={imageUrl}
					textFromUrl={textFromUrl}
					isTextFromImageModalOpen={isTextFromImageModalOpen}
					changeTextFromImageModalVisibility={this.changeTextFromImageModalVisibility}
					isDataLoading={isDataLoading}
					addTextFromImageToPostMessage={addTextFromImageToPostMessage}
				/>}
				{isAttachImageMetadataModalOpen &&
					<AttachImageMetadataModal
						imageUrl={imageUrl}
						imageMetadata={mediaMetadata && mediaMetadata.length ? mediaMetadata.find(data => data.mediaId === imageUrl) : {}}
						isAttachImageMetadataModalOpen={isAttachImageMetadataModalOpen}
						changeAttachImageMetadataModalVisibility={this.changeAttachImageMetadataModalVisibility}
						handleAttachMetadata={handleAttachMetadata}
						isPriorPostedPostView={isPriorPostedPostView}
					/>}
				{isVideoThumbnailCaptureModalOpen &&
					<VideoThumbnailCaptureModal
						videoUrl={videoUrl}
						imageMaxSize={imageMaxSize}
						setThumbnailImage={(thumbnailUrl, duration, is4KVideo, videoTimeOffset) => {
							console.log({
								thumbnail: thumbnailUrl, duration, is4KVideo, videoTimeOffset, videoFileObj,
							});
							return this.uploadFiles('video', uploadPreSignedVideo(videoFileObj, {
								thumbnail: thumbnailUrl, duration, is4KVideo, videoTimeOffset,
							}));
						}}
						isVideoThumbnailCaptureModalOpen={isVideoThumbnailCaptureModalOpen}
						changeVideoThumbnailCaptureModalOpenVisibility={this.changeVideoThumbnailCaptureModalOpenVisibility}
						cancelVideoThumbnailCaptureHandler={this.cancelVideoThumbnailCaptureHandler}
					/>}
			</Fragment>
		);
	}
}

CalendarImageUploader.defaultProps = {
	isBulkUploadImages: false,
	isBulkUploadVideos: false,
	isPriorPostedPostView: false,
	toggleDisableSuggestionImages: null,
	toggleAlertModal: () => {},
	setHashtagsImageUrl: null,
	message: '',
	images: [],
	videos: [],
	setImages: null,
	video: null,
	pdf: null,
	imageSuggestion: false,
	toggleImageSuggestion: null,
	isMediaFilesLoading: false,
	changedMediaFilesLoaderVisibility: null,
	isFeaturedImageToBlogPost: false,
	dropZoneTitle: 'Add photo or video',
	selectedAccounts: [],
	audio: null,
	links: [],
};

CalendarImageUploader.propTypes = {
	isBulkUploadImages: PropTypes.bool,
	isBulkUploadVideos: PropTypes.bool,
	videos: PropTypes.arrayOf(PropTypes.object),
	isPriorPostedPostView: PropTypes.bool,
	toggleDisableSuggestionImages: PropTypes.func,
	toggleAlertModal: PropTypes.func,
	setHashtagsImageUrl: PropTypes.func,
	message: PropTypes.string,
	imageSuggestion: PropTypes.bool,
	toggleImageSuggestion: PropTypes.func,
	setImages: PropTypes.func,
	images: PropTypes.arrayOf(PropTypes.object),
	video: PropTypes.objectOf(PropTypes.object),
	audio: PropTypes.objectOf(PropTypes.object),
	pdf: PropTypes.objectOf(PropTypes.object),
	selectedAccounts: PropTypes.arrayOf(PropTypes.object),
	isMediaFilesLoading: PropTypes.bool,
	changedMediaFilesLoaderVisibility: PropTypes.func,
	isFeaturedImageToBlogPost: PropTypes.bool,
	dropZoneTitle: PropTypes.string,
	links: PropTypes.arrayOf(PropTypes.string),
};

export default CalendarImageUploader;
