/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './VideoGuideIframe.css';

export default class VideoGuideIframe extends Component {
	static changeVideoGuideIframeVisibility(url) {
		if (VideoGuideIframe.__instance) {
			VideoGuideIframe.__instance.__changeVideoGuideIframeVisibility(url);
		} else {
			throw new Error('There are no any instance of VideoGuideIframe. You likely forgot to add it to your layout.');
		}
	}

	constructor(props) {
		super(props);
		VideoGuideIframe.__instance = this;
	}

	state = {
		open: false,
		url: '',
	};

	__changeVideoGuideIframeVisibility = (url = '') => {
		const { open } = this.state;
		if (open) {
			this.setState({ open: false, url });
		} else {
			this.setState({ open: true, url });
		}
	};

	render() {
		const { open, url } = this.state;
		if (!open) {
			return null;
		}

		return ReactDOM.createPortal(
			<div className="video-guide-iframe">
				<iframe
					src={`${url}?autoplay=1`}
					allow="autoplay"
					title="showing-video-guide"
					frameBorder="0"
					scrolling="no"
					style={{
						width: '100%',
						height: '100%',
					}}
				/>
				<div className="close-icon" onClick={this.__changeVideoGuideIframeVisibility}>×</div>
			</div>,
			document.body,
		);
	}
}
