import moment from 'moment';
import { getNewDateWithoutSettingTimezone } from './dateHelper';


export const hasSubscription = ({ subscription = {}, timezone } = {}, checkForExt = false) => {
	if (subscription.expirationDate) {
		const buildDate = getNewDateWithoutSettingTimezone(
			subscription.expirationDate,
			timezone || moment.tz.guess(),
		);
		const checkPlan = checkForExt ? true : subscription.plan !== 'extension';
		return moment(`${buildDate.date} ${buildDate.time}`, 'YYYY-MM-DD HH:mm').toDate() > new Date() && checkPlan;
	}
	if (subscription.paymentAmount === 0) {
		return false;
	}
	return null;
};


export const hasSubscriptionCanceled = ({
	subscription: {
		paymentProviderData: { subscriptionId } = {},
		paymentProvider,
	} = {},
} = {}) => paymentProvider === 'recurly' && subscriptionId === (null || undefined);
