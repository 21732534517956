/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

import HelloWoofyExclusiveDeal from '../../../common/components/HelloWoofyExclusiveDeal/HelloWoofyExclusiveDeal';

import './ConfirmModal.css';

const ConfirmModal = ({
	isOpen,
	confirmActionText = 'Yes',
	rejectActionText = 'No',
	onConfirm,
	onClose,
	message,
	isWarningMessageForSocialNetworks,
	needToShowHelloWoofyExclusiveDeal,
	exclusiveMsg = '',
}) => (
	<Modal isOpen={isOpen} className="confirm-modal" centered>
		<ModalBody className={isWarningMessageForSocialNetworks ? 'warning-message-for-social-networks' : ''}>{message}
			{needToShowHelloWoofyExclusiveDeal && <HelloWoofyExclusiveDeal msg={exclusiveMsg} />}
		</ModalBody>
		<ModalFooter>
			<Button
				color="light"
				onClick={onClose}
				className={`confirm-no ${!onConfirm ? 'only' : ''}`}
			>{rejectActionText}
			</Button>
			{onConfirm &&
				<Button color="primary" onClick={onConfirm} className="confirm-yes">{confirmActionText}</Button>}
		</ModalFooter>
	</Modal>
);

ConfirmModal.defaultProps = {
	isWarningMessageForSocialNetworks: false,
	needToShowHelloWoofyExclusiveDeal: false,
};

export default ConfirmModal;
