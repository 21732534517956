/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { getCalendar } from '../../../common/calendarHelper';
import { post, getProps, put, httpDelete, get } from '../../../common/httpRequests';
import { CAMPAIGN_URL, GENERATE_CAMPAIGNS_DATES_URL, POST_URL } from '../../../common/apiRouting';

export function createCampaign(campaign) {
	const data = getProps(campaign, [
		'name',
		'accountId',
		'accountIds',
		'patternSchedule',
		'postFrequency',
		'postTime',
		'dates',
		'startDate',
		'endDate',
		'libraryCategories',
		'calendarId',
		'libraryPostPriority',
		'promiseContent',
		'isUserWantToRepeatContent',
		'accountsMetadata',
	]);

	return post(CAMPAIGN_URL, data, true)
		.then(res => res.json());
}

export function updateCampaign(campaign) {
	const data = getProps(campaign, [
		'name',
		'accountId',
		'accountIds',
		'patternSchedule',
		'postFrequency',
		'postTime',
		'dates',
		'startDate',
		'endDate',
		'id',
		'libraryCategories',
		'calendarId',
		'libraryPostPriority',
		'promiseContent',
		'isUserWantToRepeatContent',
		'accountsMetadata',
	]);

	const id = campaign.id || campaign._id;

	return put(`${CAMPAIGN_URL}/${id}`, data, true)
		.then(res => res.json());
}

export function deleteCampaign(campaignId) {
	return httpDelete(`${CAMPAIGN_URL}/${campaignId}`, true);
}

export function generateCampaignsDatesForPattern(data) {
	return post(GENERATE_CAMPAIGNS_DATES_URL, data, true)
		.then(res => res.json());
}

export function getPostsCountPerDay(data) {
	const { dateStart, dateEnd } = data;
	return get(`${POST_URL}/count?type=campaignCreate&calendarId=${getCalendar()}&dateStart=${dateStart}&dateEnd=${dateEnd}`, true)
		.then(res => res.json());
}
