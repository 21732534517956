/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Loader from '../LoaderAnimation/Loader';
import { getWords } from './WordsApi.action';
import { getEmojiAndHashtags } from '../PredictiveLinguistics/PredictiveLinguistics.action';
import { debounce } from '../../../helpers/debounce';
import './HighlightWord.css';

class HighlightWord extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loader: true,
			words: null,
		};
		this.debouncedHashtags = this.debouncedHashtags.bind(this);
		this.handleChooseWord = this.handleChooseWord.bind(this);
	}

	componentDidMount() {
		const { highlightWord, hashtagSuggestion } = this.props;

		if (hashtagSuggestion) {
			return;
		}

		getWords(highlightWord)
			.then(res => {
				if (this.isCancelled) {
					return;
				}

				if (res) {
					this.setState({ loader: false, words: res.synonyms ? res.synonyms : [] });
				}
			})
			.catch(() => {
				if (this.isCancelled) {
					return;
				}

				this.setState({ loader: false, words: [] });
			});
	}

	componentWillReceiveProps(props) {
		if (props.hashtagSuggestion) {
			this.debouncedHashtags(props.highlightWord);
		}
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	handleChooseWord = (word) => {
		const { hashtagSuggestion } = this.props;
		this.setState({ loader: false, words: [] });
		this.props.replaceWord(word, hashtagSuggestion);
	};

	debouncedHashtags = debounce((text) => {
		if (!text) {
			return;
		}
		this.emojiAndHashtagsAbortController = new window.AbortController();
		getEmojiAndHashtags(text, this.emojiAndHashtagsAbortController)
			.then((res) => {
				const { hashtags = [] } = res;
				if (this.isCancelled) {
					return;
				}

				if (hashtags.length === 0) {
					this.setState({ loader: false, words: [] });
				}

				if (res) {
					this.setState({ loader: false, words: hashtags });
				}

				this.emojiAndHashtagsAbortController = null;
			})
			.catch(() => {
				if (this.isCancelled) {
					return;
				}

				this.setState({ loader: false, words: [] });
			});
	}, 250);

	render() {
		return (
			<div className="highlight" style={this.props.highlightStyle}>
				{this.state.loader ? <Loader /> : null}
				{!this.state.loader && this.state.words.length ?
					<Scrollbars style={{ width: '100%', height: 110 }}>
						{this.state.words.map(word => (<div key={word} className="word" onMouseDown={(e) => { e.preventDefault(); this.handleChooseWord(word); }}>{word}</div>))}
					</Scrollbars> : null}
				{!this.state.loader && !this.state.words.length ?
					<div className="word-out">{`No match found for "${this.props.highlightWord}"`}</div> : null}
			</div>
		);
	}
}

export default HighlightWord;
