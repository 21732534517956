/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import urlToImage from '../../../images/urlToImage.svg';

import GetImagesFromUrlModal from './GetImagesFromUrlModal/GetImagesFromUrlModal';

import './GetImagesFromUrl.css';

const GetImagesFromUrl = ({
	addedImageToPost, links, images, maxFilesCount,
}) => {
	const [isGetImagesFromURLModalOpen, changeGetImagesFromURLModalVisibility] = useState(false);
	return (
		<Fragment>
			<img
				src={urlToImage}
				alt="open-graph"
				className="get-image-from-url"
				onClick={() => changeGetImagesFromURLModalVisibility(true)}
			/>
			<GetImagesFromUrlModal
				isModalOpen={isGetImagesFromURLModalOpen}
				changeGetImagesFromURLModalVisibility={changeGetImagesFromURLModalVisibility}
				addedImageToPost={addedImageToPost}
				linksFromMessage={links}
				images={images}
				maxFilesCount={maxFilesCount}
			/>
		</Fragment>);
};

GetImagesFromUrl.defaultProps = {
	links: [],
	images: [],
	maxFilesCount: 10,
};

GetImagesFromUrl.propTypes = {
	addedImageToPost: PropTypes.func.isRequired,
	links: PropTypes.arrayOf(PropTypes.string),
	images: PropTypes.arrayOf(PropTypes.string),
	maxFilesCount: PropTypes.number,
};

export default GetImagesFromUrl;
