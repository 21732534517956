/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component } from 'react';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Alert,
	Form,
	FormGroup,
	Input,
} from 'reactstrap';
import { Grid } from '@mui/material';
import uuid from 'uuid';
import postIcon from '../../../images/post-icon.svg';
import searcIcon from '../../../images/search.svg';
import artBoard from '../../../images/artboard.png';
import './ImageSuggestion.css';

import {
	getImageSuggestions,
	getAllBannerBearTemplates,
	getImageFromBannerBear,
	creatBannerBearImageFromTemplate,
} from './ImageSuggestion.action';
// eslint-disable-next-line import/first
import Loader from '../LoaderAnimation/Loader';
import BannerBearEditModal from '../../../componentsNew/BannerBearEditModal/BannerBearEditModal';
import { uploadPreSignedImage } from '../../../components/Calendar/CalendarImageUploader/CalendarImageUploader.action';
import { DELETE_FILES_URL } from '../../apiRouting';
import { getToken } from '../../auth';
import { subsActions, subsErrorReasons } from '../../../constants/subscriptionValidation';

const defaultNumberOfImagesLimits = 10;
const bulkNumberOfImagesLimits = 30;
const featuredImageNumberOfImagesLimits = 1;

class ImageSuggestion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			images: [],
			selectedImages: [],
			loader: true,
			errorMessage: '',
			noImages: '',
			errors: false,
			search: {
				value: '',
				isValid: false,
			},
			selectLoader: false,
			activeTab: 'stock',
			templates: [],
			templateLoader: true,
			openBannerBearModal: false,
			curruntTemplate: {},
			curruntTemplateImage: '',
			temporaryImageList: [],
			selectedTag: '',
			// todo this is the list of the tags which will be used in the related templates and fatch based on the template. Asign all the tags in the bannerbear template.
			tagsList: [
				'Instagram Post',
				'Quote',
				'Facebook Post',
				'Social Media',
				'tag1',
			],
		};
	}

	componentDidMount() {
		const {
			isLoadVideo,
			isBulkUploadImages,
			isJournalPost,
			isFeaturedImageToBlogPost,
		} = this.props;
		const needToShowLoader =
      isBulkUploadImages || isJournalPost || isFeaturedImageToBlogPost;

		const imagesLimits = this.detectNumberOfImagesLimits();

		if (needToShowLoader) {
			this.setState({
				loader: false,
			});
		}
		if (isLoadVideo) {
			this.setState({
				loader: false,
				errors: true,
			});

			const message = (
				<div style={{ color: '#e84352' }}>
          Maximum {imagesLimits} images or one video can be uploaded, please
          remove some of them to choose other images.
				</div>
			);
			this.props.toggleAlertModal(message);

			return;
		}

		this.setState({
			selectedImages: this.props.images,
			noImages:
        this.props.images.length > imagesLimits
        	? `Maximum ${imagesLimits} images can be uploaded, please remove some of them to choose other images.`
        	: '',
		});

		if (this.props.images.length < imagesLimits && this.props.message.length) {
			this.getImages(this.props.message);
		} else {
			this.setState({ loader: false });
		}
		// getAllBannerBearTemplates()
		// 	.then((response) => {
		// 		console.log('responsedata', response.data);
		// 		this.setState({ templates: response.data, templateLoader: false });
		// 	})
		// 	.catch((err) => {
		// 		console.log('Error while get templates', err);
		// 		this.setState({ templates: [], templateLoader: false });
		// 	});
	}

	// load all the bannerbaer template while opening the image suggestion modal

  loadAllBannerBearTemplates = (name, tag) =>
  	getAllBannerBearTemplates(name, tag)
  		.then((response) => {
  			console.log('responsedata', response.data);
  			this.setState({ templates: response.data, templateLoader: false });
  		})
  		.catch((err) => {
  			console.log('Error while get templates', err);
  			this.setState({ templates: [], templateLoader: false });
  		});

  componentWillUnmount() {
  	if (this.abortImageSuggestionController) {
  		this.abortImageSuggestionController.abort();
  	}
  	this.isCancelled = true;
  }

  detectNumberOfImagesLimits = () => {
  	const {
  		isBulkUploadImages,
  		isJournalPost,
  		isFeaturedImageToBlogPost,
  	} = this.props;
  	if (isJournalPost || isBulkUploadImages) {
  		return bulkNumberOfImagesLimits;
  	} else if (isFeaturedImageToBlogPost) {
  		return featuredImageNumberOfImagesLimits;
  	}
  	return defaultNumberOfImagesLimits;
  };

  getImages = (value) => {
  	if (this.abortImageSuggestionController) {
  		this.abortImageSuggestionController.abort();
  	}
  	this.setState({ loader: true });
  	this.abortImageSuggestionController = new window.AbortController();
  	getImageSuggestions({ query: value }, this.abortImageSuggestionController)
  		.then((arrayOfImages) => {
  			if (this.isCancelled) {
  				return;
  			}
  			const images = arrayOfImages.map((item) => {
  				const id = uuid();
  				return { image: item, id };
  			});
  			this.setState({ images, loader: false });
  		})
  		.catch(() => {
  			if (this.isCancelled) {
  				return;
  			}
  			this.setState({ images: [], loader: false });
  		})
  		.finally(() => (this.abortImageSuggestionController = null));
  };

  isGifImage = (imageUrl) => /.gif/g.test(imageUrl);

  isActive = (image) => {
  	const { isBulkUploadImages, isJournalPost } = this.props;
  	const { selectedImages } = this.state;

  	const selectedImagesCount = selectedImages.length;
  	const allowedSuggestionImagesCount = this.detectNumberOfImagesLimits();

  	if (selectedImages.some((el) => (el.url || el.image) === image.image)) {
  		return 'active';
  	} else if (
  		!isJournalPost &&
      !isBulkUploadImages &&
      selectedImagesCount > 0 &&
      this.isGifImage(image.image)
  	) {
  		return 'no-active';
  	} else if (
  		!isJournalPost &&
      !isBulkUploadImages &&
      (selectedImagesCount >= allowedSuggestionImagesCount ||
        (selectedImagesCount > 0 &&
          selectedImages.some((el) => this.isGifImage(el.url || el.image))))
  	) {
  		return 'no-active';
  		// check limits for journal posts
  	} else if (
  		(isJournalPost || isBulkUploadImages) &&
      selectedImagesCount >= allowedSuggestionImagesCount
  	) {
  		return 'no-active';
  	}
  	return '';
  };

  chooseImage = (image) => {
  	if (this.isActive(image) !== 'no-active') {
  		const { selectedImages } = this.state;
  		const choosedImagesResult = selectedImages.some((el) => (el.url || el.image) === image.image)
  			? selectedImages.filter((el) => (el.url || el.image) !== image.image)
  			: [...selectedImages, image];
  		this.setState({
  			selectedImages: choosedImagesResult,
  			errorMessage: '',
  		});
  	} else {
  		this.setState({
  			errorMessage: 'Oops, you could not upload more images.',
  		});
  	}
  };

  selectImages = () => {
  	this.setState({ selectLoader: true });
  	const { selectedImages } = this.state;
  	const imagePromises = selectedImages.map((imgObj) =>
  			new Promise((resolve, reject) => {
  				fetch(`https://cors-anywhere.hellowoofy.com/${imgObj.image}`)
  					.then((r) =>
  						r
  							.blob()
  							.then((blobResponse) => {
  								resolve(blobResponse);
  							})
  							.catch((err) => reject(err)))
  					.catch((err) => reject(err));
  			}));
  	Promise.all(imagePromises)
  		.then((imagesResponseArray) => {
  			if (imagesResponseArray.some((i) => i.size > this.props.imageMaxSize)) {
  				this.setState({ selectedImages: [] });
  				this.setState({
  					errorMessage: `Images should be less than ${this.props
  						.imageMaxSize / 1048576} Mb`,
  				});
  			} else {
  				this.props.setImages(selectedImages);
  				this.props.onToggle();
  			}
  		})
  		.catch((err) => console.error(err))
  		.finally(() => this.setState({ selectLoader: false }));
  };
  deleteTemporaryFilesFromGoogleStorage = (filesList) => {
  	if (!filesList || filesList.length === 0) return Promise.resolve();
  	return fetch(`${DELETE_FILES_URL}`, {
  		method: 'POST',
  		headers: {
  			Authorization: getToken(),
  			'Content-type': 'application/json',
  		},
  		body: JSON.stringify({
  			files: filesList,
  		}),
  	})
  		.then((res) => res.json())
  		.then(() => {
  			Promise.resolve();
  		})
  		.catch((err) => Promise.reject(err));
  };

  handleInputChange = (event) => {
  	event.persist();
  	const { name, value } = event.target;

  	this.setState((prevState) => ({
  		[name]: {
  			...prevState[name],
  			value,
  		},
  	}));
  };

  handleCloseBannerBearModal = () => {
  	this.setState({
  		openBannerBearModal: false,
  	});
  };

  handleValidate = () => {
  	const { search } = this.state;
  	const isValid = search.value !== '';

  	this.setState({
  		search: { ...search, isValid },
  	});

  	return isValid;
  };

  handleSubmitForm = (event) => {
  	event.preventDefault();
  	return this.handleValidate();
  };

  // in search click of enter searching for both banner bear and pexels search
  handleKeyPress = (event) => {
  	const { search, selectedTag } = this.state;
  	if (event.charCode === 13) {
  		this.getImages(search.value);
  		// this.loadAllBannerBearTemplates(search.value, selectedTag);
  	}
  };

  handleAtiveTab = (tab) =>
  	this.setState({
  		activeTab: tab,
  	});

  handleOpenBannerBearModal = (templateUuid) => {
  	this.setState((prevState) => ({
  		curruntTemplate: prevState?.templates?.find((template) => template.uid === templateUuid),
  		curruntTemplateImage: prevState?.templates?.find((template) => template.uid === templateUuid)?.preview_url,
  		openBannerBearModal: true,
  	}));
  };

  // handle Get image from the banner bear
  handleGetImage = async (id) =>
  	new Promise((resolve) => {
  		let imgResponse;
  		const token = setInterval(async () => {
  			const responseCheck = await getImageFromBannerBear(id).then((response) => response.data);
  			if (!responseCheck?.data?.image_url) {
  				imgResponse = responseCheck;
  				clearInterval(token);
  				return resolve(imgResponse);
  			}
  		}, 2500);
  	});

  // creating the image from the bannerbear template
  hanldeGenerateImageFromBannerBear = (uid, modifications, handleEnable) => {
  	creatBannerBearImageFromTemplate({
  		imageData: {
  			template: uid,
  			modifications,
  		},
  	}).then(async (response) => {
  		// setTimeout(async () => {
  		if (response?.data?.uid) {
  			const data = await this.handleGetImage(response?.data?.uid);
  			this.setState({
  				curruntTemplateImage: data?.image_url,
  			});
  			handleEnable();
  		}
  		// }, 1500);
  	});
  };

  // handling the temporry image used in the bannerbear template
  handleUploadTemporaryImage = (uploadedImg) => {
  	const { type } = uploadedImg;
  	console.log(type, 'check type');
  	return uploadPreSignedImage(uploadedImg, type).then((data) => {
  		const url = data?.image;
  		const imgId = url?.substring(url?.lastIndexOf('/') + 1);
  		this.setState((prevState) => ({
  			temporaryImageList: [...prevState?.temporaryImageList, imgId],
  		}));
  		return url;
  	});
  };

  // after used as post delete the original image used in the template.
  finalizeBannerBearImage = () => {
  	this.setState({
  			openBannerBearModal: false,
  			selectedImages: [{ image: this.state.curruntTemplateImage }],
  		}, () => this.selectImages());

  	this.deleteTemporaryFilesFromGoogleStorage(this.state.temporaryImageList);
  };

  // handle the dropdown selection of the tag
  handleSelectTag = (e) => {
  	console.log(e.target.value, 'handleselect');
  	this.setState({
  		selectedTag: e.target.value,
  	});
  	this.loadAllBannerBearTemplates(this.state.search.value, e.target.value);
  };

  render() {
  	const {
  		isOpen, onToggle, showBannerBear, setUpgradeModalMetadata, setIsUpgradeModalShown,
  	} = this.props;
  	const {
  		images,
  		loader,
  		errorMessage,
  		noImages,
  		errors,
  		selectedImages,
  		selectLoader,
  		templateLoader,
  		templates,
  		openBannerBearModal,
  		curruntTemplate,
  		curruntTemplateImage,
  		selectedTag,
  		tagsList,
  	} = this.state;

  	const selectedImagesCount = selectedImages.length;

  	return (
  		<Modal
  			isOpen={isOpen && !errors}
  			// toggle={() => onToggle(false)}
  			size="lg"
  			className="image-suggestion-modal"
  		>
  			<ModalHeader toggle={() => onToggle(false)}>
  				<img src={postIcon} alt="suggestion" />
          			Select Suggested Images
  				{/* {this.state.activeTab === 'bannerBear' && (
  					<div>
  						<select
  							className="tag-select"
  							value={selectedTag}
  							onChange={this.handleSelectTag}
  						>
  							{' '}
  							<option key="all" value="">
                  				All
  							</option>
  							{tagsList?.map((tag) => (
  								<option value={tag} key={tag}>
  									{tag}
  								</option>
  							))}
  						</select>
  					</div>
  				)} */}
  				<Form
  					className="image-suggestion-search"
  					onSubmit={this.handleSubmitForm}
  				>
  					<FormGroup>
  						<img
  							src={searcIcon}
  							className="image-suggestion-search-icon"
  							alt="search-icon"
  						/>
  						<Input
  							type="search"
  							name="search"
  							className="image-suggestion-search-input"
  							placeholder="Search"
  							onChange={this.handleInputChange}
  							onKeyPress={this.handleKeyPress}
  						/>
  					</FormGroup>
  				</Form>
  			</ModalHeader>
  			<ModalBody className="image-suggestion-wrap">
  				{/* <Grid container spacing={2}>
  					<Grid item xs={6}>
  						<div
  							className={`image-suggestion-tab-button ${this.state
  								.activeTab === 'bannerBear' && 'active-tab'}`}
  							onClick={() => {
  								if (showBannerBear) {
  									return this.handleAtiveTab('bannerBear');
  								}
  								setUpgradeModalMetadata({
  									reason: subsErrorReasons.no_access,
  									action: subsActions.bannerBearImagesPerMonth,
  								});
  								return setIsUpgradeModalShown(true);
  							}}
  						>
						Banner Bear
  						</div>
  					</Grid>
  					<Grid item xs={6}>
  						<div
  							className={`image-suggestion-tab-button ${this.state
  								.activeTab === 'stock' && 'active-tab'}`}
  							onClick={() => this.handleAtiveTab('stock')}
  						>
                		Stock Images
  						</div>
  					</Grid>
  				</Grid> */}
  				{this.state.activeTab === 'stock' && (
  					<>
  						{errorMessage && (
  							<Alert color="danger" style={{ width: '100%' }}>
  								{errorMessage}
  							</Alert>
  						)}
  						{loader && <Loader />}
  						{!loader && images.length
  							? images.map((img) => (
  								<div
  									key={img.id}
  									className={`image-suggestion-item ${this.isActive(img)}`}
  									style={{ backgroundImage: `url(${img.image})` }}
  									onClick={() => this.chooseImage(img)}
  								/>
  							))
  							: null}
  						{!loader && !images.length ? (
  							<div className="no-images">
  								<img
  									src={artBoard}
  									width={261}
  									height={131}
  									className="art-board"
  									alt="art-board"
  								/>
  								{noImages.length ? noImages : 'Images not found.'}
  							</div>
  						) : null}
  					</>
  				)}
  				{/* {this.state.activeTab === 'bannerBear' && (
  					<>
  						{templateLoader && <Loader />}

  						{!templateLoader && templates.length
  							? templates.map((img) => (
  								<div
  									key={img.self}
  									className={`image-suggestion-item ${this.isActive(img)}`}
  									style={{ backgroundImage: `url(${img.preview_url})` }}
  									onClick={() => this.handleOpenBannerBearModal(img.uid)}
  								/>
  							))
  							: null}
  						{!templateLoader && !templates.length ? (
  							<div className="no-images">
  								<img
  									src={artBoard}
  									width={261}
  									height={131}
  									className="art-board"
  									alt="art-board"
  								/>
  								{noImages.length ? noImages : 'Images not found.'}
  							</div>
  						) : null}
  					</>
  				)} */}
  			</ModalBody>
  			{!loader && !images.length ? null : (
  				<ModalFooter>
  					{selectLoader && <Loader />}
  					<div className="submit-buttons">
  						<Button color="light" onClick={() => onToggle(false)}>
                Cancel
  						</Button>
  						<Button
  							className="select-images"
  							color="primary"
  							onClick={() => this.selectImages()}
  							disabled={selectLoader}
  						>
  							{`Select images (${selectedImagesCount})`}
  						</Button>
  					</div>
  				</ModalFooter>
  			)}

  			{/* <BannerBearEditModal
  				open={openBannerBearModal}
  				handleClose={this.handleCloseBannerBearModal}
  				template={curruntTemplate}
  				finalizeBannerBearImage={this.finalizeBannerBearImage}
  				hanldeGenerateImageFromBannerBear={
  					this.hanldeGenerateImageFromBannerBear
  				}
  				curruntTemplateImage={curruntTemplateImage}
  				handleUploadTemporaryImage={this.handleUploadTemporaryImage}
  			/> */}
  		</Modal>
  	);
  }
}

ImageSuggestion.defaultProps = {
	isBulkUploadImages: false,
	isJournalPost: false,
	isFeaturedImageToBlogPost: false,
	message: '',
};

export default ImageSuggestion;
