import { Autocomplete } from './Autocomplete';
import autocompleteStrategy from './autocompleteStrategy';

export default class AutocompletePlugin {
	decorators = [
  	{
  		strategy: autocompleteStrategy,
  		component: Autocomplete,
  	},
	];
}
