/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, October 2020
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './TextSummariesCounter.css';

const TextSummariesCounter = ({ countWords }) => {
	const [textLength, changeTextLength] = useState(0);
	useEffect(() => {
		if (countWords) {
			changeTextLength(countWords);
		} else {
			changeTextLength(0);
		}
	}, [countWords]);

	return (
		<div className="text-length-counter-container">
			<div className="length-counter">{`You need to have at least 100 words for a good summary or URL, you currently have ${textLength}.`}</div>
		</div>
	);
};

TextSummariesCounter.propTypes = {
	countWords: PropTypes.number.isRequired,
};

export default TextSummariesCounter;
