/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, November 2020
 */

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Alert, Button, FormGroup, Input, Label } from 'reactstrap';

import './UTMParamsForm.css';

const UTMParamsForm = ({ link, changeUTMParamsFormVisibility, changedLinkToLinkWithUTMParams }) => {
	const [linkWithUtmParams, changedLinkWithUtmParams] = useState('');
	const [utmSource, changedUtmSourceValue] = useState('');
	const [utmMedium, changedUtmMediumValue] = useState('');
	const [utmCampaign, changedUtmCampaignValue] = useState('');
	const [utmTerm, changedUtmTermValue] = useState('');
	const [utmContent, changedUtmContentValue] = useState('');
	const [errorMessage, changedErrorMessage] = useState('');

	useEffect(() => {
		const arrayOfParams = [];
		if (utmSource.trim()) {
			arrayOfParams.push(`utm_source=${utmSource}`);
		}
		if (utmMedium.trim()) {
			arrayOfParams.push(`utm_medium=${utmMedium}`);
		}
		if (utmCampaign.trim()) {
			arrayOfParams.push(`utm_campaign=${utmCampaign}`);
		}
		if (utmTerm.trim()) {
			arrayOfParams.push(`utm_term=${utmTerm}`);
		}
		if (utmContent.trim()) {
			arrayOfParams.push(`utm_content=${utmContent}`);
		}
		const linkWithParams = link.endsWith('/') ? `${link}?` : `${link}/?`;
		const linkWithUTMParams = `${linkWithParams}${arrayOfParams.join('&')}`;
		changedLinkWithUtmParams(linkWithUTMParams);
	}, [utmSource, utmMedium, utmCampaign, utmTerm, utmContent]);

	const validateUserData = () => {
		if (!utmSource.trim() || !utmMedium.trim() || !utmCampaign.trim()) {
			return 'Please fill all required fields';
		}
		return false;
	};

	const handleCancelButtonClick = () => {
		changeUTMParamsFormVisibility(false);
	};

	const handleApplyButtonClick = () => {
		const dataError = validateUserData();
		if (dataError) {
			return changedErrorMessage(dataError);
		}
		changedLinkToLinkWithUTMParams(linkWithUtmParams, link);
		return changeUTMParamsFormVisibility(false);
	};

	return (
		<Fragment>
			<FormGroup>
				<Label className="utm-params-form-label">Link</Label>
				<div className="link-preview">{linkWithUtmParams}</div>
			</FormGroup>
			{errorMessage && <Alert color="danger">{errorMessage}</Alert>}
			<FormGroup>
				<Label className="utm-params-form-label">
					Campaign Source<span className="utm-params-form-required">*</span>
				</Label>
				<Input
					type="text"
					value={utmSource}
					onChange={event => changedUtmSourceValue(event.target.value)}
					placeholder="i.e specific portal name or social network name"
				/>
			</FormGroup>
			<FormGroup>
				<Label className="utm-params-form-label">
					Campaign Medium<span className="utm-params-form-required">*</span>
				</Label>
				<Input
					type="text"
					value={utmMedium}
					onChange={event => changedUtmMediumValue(event.target.value)}
					placeholder="i.e banner, email, social media"
				/>
			</FormGroup>
			<FormGroup>
				<Label className="utm-params-form-label">
					Campaign Name<span className="utm-params-form-required">*</span>
				</Label>
				<Input
					type="text"
					value={utmCampaign}
					onChange={event => changedUtmCampaignValue(event.target.value)}
					placeholder="i.e name of your campaign"
				/>
			</FormGroup>
			<FormGroup>
				<Label className="utm-params-form-label">Campaign Term</Label>
				<Input
					type="text"
					value={utmTerm}
					onChange={event => changedUtmTermValue(event.target.value)}
					placeholder="i.e keyword for your Google Analytics report"
				/>
			</FormGroup>
			<FormGroup>
				<Label className="utm-params-form-label">Campaign Content</Label>
				<Input
					type="text"
					value={utmContent}
					onChange={event => changedUtmContentValue(event.target.value)}
					placeholder="i.e what language or product "
				/>
			</FormGroup>
			<FormGroup className="utm-params-form-button-container">
				<Button
					color="light"
					onClick={handleCancelButtonClick}
					className="utm-params-form-button"
				>CANCEL
				</Button>
				<Button
					color="primary"
					onClick={handleApplyButtonClick}
					className="utm-params-form-button"
				>APPLY
				</Button>
			</FormGroup>
		</Fragment>
	);
};

UTMParamsForm.propTypes = {
	link: PropTypes.string.isRequired,
	changeUTMParamsFormVisibility: PropTypes.func.isRequired,
	changedLinkToLinkWithUTMParams: PropTypes.func.isRequired,
};

export default UTMParamsForm;
