import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Modifier, EditorState } from 'draft-js';
import { isMobile } from 'react-device-detect';
import { getSuggestion } from '../../../common/components/PredictiveLinguistics/PredictiveLinguistics.action';
import { useDebounce } from '../../../helpers/debounce';
import {
	getCurrentContentBlockKey,
	getCurrentContentBlockText,
} from '../../../common/editorHelper';

import './Autocomplete.css';
import { escapeRegExp } from './autocompleteStrategy';

export const Autocomplete = ({
	decoratedText = '',
	dir,
	entityKey,
	getEditorState,
	offsetKey,
	setEditorState,
	contentState,
	...props
}) => {
	const [suggestion, setSuggestion] = useState('');
	const [loading, setLoading] = useState(false);
	const abortController = useRef(null);
	const element = useRef(null);

	const editorState = getEditorState();

	let touchStart = {};
	const currentContentBlockKey = getCurrentContentBlockKey(editorState);
	const currentContentBlockText =
		getCurrentContentBlockText(currentContentBlockKey, contentState);

	const handleTabPressAndSwipeRight = useCallback(
		event => {
			if (event.keyCode === 13) {
				if (abortController.current) {
					abortController.current.abort();
				}
				setSuggestion('');
			}

			let xDiff;
			let yDiff;

			if (event.type === 'touchmove' && (!touchStart.xDown || !touchStart.yDown)) {
				event.preventDefault();
				return;
			} else if (event.type === 'touchmove') {
				event.preventDefault();
				const xUp = event.touches[0].clientX;
				const yUp = event.touches[0].clientY;

				xDiff = touchStart.xDown - xUp;
				yDiff = touchStart.yDown - yUp;
			}

			if ((event.keyCode === 9 || (Math.abs(xDiff) > Math.abs(yDiff) && xDiff < 0)) && suggestion && decoratedText !== suggestion) {
				event.preventDefault();

				if (!currentContentBlockText || !currentContentBlockText.length) {
					return;
				}

				const currentContent = editorState.getCurrentContent();
				const selection = editorState.getSelection();

				const blockSelection = selection.merge({
					anchorOffset: currentContentBlockText.length - decoratedText.length,
					focusOffset: currentContentBlockText.length,
				});

				const newContentState = Modifier.replaceText(
					currentContent,
					blockSelection,
					suggestion,
				);

				const updatedEditorState = EditorState.push(editorState, newContentState);

				setEditorState(updatedEditorState);
			}
		},
		[suggestion, decoratedText],
	);

	const getTouches = (event) => event.touches ||	event.originalEvent.touches;

	const handleTouchStart = useCallback(event => {
		const firstTouch = getTouches(event)[0];
		touchStart = { xDown: firstTouch.clientX, yDown: firstTouch.clientY };
	}, [touchStart]);

	useEffect(() => {
		const { parentElement: { parentElement: swipeElement } } = element.current;
		window.addEventListener('keydown', handleTabPressAndSwipeRight, false);
		swipeElement.addEventListener('touchstart', handleTouchStart, true);
		swipeElement.addEventListener('touchmove', handleTabPressAndSwipeRight, false);

		return () => {
			window.removeEventListener('keydown', handleTabPressAndSwipeRight);
			swipeElement.removeEventListener('touchstart', handleTouchStart);
			swipeElement.removeEventListener('touchmove', handleTabPressAndSwipeRight);
		};
	}, [suggestion, decoratedText]);

	// Since next effect is debounced, his clear is debounced too, so need to clear immediately here.
	useEffect(() => () => {
		setSuggestion('');
	}, [decoratedText]);

	useEffect(() => {
		if (!abortController.current) {
			abortController.current = new window.AbortController();
		}
		return () => {
			if (abortController.current) {
				abortController.current.abort();
			}
		};
	}, []);

	useDebounce(() => {
		if (!decoratedText.trim() || decoratedText !== currentContentBlockText) return;

		if (loading) {
			abortController.current.abort();
			abortController.current = null;
			abortController.current = new window.AbortController();
		}
		setLoading(true);
		getSuggestion(decoratedText, abortController.current).then(res => {
			setSuggestion(res.suggestion);
			setLoading(false);
		});

		return () => {
			setSuggestion('');
		};
	}, 300, [decoratedText]);

	const suggestedText = useMemo(() => {
		// TODO: need to add logic for set suggestion for active line/string(we can add event listener for click, but in decoratedText we have only previous line text)
		if (suggestion.trim()) {
			const escapedDecoratedText = new RegExp(escapeRegExp(decoratedText.replace(/ +/g, ' ').trim()), 'i');
			return suggestion.replace(escapedDecoratedText, '');
		}
		return '';
	}, [suggestion]);

	return (
		<span
			className="autocomplete__wrapper"
			{...props}
			ref={element}
		>
			{props.children}
			{!!suggestedText.length && (
				<span className="autocomplete__suggestion" data-suggestion={suggestedText}>
					{isMobile ? <span className="autocomplete__swipe-button" /> : <span className="autocomplete__tab-button" />}
				</span>
			)}
		</span>
	);
};
