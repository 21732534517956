export const subscriptionType = {
	monthly_pro: 'monthly_pro',
	annual_pro: 'annual_pro',
	monthly_smb: 'monthly_smb',
	annual_smb: 'annual_smb',
	monthly_agency: 'monthly_agency',
	annual_agency: 'annual_agency',
	monthly_single: 'monthly_single',
	annual_single: 'annual_single',
	monthly_agencyplus: 'monthly_agencyplus',
	annual_agencyplus: 'annual_agencyplus',
	monthly_enterprise: 'monthly_enterprise',
	annual_enterprise: 'annual_enterprise',
	monthly_basic: 'monthly_basic',
	annual_basic: 'annual_basic',
	monthly_essentials: 'monthly_essentials',
	annual_essentials: 'annual_essentials',
	monthly_advanced: 'monthly_advanced',
	annual_advanced: 'annual_advanced',
	monthly_business: 'monthly_business',
	annual_business: 'annual_business',
	monthly_enterprisenew: 'monthly_enterprisenew',
	annual_enterprisenew: 'annual_enterprisenew',
	monthly_basicnew: 'monthly_basicnew',
	annual_basicnew: 'annual_basicnew',
	monthly_advancednew: 'monthly_advancednew',
	annual_advancednew: 'annual_advancednew',
	monthly_businessnew: 'monthly_businessnew',
	annual_businessnew: 'annual_businessnew',
	premium: 'premium',
};

export const agencyPlan = [subscriptionType.annual_agency, subscriptionType.monthly_agency];
export const smbPlan = [subscriptionType.monthly_smb, subscriptionType.annual_smb];
export const proPlan = [subscriptionType.monthly_pro, subscriptionType.annual_pro];
export const singlePlan = [subscriptionType.monthly_single, subscriptionType.annual_single];
export const agencyPlusPlan = [subscriptionType.monthly_agencyplus, subscriptionType.annual_agencyplus];
export const enterprisePlan = [subscriptionType.monthly_enterprise, subscriptionType.annual_enterprise];
export const basicPlan = [subscriptionType.annual_basic, subscriptionType.annual_basicnew, subscriptionType.monthly_basic, subscriptionType.monthly_basicnew];
export const advancedPlan = [subscriptionType.annual_advanced, subscriptionType.annual_advancednew, subscriptionType.monthly_advanced, subscriptionType.monthly_advancednew];
export const businessPlan = [subscriptionType.annual_business, subscriptionType.annual_businessnew, subscriptionType.monthly_business, subscriptionType.monthly_businessnew];

