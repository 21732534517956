/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Form,
	FormGroup,
	Label,
	Row,
	Col,
	Alert,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import Switch from 'react-toggle-switch';

import { getCalendar } from '../../../common/calendarHelper';
import * as selectors from '../../../selectors';
import { getAccountsFromDB } from '../../../actions/accounts';
import SimilarityModal from '../../../common/components/SimilarityModal/SimilarityModal';
import DropdownSelectCategory from '../DropdownSelectCategory/DropdownSelectCategory';
import '../../../../node_modules/react-toggle-switch/dist/css/switch.min.css';
import postIcon from '../../../images/post-icon.svg';
import iconBlackTwitter from '../../../images/icon-black-twitter.svg';
import iconColorTwitter from '../../../images/icon-color-twitter.svg';
import iconBlackFacebook from '../../../images/icon-black-facebook.svg';
import iconColorFacebook from '../../../images/icon-color-facebook-by-meta.png';
import iconBlackLinkedin from '../../../images/icon-black-linkedin.svg';
import iconColorLinkedin from '../../../images/icon-color-linkedin.svg';
import iconBlackInstagram from '../../../images/icon-black-instagram.svg';
import iconColorInstagram from '../../../images/icon-color-instagram-by-meta.png';

import CalendarImageUploader from '../../Calendar/CalendarImageUploader/CalendarImageUploader';
import CalendarContentPicker from '../../Calendar/CalendarContentPicker/CalendarContentPicker';
import ManageHashtags from '../../Calendar/CalendarManageHashtags/ManageHashtags';
import Quotes from '../Quotes/Quotes';
import { createLibraryPost, updateLibraryPost, deleteFiles } from '../LibraryPosts.action';

import ModalLoader from '../../../common/components/ModalLoader/ModalLoader';
import MessageEditor from '../../../common/components/MessageEditor/MessageEditor';
import {
	urlRegexp,
	getLinksFromText,
	getHashtagsFromText,
	addOrRemoveValueFromText,
	getTextWithUTMParams,
} from '../../../common/textHelper';

import { getSimilarColor } from '../../../common/similarityHelper';

import { woofyText } from '../../../constants/woofyText';

import cloneObject from '../../../utils/cloneObject';

import ComingSoonModal from '../../Settings/ComingSoon/ComingSoonModal';
import AlertModal from '../../../common/components/AlertModal/AlertModal';
import ConfirmModal from '../../../common/components/ConfirmModal/ConfirmModal';
import LanguageTranslator from '../../../common/components/LanguageTranslator/LanguageTranslator';
import ShowingVideoGuideButton from '../../ShowingVideoGuideButton/ShowingVideoGuideButton';
import PostLimitsWarningTooltip from '../../../common/components/PostLimitsWarningTooltip/PostLimitsWarningTooltip';
import Loader from '../../../common/components/LoaderAnimation/Loader';
import TextMessageCounter from '../../../common/components/TextMessageCounter/TextMessageCounter';
import AddUTMParamsToLink from '../../../common/components/AddUTMParamsToLink/AddUTMParamsToLink';
import AddTitleToGMBPost from '../../../common/components/AddTitleToGMBPost/AddTitleToGMBPost';

import './AddContentModal.css';
import { UserSubscriptionLimitsContext, UserSubscriptionService } from '../../UserSubscriptions/UserSubscriptionService';
import CalendarSummarizeModal from '../../Calendar/CalendarSummarizeModal/CalendarSummarizeModal';
import ManageCTA from '../../Calendar/CalendarManageCTA/ManageCTA';
import { subsActions, subsErrorReasons } from '../../../constants/subscriptionValidation';
import UpgradeNowButton from '../../../common/components/UpgradePlanModal/UpgradeNowButton';
import CaptionGenerationModal from '../../../componentsNew/CaptionGenerationModal/CaptionGenerationModal';


const ManageEmojis = React.lazy(() => import(/* webpackChunkName: "manage-emojis" */'../../Calendar/CalendarManageEmojis/ManageEmojis'));

class AddContentModal extends Component {
	static contextType = UserSubscriptionLimitsContext;

	constructor(props) {
		super(props);
		this.state = {
			post: {
				message: '',
				categories: [],
				variations: [
					{ message: '', socialMedia: 'twitter' },
					{ message: '', socialMedia: 'facebook' },
					{ message: '', socialMedia: 'linkedin' },
					{ message: '', socialMedia: 'instagram' },
				],
				images: [],
				pending: false,
				rejected: false,
				draft: false,
			},
			listOfHashtags: {},
			hashtagsList: [],
			selectedOptions: [],
			textValue: props.initialText || '',
			errorMessage: '',
			socialMedia: ['twitter', 'facebook', 'linkedin', 'instagram'],
			activeSocialMedia: 'twitter',
			switchStatus: false,
			images: props.initialImages || [],
			video: {},
			dropdownOpen: false,
			quotes: null,
			link: '',
			links: [],
			similar: null,
			similarityScore: [],
			checkSimilarModal: false,
			loader: false,
			imageSuggestion: false,
			addToText: '',
			isDisableSuggestingImages: false,
			/* <AlertModal /> */
			alertModalMessage: '',
			isOpenAlertModal: false,
			isOpenConfirmModal: false,
			suggestionHashtags: [],
			emojiList: undefined,
			addEmoji: null,
			emojiCount: 15,
			hashtagsImageUrl: '',
			isMediaFilesLoading: false,
			isAddTitleToGMBPostSelected: false,
			titleOfPost: '',
			// eslint-disable-next-line
			userAccounts: [],
			summarizeModal: false,
			messageEditorFocused: true,
			callToAction: null,
			mediaMetadata: [],
			upgradeSubsBtn: false,
			openCaptionGenerationModal: false,
		};
	}

	componentDidMount() {
		const { getAccountsFromDBAction } = this.props;
		getAccountsFromDBAction(true);
		this.getCurrentPost();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.initialText && props.initialText !== state.textValue) {
			return {
				textValue: props.initialText,
			};
		}
		if (props.accountsList) {
			const { response: { items = [] } = {} } = props.accountsList;
			return {
				userAccounts: items,
			};
		}
		return null;
	}

	componentDidUpdate() {
		if (this.props.resizeHeight) {
			this.props.resizeHeight();
		}
	}

	onChangeHashtagList = (list, hashtags) => {
		const newHashtags =	hashtags.filter(hashtag => hashtag.groupId === list._id)
			.map(item => item.title);
		this.setState({
			listOfHashtags: list,
			hashtagsList: [...new Set(newHashtags)],
		});
	};

	onSelectOption = categoryId => {
		const { post, selectedOptions } = this.state;
		let newSelectedOptions;
		const isCategoryAlreadySelected = selectedOptions.some(el => el === categoryId);
		if (isCategoryAlreadySelected) {
			newSelectedOptions = selectedOptions.filter(category => category !== categoryId);
		} else {
			newSelectedOptions = [categoryId];
		}
		this.setState({ selectedOptions: newSelectedOptions, post: { ...post, categories: newSelectedOptions } });
		this.toggle();
	};

	onRemove = (field) => {
		const { video, mediaMetadata } = this.state;
		if (video && video.url === field.url) {
			this.setState({
				video: {},
			});
		}

		const images = this.state.images.filter((element) => element.id !== field.id);
		const newMediaMetadata = mediaMetadata.filter((data) => images.find((newImg) => newImg.image === data.mediaId));

		this.setState({
			images,
			mediaMetadata: newMediaMetadata,
		});
	};

	setImages = images => {
		this.setState({ images });
	};

	setVideo = (addedVideo) => {
		this.setState({ video: addedVideo });
	};

	setImage = (image) => {
		this.setState(prevState => {
			const filteredImages = prevState.images.filter((img) => (img.image ? img.image !== image.image : img !== image.image));
			return ({
				images: [...filteredImages, { image: image.image, id: image.id }],
			});
		});
	};

	getSocialIcon = (socialName) => {
		const { activeSocialMedia, switchStatus } = this.state;
		switch (socialName) {
		case 'twitter':
			return activeSocialMedia === socialName
				&& switchStatus ? iconColorTwitter : iconBlackTwitter;
		case 'facebook':
			return activeSocialMedia === socialName
				&& switchStatus ? iconColorFacebook : iconBlackFacebook;
		case 'linkedin':
			return activeSocialMedia === socialName
				&& switchStatus ? iconColorLinkedin : iconBlackLinkedin;
		case 'instagram':
			return activeSocialMedia === socialName
				&& switchStatus ? iconColorInstagram : iconBlackInstagram;
		default:
			return '';
		}
	};

	getQuote = (quote) => {
		this.setState({ addToText: quote });

		const {
			post, switchStatus, activeSocialMedia, textValue,
		} = this.state;

		if (switchStatus) {
			post.variations.map((el, index) =>
				(el.socialMedia === activeSocialMedia ?
					post.variations[index].message = `${textValue} ${quote}` : null));
		} else {
			post.message = `${textValue} ${quote}`;
		}

		this.setState({ post });
	};


	getCurrentPost = () => {
		const { currentPost } = this.props;
		const { socialMedia } = this.state;
		let post;

		if (currentPost) {
			post = cloneObject(currentPost);
			post.images = post.images.filter(img => img);

			const variations = cloneObject(currentPost.variations);
			socialMedia.map((soc, index) =>
				(variations.some(el => el.socialMedia === soc) ?
					post.variations[index] = variations.filter(el => el.socialMedia === soc)[0]
					: post.variations[index] = { message: '', socialMedia: soc }));
			post.draft = false;

			this.setState({
				post,
				video: post.video || {},
				textValue: variations.length ? variations[0].message : post.message,
				selectedOptions: post.categories,
				quotes: null,
				switchStatus: !!variations.length,
				activeSocialMedia: variations.length ?
					variations[0].socialMedia : this.state.activeSocialMedia,
				titleOfPost: post.postTitle || '',
				isAddTitleToGMBPostSelected: !!post.postTitle,
				mediaMetadata: post.mediaMetadata || [],
			});
		}
	};

	getQuotes = (quotes) => {
		this.setState({ quotes });
	};

	getLink = (link) => {
		this.setState({ link });
	};

	setEmojiList = (emojiList) => this.setState({ emojiList });

	setEmoji = (emoji) => this.setState({ addEmoji: emoji });

	setHashtagsImageUrl = url => {
		this.setState({ hashtagsImageUrl: url });
	};

	updateSuggestionHashtags = (suggestionHashtags, _id) => {
		const hashtagsKeys = Object.keys(suggestionHashtags);
		const hashtagsKeysTotal = hashtagsKeys.length;
		if (hashtagsKeysTotal) {
			const hashtags = [];
			hashtagsKeys.map(hashtagKey =>
				hashtags
					.push({
						title: suggestionHashtags[hashtagKey],
						_id,
						groupId: _id,
					}));

			this.setState({
				suggestionHashtags: [...new Set(hashtags)].slice(0, 8),
			});
		} else {
			const filteredSuggestionHashtags =
				this.state.suggestionHashtags
					.filter(suggestionHashtag => suggestionHashtag.groupId !== _id);

			this.setState({
				hashtagsList: filteredSuggestionHashtags,
				suggestionHashtags: [],
			});
		}
	};

	openModalConfirm = () => {
		this.setState({
			isOpenConfirmModal: true,
		});
	};

	closeModal = () => {
		this.setState({
			isOpenConfirmModal: false,
		});
	};

	modalToggle = (congrats = false) => {
		this.setState({
			listOfHashtags: {},
			hashtagsList: [],
			suggestionHashtags: [],
			images: [],
			isOpenConfirmModal: false,
		}, () => this.props.toggle(congrats));
	};

	toggleCheckSimilarModal = () => {
		this.setState({ checkSimilarModal: !this.state.checkSimilarModal });
	};

	toggle = () => {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen,
		}));
	};

	toggleImageSuggestion = () => {
		if (this.state.isDisableSuggestingImages) return;

		this.setState(prevState => ({
			imageSuggestion: !prevState.imageSuggestion,
			messageEditorFocused: !prevState.messageEditorFocused,
		}));
	};

	toggleDisableSuggestionImages = isDisabled => {
		this.setState({
			isDisableSuggestingImages: isDisabled,
		});
	};

	chooseSocial = (social) => {
		if (this.state.switchStatus) {
			const variant = this.state.post.variations.filter(el => el.socialMedia === social);
			this.setState({
				activeSocialMedia: social,
				textValue: variant[0].message,
			});
		}
	};

	handleSaveDraft = () => {
		const { post } = this.state;
		post.pending = false;
		post.rejected = false;
		post.draft = true;
		this.setState({ post });
		this.handleAddToLibrary({ complianceEngineRules: { ignoreComplianceCheck: false } });
	};

	validatePostData = post => {
		const { callToAction } = this.state;
		if (!post.categories.length && !post.draft) {
			return 'Please, choose some categories in your awesome post!';
		}

		const postWithoutMessage = !post.message.trim() && !post.variations.filter(el => el.message !== '').length;
		if (postWithoutMessage) {
			return 'Please, provide some content in your awesome post!';
		}

		if (callToAction && callToAction.actionType && callToAction.actionType !== 'CALL' && callToAction.actionType !== 'default' && callToAction.actionType !== 'OFFER') {
			let isValidUrl = false;
			if (!callToAction.url) {
				return 'Call to Action Url is required';
			} else if (callToAction.url.match(urlRegexp).length > 0) {
				isValidUrl = true;
			}
			if (callToAction.url && !isValidUrl) {
				return 'Please correct the Call to Action URL (add https:// and make sure it is a valid URL)';
			}
		}
		const isGMBOfferSelected = callToAction && callToAction.actionType && callToAction.actionType === 'OFFER';
		if (isGMBOfferSelected) {
			if (!post.postTitle) {
				return 'Please add title to Your Google My Business Offer post';
			}
			if (callToAction.offer && callToAction.offer.redeemOnlineUrl && !callToAction.offer.redeemOnlineUrl.match(urlRegexp).length > 0) {
				return 'Please correct the Call to Action Redeem online url (add https:// and make sure it is a valid URL)';
			}
		}
		if (callToAction && callToAction.actionType && callToAction.url && callToAction.actionType === 'default' && callToAction.actionType !== 'CALL') {
			return 'Please select Call to Action type';
		}

		return false;
	};

	createPost = complianceEngineRules => {
		const { post } = this.state;
		const { currentPost, loadCategories } = this.props;
		const { limits } = this.context;
		const { user } = this.props;
		const userPostLimit = limits[user?.response?.subscription?.plan.split('_')[1]]?.libraryPosts;
		this.setState({ loader: true });

		const libraryPostPromise = () => {
			if (!currentPost || !currentPost._id) {
				return createLibraryPost({ ...post, complianceEngineRules });
			}
			 return updateLibraryPost({ ...post, complianceEngineRules });
		};

		return libraryPostPromise()
			.then(() => {
				this.setState({
					loader: false,
					similar: null,
				}, () => this.hideModalTimeout());
				if (loadCategories) {
					loadCategories();
				}
			})
			.catch(err => {
				const {
					score = {}, status, message, upgradeSubs,
				} = err;
				if (status === 403) {
					if (message === `The limit of ${userPostLimit} posts in Library is reached.`) {
						this.setState({
							loader: false,
							errorMessage: message,
							upgradeSubsBtn: upgradeSubs,
						});
						return setTimeout(() => window.open('/upgrade-profile', '_blank'), 1500);
					}
					return this.setState({
						loader: false,
						errorMessage: message,
						upgradeSubsBtn: upgradeSubs,
					});
				}
				return this.setState({
					loader: false,
					similar: score.maxScore,
					checkSimilarModal: true,
					similarityScore: { socialMedias: score.socialMedias, library: score.library },
				});
			});
	};

	handleAddToLibrary = ({ complianceEngineRules = {} }) => {
		const {
			post,
			video = {},
			images,
			isAddTitleToGMBPostSelected,
			titleOfPost,
			callToAction,
			mediaMetadata,
		} = this.state;

		if (images) {
			const newImages = images.map(item => item.image || item.file);
			post.images = [...newImages];
		}

		if (complianceEngineRules.ignoreComplianceCheck) {
			this.toggleCheckSimilarModal();
		}
		if (isAddTitleToGMBPostSelected && titleOfPost.trim().length) {
			post.postTitle = titleOfPost;
		}
		if (Object.keys(video).length) {
			post.video = video;
		} else {
			post.video = undefined;
		}
		this.setState({ post });

		const errorMessage = this.validatePostData(post);

		if (errorMessage) {
			return this.setState({
				errorMessage,
			});
		}
		if (!post.message.length) {
			post.message = post.variations.filter(el => el.message !== '')[0].message;
		}

		if (callToAction && callToAction.url && callToAction.actionType && callToAction.actionType !== 'CALL') {
			post.callToAction = callToAction;
		} else if (callToAction && callToAction.actionType && (callToAction.actionType === 'CALL' || callToAction.actionType === 'OFFER')) {
			post.callToAction = callToAction;
		} else {
			post.callToAction = null;
		}

		if (mediaMetadata.length) {
			post.mediaMetadata = mediaMetadata;
		}

		return this.setState({ post }, () => this.createPost(complianceEngineRules));
	};

	handleAttachMetadata = (metadata) => {
		const { mediaMetadata } = this.state;

		if (metadata.mediaId) {
			const resultMetadata = mediaMetadata.filter(data => data.mediaId !== metadata.mediaId);

			resultMetadata.push(metadata);
			this.setState({ mediaMetadata: resultMetadata });
		}
	};


	hideModalTimeout = () => {
		const { toggleCongratsModal } = this.props;
		const { post } = this.state;

		const draftMessage = 'Your post has been saved as draft';
		const libraryMessage = 'Your post has been successfully added to the Library';
		const message = post.draft ? draftMessage : libraryMessage;
		const postType = post.draft ? 'drafts' : 'library';

		setTimeout(() => {
			this.modalToggle(true);
			const data = {
				open: true, message, postType,
			};
			toggleCongratsModal(data);
		}, 1000);
	};

	onPostValueChange = value => {
		const textValue = addOrRemoveValueFromText(value, this.state.textValue);
		this.setState({
			textValue,
			addToText: textValue.match(value) && this.state.textValue ? value : '',
		});
	};

	handleChangeMessage = (textValue) => {
		this.setState({
			textValue,
			addEmoji: null,
			links: getLinksFromText(textValue),
		});

		const { post, switchStatus, activeSocialMedia } = this.state;
		if (switchStatus) {
			post.variations.map((el, index) =>
				(el.socialMedia === activeSocialMedia ?
					post.variations[index].message = textValue : null));
		} else {
			post.message = textValue;
		}

		this.setState({ post });
	};

	toggleSummarizeModal = () => {
		this.setState({
			summarizeModal: !this.state.summarizeModal,
			messageEditorFocused: !this.state.messageEditorFocused,
		});
	};

	handleChangeSwitch = () => {
		const { post, activeSocialMedia, switchStatus } = this.state;
		const variant = post.variations.find(el => el.socialMedia === activeSocialMedia);
		this.setState(prevstate => ({
			switchStatus: !prevstate.switchStatus,
			textValue: !switchStatus && variant ? variant.message : post.message,
		}));
	};

	clearAddToText = () => this.setState({ addToText: '' });

	handleShowComingSoonModal = () => {
		this.setState({ switchStatus: false });
	};

	updateExistingCategories = existingCategories => {
		const { updateExistingCategories } = this.props;
		if (updateExistingCategories) {
			updateExistingCategories(existingCategories);
		}
	};

	toggleAlertModal = message => {
		const { imageSuggestion, isOpenAlertModal } = this.state;
		if (imageSuggestion && isOpenAlertModal) {
			this.setState({
				imageSuggestion: false,
			});
		}

		this.setState({
			isOpenAlertModal: !this.state.isOpenAlertModal,
			alertModalMessage: message,
		});
	};

	addTextFromImageToPostMessage = textFormImage => {
		const { textValue } = this.state;
		const newPostMessage = `${textValue} ${textFormImage}`;
		this.setState({ textValue: newPostMessage });
	};

	changedMediaFilesLoaderVisibility = newValue => {
		this.setState({ isMediaFilesLoading: newValue });
	};

	changedLinkToLinkWithUTMParams = (linkWithUTM, existingLink) => {
		const { textValue } = this.state;
		const newPostMessage = getTextWithUTMParams(textValue, existingLink, linkWithUTM);
		this.setState({ textValue: newPostMessage });
	};

	changeAddTitleToGMBPostSelected = newValue => this.setState({ isAddTitleToGMBPostSelected: newValue });

	changeGMBPostTitle = newValue => this.setState({ titleOfPost: newValue });

	getFileListToDelete = () => {
		const calendar = getCalendar();
		if (this.props.location.pathname !== `/${calendar}/`) {
			return [];
		}
		let filesToDelete = [];
		const imagesToDelete = [];
		const videoFilesToDelete = [];
		this.state.images.map(image => {
			if (typeof image === 'object' && image.image.indexOf('storage.googleapis.com') >= 0) {
				imagesToDelete.push(image.image.split('/').reverse()[0]);
			} else if (typeof image === 'object' && image.image.indexOf('storage.googleapis.com') < 0) {
				return null;
			} else if (typeof image === 'string' && image.indexOf('storage.googleapis.com') >= 0) {
				imagesToDelete.push(image.split('/').reverse()[0]);
			}
			return null;
		});
		if (this.state.video && this.state.video.url && this.state.video.url.indexOf('storage.googleapis.com') >= 0) {
			videoFilesToDelete.push(this.state.video.url.split('/').reverse()[0]);
			videoFilesToDelete.push(this.state.video.audioTrack.split('/').reverse()[0]);
			videoFilesToDelete.push(this.state.video.thumbnail.split('/').reverse()[0]);
		}

		filesToDelete = filesToDelete.concat(imagesToDelete);
		filesToDelete = filesToDelete.concat(videoFilesToDelete);
		filesToDelete = filesToDelete.filter(i => i); // remove any null values
		return filesToDelete;
	};

	handleAppendCaption = (responseText) => {
		this.setState((prevState) => ({
			textValue: prevState.textValue + responseText,
		}));
	};

	handleCloseCaptionGenerationModal = () => {
		this.setState({
			openCaptionGenerationModal: false,
		});
	};

	handleOpenCaptionGenerationModal = () => {
		this.setState({
			openCaptionGenerationModal: true,
		});
	};

	render() {
		const {
			images,
			video,
			imageSuggestion,
			errorMessage,
			similar,
			switchStatus,
			similarityScore,
			checkSimilarModal,
			loader,
			emojiList,
			addEmoji,
			emojiCount,
			textValue,
			hashtagsImageUrl,
			isMediaFilesLoading,
			isAddTitleToGMBPostSelected,
			titleOfPost,
			userAccounts,
			mediaMetadata,
			callToAction,
		} = this.state;
		const { isOpen, user } = this.props;
		const { limits, setIsUpgradeModalShown, setUpgradeModalMetadata } = this.context;

		const isAdvancedArticleSummarizerAvailable = UserSubscriptionService.getPlanItemLimit(user.response, limits, 'advancedArticleSummarizer');
		const headerModalTitle = imageSuggestion ? 'Select Suggested Images' : 'Graphic Design Tool';
		const headerModalContent = (<div><img src={postIcon} alt="Graphic Design Tool" />{headerModalTitle}</div>);
		// const userSubscriptionPlan = UserSubscriptionService.getUserSubscriptionPlan(user.response);
  		// const isBannerBearAllowed = limits[userSubscriptionPlan].bannerBearImagesPerMonth > 0 || false;

		return (
			<Fragment>
				<Modal
					isOpen={isOpen}
					toggle={this.openModalConfirm}
					size="lg"
					className="add-content-modal"
					centered
				>
					<div id="modal-add-content">
						<ModalHeader toggle={this.openModalConfirm}>
							<div className="add-content-modal-title">
								<img src={postIcon} alt="post" />Add to Library
								<PostLimitsWarningTooltip />
							</div>
							<ShowingVideoGuideButton url="https://player.vimeo.com/video/393781897" />
						</ModalHeader>
						<ModalBody>
							<Form>
								<FormGroup tag="fieldset">
									<FormGroup>
										{errorMessage ?

											<Alert
												color="danger"
												style={this.state.upgradeSubsBtn ? {
													display: 'flex',
												} : {}}
												toggle={() => this.setState({ errorMessage: '' })}
											>
												<div
													style={this.state.upgradeSubsBtn ? {
														margin: 'auto 0',
														paddingRight: 16,
													} : {}}
												>{errorMessage}</div>
												{this.state.upgradeSubsBtn && <UpgradeNowButton inNewTab />}
											</Alert> : null}
										<div className="input-field">
											<div className="social-line">
												{similar ?
													<span className="post-similar-line">
														<span
															style={{ color: getSimilarColor(similar) }}
														>{similar}%
														</span>{' Similar'}
													</span> : null}
												Multi Post
												<Switch
													// onClick={this.handleChangeSwitch}
													// temporary solution for Coming soon modal
													onClick={() => this.setState({ switchStatus: !this.state.switchStatus })}
													on={switchStatus}
													className="switch"
												/>
												{this.state.socialMedia.map(soc => (
													<span
														key={soc}
														className={
															this.state.switchStatus ?
																'post-social-icon'
																: 'post-social-icon-no-active'
														}
													>
														<img
															onClick={() => this.chooseSocial(soc)}
															src={this.getSocialIcon(soc)}
															alt="Social Icon"
														/>
													</span>))}
											</div>
											<div className="header-translator-container">
												<div className="header-translator">
													<Label className="header-label">What do you want to say?</Label>
													<div id="add-content-modal-translator">
														<LanguageTranslator text={textValue} setTranslatedText={this.handleChangeMessage} />
													</div>
												</div>
												 <AddTitleToGMBPost
													isAddTitleToGMBPostSelected={isAddTitleToGMBPostSelected}
													changeAddTitleToGMBPostSelected={this.changeAddTitleToGMBPostSelected}
													titleOfPost={titleOfPost}
													changeGMBPostTitle={this.changeGMBPostTitle}
													userAccounts={userAccounts}
												 />
											</div>
											<div id="add-content-modal-message-editor">
												<MessageEditor
													focused={this.state.messageEditorFocused}
													message={this.state.textValue}
													onChange={this.handleChangeMessage}
													updateSuggestionHashtags={this.updateSuggestionHashtags}
													addToText={this.state.addToText}
													clearAddToText={this.clearAddToText}
													setEmojiList={this.setEmojiList}
													addEmoji={addEmoji}
													setEmoji={this.setEmoji}
													emojiCount={emojiCount}
													user={user}
													limits={limits}
													handleAppendCaption={this.handleAppendCaption}
													handleOpenCaptionGenerationModal={
														this.handleOpenCaptionGenerationModal
													}
												/>
											</div>
											<div id="add-content-modal-text-message-counter-container">
												<TextMessageCounter text={textValue} />
											</div>
										</div>
									</FormGroup>
									<FormGroup className="add-content-add-utm-container">
										<AddUTMParamsToLink
											messageText={textValue}
											changedLinkToLinkWithUTMParams={this.changedLinkToLinkWithUTMParams}
										/>
									</FormGroup>

									<FormGroup>
										<div className="quotes-container">
											<Label
												style={{ marginTop: 13 }}
											>
												Quotes
											</Label>
											<Button
												style={{ marginBottom: 7 }}
												color="primary"
												onClick={() => {
													if (!isAdvancedArticleSummarizerAvailable) {
														setUpgradeModalMetadata({
															reason: subsErrorReasons.no_access,
															action: subsActions.advancedArticleSummarizer,
														});
														return setIsUpgradeModalShown(true);
													}
													this.toggleSummarizeModal();
												}}
											>
												Summarize Article or Paragraph
											</Button>
										</div>
										<Quotes
											quotes={this.state.quotes}
											link={this.state.link}
											onAddQuote={this.getQuote}
										/>
									</FormGroup>
									<FormGroup className="hashtags-container">
										<Label>Hashtags (Please Click or Tap Hashtags Below to Add to Your Post)</Label>
										<ManageHashtags
											changeHashtagList={this.onChangeHashtagList}
											detectedFromURL={this.state.link}
											additionalDropdownItems={{ title: 'Suggested', _id: 'Suggested', order: 1 }}
											suggestionHashtags={this.state.suggestionHashtags}
										/>
									</FormGroup>
								</FormGroup>
								<FormGroup id="add-content-modal-hashtags">
									<CalendarContentPicker
										onSelect={this.onPostValueChange}
										hashtags={getHashtagsFromText(this.state.textValue)}
										mode="link"
										link={this.state.links}
										getQuotes={this.getQuotes}
										getLink={this.getLink}
										listOfHashtags={this.state.listOfHashtags}
										hashtagsList={this.state.hashtagsList}
									/>
								</FormGroup>
								<FormGroup>
									<Label>Emojis</Label>
									<React.Suspense fallback={<Loader />}>
										<ManageEmojis setEmoji={this.setEmoji} emojiList={emojiList} />
									</React.Suspense>
								</FormGroup>
								<FormGroup>
									<div className="d-flex flex-column w-100">
										<Label>
											{callToAction && callToAction.actionType && callToAction.actionType === 'OFFER' ? 'Add offer details for GMB' : 'Add link / Call to action ( IG story, GMB, Alexa and Pinterest only )'}
										</Label>
										<ManageCTA
											setCallToAction={(cta) => {
												this.setState({
													callToAction: {
														url: cta.actionType === 'CALL' ? '' : cta.url,
														offer: cta.offer,
														actionType: cta.actionType,
													},
												});
											}}
											timezone={user?.response?.timezone}
											isEditPost={{ event: { post: this.props.currentPost } }}
										/>
									</div>
								</FormGroup>
								<FormGroup tag="fieldset">
									<Row>
										<Col>
											<FormGroup className="post-date-options" >
												<Label>Category</Label>
												<DropdownSelectCategory
													selectedOptions={this.state.selectedOptions.length ?
														this.state.selectedOptions :
														this.state.post.categories}
													updateExistingCategories={this.updateExistingCategories}
													onSelectOption={this.onSelectOption}
													dropdownOpen={this.state.dropdownOpen}
													toggle={this.toggle}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Images Related Hashtags</Label>
												<div id="add-content-modal-images-related-hashtags">
													<CalendarContentPicker
														onSelect={this.onPostValueChange}
														hashtags={getHashtagsFromText(this.state.textValue)}
														mode="img"
														img={hashtagsImageUrl}
													/>
												</div>
											</FormGroup>
											{/* {this.props.currentPost && this.props.currentPost._id ? <FormGroup> */}
											{/*	<AddContentPostExpiry */}
											{/*		timezone={user?.response?.timezone} */}
											{/*		post={this.state.post} */}
											{/*	/> */}
											{/* </FormGroup> : null} */}
										</Col>
										<Col>
											<FormGroup id="add-content-modal-image-uploader">
												<CalendarImageUploader
													setImage={this.setImage}
													setVideo={this.setVideo}
													onRemove={this.onRemove}
													mediaMetadata={mediaMetadata}
													handleAttachMetadata={this.handleAttachMetadata}
													editImage={this.props.currentPost ?
														this.props.currentPost.images.filter(img => img) : images}
													images={images}
													video={video}
													className="add-content-uploader"
													toggleDisableSuggestionImages={this.toggleDisableSuggestionImages}
													toggleAlertModal={this.toggleAlertModal}
													setHashtagsImageUrl={this.setHashtagsImageUrl}
													setEmojiList={this.setEmojiList}
													emojiCount={emojiCount}
													imageSuggestion={this.state.imageSuggestion}
													toggleImageSuggestion={this.toggleImageSuggestion}
													setImages={this.setImages}
													message={this.state.textValue}
													isLoadVideo={!!(video && Object.keys(video).length)}
													addTextFromImageToPostMessage={this.addTextFromImageToPostMessage}
													isMediaFilesLoading={isMediaFilesLoading}
													changedMediaFilesLoaderVisibility={this.changedMediaFilesLoaderVisibility}
													links={this.state.links}
													// showBannerBear={isBannerBearAllowed}
													showBannerBear={false}
												/>
											</FormGroup>
										</Col>
									</Row>
								</FormGroup>
							</Form>
						</ModalBody>
						<ModalFooter>
							<Button
								color="light"
								onClick={() => {
									const filesToDelete = this.getFileListToDelete();
									if (filesToDelete && filesToDelete.length > 0) {
										deleteFiles(filesToDelete).then(() => {
											this.props.toggle(false);
										}).catch(err => {
											console.log(err);
											this.props.toggle(false);
										});
									} else {
										this.props.toggle(false);
									}
								}}
							>Cancel</Button>
							<Button
								color="light"
								onClick={this.handleSaveDraft}
								disabled={isMediaFilesLoading}
							>Save draft
							</Button>
							<Button
								color="primary"
								onClick={this.handleAddToLibrary}
								disabled={isMediaFilesLoading}
							>{this.props.currentPost && this.props.currentPost._id ? 'Save' : 'Add to Library'}
							</Button>
						</ModalFooter>
					</div>
				</Modal>

				<SimilarityModal
					isLibraryPost
					onPostCreate={this.handleAddToLibrary}
					isOpen={checkSimilarModal && !loader}
					toggle={this.toggleCheckSimilarModal}
					similar={this.state.similar}
					similarityScore={similarityScore}
					isPostCreate
					isTwitter
				/>
				<ModalLoader message="Your post is being processed right now. Please do not close this window" isOpen={loader} />

				<ComingSoonModal
					isOpen={this.state.switchStatus}
					toggle={this.handleShowComingSoonModal}
				/>

				<AlertModal
					toggle={this.toggleAlertModal}
					isOpen={this.state.isOpenAlertModal}
					message={this.state.alertModalMessage}
					contentHeader={headerModalContent}
				/>

				<ConfirmModal
					onClose={this.closeModal}
					isOpen={this.state.isOpenConfirmModal}
					onConfirm={() => {
						const filesToDelete = this.getFileListToDelete();
						if (filesToDelete && filesToDelete.length > 0) {
							deleteFiles(filesToDelete).then(() => {
								this.modalToggle(false);
							}).catch(err => {
								console.log(err);
								this.modalToggle(false);
							});
						} else {
							this.modalToggle(false);
						}
					}}
					message={woofyText.unsavedChanges}
				/>

				<CalendarSummarizeModal
					toggle={this.toggleSummarizeModal}
					isOpen={this.state.summarizeModal}
					onSelect={this.onPostValueChange}
					user={user}
					limits={limits}
				/>

				<CaptionGenerationModal
					open={this.state.openCaptionGenerationModal}
					handleClose={this.handleCloseCaptionGenerationModal}
					handleAppendCaption={this.handleAppendCaption}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	accountsList: selectors.getAccounts(state),
	user: selectors.getCurrentUser(state),
});

const mapDispatchToProps = dispatch => ({
	getAccountsFromDBAction: bindActionCreators(getAccountsFromDB, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddContentModal));
