/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { get, post, put, httpDelete } from '../../common/httpRequests';

import {
	USER_URL,
	CONFIG_URL,
	SETTINGS_URL,
	ACCOUNTS_COUNT,
	SENTRY_CONFIG_URL,
	GET_ALL_SHOP_PRODUCTS,
	SEND_REFERRAL_EMAIL_URL,
	GET_SHOPIFY_INSTALL_DATA,
	ACTIVATE_SHOPIFY_INSTALL,
	GET_USER_BILLING_INFORMATION_LINK,
	CHAT_GTP_USAGE,
} from '../../common/apiRouting';
import { getCalendar } from '../../common/calendarHelper';
import { getToken } from '../../common/auth';

export const getConfig = () => get(CONFIG_URL)
	.then(res => res.json(res));

export const getSentryConfig = () => get(SENTRY_CONFIG_URL)
	.then(res => res.json(res));

export const getSettings = () => get(SETTINGS_URL, true)
	.then(res => res.json(res));

export const getAccountsCount = () => get(ACCOUNTS_COUNT, true)
	.then(res => res.json());

export const saveSettings = (data) => put(SETTINGS_URL, data, true)
	.then(res => res.json());

export const sendReferralEmail = (email) => post(SEND_REFERRAL_EMAIL_URL, email, true)
	.then(res => res.json());

export function deleteAccount(allData = false) {
	return httpDelete(`${USER_URL}?allData=${allData}`, true)
		.then(res => res.json());
}

export function getShopifyInstallData(shopName) {
	return get(`${GET_SHOPIFY_INSTALL_DATA}?shop=${shopName}`, true)
		.then(res => res.json());
}

export function activateShopifyInstall(accountId) {
	return post(`${ACTIVATE_SHOPIFY_INSTALL}`, { accountId, calendarId: getCalendar() }, true)
		.then(res => res.json());
}

export function getUserBillingInformationLink() {
	return get(`${GET_USER_BILLING_INFORMATION_LINK}`, true)
		.then(res => res.json());
}

export function getAllShopProducts(limit = 5, page = 1) {
	return get(`${GET_ALL_SHOP_PRODUCTS}?limit=${limit}&page=${page}`, true)
		.then(res => res.json());
}

export function createShopProduct({
	title,
	description,
	price,
	email,
	image,
	url,
	emailSubject,
	emailBody,
	phone,
}) {
	const formData = new FormData();

	formData.append('image', image);
	formData.append('title', title);
	formData.append('description', description);
	formData.append('price', price);
	formData.append('email', email);
	formData.append('phone', phone);
	formData.append('url', url);
	formData.append('emailSubject', emailSubject);
	formData.append('emailBody', emailBody);

	const options = {
		method: 'POST',
		headers: {},
		body: formData,
	};
	options.headers.Authorization = `${getToken()}`;

	return fetch(GET_ALL_SHOP_PRODUCTS, options);
}

export function editShopProduct(productId, updatedData) {
	const formData = new FormData();

	Object.keys(updatedData).forEach(key => {
		formData.append(key, updatedData[key]);
	});

	const options = {
		method: 'PUT',
		headers: {},
		body: formData,
	};
	options.headers.Authorization = `${getToken()}`;

	return fetch(`${GET_ALL_SHOP_PRODUCTS}/${productId}`, options);
}


export function deleteShopProduct(productId) {
	const options = {
		method: 'DELETE',
		headers: {},
	};
	options.headers.Authorization = `${getToken()}`;

	return fetch(`${GET_ALL_SHOP_PRODUCTS}/${productId}`, options);
}

export const getChatGptUsage = (year) => get(`${CHAT_GTP_USAGE}?year=${year || (new Date().getFullYear())}`, true)
	.then(res => res.json());
