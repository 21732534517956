/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

import ReactGA from 'react-ga';

const reactGoogleAnalytics = {
	instance: ReactGA,
	init: () => {
		ReactGA.initialize('UA-143328636-5');
	},
	track: () => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	},
};

export default reactGoogleAnalytics;
