/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

export function getSimilarColor(similar) {
	if (similar > 70 && similar < 85) {
		return '#ffe500';
	} else if (similar > 85 && similar < 95) {
		return '#ff9800';
	}
	return '#ff1100';
}
