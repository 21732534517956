import React, { Component, Fragment } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import cx from 'classnames';

import ImageCropComponent from './ImageCropComponent';

import './style.css';

class ImageCropModal extends Component {
	state = {
		openModal: false,
		// cropConfirmed: false,
		croppedImage: null,
		currentRatio: null,
	};

	toggleModalHandler = (openModal = false) => this.setState({ openModal });
	// toggleConfirmCrop = (cropConfirmed = false) => this.setState({ cropConfirmed });
	changeCropImage = (croppedImage = null, currentRatio) => this.setState({ croppedImage, currentRatio });

	save = () => {
		const {
			changeOriginalImg,
		} = this.props;
		const {
			croppedImage,
			currentRatio,
		} = this.state;
		const newFile = croppedImage.file;
		newFile.name = croppedImage.name;
		newFile.preview = croppedImage.uri;

		/*
		{
			"uri": "blob:http://localhost:3000/b59f56c1-cbc6-49d8-9e67-f4ab59756966",
			"file": {},
			"name": "0_tim_bg.png"
		}
		{
			"title": "Portrait",
			"subTitle": "4:5",
			"rateType": "preset-4-5",
			"rate": 0.8
		}
		*/

		console.log('save', croppedImage, currentRatio);
		changeOriginalImg([newFile]);
		this.close();
	};
	show = () => this.toggleModalHandler(true);
	toggle = () => this.toggleModalHandler(!this.state.openModal);
	close = () => this.toggleModalHandler(false);

	render() {
		const {
			// file,
			imageUrl,
			// BtnComponent,
			iconUrl,
			iconClassName,
			addSocialImage,
			socialImages,
			// changeOriginalImg,
		} = this.props;
		const {
			openModal,
			// cropConfirmed,
			croppedImage,
		} = this.state;

		return (
			<Fragment>
				<img
					src={iconUrl}
					className={cx(iconClassName)}
					alt="crop-icon"
					onClick={this.show}
				/>
				<Modal
					key={`modal-${imageUrl}`}
					isOpen={openModal}
					toggle={this.toggle}
					size="lg"
					className="crop-image-modal"
				>
					<ModalHeader
						toggle={this.toggle}
					>
						Picture editing
					</ModalHeader>
					<ModalBody>
						<ImageCropComponent
							socialImages={socialImages}
							imageUrl={imageUrl}
							setCropImageHandler={(image, ratio) => {
								// console.log('\nsetCropImageHandler:', image);
								// console.log('\nsetCropImageHandler ratio:', ratio);
								this.changeCropImage(image, ratio);
								// eslint-disable-next-line no-unused-expressions
								addSocialImage && addSocialImage(image, ratio);
							}}
							// confirmCropHandler={() => this.toggleConfirmCrop(true)}
							// declineCropHandler={() => this.toggleConfirmCrop(false)}
						/>
					</ModalBody>
					<ModalFooter>
						<Button disabled={!croppedImage} color="primary" onClick={this.save}>Save</Button>{' '}
						<Button color="secondary" onClick={this.close}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}
}

// ImageCropModal.propTypes = {
// 	// imageUrl: PropTypes.string,
// 	// BtnComponent: PropTypes.any,
// };

export default ImageCropModal;
