import moment from 'moment';
import { SocialIntegrationType } from '../constants/mediaTypes.js';

export const isGifImage = imageUrl => /.gif/g.test(imageUrl);

export const containsGifImages = images => images.filter((img) => isGifImage(img)).length;

export const showFacebookIssueModal = () => {
	const lastNotifyTime = localStorage.getItem('facebookIssueModalNotifyDate');
	let shouldNotify = false;

	if (lastNotifyTime && moment().diff(new Date(lastNotifyTime), 'hours') >= 1) {
		localStorage.setItem('facebookIssueModalNotifyDate', new Date());
		shouldNotify = true;
	}

	if (!lastNotifyTime) {
		localStorage.setItem('facebookIssueModalNotifyDate', new Date());
		shouldNotify = true;
	}

	return shouldNotify;
};

export const setShowCongratsModal = (show) => localStorage.setItem('showCongratsModal', show);

export const sleep = time => new Promise(res => setTimeout(res, time));

export const showCongratsModal = () => localStorage.getItem('showCongratsModal') === 'true';

export const setStartUsingPlatformStep = (step) => localStorage.setItem('startUsingPlatformStep', step);

export const startUsingPlatformStep = () => localStorage.getItem('startUsingPlatformStep');

export const startUsingPlatformSteps = {
	SHOW_SETTINGS: 'SHOW_SETTINGS',
	SHOW_POST_CREATION: 'SHOW_POST_CREATION',
};

export const isUsingPlatformStepsCompleted = () => startUsingPlatformStep() === 'false';

export const isSettingsStep = () => !isUsingPlatformStepsCompleted() && startUsingPlatformStep() === startUsingPlatformSteps.SHOW_SETTINGS;

export const isPostCreationStep = () => !isUsingPlatformStepsCompleted() && startUsingPlatformStep() === startUsingPlatformSteps.SHOW_POST_CREATION;

