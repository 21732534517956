/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, June 2020
 */

import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	Navbar,
	NavbarBrand,
	NavItem,
	Nav,
	NavLink,
	Dropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
	Tooltip,
} from 'reactstrap';

import ReactTooltip from 'react-tooltip';

import store from 'store';
import * as selectors from '../../../../selectors';
import helloWoofyIconForAccount from '../../../../images/helloWoofyIconForAccount.png';
// import lifetimeProDealIcon from '../../../../images/lifetimeProDealIcon.svg';
// import cropImageIcon from '../../../../images/crop_black_24dp.svg';
import logo from '../../../../images/Woofy-logo.png';
import CalendarDropDown from '../../../Calendar/CalendarDropDown/CalendarDropDown';
import NotificationDropDown from '../NotificationDropDown/NotificationDropDown';
import { UserSubscriptionService } from '../../../UserSubscriptions/UserSubscriptionService';
import { idRegexp } from '../../../../common/textHelper';
import { clearAuthToken } from '../../../../common/auth';

import userMenuSettingsIcon from '../../../../images/userMenuSettingsIcon.svg';
import userMenuLogoutIcon from '../../../../images/userMenuLogoutIcon.svg';
import navBarMenuHandBag from '../../../../images/navBarMenuHandBag.svg';
import quickCoursesMenuSettingsIcon from '../../../../images/quickCoursesMenuSettingsIcon.png';

import './NavBar.css';
import { logout } from '../../../Login/Login.action';

const tooltipMessage = 'Find everything you need to run your small business and grow.';

const NavBar = ({ location, user: { response: userData } = {}, history }) => {
	useEffect(() => {
		ReactTooltip.rebuild();
	});
	const [isUserMenuOpen, changeUserMenuVisibility] = useState(false);
	const [isUniversityTooltipOpen, setUniversityTooltipOpen] = useState(false);
	const isUserHaveSubscription = UserSubscriptionService.isUserHaveSubscription(userData);

	const calendar = store.get('currentCalendar');
	const routeActive = (path) => location.pathname === path;
	const handleCalendarSelect = (id) => {
		store.set('currentCalendar', `${id}`);
		window.location = window.location.pathname.replace(idRegexp, `${id}/`);
	};
	const onLogout = async () => {
		await logout();
		clearAuthToken();
		// TODO need to figure out if need next action here
		localStorage.removeItem('needToRemindInstallMobileApp');
		store.set('currentCalendar', '');
		history.push('/login');
	};

	return (
		<Navbar
			expand="md"
			id="main-menu"
		>
			<Nav navbar>
				<NavbarBrand href={`/${calendar}/`}>
					<img src={logo} className="logo" alt="logo" />
				</NavbarBrand>
				<NavItem active={routeActive(`/${calendar}/`)} id="navbar-home-page">
					<NavLink tag={Link} to={`/${calendar}/`}><span role="img" aria-label="house">🏡</span>Home</NavLink>
				</NavItem>
				{/* <NavItem active={routeActive(`/${calendar}/discover`)} id="navbar-discover-page">
					<NavLink tag={Link} to={`/${calendar}/discover`}><span role="img" aria-label="glass">🔍</span>Discover</NavLink>
				</NavItem> */}
				<NavItem active={routeActive(`/${calendar}/library`)} id="navbar-library-page">
					<NavLink tag={Link} to={`/${calendar}/library`}><span role="img" aria-label="books">📚</span>Library</NavLink>
				</NavItem>
				<NavItem active={routeActive(`/${calendar}/calendar`)} id="navbar-calendar-page">
					<NavLink tag={Link} to={`/${calendar}/calendar`}><span role="img" aria-label="calendar">🗓</span>Calendar</NavLink>
				</NavItem>
				<NavItem id="navbar-store-page">
					<NavLink
						href="https://calendly.com/hellowoofy-arjun/live-smb-consulting-call-ama"
						target="_blank"
						rel="noopener noreferrer"
						data-for="tooltip-for-store"
						data-tip={tooltipMessage}
					><span role="img" aria-label="analytic">🤙</span>Consulting
					</NavLink>
				</NavItem>
				<NavItem active={routeActive(`/${calendar}/journal`)} id="navbar-journal-page">
					<NavLink tag={Link} to={`/${calendar}/journal`}><span role="img" aria-label="cog">📘</span>Journal</NavLink>
				</NavItem>
				<NavItem id="navbar-faqs-page">
					<NavLink
						// href="https://hellowoofy.com/knowledge-base"
						href="https://hellowoofy.com/support"
						target="_blank"
						rel="noopener noreferrer"
					><span role="img" aria-label="faqs">❓</span> Support
					</NavLink>
				</NavItem>
			</Nav>

			<Nav navbar className="ml-auto nav-right">
				{/* {!isUserHaveSubscription && !userData.teamId &&
				<div className="hello-woofy-exclusive-deal">
					<NavLink tag={Link} to={`/${calendar}/upgrade-profile`}>
						<img src={lifetimeProDealIcon} alt="woofy-icon" />
					</NavLink>
				</div>} */}
				{!isUserHaveSubscription && !userData.teamId &&
			<div className="hello-woofy-exclusive-deal">
				<NavLink tag={Link} to={`/${calendar}/upgrade-profile`}>
					<button className="deal-btn" >
						<div className="left" >
							Grab your limited time deal
						</div>
						<div className="right" >
							<img src="https://www.svgrepo.com/show/39955/up-arrow.svg" alt="arrow" />
						</div>
					</button>
				</NavLink>
			</div>}
				<NotificationDropDown />

				<div className="calendar-dropdown">
					<CalendarDropDown
						position="relative"
						onSelect={handleCalendarSelect}
						isUserHaveSubscription={isUserHaveSubscription}
						user={userData}
					/>
				</div>

				<Dropdown isOpen={isUserMenuOpen} toggle={() => changeUserMenuVisibility(!isUserMenuOpen)}>
					<DropdownToggle nav caret className="drop-down-toggle">
						<img
							id="user-profile-avatar"
							className={isUserMenuOpen ? 'navbar-avatar active' : 'navbar-avatar'}
							src={userData.avatar || helloWoofyIconForAccount}
							alt="user-avatar"
						/>
					</DropdownToggle>
					<DropdownMenu right>
						<DropdownItem
							id="dropdown-setting-button"
							onClick={() => history.push(`/${calendar}/settings`)}
							className="settings-button"
						>
							<img src={userMenuSettingsIcon} alt="user-menu-settings-icon" />Settings
						</DropdownItem>
						<DropdownItem
							onClick={() => history.push(`/${calendar}/analytics`)}
							className="settings-button"
						>
							<img src={navBarMenuHandBag} alt="nav-bar-menu-hand-bag-icon" />Analytics
						</DropdownItem>
						<Tooltip
							placement="right"
							isOpen={isUniversityTooltipOpen}
							target="university-dropdown-item"
							toggle={() => setUniversityTooltipOpen(!isUniversityTooltipOpen)}
							delay={{ show: 0, hide: 0 }}
						>Quick courses to help you with your small business. Coming soon
						</Tooltip>
						<DropdownItem
							toggle={false}
							className="settings-button"
							id="university-dropdown-item"
						>
							<img
								src={quickCoursesMenuSettingsIcon}
								alt="quick-courses-menu-settings-icon"
								className="png-icon"
							/>University
						</DropdownItem>
						<DropdownItem
							toggle={false}
							className="settings-button"
							id="support-call-dropdown-item"
							onClick={() => window.open('https://calendly.com/hellowoofy/support-hellowoofy', '_blank')}
						>
							<span className="png-icon" style={{ display: 'inline-block' }} aria-label="Call Support" role="img">📞</span>Support
						</DropdownItem>
						<DropdownItem onClick={() => onLogout()} className="logout-button">
							<img src={userMenuLogoutIcon} alt="user-menu-logout-icon" />Log Out
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</Nav>
			<ReactTooltip
				id="tooltip-for-store"
				place="right"
				effect="solid"
			/>
		</Navbar>
	);
};

const mapStateToProps = state => ({
	user: selectors.getCurrentUser(state),
});

export default withRouter(connect(mapStateToProps)(NavBar));
