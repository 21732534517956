/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, October 2020
 */

import { getConfig } from '../components/Settings/Settings.action';

import getArrayOfUrlsFromText from '../helpers/getArrayOfUrlsFromText';

const detectTwitterPostLength = message => getConfig()
	.then(config => {
		const { twitterUrlLength } = config;

		let clearTextWithoutUrls = message;

		const arrayOfUrls = getArrayOfUrlsFromText(message);

		if (arrayOfUrls.length) {
			arrayOfUrls.forEach(url => clearTextWithoutUrls = clearTextWithoutUrls.replace(url, ''));
		}

		const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
		const arrayOfEmojis = message.match(emojiRegex) || [];
		const quantityEmojisInText = arrayOfEmojis.length;

		return arrayOfUrls.length * twitterUrlLength + quantityEmojisInText + clearTextWithoutUrls.length;
	})
	.catch(error => console.log('[detectTwitterPostLength error]:', error));

export default detectTwitterPostLength;
