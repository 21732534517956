/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import defaultAvatar from '../../../images/default-avatar.png';

import './Footer.css';

class Upgrade extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { user } = this.props;
		return (
			<div className="footer-section">
				<div className="account-link">
					<div
						className="avatar"
						style={{ backgroundImage: `url(${user.avatar || defaultAvatar})` }}
					>{}
					</div>
					<a href="/settings" target="_blank" rel="noopener noreferrer">My Account</a>
				</div>
				<div className="tutorial-link">
					<a href="https://vimeo.com/449323597" target="_blank" rel="noopener noreferrer">Tutorial</a>
				</div>
				{/* <div className="footer-logo"></div> */}
			</div>
		);
	}
}

export default withRouter(Upgrade);
