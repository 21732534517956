/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { useEffect } from 'react';

export const debounce = (callback, delay) => {
	let timeout = null;
	return (...args) => {
		const next = () => callback(...args);
		clearTimeout(timeout);
		timeout = setTimeout(next, delay);
	};
};

export const useDebounce = (effect, delay, deps) => {
	useEffect(() => {
		const timeout = setTimeout(effect, delay);
		return () => clearTimeout(timeout);
	}, deps);
};
