/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { combineReducers } from 'redux';
import * as requestActionTypes from '../constants/requestActionTypes';
import { withLoadable } from './_common/request';

// Requests
const getAll = withLoadable(requestActionTypes.GET_SETTINGS)((state = {}) => state);

const reducers = {
	getAll,
};

export default combineReducers(reducers);
