/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Button, Tooltip } from 'reactstrap';

import PropTypes from 'prop-types';
import * as selectors from '../../../selectors';

import ConfirmModal from '../ConfirmModal/ConfirmModal';
import UserSocialAccountsBlock from '../UserSocialAccountsBlock/UserSocialAccountsBlock';
import CalendarPostModal from '../../../components/Calendar/CalendarPostModal/CalendarPostModal';

import { clickable } from '../../../helpers/makeElementClicable';
import { goToJournalPostDetails } from '../../../helpers/goToJournalPostDetails';

import notPostedPostIcon from '../../../images/notPostedPostIcon.svg';

import './NotPostedPostWarning.css';
import CalendarCongratsModal from '../../../components/Calendar/CalendarCongratsModal/CalendarCongratsModal';
import { isPostWasBlocked } from '../../../helpers/isPostWasBlocked';

const NotPostedPostWarning = ({
	postData,
	timezone,
	closePreviousModal,
	needToShowText,
	accounts: { response: { items: userAccounts } = [] },
	history,
	reloadCalendarEvents,
	eventClick,
	needToShowButton = false,
}) => {
	const [isRepostedPostModalOpen, changeRepostedPostModalVisibility] = useState(false);
	const [isPostToSocialMediaModalOpen, changePostToSocialMediaModalVisibility] = useState(false);
	const [isRetryTooltipOpen, setRetryTooltipOpen] = useState(false);

	const [blockedReason, changeBlockedReason] = useState('');
	const [postBlockedAccounts, changePostBlockedAccounts] = useState([]);
	const resendPost = () => {
		changePostToSocialMediaModalVisibility(true);
		changeRepostedPostModalVisibility(false);
	};

	const [congratsModalOpen, setCongratsModalOpen] = useState(false);
	const [congratsMessage, setCongratsMessage] = useState('');
	const [postType, setPostType] = useState('');
	const [updatedPostData, setUpdatedPostData] = useState({});
	const [subMessage, setSubMessage] = useState('');

	let errorMessage = useMemo(() => {
		switch (blockedReason) {
		case 'No content from library':
			return 'It seems you need to add more content as the library category chosen doesn’t have enough compliant content to post with. Please add more and click “update campaign” to continue future posting(s) in this campaign.';
		default:
			return blockedReason;
		}
	}, [blockedReason]);


	useEffect(() => {
		const {
			blocked = false, postedInfo = [], accountIds = [],
		} = postData;
		let reason = '';
		let isBlocked = blocked;
		let blockedAccounts = [];

		const postWasBlocked = isPostWasBlocked(postData);
		if (postWasBlocked && !errorMessage && postedInfo.length) {
			const blockedPost = postedInfo.filter(info => !info.id)[0];
			if (blockedPost) {
				errorMessage = blockedPost.blockedReason;
				isBlocked = true;
			}
		}

		if (isBlocked) {
			reason = errorMessage;
		}

		if (!reason && postWasBlocked && errorMessage) {
			reason = errorMessage;
		}
		blockedAccounts = postedInfo.filter(info => !info.id && info.id !== 'reminder').map(info => info.accountId);
		blockedAccounts = blockedAccounts.filter(acc => accountIds.includes(acc));

		const arrayOfBlockedAccounts = userAccounts.filter(account => blockedAccounts.includes(account._id));
		const mobileTokensReason = 'No Mobile tokens found';

		if (reason && reason.length && reason.includes(mobileTokensReason)) {
			reason = 'Please install mobile app on your device and log in. Or re-authorize in the already installed mobile app.';
		}

		changeBlockedReason(reason);
		changePostBlockedAccounts(arrayOfBlockedAccounts);
	}, [postData]);

	const getMessageText = () => (
		<Fragment>
			<div>Looks like this post was not posted to:</div>
			<div>{postBlockedAccounts.map(({
				socialMedia, avatar, hootsuiteId, tokenExpired, name, _id,
			}) => (
				!(hootsuiteId && tokenExpired) && (
					<UserSocialAccountsBlock
						key={`${socialMedia} ${avatar}`}
						socialMedia={socialMedia}
						avatar={avatar || ''}
						hootsuite={!!hootsuiteId}
						name={name}
						acocuntId={_id}
					/>
				)
			))}
			</div>
			<div>This post was blocked.
				{blockedReason && <div>Reason: <p style={{ color: 'red', overflowX: 'auto' }} dangerouslySetInnerHTML={{ __html: blockedReason }} /></div>}
			</div>
			<div>Let’s try that again?</div>
		</Fragment>);

	const congratsModalEvent = (postedInfo) => {
		setPostType(postedInfo.postType);
		setCongratsMessage(postedInfo.message);
		setCongratsModalOpen(postedInfo.open);
		setSubMessage(postedInfo.subMessage);
		setUpdatedPostData(postedInfo.postData);
	};

	const onPostSave = postedInfo => {
		if (!postedInfo.open) {
			reloadCalendarEvents();
			closePreviousModal(postedInfo);
		}
		congratsModalEvent(postedInfo);
	};

	const retryIconClick = event => {
		if (!postData.accountIds.length) {
			return eventClick();
		}
		if (postData.journalPost) {
			return goToJournalPostDetails(history, postData._id);
		}
		event.stopPropagation();
		return changeRepostedPostModalVisibility(true);
	};

	if (!postBlockedAccounts.length && postData.accountIds.length) return false;
	return (
		<div>
			{ !needToShowText && needToShowButton ?

				<div id="retry-button">
					<Button
						color="light"
						className="retry-btn"
						onClick={event => retryIconClick(event)}
					>
						Retry
					</Button>
					<Tooltip
						backgroundColor="#07a3ed"
						placement="top"
						isOpen={isRetryTooltipOpen}
						target="retry-button"
						toggle={() => setRetryTooltipOpen(!isRetryTooltipOpen)}
						delay={{ show: 0, hide: 0 }}
					>Please check your social account before attempting to retry
					</Tooltip>
				</div> :
				<div
					className="not-posted-post-warning-icon"
					{...clickable(postData.journalPost ? null : event => event.stopPropagation())}
				>
					{needToShowText &&
				<div
					className="retry-button-text"
					{...clickable(postData.journalPost ? null : () => changeRepostedPostModalVisibility(true))}
				>Retry
				</div>}
					<img
						src={notPostedPostIcon}
						alt="not-posted-post-icon"
						className="not-posted-post-icon"
						onClick={event => retryIconClick(event)}
					/>
				</div>}
			{isRepostedPostModalOpen && <ConfirmModal
				isOpen={isRepostedPostModalOpen}
				onConfirm={resendPost}
				onClose={() => changeRepostedPostModalVisibility(false)}
				message={getMessageText()}
				rejectActionText="Cancel"
				confirmActionText="Retry"
			/>}

			{isPostToSocialMediaModalOpen && <CalendarPostModal
				isOpen={isPostToSocialMediaModalOpen}
				toggle={changePostToSocialMediaModalVisibility}
				blockedPostData={postData}
				timezone={timezone}
				onSave={onPostSave}
				needToResend
				retry={!postData.campaignId && true}
				blockedAccountList={postBlockedAccounts}
			/>}

			<CalendarCongratsModal
				isOpen={congratsModalOpen}
				toggle={onPostSave}
				message={congratsMessage}
				subMessage={subMessage}
				url={postType}
				postData={updatedPostData}
				timezone={timezone}
				reloadCalendarEvents={() => {}}
			/>
		</div>
	);
};

NotPostedPostWarning.defaultProps = {
	closePreviousModal: null,
	needToShowText: false,
};

NotPostedPostWarning.propTypes = {
	postData: PropTypes.shape({}).isRequired,
	accounts: PropTypes.shape({}).isRequired,
	timezone: PropTypes.string.isRequired,
	closePreviousModal: PropTypes.func,
	needToShowText: PropTypes.bool,
	history: PropTypes.shape({}).isRequired,
	reloadCalendarEvents: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	accounts: selectors.getAccounts(state),
});

export default compose(
	connect(mapStateToProps, null),
	withRouter,
)(NotPostedPostWarning);
