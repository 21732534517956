/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, July 2020
 */

import moment from 'moment-timezone';

const detectWeekStartAndEndDates = (date, timezone) => {
	const startWeekDate = moment(date).tz(timezone).startOf('week').format('YYYY-MM-DD');
	const endWeekDate = moment(date).tz(timezone).endOf('week').format('YYYY-MM-DD');
	return [startWeekDate, endWeekDate];
};

export default detectWeekStartAndEndDates;
