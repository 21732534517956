/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment } from 'react';
import { Picker } from 'emoji-mart';
import { withRouter } from 'react-router-dom';
import Switch from 'react-toggle-switch';
import { compose } from 'redux';
import 'emoji-mart/css/emoji-mart.css';

import { updateExtensionSettingsInApi } from '../ChromeExtension.action';
import { APP_CMD_OPEN_MODAL_URL } from '../ChromeExtension';
import { withComingSoonModal } from './ComingSoonModal';
import SwitchSpinner from '../../../common/components/SwitchSpinner/SwitchSpinner';

import { EmojisDropDownIcon } from '../../../icons/svgIcons';

import './ExtensionSettings.css';

class ExtensionSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			emojisPopupOpen: false,
			predictiveLinguisticsToggleIsActive: false,
			predictiveLinguisticsLoader: false,
			predictiveLinguisticsDomain: [],
			predictiveLinguisticsDomainLoader: false,
			emojiSuggestionIsActive: false,
			emojiDblClickReplacementIsActive: false,
			emojiSuggestionLoader: false,
			emojiReplacementLoader: false,
		};
	}

	static getDerivedStateFromProps(nextProps) {
		const { extensionSettings, currentExtensionSettings } = nextProps;
		const predictiveLinguisticsToggleIsActive = currentExtensionSettings.length === 0 ? extensionSettings.predictiveLinguisticsToggleIsActive : currentExtensionSettings.predictiveLinguisticsToggleIsActive;
		const predictiveLinguisticsDomain = currentExtensionSettings.length === 0 ? extensionSettings.excludedDomains : currentExtensionSettings.excludedDomains;
		const emojiSuggestionIsActive = currentExtensionSettings.length === 0 ? extensionSettings.emojiSuggestionIsActive : currentExtensionSettings.emojiSuggestionIsActive;
		const emojiDblClickReplacementIsActive = currentExtensionSettings.length === 0 ? extensionSettings.emojiDblClickReplacementIsActive : currentExtensionSettings.emojiDblClickReplacementIsActive;

		return {
			predictiveLinguisticsToggleIsActive,
			predictiveLinguisticsDomain,
			emojiSuggestionIsActive,
			emojiDblClickReplacementIsActive,
		};
	}

	setEmojisPopupOpen(emojisPopupOpen) {
		this.setState({ emojisPopupOpen }, () => {
			const { eventMessage, sendDocHeightMsg } = this.props;
			if (eventMessage) {
				sendDocHeightMsg(eventMessage);
			}
		});
	}

	openModal(url) {
		const { eventMessage } = this.props;

		if (eventMessage) {
			eventMessage.source.postMessage(JSON.stringify({
				action: APP_CMD_OPEN_MODAL_URL, data: { url },
			}), '*');
		}
	}

	handleSelectEmoji() {
		this.setEmojisPopupOpen(false);
	}

	toggleEmojiSuggestion = (newValue) => {
		this.setState({ emojiSuggestionLoader: true });
		const { eventMessage, sendSettingsForUpdate, currentExtensionSettings = {} } = this.props;
		const {
			postingBlocked,
			colorBlindnessActivated,
			urlShortenerActivated,
		} = currentExtensionSettings;

		const {
			predictiveLinguisticsToggleIsActive,
			predictiveLinguisticsDomain,
			emojiDblClickReplacementIsActive,
		} = this.state;
		const args = {
			postingBlocked,
			colorBlindnessActivated,
			urlShortenerActivated,
			predictiveLinguisticsToggleIsActive,
			excludedDomains: predictiveLinguisticsDomain,
			emojiDblClickReplacementIsActive: newValue ? emojiDblClickReplacementIsActive : false,
			emojiSuggestionIsActive: newValue,
		};

		// TODO: refactor
		updateExtensionSettingsInApi(args).then(settings => {
			sendSettingsForUpdate(eventMessage, settings);

			this.setState({
				emojiSuggestionLoader: false,
			});
		});
	};

	togglePredictiveLinguistics = (newValue) => {
		this.setState({ predictiveLinguisticsToggleIsActive: newValue, predictiveLinguisticsLoader: true });
		const { eventMessage, sendSettingsForUpdate, currentExtensionSettings = {} } = this.props;

		const {
			postingBlocked,
			colorBlindnessActivated,
			urlShortenerActivated,
			emojiDblClickReplacementIsActive,
		} = currentExtensionSettings;
		const { predictiveLinguisticsDomain, emojiSuggestionIsActive } = this.state;
		const args = {
			postingBlocked,
			colorBlindnessActivated,
			urlShortenerActivated,
			predictiveLinguisticsToggleIsActive: newValue,
			excludedDomains: predictiveLinguisticsDomain,
			emojiSuggestionIsActive,
			emojiDblClickReplacementIsActive,
		};

		// TODO: refactor
		updateExtensionSettingsInApi(args).then(settings => {
			sendSettingsForUpdate(eventMessage, settings);

			this.setState({
				predictiveLinguisticsLoader: false,
			});
		});
	};

	togglePredictiveLinguisticsDomain = () => {
		this.setState({ predictiveLinguisticsDomainLoader: true });
		const {
			eventMessage,
			sendSettingsForUpdate,
			hostname,
			currentExtensionSettings: {
				postingBlocked,
				colorBlindnessActivated,
				urlShortenerActivated,
				excludedDomains,
			} = {},
		} = this.props;

		let updatedExcludedDomains;
		if (excludedDomains.some(el => el === hostname)) {
			updatedExcludedDomains = excludedDomains.filter(el => el !== hostname);
		} else {
			updatedExcludedDomains = [...excludedDomains, hostname];
		}

		const {
			predictiveLinguisticsToggleIsActive,
			emojiSuggestionIsActive,
			emojiDblClickReplacementIsActive,
		} = this.state;
		const args = {
			postingBlocked,
			colorBlindnessActivated,
			urlShortenerActivated,
			predictiveLinguisticsToggleIsActive,
			excludedDomains: updatedExcludedDomains,
			emojiSuggestionIsActive,
			emojiDblClickReplacementIsActive,
		};

		// TODO: refactor
		updateExtensionSettingsInApi(args).then(settings => {
			sendSettingsForUpdate(eventMessage, settings);

			this.setState({
				predictiveLinguisticsDomainLoader: false,
			});
		});
	};

	toggleEmojiReplacementSuggestion = (newValue) => {
		this.setState({ emojiReplacementLoader: true });
		const { eventMessage, sendSettingsForUpdate, currentExtensionSettings = {} } = this.props;
		const {
			postingBlocked,
			colorBlindnessActivated,
			urlShortenerActivated,
		} = currentExtensionSettings;

		const {
			predictiveLinguisticsToggleIsActive,
			predictiveLinguisticsDomain,
			emojiSuggestionIsActive,
		} = this.state;
		const args = {
			postingBlocked,
			colorBlindnessActivated,
			urlShortenerActivated,
			predictiveLinguisticsToggleIsActive,
			excludedDomains: predictiveLinguisticsDomain,
			emojiSuggestionIsActive,
			emojiDblClickReplacementIsActive: newValue,
		};

		// TODO: refactor
		updateExtensionSettingsInApi(args).then(settings => {
			sendSettingsForUpdate(eventMessage, settings);

			this.setState({
				emojiReplacementLoader: false,
			});
		});
	};

	render() {
		const { hostname, currentExtensionSettings, PromptWrapper } = this.props;
		const {
			emojisPopupOpen,
			predictiveLinguisticsToggleIsActive,
			predictiveLinguisticsLoader,
			predictiveLinguisticsDomainLoader,
			emojiSuggestionLoader,
			emojiReplacementLoader,
			emojiSuggestionIsActive,
			emojiDblClickReplacementIsActive,
			predictiveLinguisticsDomain,
		} = this.state;
		const getPpredictiveLinguisticsDomainActivated = () => predictiveLinguisticsDomain.every(el => el !== hostname);
		const turnOffOrOnPLForDomain = getPpredictiveLinguisticsDomainActivated() ? 'on' : 'off';
		console.log(PromptWrapper);

		return (
			<Fragment>
				<div className="extension-settings">
					<div className="setting">
						<div className="setting-name">
							Autocomplete
						</div>
						<div className="setting-toggle">
							{predictiveLinguisticsLoader && <SwitchSpinner />}
							<Switch
								onClick={() => this.togglePredictiveLinguistics(!predictiveLinguisticsToggleIsActive)}
								on={predictiveLinguisticsToggleIsActive}
								className="ext-switch"
							/>
						</div>
					</div>
					<div className="sub-setting">
						<div className="branch">{' '}</div>
						<div className="sub-title">
							<div>turn {turnOffOrOnPLForDomain} for <b>{hostname}</b></div>
						</div>
						<div className="setting-toggle">
							{predictiveLinguisticsDomainLoader && <SwitchSpinner />}
							<Switch
								onClick={predictiveLinguisticsToggleIsActive ? () => this.togglePredictiveLinguisticsDomain() : null}
								on={getPpredictiveLinguisticsDomainActivated()}
								className={`ext-switch${predictiveLinguisticsToggleIsActive ? '' : ' disabled'}`}
							/>
						</div>
					</div>
					<div className="setting">
						<div className="setting-name">
							Emoji Suggestions
						</div>
						<div className="setting-toggle">
							{emojiSuggestionLoader && <SwitchSpinner />}
							<Switch
								onClick={() => this.toggleEmojiSuggestion(!emojiSuggestionIsActive)}
								on={emojiSuggestionIsActive}
								className="ext-switch"
							/>
						</div>
					</div>
					<div className="sub-setting">
						<div className="branch">{' '}</div>
						<div className="sub-title">
							Emoji double click replacement
						</div>
						<div className="setting-toggle">
							{emojiReplacementLoader && <SwitchSpinner />}
							<Switch
								onClick={emojiSuggestionIsActive ? () => this.toggleEmojiReplacementSuggestion(!emojiDblClickReplacementIsActive) : null}
								on={emojiDblClickReplacementIsActive}
								className={`ext-switch${emojiSuggestionIsActive ? '' : ' disabled'}`}
							/>
						</div>
					</div>
					<div className="setting">
						<div className="setting-name">
							All Emoji
						</div>
						<div className="setting-toggle">
							<EmojisDropDownIcon
								className="open-drop-down-button"
								onClick={(e) => {
									e.preventDefault();
									this.setEmojisPopupOpen(!emojisPopupOpen);
								}}
							>Add
							</EmojisDropDownIcon>
						</div>
					</div>
				</div>
				{emojisPopupOpen && (
					<div className="emojis">
						<Picker
							set="twitter"
							onSelect={emoji => {
								this.props.selectEmoji(emoji);
							}}
						/>
					</div>
				)}
				<div className="extension-settings">
					<div className="setting">
						<button
							className="btn ext-btn-single-post"
							onClick={() => this.openModal('/create-single-post')}
						>
							Single Post
						</button>
						<button
							className="btn ext-btn-add-content"
							onClick={() => this.openModal('/add-content')}
						>
							Add Content
						</button>
					</div>
				</div>

				<PromptWrapper currentExtensionSettings={currentExtensionSettings} />
			</Fragment>
		);
	}
}

export default compose(
	withRouter,
	withComingSoonModal,
)(ExtensionSettings);
