/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

export const getRequestError = (entity) => {
	if (!entity.isLoading && entity.hasError) {
		return entity.response.message;
	}
	return '';
};

export const isDataLoadedSuccess = (entity) =>
	!entity.isLoading && !entity.hasError && entity.response;

export const getSuccessData = (entity) => {
	if (!entity.isLoading && !entity.hasError && entity.response) {
		return entity.response;
	}
	return {};
};

export const loadingKey = (key) => `${key}_REQUEST_LOADING`;
export const failKey = (key) => `${key}_REQUEST_FAIL`;
export const successKey = (key) => `${key}_REQUEST_SUCCESS`;
export const updateKey = (key) => `${key}_REQUEST_UPDATE`;
export const clearKey = (key) => `${key}_REQUEST_CLEARED`;

