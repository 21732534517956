/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment } from 'react';
import './Quotes.css';

import Loader from '../../../common/components/LoaderAnimation/Loader';
import {
	UserSubscriptionLimitsContext, UserSubscriptionService,
} from '../../UserSubscriptions/UserSubscriptionService';

class Quotes extends Component {
	static contextType = UserSubscriptionLimitsContext;

	constructor(props) {
  	super(props);
  	this.state = {
  		suggestionsFromArticleUrlAccess: false,
  		quotes: [{
  			id: '',
  			quote: '',
  		}],
  		number: 0,
  		link: '',
  		loader: true,
  	};

  	this.handlePrevQuote = this.handlePrevQuote.bind(this);
  	this.handleNextQuote = this.handleNextQuote.bind(this);
	}

	componentDidMount() {
  	if (!this.context) return;

  	const { limits, currentSubscription } = this.context;
  	const suggestionsFromArticleUrlAccess = UserSubscriptionService.getPlanItemLimit({ subscription: currentSubscription }, limits, 'suggestionsFromArticleUrl');
  	this.setState({ suggestionsFromArticleUrlAccess, loader: suggestionsFromArticleUrlAccess });
  	if (!suggestionsFromArticleUrlAccess) return;

  	if (this.props.link && this.props.link !== this.state.link) {
  		this.setState({ link: this.props.link, loader: true });
  	} else {
  		const quotes = (this.props.quotes || []).map(el => ({
  			id: this.props.quotes.indexOf(el), quote: `"${el}"`,
  		}));
  		this.setState({ quotes, loader: false });
  	}
	}

	componentWillReceiveProps(props) {
  	if (!this.state.suggestionsFromArticleUrlAccess) return;
  	if (props.link && props.link !== this.state.link) {
  		this.setState({ link: props.link, loader: true });
  	} else if (props.quotes && props.quotes.length) {
  		const quotes = props.quotes.map(el => ({ id: props.quotes.indexOf(el), quote: `"${el}"` }));
  		this.setState({ quotes, loader: false });
  	}
  	if (!props.link) this.setState({ link: '' });
	}

	handlePrevQuote() {
  	const { quotes, number } = this.state;
  	this.setState({
  		number: quotes[number - 1] ? number - 1 : number,
  	});
	}

	handleNextQuote() {
  	const { quotes, number } = this.state;
  	this.setState({
  		number: quotes[number + 1] ? number + 1 : number,
  	});
	}

	render() {
  	const { onAddQuote } = this.props;
  	const {
  		quotes, number, link, suggestionsFromArticleUrlAccess,
  	} = this.state;

  	return (
  		<div className="quotes-component-container" style={{ padding: !suggestionsFromArticleUrlAccess && 5 }}>
  			<div className="quotes-component">
  				{this.state.loader ? <Loader /> :
  					<Fragment>
  						{
  							suggestionsFromArticleUrlAccess ?
  								<>
  									<div
  										className="quote-row"
  										style={number < 1 ? { visibility: 'hidden' } : { visibility: 'visible' }}
  									>
  										{quotes.length && link ? <div onClick={this.handlePrevQuote}>{'<'}</div> : null}
  									</div>
  									<div className="quote-row quote-row-center">
  										<div className="quote-text">
  											{quotes.length && link ?
  												<i>{quotes[number].quote}</i>
  												:
  												<span>{link ? 'No Quotes' : 'Add a URL above and Woofy will fetch quote suggestions'}</span>}
  										</div>
  									</div>
  									<div
  										className="quote-row"
  										style={number === quotes.length - 1 ? { visibility: 'hidden' } : { visibility: 'visible' }}
  									>
  										{quotes.length && link ? <div onClick={this.handleNextQuote}>{'>'}</div> : null}
  									</div>
  									{quotes.length && link ?
  										<span
  											className="add-quote-to-post"
  											onClick={() => onAddQuote(quotes[number].quote)}
  										>+ add to post
  										</span>
  										: null}
  								</> :
  								<div className="quote-text">
                    Upgrade your plan and get Quotes
  								</div>
  						}
  					</Fragment>}
  			</div>
  		</div>
  	);
	}
}

export default Quotes;
