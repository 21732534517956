/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { getToken, clearAuthToken } from '../../common/auth';
import cloneObject from '../../utils/cloneObject';

const convertToGetParam = (data = {}) => Object.entries(data).map(e => e.join('=')).join('&');


const completedFetch = ({ url = '' }, options) => fetch(url, options)
	.then((response) => {
		if ((response.status === 401 || response.status === 409) && window.location.pathname !== '/login') {
			clearAuthToken();
			window.location = '/login';
		}

		if (!response.ok) {
			return response.json().then(res => Promise.reject(res));
		}
		return response.json();
	});

export const get = (requestAction, data = null) => {
	const options = {
		method: 'GET',
		headers: {},
	};
	options.headers.Authorization = `${getToken()}`;
	const url = data ? `${requestAction.url}?${convertToGetParam(data)}` : requestAction.url;
	const shadowRequestAction = Object.assign(cloneObject(requestAction), { url });
	return completedFetch(shadowRequestAction, options);
};

export const post = (requestAction, body) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	};
	options.headers.Authorization = `${getToken()}`;

	return completedFetch(requestAction, options);
};

export const put = (requestAction, body) => {
	const options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	};
	options.headers.Authorization = `${getToken()}`;

	return completedFetch(requestAction, options);
};

export const httpDelete = (requestAction) => {
	const options = {
		method: 'DELETE',
		headers: {},
	};
	options.headers.Authorization = `${getToken()}`;

	return completedFetch(requestAction, options);
};
