/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import * as requestActionTypes from '../constants/requestActionTypes';
import { get } from './_common/request';
import { loadingKey, failKey, successKey } from '../helpers/store';
import { getCalendar } from '../common/calendarHelper';

export const getAccountsFromDB = (silent = false, data = {}) => (dispatch) => {
	const requestType = requestActionTypes.GET_ACCOUNTS;
	if (!silent) {
		dispatch({ type: loadingKey(requestType.key) });
	}
	return get(requestType, { ...data, calendarId: getCalendar() })
		.then((response) => {
			dispatch({ type: successKey(requestType.key), payload: response });
			return Promise.resolve(response);
		})
		.catch((err) => {
			const msg = typeof err === 'string' ? { message: err } : err;
			dispatch({ type: failKey(requestType.key), payload: msg });
			return Promise.reject(msg);
		});
};

export const syncAccountsFromDB = (silent = false) => (dispatch) => {
	const requestType = requestActionTypes.SYNC_ACCOUNTS;
	if (!silent) {
		dispatch({ type: loadingKey(requestType.key) });
	}
	return get(requestType)
		.then((response) => {
			dispatch({ type: successKey(requestType.key), payload: response });
			return Promise.resolve(response);
		})
		.catch((err) => {
			const msg = typeof err === 'string' ? { message: err } : err;
			dispatch({ type: failKey(requestType.key), payload: msg });
			return Promise.reject(msg);
		});
};
