import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import cx from 'classnames';
import {
	Button,
} from 'reactstrap';
// import { getOrientation } from 'get-orientation/browser';
import ReactTooltip from 'react-tooltip';
import { getCroppedImg, parsedImgKey/* , getRotatedImage */ } from '../../../../helpers/canvasUtils';
import resetIcon from '../../../../images/refresh_black_24dp.svg';
import rotateLeftIcon from '../../../../images/rotate_left_black_24dp.svg';
import rotateRightIcon from '../../../../images/rotate_right_black_24dp.svg';
import centeredFocusIcon from '../../../../images/center_focus_strong_black_24dp.svg';
import zoomInIcon from '../../../../images/zoom_in_black_24dp.svg';
import zoomOutIcon from '../../../../images/zoom_out_black_24dp.svg';

/* const ORIENTATION_TO_ANGLE = {
	3: 180,
	6: 90,
	8: -90,
}; */
const DEFAULT_ZOOM_VALUE = 1;
const initCropState = { x: 0, y: 0 };
const RATE_MAP = [
	// {
	// 	title: 'Custom',
	// 	subTitle: '',
	// 	rateType: 'preset-custom',
	// 	// rate: 1 / 1,
	// },
	{
		title: 'Post',
		subTitle: '1:1',
		rateType: 'preset-1-1',
		rate: 1 / 1,
	},
	{
		title: 'Landscape',
		subTitle: '1.91:1',
		rateType: 'preset-1.91-1',
		rate: 1.91 / 1,
	},
	{
		title: 'Portrait',
		subTitle: '4:5',
		rateType: 'preset-4-5',
		rate: 4 / 5,
	},
	{
		title: 'Portrait (horizontal)',
		subTitle: '5:4',
		rateType: 'preset-5-4',
		rate: 5 / 4,
	},
	{
		title: 'Story',
		subTitle: '9:16',
		rateType: 'preset-9-16',
		rate: 9 / 16,
	},
];


/* TODO handled socialImages prop and view all socialImages items for editing */
const ImageCropComponent = ({
	imageUrl, /* file, socialImages, confirmCropHandler, declineCropHandler, */ setCropImageHandler,
}) => {
	const [imageSrc] = useState(imageUrl);
	const [crop, setCrop] = useState({ ...initCropState });
	const [zoom, setZoom] = useState(DEFAULT_ZOOM_VALUE);
	const [rotation, setRotation] = useState(0);
	const [aspectType, setAspectType] = useState(RATE_MAP[0].rateType);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);
	const [disabledButotn, setDisabledButton] = useState(false);

	const getRate = (rateType = RATE_MAP[0].rateType) =>
		RATE_MAP.find(item => item.rateType === rateType) || RATE_MAP[0];
	// eslint-disable-next-line no-shadow
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);
	const showCroppedImage = useCallback(async () => {
		setDisabledButton(true);
		try {
			const cropItem = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
			console.log('cropItem', cropItem);
			const croppedImageBlobUri = {
				uri: cropItem.uri,
				file: cropItem.file,
				name: cropItem.imageKey,
			};

			setCroppedImage(croppedImageBlobUri.uri);
			setCropImageHandler(croppedImageBlobUri, getRate(aspectType));
			setDisabledButton(false);
		} catch (e) {
			console.error(e);
			setDisabledButton(false);
		}
	}, [imageSrc, croppedAreaPixels, rotation]);
	const removeCroppedImage = () => {
		const { imageKey } = parsedImgKey(imageSrc);
		setCropImageHandler({ name: imageKey }, getRate(aspectType));
		setCroppedImage(null);
		setDisabledButton(false);
	};

	const resetPositionHandler = () => {
		setCrop({ ...initCropState });
		setRotation(0);
		setZoom(DEFAULT_ZOOM_VALUE);
	};
	const changeZoom = (stepValue) => {
		setZoom(zoom + stepValue);
	};
	const centeredHandler = () => {
		setCrop({ ...initCropState });
	};
	const rotateLeftHandler = () => {
		setRotation(rotation - 90);
	};
	const rotateRightHandler = () => {
		setRotation(rotation + 90);
	};

	return (
		// eslint-disable-next-line react/jsx-filename-extension
		<React.Fragment>
			<div className="crop-img-template">
				<div className="crop-img-menu">
					<div className="crop-preset-list">
						{
							RATE_MAP.map(({
											  title, subTitle, rateType,
										  }) => (<div className="crop-img-preset">
								<div
									onClick={!croppedImage && (() => setAspectType(rateType))}
									className={cx('crop-preset-item', rateType, {
										'preset-selected': rateType === aspectType,
										disabled: croppedImage,
									})}
								>
									<span className="crop-preset-subtitle">{subTitle}</span>
									<span className="crop-preset-title">{title}</span>
								</div>
							</div>))
						}
					</div>

					{
						croppedImage ?
							(<Button
								onClick={removeCroppedImage}
								variant="contained"
								color="secondary"
								className="crop-confirm-btn"
							>
								Retry
							</Button>)
							:
							(<Button
								onClick={showCroppedImage}
								variant="contained"
								color="success"
								className="crop-confirm-btn"
								disabled={disabledButotn}
							>
								Confirm Crop
							</Button>)
					}
				</div>
				<div className="crop-img-component">
					<div className="crop-img-submenu">
						<img
							onClick={centeredHandler}
							src={centeredFocusIcon}
							alt="reset position"
							className="crop-submenu-icon-btn"
							data-tip="Centered position"
						/>
						<div className="separate sep-vertical" />
						<img
							onClick={() => changeZoom(0.1)}
							src={zoomInIcon}
							alt="zoom in"
							className="crop-submenu-icon-btn"
							data-tip="Zoom in"
						/>
						<img
							onClick={() => changeZoom(-0.1)}
							src={zoomOutIcon}
							alt="zoom out"
							className="crop-submenu-icon-btn"
							data-tip="Zoom out"
						/>
						<div className="separate sep-vertical" />
						<img
							onClick={rotateLeftHandler}
							src={rotateLeftIcon}
							alt="turn left"
							className="crop-submenu-icon-btn"
							data-tip="Turn left 90 dec"
						/>
						<img
							onClick={rotateRightHandler}
							src={rotateRightIcon}
							alt="turn right"
							className="crop-submenu-icon-btn"
							data-tip="Turn right 90 dec"
						/>
						<div className="separate sep-vertical" />
						<img
							onClick={resetPositionHandler}
							src={resetIcon}
							alt="reset"
							className="crop-submenu-icon-btn"
							data-tip="Reset image"
						/>
					</div>
					<div className="crop-img-editor-container">
						{
							croppedImage ?
								(<img className="preview-crop-img" src={croppedImage} alt="crop result" />)
								:
								(<Cropper
									image={imageSrc}
									crop={crop}
									zoom={zoom}
									minZoom={0.3}
									rotation={rotation}
									aspect={getRate(aspectType).rate}
									restrictPosition={false}
									onCropChange={setCrop}
									onCropComplete={onCropComplete}
									onZoomChange={setZoom}
									onRotationChange={setRotation}
								/>)
						}
					</div>
				</div>
				<ReactTooltip
					place="top"
					effect="float"
				/>
			</div>
		</React.Fragment>
	);
};

export default ImageCropComponent;
