/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, November 2020
 */

import linkify from 'linkify-it';

const linkifyOptions = { fuzzyEmail: false };

const getArrayOfUrlsFromText = text => {
	const objectsOfURLs = linkify(linkifyOptions).match(text) || [];
	if (!Object.values(objectsOfURLs)) {
		console.log('There are no urls was detected');
		return [];
	}
	return objectsOfURLs.map(urlObject => urlObject.raw);
};

export default getArrayOfUrlsFromText;
