import React, { Fragment } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import woofyDog from '../../../images/peakingsnoopy.png';
import {
	getReasonUpgradeMessage,
	upgradeToPlanMessage,
} from '../../../utils/subcription';

import styles from './UpgradePlanModal.module.css';
import UpgradeNowButton from './UpgradeNowButton';

const UpgradePlanModal = (props) => {
	const {
		upgradeSubscriptionType, isOpen, onClose,
		user, customMessage, upgradeModalMetadata, limits,
	} = props;

	const currentPlan = user.response && user.response.subscription && user.response.subscription.plan;

	const bodyMessage =
		(<p className={styles.upgradeText}>
			<div style={{ paddingBottom: 6 }}>{customMessage || getReasonUpgradeMessage(user.response, upgradeModalMetadata)}</div>
			<div>{upgradeToPlanMessage(user.response, limits, upgradeModalMetadata)}</div>
		</p>);

	return (
		<Modal
			isOpen={isOpen}
			   className={styles.root}
			   contentClassName={styles.modalContent}
			   centered
			toggle={onClose}
		>
			<ModalHeader toggle={onClose} className={styles.header} />
			<ModalBody className={styles['upgrade-modal-body']}>
				<img src={woofyDog} className={styles.dogIcon} alt="Woofy Dog Upgrade" />
				<div className={styles.mainContent}>
					<div style={{ fontWeight: 'bold', fontSize: 30 }}>Hey there!</div>
					{upgradeSubscriptionType || !currentPlan ? (
						<Fragment>
							{bodyMessage}
							<UpgradeNowButton
								onClose={onClose}
							/>
						</Fragment>
					) : (
						<Fragment>
							<p className={styles.upgradeText}>Please, contact
								<a href="mailto:arjun@hellowoofy.com" className={styles.emailLink}>arjun@hellowoofy.com</a>
							</p>
						</Fragment>
					)}
				</div>
			</ModalBody>
		</Modal>
	);
};

export default React.memo(UpgradePlanModal);
