/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2020
 */

import React, { Fragment } from 'react';
import moment from 'moment';

function generateHours() {
	const hours = [];
	for (let i = 0; i < 24; i++) {
		const key = i >= 10 ? i : `0${i}`;
		hours.push(`${key}:00`);
		hours.push(`${key}:15`);
		hours.push(`${key}:30`);
		hours.push(`${key}:45`);
	}
	return hours;
}

const renderHourSelect = timezone => {
	const date = new Date();
	const arrayOfTimeDate = generateHours();
	const helloWoofyTimeZone = moment().tz(timezone).format('Z');
	return (
		<Fragment>
			<option value="">Select Hour&nbsp;&nbsp;&nbsp;Hellowoofy GMT {helloWoofyTimeZone}</option>
			{arrayOfTimeDate.map(hour => {
				date.setHours(hour.split(':')[0]);
				date.setMinutes(hour.split(':')[1]);
				return <option key={hour} value={hour}>{moment(date).format('h:mm A')}</option>;
			})}
		</Fragment>);
};
export default renderHourSelect;
