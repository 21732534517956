/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Fragment } from 'react';
import {
	Modal,
	ModalBody,
	Button,
} from 'reactstrap';
import { getSimilarColor } from '../../similarityHelper';
import './SimilarityModal.css';
import SimilarityBlock from '../../../common/components/SimilarityBlock/SimilarityBlock';

const SimilarityModal = (props) => {
	const {
		isOpen, toggle, similar, isTwitter = false,
		isPostCreate, createdPost = {}, onPostCreate,
		similarityScore = {}, onBatchCreate, isCalendar,
	} = props;
	const socialMedias = similarityScore.socialMedias || [];
	const library = similarityScore.library;

	const needToHidePostAnyWayButton = socialMedias.some(socialMedia => (socialMedia.socialMedia === 'twitter' && socialMedia.maxScore >= 95));

	const similarityDataError = (
		<div className="similarity-message-block">
			{'Oops, looks like you already scheduled something before (in the last or next 30 days) or have a post in your library that’s '}
			<span
				style={{ color: getSimilarColor(similar) }}
			>
				{similar && similar.toFixed()}%
			</span>
			{' similar to this post.'}
			<br />
			Let’s try to change it up a bit
		</div>
	);

	const onPostAnyway = () => {
		if (onPostCreate) {
			const acceptedPost = { ...createdPost };
			acceptedPost.complianceEngineRules = { ignoreComplianceCheck: true };
			return onPostCreate(acceptedPost);
		}
		return onBatchCreate({ ignoreComplianceCheck: true });
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			centered
			className={`campaign-confirm similarity-library-post-modal ${isPostCreate && 'similarity-calendar-post-modal'}`}
		>{
				isPostCreate
					?
					<div className="Button">
						<Fragment>
							{similar ? similarityDataError :
								<div className="similarity-calendar-post-modal-message">
									Oops, looks like you already scheduled something before
									(in the last or next 30 days)
									thats’s similar to this post.
									<br />
									Let’s try to change it up a bit
								</div>
							}
							<SimilarityBlock isLibraryPost similar={{ socialMedias, library }} />
							<div className="similarity-calendar-post-modal-buttons">
								<Button className="similarity-calendar-post-modal-button" color="light" onClick={toggle}>CANCEL</Button>
								{!needToHidePostAnyWayButton &&
								<Button
									className="similarity-calendar-post-modal-button"
									color="primary"
									onClick={onPostAnyway}
								>
									{ isCalendar ? 'POST ANYWAYS' : 'ADD CONTENT ANYWAY'}
								</Button>}
							</div>
						</Fragment>
					</div>
					:
					<ModalBody>
						{ !isTwitter &&
						<div>
							The post is too similar! You must change the content or hashtags since your current content is
							<span
								style={{ color: getSimilarColor(similar) }}
							> {similar && similar.toFixed()}%
							</span>
							{' similar to something you posted/saved before'}
						</div>}
						{ isTwitter && similarityDataError }
					</ModalBody>
			}

		</Modal>
	);
};

export default SimilarityModal;
