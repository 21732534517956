/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { get } from '../../../common/httpRequests';
import { POST_URL, CAMPAIGN_URL, JOURNAL_POSTS_URL } from '../../../common/apiRouting';

export function getPosts(id, startDate, endDate) {
	let baseUrl = `${POST_URL}?calendarId=${id}`;
	if (startDate && endDate) {
		baseUrl += `&dateStart=${startDate}&dateEnd=${endDate}`;
	} else if (startDate && !endDate) {
		baseUrl += `&dateStart=${startDate}`;
	} else if (!startDate && endDate) {
		baseUrl += `&dateEnd=${endDate}`;
	}
	return get(baseUrl, true)
		.then(res => res.json());
}

export function getPostsById(postId) {
	return get(`${POST_URL}/${postId}`, true)
		.then(res => res.json());
}

export function getCampaigns(id) {
	return get(`${CAMPAIGN_URL}?calendarId=${id}`, true)
		.then(res => res.json());
}

export function getCampaign(id) {
	return get(`${CAMPAIGN_URL}/${id}`, true)
		.then(res => res.json());
}

export function getPostsByCampaignId(id) {
	return get(`${POST_URL}?campaignId=${id}`, true)
		.then(res => res.json());
}

export function getDraftPosts(options, calendarId) {
	let params = '?';
	Object.keys(options).forEach((key) => {
		if (options[key].length || typeof options[key] === 'number') {
			params = `${params + key}=${options[key]}&`;
		}
	});

	return get(`${POST_URL + params}calendarId=${calendarId}`, true)
		.then(res => res.json());
}


export function getJournalPostsForCalendar() {
	return get(`${JOURNAL_POSTS_URL}/calendar`, true)
		.then(res => res.json());
}
