/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */
import { loadingKey, failKey, successKey, updateKey, clearKey } from '../../helpers/store';

const loadingReducer = state => ({
	...state,
	hasError: false,
	isLoading: true,
});
const successReducer = (state, action) => ({
	...state,
	hasError: false,
	isLoading: false,
	response: Object.assign({}, action.payload),
});
const errorReducer = (state, action) => ({
	...state,
	hasError: true,
	isLoading: false,
	response: Object.assign({}, action.payload),
});
const updateReducer = (state, action) => ({
	...state,
	hasError: false,
	isLoading: false,
	response: Object.assign({}, action.payload),
});
const clearReducer = () => ({
	hasError: false,
	isLoading: false,
});

export const noReducer = state => state;

const createRequestReducer = ({ key }) => ({
	[loadingKey(key)]: loadingReducer,
	[failKey(key)]: errorReducer,
	[successKey(key)]: successReducer,
	[updateKey(key)]: updateReducer,
	[clearKey(key)]: clearReducer,
});

export const withLoadable = requestName => {
	const actionReducerMap = createRequestReducer(requestName);
	return baseReducer =>
		(state, action) => {
			const reducerFunction = actionReducerMap[action.type] || noReducer;
			const newState = reducerFunction(state, action);
			return baseReducer(newState, action);
		};
};
