/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import moment from 'moment';

import arrowLeftIcon from '../../../images/chevron-left.svg';
import arrowRightIcon from '../../../images/chevron-right.svg';

import './CalendarMonthPicker.css';

class CalendarMonthPicker extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dateFormat: 'MMMM YYYY',
		};
	}

	onChangeMonth(value) {
		const currentMonth = moment(this.props.currentMonth).add(value ? -1 : 1, 'month');

		this.props.onChangeMonth(currentMonth);
	}

	renderCurrentMonth() {
		return moment(this.props.currentMonth).format(this.state.dateFormat);
	}

	render() {
		const { isDisableChangeMonthButtons } = this.props;

		return (
			<div className="month-picker">
				{!isDisableChangeMonthButtons ?
					<span onClick={() => this.onChangeMonth(true)} className="arrow" style={{ backgroundImage: `url(${arrowLeftIcon})` }} />
					: null}
				<div className="current-month">{this.renderCurrentMonth()}</div>
				{!isDisableChangeMonthButtons ?
					<span onClick={() => this.onChangeMonth(false)} className="arrow" style={{ backgroundImage: `url(${arrowRightIcon})` }} />
					: null}
			</div>
		);
	}
}

export default CalendarMonthPicker;
