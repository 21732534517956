/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './SignInButtons.css';

class SignInButtons extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
	}

	render() {
		return (
			<div className="info-section">
				<div className="feature">
					<span role="img" aria-label="pencil">📝</span> ‪Autocomplete any word or phrase. Type to begin.
				</div>
				<div className="feature">
					<span role="img" aria-label="mag">🔎</span> Find contextually relevant and engaging emojis. Highlight a word or an entire sentence for suggestions.
				</div>
				<div className="feature">
					<span role="img" aria-label="writing_hand">✍️</span> Manually enter emojis in any text field.
				</div>
				<div className="feature">
					<span role="img" aria-label="calendar">📅</span> Schedule or add content into HelloWoofy’s AI driven social media scheduler (ie articles, blog posts etc).
				</div>
				<div className="feature">
					<span role="img" aria-label="envelope_with_arrow">📩</span> <a href="mailto:arjun@hellowoofy.com">arjun@hellowoofy.com</a>
				</div>
				<div className="button-section">
					<a href="/login" className="btn-login" target="_blanck">Login</a>
					<a href="/signup" className="btn-signup" target="_blanck">Sign Up</a>
				</div>
			</div>
		);
	}
}

export default withRouter(SignInButtons);
