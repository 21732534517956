/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */
import debounce from 'lodash/debounce';
import { get, post } from '../../httpRequests';
import { PREDICTIVE_SUGGESTION_API_URL, PREDICTIVE_EMOJI_HASHTAGS_API_URL } from '../../apiRouting';

const getEmojiAndHashtagsHandler = (text, controller, resolve, reject) => get(`${PREDICTIVE_EMOJI_HASHTAGS_API_URL}?text=${encodeURIComponent(text)}`, true, controller)
	.then(result => result.json())
	.then(resolve)
	.catch(reject);
const getEmojiAndHashtagsDebounce = debounce(getEmojiAndHashtagsHandler, 1500);

export function getSuggestion(text, controller) {
	return post(PREDICTIVE_SUGGESTION_API_URL, { text }, true, controller)
		.then(res => res.json());
}

export function getEmojiAndHashtags(text, controller) {
	return new Promise((resolve, reject) => {
		getEmojiAndHashtagsDebounce(text, controller, resolve, reject);
	});
}
