import React from 'react';

export const ErrorIcon = props => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 64 64"
		enableBackground="new 0 0 64 64"
		xmlSpace="preserve"
		{...props}
	>
		<g>
			<g id="XMLID_47_">
				<g>
					<g>
						<path fill="#F94646" d="M32,0c17.7,0,32,14.3,32,32S49.7,64,32,64S0,49.7,0,32S14.3,0,32,0z" />
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<polygon fill="#FFFFFF" points="29,16 35,16 34,36 30,36 				" />
					</g>
				</g>
				<g>
					<g>
						<circle fill="#FFFFFF" cx="32" cy="44" r="4" />
					</g>
				</g>
			</g>
		</g>
	</svg>);

export const EditIcon = props => (
	<svg
		width="21"
		height="20"
		viewBox="0 0 21 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M17.9006 12.3454V17.0921C17.9006 17.5636 17.7133 18.0158 17.3799 18.3492C17.0465 18.6826 16.5944 18.8699 16.1229 18.8699H3.67841C3.20692 18.8699 2.75473 18.6826 2.42133 18.3492C2.08794 18.0158 1.90063 17.5636 1.90063 17.0921V4.64765C1.90063 4.17616 2.08794 3.72397 2.42133 3.39057C2.75473 3.05717 3.20692 2.86987 3.67841 2.86987H8.42508" stroke="#747B95" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path fillRule="evenodd" clipRule="evenodd" d="M16.1229 1.09216L19.6785 4.64772L10.7896 13.5366H7.23401V9.98105L16.1229 1.09216Z" stroke="#747B95" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export const CreatePostIcon = props => (
	<svg
		width="18"
		height="20"
		viewBox="0 0 18 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="20">
			<rect width="18" height="20" fill="white" />
		</mask>
		<g mask="url(#mask0)">
			<path d="M8.36367 13.0927L8.35815 13.1014L8.35291 13.1104L7.27779 14.9431C6.90352 15.5811 7.00734 16.3913 7.53037 16.9144L9.46688 18.8509C10.2996 19.6836 11.714 19.3817 12.134 18.2815L17.1416 5.16638C17.6425 3.85446 16.3558 2.56771 15.0439 3.06863L1.92877 8.07622C0.828584 8.49629 0.526636 9.91064 1.35936 10.7434L3.33794 12.7219C3.86096 13.245 4.67122 13.3488 5.30922 12.9745L7.14194 11.8994L7.15317 11.8928L7.16412 11.8858L10.4736 9.75418L8.36367 13.0927Z" stroke="#747B95" strokeWidth="1.25" />
		</g>
	</svg>
);

export const ArrowIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="8"
		height="14"
		viewBox="0 0 8 14"
		{...props}
	>
		<path fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 13L1 7l6-6" opacity=".6" />
	</svg>
);

export const VideoIcon = props => (
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M16 11L8.5 15.3301L8.5 6.66987L16 11Z" stroke="#2F80ED" />
		<circle cx="11" cy="11" r="10.5" style={{ stroke: '#478DEF', position: 'relative', strokeWidth: 1 }} />
	</svg>
);

export const ViewCampaignIcon = props => (
	<svg width="65" height="61" viewBox="0 0 65 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g filter="url(#filter0_d)">
			<path d="M46.218 39.729H25.781C24.222 39.729 23 38.276 23 36.422V21.431C23 19.577 23.7 18.257 25.26 18.257H32.043V17.127C32.043 16.519 32.413 16 33.028 16C33.644 16 34.143 16.494 34.143 17.102L34.304 18.257H37.696L37.857 17.102C37.857 16.494 38.356 16 38.971 16C39.587 16 40.085 16.494 40.085 17.102L39.957 18.257H46.739C48.3 18.257 49 19.577 49 21.431V36.422C49 38.276 47.778 39.729 46.218 39.729ZM46.218 20.329H40.085V20.776C40.085 21.384 39.441 21.649 38.826 21.649C38.21 21.649 37.857 21.384 37.857 20.776V20.328H34.143V20.776C34.143 21.384 33.789 21.649 33.173 21.649C32.558 21.649 31.915 21.384 31.915 20.776V20.328L25.261 20.518C25.066 20.518 25.228 20.747 25.228 21.431L25.261 23.91H46.739L46.772 21.43C46.772 20.747 46.414 20.328 46.218 20.328V20.329ZM46.772 36.422L46.739 26.171H25.261L25.228 36.422C25.228 37.106 25.066 37.475 25.261 37.475H46.739C46.934 37.475 46.772 37.106 46.772 36.422ZM32.043 34.084H28.653V30.692C28.653 30.286 28.533 29.595 28.943 29.595H32.41C32.82 29.595 33.152 29.922 33.152 30.329V33.757C33.152 34.163 32.454 34.084 32.043 34.084ZM28.653 34.084C28.242 34.084 28.653 33.756 28.653 34.084V34.084Z" fill="#705EFF" />
		</g>
		<defs>
			<filter id="filter0_d" x="0" y="-7" width="76" height="73.729" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dx="2" dy="2" />
				<feGaussianBlur stdDeviation="12.5" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.439216 0 0 0 0 0.368627 0 0 0 0 1 0 0 0 1 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
		</defs>
	</svg>
);

export const EmojisDropDownIcon = props => (
	<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx="13.5" cy="13.5" r="13" stroke="#C4C4C4" />
		<mask id="path-2-inside-1" fill="white">
			<path d="M19.5 16.5001C19.5 17.288 19.3448 18.0682 19.0433 18.7962C18.7417 19.5241 18.2998 20.1856 17.7426 20.7427C17.1855 21.2999 16.5241 21.7418 15.7961 22.0433C15.0681 22.3449 14.2879 22.5001 13.5 22.5001C12.7121 22.5001 11.9319 22.3449 11.2039 22.0433C10.4759 21.7418 9.81451 21.2999 9.25736 20.7427C8.70021 20.1855 8.25825 19.5241 7.95672 18.7962C7.65519 18.0682 7.5 17.288 7.5 16.5001L13.5 16.5001H19.5Z" />
		</mask>
		<path d="M19.5 16.5001C19.5 17.288 19.3448 18.0682 19.0433 18.7962C18.7417 19.5241 18.2998 20.1856 17.7426 20.7427C17.1855 21.2999 16.5241 21.7418 15.7961 22.0433C15.0681 22.3449 14.2879 22.5001 13.5 22.5001C12.7121 22.5001 11.9319 22.3449 11.2039 22.0433C10.4759 21.7418 9.81451 21.2999 9.25736 20.7427C8.70021 20.1855 8.25825 19.5241 7.95672 18.7962C7.65519 18.0682 7.5 17.288 7.5 16.5001L13.5 16.5001H19.5Z" stroke="#C4C4C4" strokeWidth="2" mask="url(#path-2-inside-1)" />
		<circle cx="18" cy="10.5" r="1" fill="#C4C4C4" stroke="#C4C4C4" />
		<circle cx="9" cy="10.5" r="1" fill="#C4C4C4" stroke="#C4C4C4" />
	</svg>
);

export const MicIcon = props => (
	<svg
		 xmlns="http://www.w3.org/2000/svg"
		 viewBox="0 0 512 512"
		 {...props}
	>
		<g>
			<g>
				<path d="M256,0c-49.456,0-89.693,40.237-89.693,89.693v176.279c0,49.45,40.237,89.687,89.693,89.687
					c49.456,0,89.693-39.957,89.693-89.071V89.693C345.693,40.237,305.456,0,256,0z M308.315,266.583
					c0,28.503-23.47,51.698-52.315,51.698s-52.321-23.464-52.321-52.315V89.693c0-28.851,23.47-52.321,52.321-52.321
					s52.321,23.47,52.315,52.321V266.583z"
				/>
			</g>
		</g>
		<g>
			<g>
				<rect x="237.314" y="409.226" width="37.372" height="84.088" />
			</g>
		</g>
		<g>
			<g>
				<path d="M305.207,474.628h-98.414c-10.321,0-18.686,8.365-18.686,18.686c0,10.321,8.365,18.686,18.686,18.686h98.414
					c10.321,0,18.686-8.365,18.686-18.686C323.893,482.993,315.528,474.628,305.207,474.628z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path d="M397.392,234.822c-10.321,0-18.686,8.365-18.686,18.686v14.949c0,67.656-55.049,122.706-122.706,122.706
					c-67.662,0-122.706-55.049-122.706-122.706v-14.949c0-10.321-8.365-18.686-18.686-18.686s-18.686,8.365-18.686,18.686v14.949
					c0,88.267,71.811,160.078,160.078,160.078s160.078-71.811,160.078-160.078v-14.949
					C416.078,243.188,407.713,234.822,397.392,234.822z"
				/>
			</g>
		</g>
	</svg>
);
