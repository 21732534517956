/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, July 2020
 */

import React from 'react';
import ReactTooltip from 'react-tooltip';

const AccessWarningTooltip = () => (
	<ReactTooltip id="access-warning-tooltip" type="warning" >
		<span>Contact your admin</span>
	</ReactTooltip>
);

export default AccessWarningTooltip;
