/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

import postWarningTooltip from '../../../images/postWarningTooltip.svg';

const renderToolTipText = () => (
	<div className="tool-tip">
		<div><span className="tool-tip-bold-text">• Twitter limits </span><span>280 character count</span></div>
		<div><span className="tool-tip-bold-text">• LinkedIn limits </span><span>3000 character count</span></div>
		<div><span className="tool-tip-bold-text">• Instagram limits </span><span>2200 character count</span></div>
		<div><span className="tool-tip-bold-text">• TikTok limits </span><span>500 character count</span></div>
		<div><span className="tool-tip-bold-text">• Snapchat limits </span><span>150 character count</span></div>
		<div><span className="tool-tip-bold-text">• Google my business limits </span><span>1500 character count</span></div>
		<div><span className="tool-tip-bold-text">• Google my Business posts </span><span>required to add a title with max length 58 character count</span></div>
		<div><span className="tool-tip-bold-text">• Google my business </span><span>supports only one image not smaller than 10kb and not greater than 25mb</span></div>
		<div><span className="tool-tip-bold-text">• Google my business limits </span><span>doesn&apos;t support GIFs and videos</span></div>
		<div><span className="tool-tip-bold-text">• Personal Facebook </span><span>reminders are supported only with HelloWoofy mobile app.</span></div>
		<div><span className="tool-tip-bold-text">• Instagram Business </span><span>If you have more than 2 posts scheduled a day for Instagram Business profiles, you will receive reminders for the rest of the posts</span></div>
		<div>
			<span className="tool-tip-bold-text">• Hootsuite </span>
			<span>have some restrictions for uploading videos.&nbsp;
				<a
					href="https://help.hootsuite.com/hc/en-us/articles/217417907-Publish-a-video"
					target="_blank"
					rel="noopener noreferrer"
					className="tool-tip-text-link"
				>More info
				</a>.
			</span>
		</div>
		<span className="tool-tip-text">
			Please verify you don&apos;t have any types of content within your categories that may not post as a result of the above social media platform
						limitations set forth by the TOS of each platform.
		</span>
	</div>);

const PostLimitsWarningTooltip = () => (
	<Fragment>
		<div id="post-warning-tooltip" data-tip="" data-for="post-limit-warning">
			<img
				src={postWarningTooltip}
				alt="post-warning-tooltip"
				className="warning-tooltip-icon"
			/>
		</div>
		<ReactTooltip
			id="post-limit-warning"
			className="post-campaign-warning-tooltip"
			place="bottom"
			effect="solid"
			backgroundColor="#07a3ed"
			getContent={renderToolTipText}
			delayHide={300}
			clickable
		/>
	</Fragment>
);

export default PostLimitsWarningTooltip;
