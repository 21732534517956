import React from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { post, get } from '../../common/httpRequests';
import {
	RECURLY_CANCEL__URL,
	RECURLY_CHANGE_SUBSCRIPTION_URL,
	RECURLY_PREVIEW_SUBSCRIPTION_URL,
	RECURLY_GET_LIMITS,
	RECURLY_GET_PLANS,
	RECURLY_CHECK_PLAN_LIMITS,
	RECURLY_CREATE_SUBSCRIPTION,
	RECURLY_COUPON_CODE_URL,
	RECURLY_ACTIVATE_SUBSCRIPTION_URL,
} from '../../common/apiRouting';
import { getUserBillingInformationLink } from '../Settings/Settings.action';
import { subscriptionType } from '../../constants/subscriptionTypes';

export const UserSubscriptionLimitsContext = React.createContext(null);

export const SubscriptionWeights = {
	basicnew: 1,
	advancednew: 2,
	businessnew: 3,
};

export const SubscriptionUpgrade = {
	free: 'basic',
	single: 'basic',
	pro: 'essentials',
	smb: 'advanced',
	agency: 'business',
	agencyplus: 'business',
	enterprise: 'enterprisenew',
	basic: 'essentials',
	essentials: 'advanced',
	advanced: 'business',
	business: 'enterprisenew',
	enterprisenew: 'businessnew',
	basicnew: 'advancednew',
	advancednew: 'businessnew',
	businessnew: null,
};

export const PlanToPricingSaving = {
	[subscriptionType.monthly_basic]: {
		price: 9.9,
		saving: 17,
		isLegacy: true,
	},
	[subscriptionType.annual_basic]: {
		price: 99,
		saving: 209,
		oldPrice: 150,
		isLegacy: true,
	},
	[subscriptionType.monthly_single]: {
		price: 5,
		saving: 17,
		isLegacy: true,
	},
	[subscriptionType.annual_single]: {
		price: 49,
		saving: 209,
		isLegacy: true,
	},
	[subscriptionType.monthly_essentials]: {
		price: 14.9,
		saving: 44,
		isLegacy: true,
	},
	[subscriptionType.annual_essentials]: {
		price: 149,
		saving: 539,
		oldPrice: 250,
		isLegacy: true,
	},
	[subscriptionType.monthly_pro]: {
		price: 7,
		saving: 44,
		isLegacy: true,
	},
	[subscriptionType.annual_pro]: {
		price: 69,
		saving: 539,
		isLegacy: true,
	},
	[subscriptionType.monthly_advanced]: {
		price: 24.9,
		saving: 62,
		isLegacy: true,
	},
	[subscriptionType.annual_advanced]: {
		price: 249,
		saving: 33,
		oldPrice: 350,
		isLegacy: true,
	},
	[subscriptionType.monthly_smb]: {
		price: 11,
		saving: 62,
		isLegacy: true,
	},
	[subscriptionType.annual_smb]: {
		price: 99,
		saving: 33,
		oldPrice: 132,
		isLegacy: true,
	},
	[subscriptionType.monthly_business]: {
		price: 69.9,
		saving: 88,
		isLegacy: true,
	},
	[subscriptionType.annual_business]: {
		price: 699,
		saving: 79,
		oldPrice: 800,
		isLegacy: true,
	},
	[subscriptionType.monthly_agency]: {
		price: 19,
		saving: 88,
		isLegacy: true,
	},
	[subscriptionType.annual_agency]: {
		price: 149,
		saving: 79,
		oldPrice: 228,
		isLegacy: true,
	},
	[subscriptionType.monthly_agencyplus]: {
		price: 49,
		saving: 224,
		isLegacy: true,
	},
	[subscriptionType.annual_agencyplus]: {
		price: 399,
		saving: 189,
		oldPrice: 588,
		isLegacy: true,
	},
	[subscriptionType.monthly_enterprisenew]: {
		price: 199.9,
		saving: 224,
		isLegacy: true,
	},
	[subscriptionType.annual_enterprisenew]: {
		price: 1999,
		saving: 189,
		oldPrice: 2400,
		isLegacy: true,
	},
	[subscriptionType.monthly_enterprise]: {
		price: 149,
		saving: 1060,
		isLegacy: true,
	},
	[subscriptionType.annual_enterprise]: {
		price: 1299,
		saving: 489,
		oldPrice: 1788,
		isLegacy: true,
	},
	[subscriptionType.annual_basicnew]: {
		price: 99,
		saving: 19.80,
	},
	[subscriptionType.monthly_basicnew]: {
		price: 9.9,
		saving: 0,
	},
	[subscriptionType.annual_advancednew]: {
		price: 399,
		saving: 69,
	},
	[subscriptionType.monthly_advancednew]: {
		price: 39,
		saving: 0,
	},
	[subscriptionType.annual_businessnew]: {
		price: 999,
		saving: 189,
	},
	[subscriptionType.monthly_businessnew]: {
		price: 99,
		saving: 0,
	},
};

export const planNames = {
	single: 'Legacy Single',
	pro: 'Legacy Pro',
	smb: 'Legacy SMB',
	agency: 'Legacy Agency',
	agencyplus: 'Legacy Agency Plus',
	enterprise: 'Legacy Enterprise',
	basic: 'Legacy Basic',
	essentials: 'Legacy Essentials',
	advanced: 'Legacy Advanced',
	business: 'Legacy Business',
	enterprisenew: 'Legacy Enterprise',
	basicnew: 'Basic',
	advancednew: 'Advanced',
	businessnew: 'Business',
};

export const planPeriodNames = {
	annual: 'Annual',
	monthly: 'Monthly',
};

export class UserSubscriptionService {
	// -------------API REQUESTS-------------
	static getLimits() {
		return get(RECURLY_GET_LIMITS, true)
			.then(res => res.json());
	}

	static getPlans() {
		return get(RECURLY_GET_PLANS, true)
			.then(res => res.json());
	}

	static createSubscription({
		fbp,
		value,
		details,
		tokenId,
		event_id,
		couponCode,
		content_name,
	}) {
		return post(RECURLY_CREATE_SUBSCRIPTION, {
			fbp,
			value,
			details,
			tokenId,
			event_id,
			couponCode,
			content_name,
		}, true)
			.then(res => res.json());
	}

	static change(newPlanCode, couponCode) {
		return post(RECURLY_CHANGE_SUBSCRIPTION_URL, { newPlanCode, couponCode }, true)
			.then(res => res.json());
	}

	static changePreview(newPlanCode, couponCode) {
		return post(RECURLY_PREVIEW_SUBSCRIPTION_URL, { couponCode, newPlanCode }, true)
			.then(res => res.json());
	}

	static cancel() {
		return post(RECURLY_CANCEL__URL, {}, true)
			.then(res => res.json());
	}

	static activateSubscription() {
		return post(RECURLY_ACTIVATE_SUBSCRIPTION_URL, {}, true)
			.then(res => res.json());
	}

	static checkLimitsForNewSubscription(newPlanName) {
		return post(RECURLY_CHECK_PLAN_LIMITS, { newPlanName }, true)
			.then(res => res.json())
			.then(response => response.data);
	}
	// -------------API REQUESTS-------------


	// -------------Window Open Methods-------------
	static goToRecurly(planName, period, user, config) {
		const recurlyPlanName = `${period}_${planName}`;

		const { recurly: { url = '' } } = config;
		const { _id: userId, email } = user;

		const recurlyUrl = `${url}/subscribe/${recurlyPlanName}/${userId}?email=${encodeURIComponent(email)}`;
		return window.open(recurlyUrl, '_self');
	}

	static openBillingInformation = debounce(() => {
		getUserBillingInformationLink()
			.then(data => {
				if (data.link) {
					return window.open(data.link, '_blank');
				}

				return undefined;
			});
	}, 1000);
	// -------------Window Open Methods-------------


	// -------------Data Manipulation Methods-------------
	static isUserHaveSubscription(user) {
		const plan = UserSubscriptionService.getUserSubscriptionPlan(user);
		const period = UserSubscriptionService.getUserSubscriptionPeriod(user);
		const isPlanExpired = user.subscription?.expirationDate ? moment.utc() > moment.utc(user.subscription?.expirationDate) : true;

		if (period === 'six_month') {
			return true;
		}

		if (period === 'trial' && !isPlanExpired) {
			return true;
		}

		return !!subscriptionType[`${period}_${plan}`];
	}

	static isUserSubscriptionCancelled(user) {
		return !user.subscription.paymentProviderData || !user.subscription.paymentProviderData.subscriptionId;
	}

	static getUserNextUpgradeSubscriptionInfo(user = {}) {
		const plan = UserSubscriptionService.getUserSubscriptionPlan(user);
		const nextPlan = SubscriptionUpgrade[plan];

		if (nextPlan) {
			return {
				full: `annual_${nextPlan}`,
				plan: nextPlan,
				period: 'annual',
				planName: UserSubscriptionService.getPlanName(nextPlan),
			};
		}

		return null;
	}

	static getUserSubscriptionPlan(user) {
		const plan = user.subscription?.plan;
		if (plan) {
			if (plan === 'premium' ||
				plan === 'monthly' ||
				plan === 'annual' ||
				plan === 'extension' ||
				plan === '6months'
			) {
				return 'pro';
			}

			return plan.split('_')[1];
		}

		return 'free';
	}

	static getPlanPeriod(planName, raw) {
		if (raw) {
			return planName.split('_')[0];
		}
		const isAnnual = 'Annual';
		if (planName.match(isAnnual)) {
			return planName.replace(isAnnual, 'Yearly');
		}
		return planName;
	}

	static getPlanName(planName, raw) {
		const name = raw ? planName.split('_')[1] : planName;

		return planNames[name] || name;
	}

	static getNextPlans(planName, count, period) {
		const newPlans = [];
		let currentPlanIndex;

		Object.keys(PlanToPricingSaving).forEach((plan, index) => {
			const planDetails = PlanToPricingSaving[plan];
			if (plan === planName) {
				currentPlanIndex = index;
			}
			if (!planDetails.isLegacy) {
				if (currentPlanIndex !== undefined && currentPlanIndex < index && newPlans.length < count) {
					if (period) {
						if (plan.includes(period)) {
							newPlans.push(plan);
						}
					} else {
						newPlans.push(plan);
					}
				}
			}
		});

		return newPlans;
	}

	static getPlanPeriodName(planPeriod, raw) {
		const name = raw ? planPeriod.split('_')[0] : planPeriod;

		return planPeriodNames[name] || name;
	}

	static isPlanPeriodMonthly(planPeriod, raw) {
		const name = raw ? planPeriod.split('_')[0] : planPeriod;

		return name === 'monthly';
	}

	static getUserSubscriptionPeriod(user) {
		const period = user.subscription.paymentPeriod;

		if (period) {
			if (period === 'yearly') {
				return 'annual';
			}

			return period;
		}

		return 'annual';
	}

	static comparePlans(planA, planB) {
		if (SubscriptionWeights[planA] === SubscriptionWeights[planB]) {
			return 0;
		} else if (SubscriptionWeights[planA] > SubscriptionWeights[planB]) {
			return -1;
		}
		return 1;
	}

	static getPlanItemLimit(user, limits = {}, limitItem) {
		const userData = JSON.parse(localStorage.getItem('user'));
		if (!userData) return 0;
		if (!limits || !userData.subscription.plan) {
			return 0;
		}

		const planName = this.getUserSubscriptionPlan(userData);
		const planLimit = limits[planName];
		return planLimit[limitItem];
	}

	static getNewPlanItemLimit(planName, limits = {}, limitItem) {
		if (!limits) {
			return 0;
		}

		const planLimit = limits[planName];
		return planLimit[limitItem];
	}

	static getCouponInfo(code, subId) {
		let query = `couponCode=${code}`;
		if (subId) {
			query = query.concat(`&subId=${subId}`);
		}
		return get(`${RECURLY_COUPON_CODE_URL}?${query}`, false)
			.then(res => res.json());
	}
	/**
	 *
	 * @param selectedPlan
	 * @param selectedPeriod
	 * @returns {{saving: Number, price: Number}}
	 */
	static getPriceWithSaving(selectedPlan, selectedPeriod) {
		let plan = selectedPlan.includes('_') ? selectedPlan : `${selectedPeriod}_${selectedPlan}`;
		if (selectedPlan) {
			if (selectedPlan === 'premium' ||
				selectedPlan === 'monthly' ||
				selectedPlan === 'annual' ||
				selectedPlan === 'extension' ||
				selectedPlan === '6months'
			) {
				plan = selectedPlan === 'annual' ? 'annual_pro' : 'monthly_pro';
			}
		}
		return PlanToPricingSaving[plan];
	}
	// -------------Data Manipulation Methods-------------
}
