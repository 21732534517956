/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, December 2020
 */

const detectCurrentEnvironment = () => {
	const { location: { hostname } } = window;
	switch (hostname) {
	case 'localhost':
		return 'default';
	case 'woofy-demo.fluvius.co':
		return 'development';
	case 'app.hellowoofy.com':
		return 'production';
	default: return 'default';
	}
};

export default detectCurrentEnvironment;
