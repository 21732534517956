/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { get, post, put, httpDelete } from '../../../common/httpRequests';
import { HASHTAG_GROUPS_URL, HASHTAGS_URL } from '../../../common/apiRouting';

export function getHashtagGroups() {
	return get(`${HASHTAG_GROUPS_URL}`, true)
		.then(res => res.json());
}

export function createHashtagGroup(group) {
	return post(`${HASHTAG_GROUPS_URL}`, group, true)
		.then(res => res.json());
}

export function updateHashtagGroup(group) {
	return put(`${HASHTAG_GROUPS_URL}/${group._id}`, group, true)
		.then(res => res.json());
}

export function deleteHashtagGroup(id) {
	return httpDelete(`${HASHTAG_GROUPS_URL}/${id}`, true);
}

export function getHashtags() {
	return get(`${HASHTAGS_URL}`, true)
		.then(res => res.json());
}

export function createHashtag(hashtag) {
	return post(`${HASHTAGS_URL}`, hashtag, true)
		.then(res => res.json());
}

export function deleteHashtag(id) {
	return httpDelete(`${HASHTAGS_URL}/${id}`, true);
}
