/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component } from 'react';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './CalendarMonth.css';
import angle from '../../../images/angle.png';
import { EditIcon } from '../../../icons/svgIcons';
import removeIcon from '../../../images/remove-icon.svg';
import { getConfig } from '../../Settings/Settings.action';
import { getPostsCountPerDay } from '../CalendarCampaignModal/CalendarCampaignModal.action';
import { getLocalDate, getUTCDateWithSettingTimezone } from '../../../common/dateHelper';
import { clickable } from '../../../helpers/makeElementClicable';
import detectWeekStartAndEndDates from '../../../helpers/detectWeekStartAndEndDates';
import { goToJournalPostDetails } from '../../../helpers/goToJournalPostDetails';

class CalendarMonth extends Component {
	constructor(props) {
		super(props);

		const { timezone } = props;
		this.eventsPerDay = {};
		this.modifiers = {
			today: getLocalDate(timezone).toDate(),
		};
		this.state = {
			selectedDates: [],
			campaignEndDate: '',
			showEventsDay: '',
			config: {
				campaignMaxDurationDays: 182,
			},
			minEventDelay: 500,
		};

		this.lastCall = 0;
		this.lastDate = '';

		this.renderDay = this.renderDay.bind(this);
		this.getActiveMode = this.getActiveMode.bind(this);
	}

	componentDidMount() {
		const {
			selectMode, campaign: { arrayOfDatesForRenderInCalendar, endDate = '' } = {}, selectedDates, isDayPickerShowing,
		} = this.props;

		// Shut-out to GRIG
		const datesToRender = arrayOfDatesForRenderInCalendar || selectedDates || [];

		getConfig().then((config) => {
			if (this.isCancelled) {
				return;
			}
			this.setState({ config });
		});

		this.renderCampaignDates(selectMode, datesToRender, endDate);

		if (isDayPickerShowing) {
			getPostsCountPerDay({
				dateStart: moment().startOf('month'),
				dateEnd: moment().add(this.state.config.campaignMaxDurationDays, 'days').endOf('month'),
			}).then((res) => {
				this.calcEventsPerDay(res);
			});
		}
	}

	componentWillReceiveProps(props) {
		const { selectMode, campaign: { arrayOfDatesForRenderInCalendar, endDate = '' } = {}, selectedDates } = props;
		this.renderCampaignDates(selectMode, arrayOfDatesForRenderInCalendar || selectedDates || [], endDate);
	}

	renderCampaignDates = (calendarSelectedMode, arrayOfDatesForRenderInCalendar = [], endDate) => {
		const { handleChangeMonth } = this.props;
		const { campaignEndDate: stateCampaignEndDate } = this.state;
		const isSelectCampaignEndDateMode = calendarSelectedMode === 'singleEndDate';
		const selectedDates = arrayOfDatesForRenderInCalendar.length ? arrayOfDatesForRenderInCalendar : [];
		const campaignEndDate = stateCampaignEndDate || endDate;
		this.setState({
			campaignEndDate,
			selectedDates,
		});
		if (isSelectCampaignEndDateMode && !stateCampaignEndDate && endDate) {
			handleChangeMonth(moment(endDate));
		}
	};

	onDayClick(day) {
		const now = Date.now();
		let momentDay = moment(day);
		if (this.checkClickDenied(day, now)) {
			return;
		} else if (momentDay.isBefore(moment(), 'day')) {
			return;
		}
		this.lastCall = now;
		this.lastDate = day.toString();

		const {
			patternSchedule,
			campaign,
			campaign: {
				endDate = '',
				arrayOfDatesForRenderInCalendar = [],
			} = {},
			selectMode,
			handleChangeMonth,
			onDayClick,
			minDate,
			timezone,
		} = this.props;

		const { selectedDates } = this.state;

		if (patternSchedule) {
			const dayOfWeek = selectMode === 'singleEndDate' ? moment() : selectedDates[0];
			const [startWeekDate, endWeekDate] = detectWeekStartAndEndDates(dayOfWeek, timezone);

			if (selectMode !== 'singleEndDate' && arrayOfDatesForRenderInCalendar.length) {
				if (momentDay.isBefore(startWeekDate, 'day') || momentDay.isAfter(endWeekDate, 'day')) {
					return;
				}
			}

			if (selectMode === 'singleEndDate') {
				const maxDate = moment(campaign.startDate).add(this.state.config.campaignMaxDurationDays, 'days'); // TODO
				if (!momentDay.isBetween(minDate, maxDate)) {
					momentDay = maxDate;
				}

				const arrayOfDates = arrayOfDatesForRenderInCalendar.length ? arrayOfDatesForRenderInCalendar : selectedDates;
				const selectedDaysOnCurrentWeek = arrayOfDates.filter(date =>
					(moment(date).isAfter(moment(startWeekDate), 'day') && moment(date).isBefore(endWeekDate, 'day')));
				const lastSelectedDateOnCurrentWeek = selectedDaysOnCurrentWeek.sort((date1, date2) => date1 - date2).pop();
				const lastDateOnCurrentWeek = moment(lastSelectedDateOnCurrentWeek).add(1, 'day');
				if (momentDay.isBefore(moment(lastDateOnCurrentWeek), 'day')) {
					return;
				}
				campaign.endDate = moment(momentDay).startOf('day').format('YYYY-MM-DD');
				return this.setState({ campaignEndDate: momentDay });
			}

			if (endDate) {
				if (momentDay.isAfter(endWeekDate, 'day')) {
					campaign.endDate = day;
				} else {
					this.updateSelectedDates(day);
				}
			}
		}

		if (onDayClick) {
			onDayClick(day);
		}

		if (minDate) {
			const isEndLengthCampaign = this.isEndLengthCampaign(day);
			if (day.getTime() >= minDate.getTime() - 86400000 && !isEndLengthCampaign) {
				this.updateSelectedDates(day);
			}
		}

		if (this.isNextMonth(day)) {
			handleChangeMonth(moment(day));
		}
	}

	getActiveMode(value) {
		if (this.props.setActiveMode !== undefined)	{
			this.props.setActiveMode('day', value);
		}
	}

	getClassName = post => {
		if (post.journalPost) {
			return 'day-event-journal-post-container';
		} else if (post.campaignId) {
			return 'day-event-campaign-container';
		}
		return 'day-event-calendar-container';
	};

	getEventsForDay(day) {
		const { timezone, isDayPickerShowing } = this.props;
		if (isDayPickerShowing) return;

		const events = this.props.posts.filter(post => {
			const date = getLocalDate(timezone, moment(post.postDate)).toDate();
			date.setHours(0, 0, 0, 0);
			return date.toISOString() === day.toISOString();
		});

		function checkAmountOfEvents(value) {
			if (value < 5) {
				return 'day-preview low';
			} else if (value >= 5 && value < 10) {
				return 'day-preview medium';
			}
			return 'day-preview high';
		}

		return (
			<div className="day-events" onClick={(e) => e.stopPropagation()}>
				<Scrollbars style={{ width: '100%', height: '100%' }}>
					<div className={checkAmountOfEvents(events.length)}>{day.getDate()}</div>
					{
						events.map(event => {
							const isPriorPostedPost = moment(event.postDate).format() < moment().format();
							return (
								<div
									key={event._id}
									className={this.getClassName(event)}
									{...clickable(() => this.calendarEventClick(event, isPriorPostedPost))}
								>
									<div className="day-event">
										{event.campaignId ?
											this.props.campaigns.map(el => (el._id === event.campaignId ? el.name : ''))
											: event.message}
									</div>
									{this.renderControlPanel(event, isPriorPostedPost)}
								</div>
							);
						})
					}
				</Scrollbars>
			</div>
		);
	}

	calendarEventClick = (post, isPriorPostedPost) => {
		const { history } = this.props;
		if (post.journalPost) {
			return goToJournalPostDetails(history, post._id);
		}
		if (isPriorPostedPost) {
			const { changeCalendarPriorPostedPostModalVisibility, setCalendarPriorPostedPostData } = this.props;
			changeCalendarPriorPostedPostModalVisibility(true);
			return setCalendarPriorPostedPostData(post._id);
		}
		return null;
	};

	isNextMonth = (date) => moment(this.props.currentMonth).add(1, 'month').isSame(date, 'month');

	checkClickDenied = (day, now) => {
		const { minEventDelay } = this.state;
		return (now - this.lastCall < minEventDelay && this.lastDate === day.toString());
	};

	updateSelectedDates(selectedDay) {
		const {
			selectMode, onSelect, campaign, timezone,
		} = this.props;
		if (!campaign) return;
		const { selectedDates: stateSelectedDates = [] } = this.state;

		const postTime = campaign ? campaign.postTime : '12:00';
		const selectedDayUTC = getUTCDateWithSettingTimezone(moment(selectedDay).format('YYYY-MM-DD'), postTime, timezone);

		const dayKey = selectedDayUTC;


		let selectedDates = stateSelectedDates;
		let index;

		const alreadyExistingDate = selectedDates.find(selectedDate => moment(selectedDate).isSame(selectedDayUTC, 'day'));

		switch (selectMode) {
		case 'singleEndDate':
			this.setState({
				campaignEndDate: dayKey,
			});
			this.props.updateSelectedDateEndCampaign(dayKey);
			break;

		case 'single':
			selectedDates[0] = dayKey;
			if (selectedDates.length && selectedDates[0] !== dayKey) {
				selectedDates[0] = '';
			} else {
				selectedDates = [dayKey];
			}
			break;

		case 'multi':
			if (alreadyExistingDate) {
				selectedDates = selectedDates.filter(selectedDate => !moment(selectedDate).isSame(alreadyExistingDate, 'day'));
			} else {
				selectedDates = [...selectedDates, dayKey];
			}
			break;

		case 'range':
			index = selectedDates.indexOf(dayKey);
			if (index > -1) {
				selectedDates.splice(index, 1);
			} else {
				selectedDates = [...selectedDates, dayKey];
			}
			break;

		default:
			break;
		}

		selectedDates = selectedDates.sort((date1, date2) => date1 - date2);

		this.setState({ selectedDates }, () => {
			if (onSelect) {
				onSelect(selectedDates);
			}
		});
	}

	calcEventsPerDay(posts) {
		const { timezone } = this.props;
		if (!this.props.posts && !posts) {
			return;
		}

		const eventsPerDay = {};

		const data = this.props.posts || posts;
		data.forEach(post => {
			const date = getLocalDate(timezone, moment(post.postDate)).toDate();
			date.setHours(0, 0, 0, 0);

			const key = date.toISOString();

			if (!eventsPerDay[key]) {
				eventsPerDay[key] = 0;
			}

			eventsPerDay[key]++;
		});

		this.eventsPerDay = eventsPerDay;
	}

	handleMouseEnter(day) {
		this.setState({ showEventsDay: day });
	}

	handleMouseLeave() {
		this.setState({ showEventsDay: '' });
	}

	isEndLengthCampaign(day) {
		const { selectedDates = [] } = this.state;
		const { campaignMaxDurationDays } = this.state.config;
		const maxDate = moment(selectedDates[0]).add(campaignMaxDurationDays, 'days');

		return selectedDates.length && moment(day).format() >= moment(maxDate).format();
	}

	renderControlPanel = (postData, isPriorPostedPost) => {
		const { journalPost } = postData;
		if (isPriorPostedPost || journalPost) {
			return null;
		}

		return (
			<div className="day-event-actions">
				<EditIcon
					width="10px"
					height="10px"
					className="edit-post"
					onClick={() => {
						if (postData.campaignId) {
							const campaign = this.props.campaigns.filter(el =>
								el._id === postData.campaignId);
							this.props.onEditCampaign(campaign[0]);
						} else {
							const post = postData;
							post.post = postData;
							post.id = postData._id;
							this.props.onEditPost({ event: post });
						}
					}}
				/>
				<img
					onClick={() => {
						if (postData.campaignId) {
							const campaign = this.props.campaigns.filter(el =>
								el._id === postData.campaignId);
							this.props.onRemovePost(true, campaign, 'campaign');
						} else {
							const post = postData;
							post.post = postData;
							post.id = postData._id;
							this.props.onRemovePost(true, { event: post }, 'post');
						}
					}}
					src={removeIcon}
					alt={removeIcon}
					className="remove-button"
				/>
			</div>);
	};

	renderDay(day) {
		let className = '';
		const { campaignEndDate, selectedDates = [] } = this.state;
		const {
			patternSchedule, campaign: { endDate = '', postTime } = {}, minDate, selectMode,
			timezone,
		} = this.props;

		const isEndLengthCampaign = this.isEndLengthCampaign(day);

		day.setHours(0, 0, 0, 0);
		const date = day.toISOString();

		const eventsPerDay = this.eventsPerDay[date];

		function checkAmountOfEvents(value) {
			if (value < 5) {
				return 'color-ball day-load-low';
			} else if (value >= 5 && value < 10) {
				return 'color-ball day-load-medium';
			}
			return 'color-ball day-load-high';
		}

		if (selectMode === 'singleEndDate') {
			if (typeof campaignEndDate === 'object') {
				if (moment(campaignEndDate).isSame(date, 'day')) {
					className += ' selected selectEndDateInPattern';
				}
			} else {
				const selectD = getUTCDateWithSettingTimezone(
					/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(campaignEndDate) ? campaignEndDate :
						moment(campaignEndDate).tz(timezone).format('YYYY-MM-DD'),
					postTime,
					timezone,
					'YYYY-MM-DD',
				);
				const currD = moment(day).format('YYYY-MM-DD');
				if (selectD === currD) {
					className += ' selected selectEndDateInPattern';
				}
			}
		}

		if (minDate && (day <= minDate || isEndLengthCampaign)) {
			className += ' day-outside day-out-pattern-schedule';
		}

		if (selectMode === 'singleEndDate') {
			let endDateOfCampaign;
			if (selectMode === 'singleEndDate') {
				endDateOfCampaign = endDate || moment().tz(timezone);
			} else {
				endDateOfCampaign = moment(this.props.selectedDates[this.props.selectedDates.length - 1]).tz(timezone);
			}
			if (day <= endDateOfCampaign) {
				className += ' day-outside day-out-pattern-schedule';
			}
		}

		if (selectedDates.length) {
			if (selectMode === 'range') {
				if (selectedDates.length === 2) {
					const firstDay = new Date(selectedDates[0]);
					const lastDay = new Date(selectedDates[1]);

					if (moment(day).isSame(moment(firstDay), 'day')) {
						className += ' selected-first';
					}

					if (moment(day).isSame(moment(lastDay), 'day')) {
						className += ' selected-last';
					}

					if (moment(day).isAfter(moment(firstDay), 'day') && moment(day).isBefore(moment(lastDay), 'day')) {
						className += ' selected-date-range';
					}
				} else if (selectedDates.length > 2) {
					selectedDates.forEach(selectedDay => {
						if (moment(selectedDay).isSame(day, 'day')) {
							className += ' selected';
						}
					});
				}
			} else {
				selectedDates.map(selectedDay => {
					const selectD = moment(selectedDay).tz(timezone).format('YYYY-MM-DD');
					const currD = moment(day).format('YYYY-MM-DD');
					if (selectD === currD) {
						return className += ' selected';
					}
					return ' day-outside day-out-pattern-schedule';
				});
			}
		}

		if (patternSchedule) {
			if (endDate && selectMode === 'singleEndDate') {
				className += '';
			} else if (endDate || !endDate && selectMode === 'multi') {
				if (selectMode !== 'singleEndDate') {
					if (selectedDates.length) {
						const [startWeekDate, endWeekDate] = detectWeekStartAndEndDates(selectedDates[0], timezone);
						const isActiveDay = moment(date).isSame(moment(startWeekDate), 'day') ||
							moment(date).isSame(moment(endWeekDate), 'day') ||
							(moment(date).isAfter(moment(startWeekDate), 'day') && moment(date).isBefore(moment(endWeekDate), 'day'));

						const selectedDay = selectedDates.find((d) => {
							let selectD;
							try {
								selectD = d.replace('Z', '');
							} catch (err) {
								selectD = moment(d).format('YYYY-MM-DDT00:00:00.000');
							}
							const currD = moment(day).format('YYYY-MM-DDT00:00:00.000');
							return selectD === currD;
						});
						if (selectedDay) {
							className += ' selected';
						} else if (!isActiveDay) {
							className += ' day-outside day-out-pattern-schedule';
						} else {
							className += '';
						}
					}
				} else {
					const patternEndDate = selectedDates[selectedDates.length - 1];
					if (moment(day).isBefore(patternEndDate, 'day')) {
						className += ' day-outside day-out-pattern-schedule';
					}
				}
			} else {
				className += ' day-outside day-out-pattern-schedule';
			}
		}

		return (
			<div className={`calendar-month-day${className}`}>
				<div className="space space-left">&nbsp;</div>
				<div className="space space-middle">
					<div
						className="day"
						onClick={() => this.getActiveMode(day)}
						onMouseEnter={() => this.handleMouseEnter(moment(day).format())}
						onMouseLeave={() => this.handleMouseLeave()}
					>
						{day.getDate()}

						{ eventsPerDay ? <span className={checkAmountOfEvents(eventsPerDay)}>{eventsPerDay}</span> : ''}
						{ eventsPerDay && this.state.showEventsDay === moment(day).format() ? this.getEventsForDay(day) : ''}
					</div>
				</div>
				<div className="space space-right">&nbsp;</div>
			</div>
		);
	}

	render() {
		const { currentMonth } = this.props;
		this.calcEventsPerDay();

		function renderWeekday(weekdayEl) {
			const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

			return <div className="DayPicker-Weekday" role="columnheader">{weekdays[weekdayEl.weekday]}</div>;
		}

		const checkLastDay = () => {
			const a = moment(currentMonth).endOf('month').format('dddd');
			if (a === 'Saturday' ||
				!this.props.campaigns ||
				!this.props.posts
			) {
				return 'calendar-angle hidden';
			}
			return 'calendar-angle';
		};

		return (
			<div className="calendar-month">
				<DayPicker
					onDayClick={(day) => this.onDayClick(day)}
					// showOutsideDays
					onDayTouchStart={(day) => this.onDayClick(day)}
					renderDay={this.renderDay}
					weekdayElement={renderWeekday}
					month={currentMonth.toDate()}
					fromMonth={currentMonth.toDate()}
					modifiers={this.modifiers}
				/>
				<img src={angle} className={checkLastDay()} alt={angle} />
			</div>
		);
	}
}

export default withRouter(CalendarMonth);
