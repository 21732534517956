/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { getToken, clearAuthToken } from './auth';
import { setCalendar } from './calendarHelper';

const completedFetch = (url, options) => fetch(url, options)
	.then(res => {
		if (res.ok) {
			return res;
		}

		return Promise.reject(res);
	})
	.catch(res => {
		if ((res.status === 401 || res.status === 409) && window.location.pathname !== '/login') {
			setCalendar('');
			clearAuthToken();
			window.location = '/login';
		}

		return (res.name === 'AbortError') ? Promise.reject() : res.json().then(message => Promise.reject(message));
	});

export const get = (url, auth, controller) => {
	const options = {
		method: 'GET',
		headers: {},
	};
	if (auth) options.headers.Authorization = `${getToken()}`;

	if (controller) options.signal = controller.signal;

	return completedFetch(url, options);
};

export const post = (url, body, auth, controller) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	};
	if (auth) options.headers.Authorization = `${getToken()}`;

	if (controller) options.signal = controller.signal;

	return completedFetch(url, options);
};

export const put = (url, body, auth) => {
	const options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	};
	if (auth) options.headers.Authorization = `${getToken()}`;

	return completedFetch(url, options);
};

export const httpDelete = (url, auth) => {
	const options = {
		method: 'DELETE',
		headers: {},
	};
	if (auth) options.headers.Authorization = `${getToken()}`;

	return completedFetch(url, options);
};

export const getProps = (data, props) => {
	const result = {};

	props.forEach(prop => (data[prop] != null ? result[prop] = data[prop] : null));

	return result;
};
