/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';

import arrow from '../../../images/arrow.png';

import './CalendarNavArrows.css';

class CalendarNavArrows extends React.Component {
	onChangeDate(value) {
		const { type, currentMonth } = this.props;
		const date = type === 'list'
			? moment(currentMonth).add(value ? -2 : 2, 'day')
			: moment(currentMonth).add(value ? -1 : 1, type);
		this.props.onChangeDate(date);
	}

	render() {
		return (
			<nav className="nav-calendar">
				<Button
					color="link"
					onClick={() => this.onChangeDate(false)}
					className={`next nav-calendar-arrow ${this.props.type}`}
				><img src={arrow} alt="next" />
				</Button>
				<Button
					color="link"
					onClick={() => this.onChangeDate(true)}
					className={`prev nav-calendar-arrow ${this.props.type}`}
				><img src={arrow} alt="prew" />
				</Button>
			</nav>
		);
	}
}

export default CalendarNavArrows;
