/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { getToken } from '../../auth';
import { DIALOGFLOW_AUDIO_URL } from '../../apiRouting';

export function sendAudio(audio) {
	const formData = new FormData();

	formData.append('file', audio);

	const options = {
		method: 'POST',
		headers: {},
		body: formData,
	};
	options.headers.Authorization = `${getToken()}`;

	return fetch(DIALOGFLOW_AUDIO_URL, options)
		.then(res => res.json());
}
