/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { get, post } from '../../../common/httpRequests';
import { CONTENT_ENGINES_URL } from '../../../common/apiRouting';

export function getTrendingHashtags() {
	return get(`${CONTENT_ENGINES_URL}/trending-hashtags`, true)
		.then(res => res.json());
}

export function getTrendingHashtagsUrl(url) {
	return get(`${CONTENT_ENGINES_URL}/analyze-url?url=${encodeURIComponent(url)}`, true)
		.then(res => res.json());
}

export function getTrendingHashtagsImg(url) {
	return get(`${CONTENT_ENGINES_URL}/analyze-image?url=${url}`, true)
		.then(res => res.json());
}

export function getQuotes(url) {
	console.log('url type : ', typeof url);
	if (url && typeof url === 'string') {
		url = [url];
	}
	return post(`${CONTENT_ENGINES_URL}/article-quotes`, url, true)
		.then(res => res.json());
}
