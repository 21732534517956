/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import throttle from 'lodash.throttle';
import { post, put } from '../../../../common/httpRequests';
import {
	DISCOVER_CREATORS_WATCH_PROGRESS,
} from '../../../../common/apiRouting';

const throttledPostRequest = throttle((briefingId, progress = 0) => post(`${DISCOVER_CREATORS_WATCH_PROGRESS}/${briefingId}`, {
	progress,
}, true)
	.then(res => res.json()), 1000, { leading: false });
const throttledPutRequest = throttle((briefingId) => put(`${DISCOVER_CREATORS_WATCH_PROGRESS}/${briefingId}`, {}, true)
	.then(res => res.json()), 1000, { trailing: false });

export function updateWatchProgress(briefingId, progress = 0) {
	return throttledPostRequest(briefingId, progress);
}

export function finishWatchProgress(briefingId) {
	return throttledPutRequest(briefingId);
}
