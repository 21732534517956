/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import './Loader.css';

class Loader extends React.Component {
	render() {
		return (
			<section>
				<div className="header">
					<div className="chart-container">
						<svg version="1.1" x="0px" y="0px" width="260px" height="260px" viewBox="0 0 260 260" enableBackground="new 0 0 260 260" className="svg-loader">
							<defs>
								<filter id="glow" x="-120%" y="-120%" width="400%" height="400%">
									<feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
									<feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
									<feBlend in="SourceGraphic" in2="blurOut" mode="overlay" />
								</filter>
							</defs>
							<g>
								<circle fill="none" cx="0px" cy="0px" r="45px" className="svg-loader_grow svg-loader_animate-main red hair-line" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" filter="url(#glow)" />
								<circle fill="none" cx="0px" cy="0px" r="45px" className="svg-loader_grow svg-loader_animate-second red hair-line" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" filter="url(#glow)" />
								<circle fill="none" cx="0px" cy="0px" r="45px" className="svg-loader_grow svg-loader_animate-third red hair-line" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" filter="url(#glow)" />
								<circle fill="none" cx="0px" cy="0px" r="45px" className="svg-loader_grow svg-loader_animate-fourth red hair-line" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" filter="url(#glow)" />
							</g>
							<text x="100" y="135" fontSize="1em" fill="rgba(255, 255, 255, .5)" >{this.props.text}</text>
						</svg>
					</div>
				</div>
			</section>
		);
	}
}

export default Loader;
