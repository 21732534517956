/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'emoji-mart/css/emoji-mart.css';

import * as selectors from '../../selectors';
import { getCurrentUser } from '../../actions/user';
import { getExtensionSettings } from '../../actions/extensionSetting';
import { isAuthorized, getToken } from '../../common/auth';
import ExtensionSettings from './ExtensionSettings/ExtensionSettings';
import SignInButtons from './SignInButtons/SignInButtons';
import { isJson } from '../../helpers/isJson';
import FetchStoreLifeCycle from '../../helpers/components/requestLifeCycle/requestLifeCycle';
import Footer from './Footer/Footer';
import VoiceOver from '../../common/components/VoiceOver/VoiceOver';

import { getExtensionSettingsFromApi } from './ChromeExtension.action';

import './ChromeExtension.css';

export const WOOFY_GCE = 'WOOFY_GCE';
export const APP_CMD_CLOSE_MODAL = 'cmdCloseModal';
export const APP_CMD_CHANGE_DOC_HEIGHT = 'cmdChangeDocHeight';
export const APP_CMD_OPEN_MODAL_URL = 'cmdOpenModalUrl';

const APP_ACTION_SET_EMOJI = 'setEmojiFromIFrame';

const MOCKED_EXT_SETTINGS = {
	response: {
		predictiveLinguisticsToggleIsActive: false,
		excludedDomains: [],
		emojiSuggestionIsActive: false,
	},
};

class ChromeExtension extends Component {
	constructor(props) {
		super(props);
		this.state = {
			eventMessage: false,
			hostname: '',
			currentExtensionSettings: [],
		};
	}

	componentDidMount() {
		if (isAuthorized()) {
			const { getCurrentUserAction, getExtensionSettingsAction } = this.props;
			getCurrentUserAction();
			getExtensionSettingsAction();
		}
		window.addEventListener('message', this.handleExtEnter);
	}

	componentDidUpdate() {
		this.sendDocHeightMsg();
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.handleExtEnter);
	}

	sendDocHeightMsg = () => {
		const { eventMessage } = this.state;
		const html = document.documentElement;
		const body = document.body;
		const height = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.offsetHeight,
		);

		if (eventMessage) {
			eventMessage.source.postMessage(JSON.stringify({
				action: APP_CMD_CHANGE_DOC_HEIGHT,
				data: height,
			}), '*');
		}
	};

	selectEmojiHandler = (emoji) => {
    	const { eventMessage } = this.state;


    	if (eventMessage) {
    		eventMessage.source.postMessage(JSON.stringify({
    			action: APP_ACTION_SET_EMOJI,
    			data: emoji,
    		}), '*');
    	}
	};

	sendSettingsForUpdate = (e, extensionSettings) => {
		this.setState({ currentExtensionSettings: extensionSettings });
		this.respondToExt(e, extensionSettings);
	};

	respondToExt = (e, data) => {
		const { action } = JSON.parse(e.data || '{}');

		e.source.postMessage(JSON.stringify({
			action: `${action}Response`,
			data,
		}), '*');

		this.setState({ eventMessage: e });
	};

	handleExtEnter = event => {
		if (!isJson(event.data)) {
			return false;
		}
		const data = JSON.parse(event.data);

		if (data.origin === WOOFY_GCE) {
			if (data.action === 'getSettings' && isAuthorized()) {
				this.setState({ eventMessage: event, hostname: data.hostname });

				return getExtensionSettingsFromApi()
					.then(res => this.sendSettingsForUpdate(event, res));
			}
			if (data.action === 'getHeight') {
				this.setState({ eventMessage: event, hostname: data.hostname });
				this.sendDocHeightMsg();
				return true;
			}
			if (data.action === 'getApiKey') {
				this.respondToExt(event, getToken());
			}
		}
		return false;
	};

	render() {
		const { user, extensionSettings } = this.props;
		const { eventMessage, hostname, currentExtensionSettings } = this.state;
		const isAuth = !!isAuthorized();

		return (
			<div className="extension">
				<div className="extension-header">
					<img src="/extensionLogo.svg" alt="" />
					<div className="title"> for Chrome </div>
				</div>
				{isAuth ?
					<FetchStoreLifeCycle entities={{ user, extensionSettings }}>
						<VoiceOver ailLoaderLength={12} ailLoaderRadius={3} isExtension />
						<ExtensionSettings
							extensionSettings={extensionSettings.response}
							hostname={hostname}
							currentExtensionSettings={currentExtensionSettings}
							sendDocHeightMsg={this.sendDocHeightMsg}
							sendSettingsForUpdate={this.sendSettingsForUpdate}
							eventMessage={eventMessage}
							selectEmoji={this.selectEmojiHandler}
						/>
						<Footer user={user.response} />
					</FetchStoreLifeCycle>
					: (
						<SignInButtons />
					)
				}
			</div>
		);
	}
}

const componentWithRouter = withRouter(ChromeExtension);

const mapStateToProps = state => ({
	user: selectors.getCurrentUser(state),
	extensionSettings: MOCKED_EXT_SETTINGS,
});

const mapDispatchToProps = dispatch => ({
	getCurrentUserAction: bindActionCreators(getCurrentUser, dispatch),
	getExtensionSettingsAction: bindActionCreators(getExtensionSettings, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(componentWithRouter);
