/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import * as requestActionTypes from '../constants/requestActionTypes';
import { get, put } from './_common/request';
import { loadingKey, failKey, successKey, updateKey } from '../helpers/store';
import cloneObject from '../utils/cloneObject';


export const getNotifications = () => (dispatch) => {
	const requestType = requestActionTypes.GET_NOTIFICATIONS;

	dispatch({ type: loadingKey(requestType.key) });
	get(requestType)
		.then((response) => {
			dispatch({ type: successKey(requestType.key), payload: response });
		})
		.catch((err) => {
			const msg = typeof err === 'string' ? { message: err } : err;
			dispatch({ type: failKey(requestType.key), payload: msg });
		});
};

export const updateNotifications = (notification, oldNotifications) => (dispatch) => {
	const requestType = cloneObject(requestActionTypes.PUT_UPDATE_NOTIFICATIONS);
	requestType.url += `/${notification._id}`;
	dispatch({ type: loadingKey(requestType.key) });
	put(requestType, notification)
		.then((response) => {
			dispatch({ type: successKey(requestType.key), payload: response });
			const newNotifications = oldNotifications.map((item) => {
				if (item._id === notification._id) {
					return { ...item, seen: true };
				}
				return item;
			});
			dispatch({
				type: updateKey(requestActionTypes.GET_NOTIFICATIONS.key),
				payload: { items: newNotifications },
			});
		})
		.catch((err) => {
			const msg = typeof err === 'string' ? { message: err } : err;
			dispatch({ type: failKey(requestType.key), payload: msg });
		});
};
