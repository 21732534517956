/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

export const getRandomArray = (array, count) => {
	const requiredCount = Math.min(count, array.length);
	const arr = [];
	while (arr.length < requiredCount) {
		const index = Math.floor(Math.random() * array.length);
		if (arr.indexOf(index) === -1) {
			arr.push(index);
		}
	}
	return arr.map((index) => array[index]);
};
