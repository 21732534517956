/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { useState, useEffect, lazy, Suspense, Fragment } from 'react';
import store from 'store';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import { ThemeProvider } from '@mui/material';

import Loader from '../../common/components/LoaderAnimation/Loader';
import storeProvider from '../../store';
import Layout from '../Layout/Layout';
import VideoGuideIframe from '../VideoGuideIframe/VideoGuideIframe';
import AppScrollToTopButton from '../AppScrollToTopButton/AppScrollToTopButton';
import useWindowScrollPosition from '../../helpers/window-scroll-position';
import reactGoogleAnalytics from '../../helpers/reactGoogleAnalytics';

import ChromeExtension from '../ChromeExtension/ChromeExtension';
import CreateSinglePost from '../ChromeExtension/CreatSinglePost/CreateSinglePost';
import AddContent from '../ChromeExtension/AddContent/AddContent';
import ShopifyInstructionModal from '../../common/components/ShopifyInstructionModal/ShopifyInstructionModal';

import detectCurrentEnvironment from '../../helpers/detectCurrentEnvironment';
import theme from '../../theme';

const HelpButtonComponent = lazy(() => import('../Help/HelpButtonComponent'));

const Login = lazy(() => import(/* webpackChunkName: "auth" */ '../Login/Login'));
const ForgotPassword = lazy(() => import(/* webpackChunkName: "auth" */'../ForgotPassword/ForgotPassword'));
const CreateNewPassword = lazy(() => import(/* webpackChunkName: "auth" */'../CreateNewPassword/CreateNewPassword'));
const Signup = lazy(() => import(/* webpackChunkName: "auth" */'../../pagesNew/SignUp/SignUp'));
// const Signup = lazy(() => import(/* webpackChunkName: "auth" */'../Signup/Signup'));

const UnknownPage = lazy(() => import(/* webpackChunkName: "secondary" */'../UnknownPage'));
const ComponentsPage = lazy(() => import(/* webpackChunkName: "secondary" */'../ComponentsPage/ComponentsPage'));
// const ZendeskButton = lazy(() => import(/* webpackChunkName: "secondary" */'../Zendesk/ZendeskButton'));
const BringYourContent = lazy(() => import(/* webpackChunkName: "auth" */ '../BringYourContent/BringYourContent'));

// const Layout = lazy(() => import(/* webpackChunkName: "layout" */'../Layout/Layout'));

// add google analytics on production
const currentEnvironment = detectCurrentEnvironment();
if (currentEnvironment === 'production') {
	reactGoogleAnalytics.init();
}

const AppPage = (props) => {
	const calendar = store.get('currentCalendar');

	const [isUserCanScrollToTop, changeUserPossibilityScrollToTop] = useState(false);
	const [isShopifyInstructionModalOpen, changedShopifyInstructionModalVisibility] = useState(false);
	const [currentUrlHash, changedCurrentUrlHashValue] = useState('');

	useEffect(() => {
		// Remove once whole platform is mobile responsive
		if (calendar) {
			document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1440');
		} else {
			document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1.0');
		}
		const shopifyInstructionWasShowing = localStorage.getItem('shopifyInstructionWasShowing');

		if (!shopifyInstructionWasShowing) {
			const { location: { hash } } = window;
			if (hash === '#shopify-new-user' || hash === '#shopify-success') {
				changedCurrentUrlHashValue(hash);
				changedShopifyInstructionModalVisibility(true);
			}
		}
	}, []);

	const closeShopifyInstruction = () => {
		changedShopifyInstructionModalVisibility(false);
		localStorage.setItem('shopifyInstructionWasShowing', 'shopifyInstructionWasShowing');
	};

	useWindowScrollPosition((position) => {
		changeUserPossibilityScrollToTop(position.y > 0);
	}, { throttle: 100 }, [isUserCanScrollToTop]);

	const extensionPage = window.location.pathname === '/extension';
	const routesToNotShowHelpBtn = ['/signup', '/login', '/forgot-password'];
	const isNeedShowHelpBtn = !routesToNotShowHelpBtn.includes(window.location.pathname);

	return (
		<Provider store={storeProvider}>
			<CookiesProvider>
				<Suspense fallback={<Loader />}>
					<BrowserRouter>
						<Switch>
							<Route exact path="/login" render={() => <Login />} />
							<Route exact path="/forgot-password" render={() => <ForgotPassword />} />
							<Route exact path="/create-new-password" render={() => <CreateNewPassword />} />
							{/* <Route exact path="/signup" render={() => <Signup />} /> */}
							 <Route
								exact
								path="/signup"
								render={() =>
									(<ThemeProvider theme={theme}>
										<Signup setShowCopyright={props.setShowCopyright} />
									</ThemeProvider>)}
							 />
							<Route exact path="/components" render={() => <ComponentsPage />} />
							<Route exact path="/extension" component={ChromeExtension} />
							<Route exact path="/create-single-post" component={CreateSinglePost} />
							<Route exact path="/add-content" component={AddContent} />
							<Route exact path="/bring-your-content" component={BringYourContent} />
							{calendar && <Route path={`/${calendar}/`} component={Layout} />}
							<Route path="/" render={() => <UnknownPage />} />
						</Switch>
					</BrowserRouter>
					{!extensionPage && isNeedShowHelpBtn && <HelpButtonComponent />}
					{/* {!extensionPage && <ZendeskButton />} */}
					{!extensionPage && isUserCanScrollToTop &&
					<Fragment>
						<Helmet>
							<body className="zendesk-position" />
						</Helmet>
						{isNeedShowHelpBtn && <AppScrollToTopButton />}
					</Fragment>}
					<VideoGuideIframe />
					{isShopifyInstructionModalOpen &&
					<ShopifyInstructionModal
						modalOpen={isShopifyInstructionModalOpen}
						closeShopifyInstruction={closeShopifyInstruction}
						currentUrlHash={currentUrlHash}
					/>}
				</Suspense>
			</CookiesProvider>
		</Provider>
	);
};

export default AppPage;
