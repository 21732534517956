/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, May 2020
 */

import React, { Fragment, useState } from 'react';
import cx from 'classnames';
import { Col } from 'reactstrap';
import ReactPlayer from 'react-player/lazy';
import { Player, BigPlayButton, LoadingSpinner, ControlBar, PlayToggle, Slider, VolumeMenuButton, FullscreenToggle } from 'video-react';

import {
	updateWatchProgress,
	finishWatchProgress,
} from './VideoCell.action';
import defaultAvatar from '../../../../images/default-avatar.png';
import volumeMuteIcon from '../../../../images/icon_volume_off.svg';
import volumeIcon from '../../../../images/icon_volume.svg';
import playIcon from '../../../../images/icon_play.svg';
import pauseIcon from '../../../../images/icon_pause.svg';
import fullscreenIcon from '../../../../images/icon_fullscreen.svg';
import removeIcon from '../../../../images/icon_delete.svg';
import editIcon from '../../../../images/icon_edit.svg';
import './MediaCell.css';
import './video-react.css';

const isSafariBrowser = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function mathDuration(duration) {
	if (duration > 59) {
		const min = Math.round(duration / 60);
		const sec = Math.round(duration - (60 * min));

		return `${min}:${sec >= 10 ? sec : `0${sec}`}`;
	}

	return `0:${duration >= 10 ? duration : `0${duration}`}`;
}

const VideoCell = (props) => {
	const {
		id,
		url,
		name,
		avatar,
		thumbnail,
		myFeed,
		deleteHandler,
		toggleEditModal,
		size: originalSize = 3,
		progress: originalProgress = 0,
		watched: originalWatched = false,
		stopPropagation = false,
		updateProgressViaApi = true,
		hideDeleteBtn = false,
		hideEditBtn = true,
		toggleVideoThumbnailModal,
		videoFileObj,
	} = props;
	let player;
	let fullscreenPlayer;
	const isSafari = isSafariBrowser();

	const [playing, onPlay] = useState(false);
	const [volume] = useState(0.8);
	const [muted, changeMute] = useState(false);
	const [controls] = useState(false);
	const [fullscreenOpen, changeFullscreen] = useState(false);
	const [playedState, changePlayedState] = useState(null);
	const [progress, changeProgress] = useState(originalWatched === true ? 0 : originalProgress);
	const [watched, changeWatched] = useState(originalWatched);
	const [duration, changeDuration] = useState(null);

	function updateProgressHandler(newProgress) {
		if (updateProgressViaApi) {
			updateWatchProgress(id, newProgress);
		}
		changeProgress(newProgress);
	}

	const setPlayFromProgress = () => {
		if (player && player.seekTo) {
			// console.log('seek to:', progress);
			player.seekTo(progress);
		}
	};
	const ref = refPlayer => {
		player = refPlayer;
	};
	const handleProgress = state => {
		const { playedSeconds = 0 } = state;
		const floorProgress = Math.floor(playedSeconds);

		if (floorProgress > 0) {
			changeProgress(floorProgress);
		}

		changePlayedState(state);
	};
	const handleDuration = (newDuration) => {
		changeDuration(mathDuration(Math.round(newDuration)));
	};
	// function escFunction(event){
	//     if(event.keyCode === 27) {
	//         //Do whatever when esc is pressed
	// 		changeControls(false);
	//     }
	// }
	// useEffect(() => {
	//     document.addEventListener("keydown", escFunction, false);
	//
	//     return () => {
	//         document.removeEventListener("keydown", escFunction, false);
	//     }
	// }, []);
	const handleClickFullscreen = () => {
		onPlay(false);
		changeFullscreen(true);
		fullscreenPlayer.toggleFullscreen();
		fullscreenPlayer.seek(progress);
		// screenfull.request(findDOMNode(player));
		// if (screenfull.isEnabled) {
		// 	changeControls(false);
		// } else {
		// 	changeControls(true);
		// }
		//
		// setTimeout(() => {
		// 	screenfull.request(findDOMNode(player));
		// }, 0);
	};

	function updateFullscreenPlayerState(state, prevState) {
		// escape from full screen
		if (prevState.isFullscreen === true && state.isFullscreen === false) {
			const newProgress = Math.floor(state.currentTime);

			changeFullscreen(false);
			fullscreenPlayer.pause();
			fullscreenPlayer.seek(newProgress);

			updateProgressHandler(newProgress);
		}

		if (watched === false && state.ended !== prevState.ended && state.ended === true) {
			if (updateProgressViaApi) {
				finishWatchProgress(id);
			}
			changeWatched(true);
		}
	}

	const onPlayHandler = () => {
		if (fullscreenOpen !== true) {
			onPlay(true);
			setPlayFromProgress();
		}
	};
	const onPauseHandler = () => {
		onPlay(false);
		setPlayFromProgress();

		if (watched === false) {
			updateProgressHandler(progress);
		}
	};

	return (
	// eslint-disable-next-line react/jsx-filename-extension
		<Col
			className="creators-feed-card"
			md={originalSize}
			xs={6}
			onClick={stopPropagation ? (e) => {
				e.stopPropagation();
			} : undefined}
		>
			<div
				className="creators-feed-card-bg"
				onClick={isSafari && ((event) => {
					if (event.target.parentNode.classList.contains('creators-feed-card-top-bar')) {
						event.preventDefault();
						event.stopPropagation();
						return;
					}

					if (playing) {
						onPauseHandler();
					} else {
						onPlayHandler();
					}
				})}
				onMouseEnter={isSafari === false && onPlayHandler}
				onMouseLeave={isSafari === false && onPauseHandler}
			>
				<div
					className="creators-feed-card-top-bar"
				>
					{myFeed === true &&
						<Fragment>
							{!hideDeleteBtn && (<img
								onClick={() => deleteHandler(id)}
								src={removeIcon}
								alt="remove icon"
							/>)}
							{!hideEditBtn && <img
								className="creators-edit-btn-icon"
								onClick={() => toggleVideoThumbnailModal(true, url, videoFileObj)}
								src={editIcon}
								alt="edit icon"
							/>}
							{toggleEditModal && <img
								className="creators-edit-btn-icon"
								onClick={() => toggleEditModal(id)}
								src={editIcon}
								alt="edit icon"
							/>}
						</Fragment>
					}
					<img
						src={fullscreenIcon}
						className="creators-feed-card-fullscreen"
						alt="fullscreenIcon"
						onClick={() => {
							handleClickFullscreen();
						}}
					/>
				</div>
				<Player
					src={url}
					ref={(refPlayer) => {
						if (refPlayer) {
							fullscreenPlayer = refPlayer;

							if (fullscreenPlayer) {
								fullscreenPlayer.subscribeToStateChange(updateFullscreenPlayerState);
							}
						}
					}}
					className={cx('fullscreen-player-hidden', {
						'fullscreen-player': !fullscreenOpen,
					})}
				>
					<BigPlayButton position="center" />
					<LoadingSpinner />

					<ControlBar>
						<PlayToggle />
						<VolumeMenuButton vertical />
						<Slider />
						<FullscreenToggle />
					</ControlBar>
				</Player>
				{thumbnail &&
					<div className="react-player-thumbnail-wrapper">
						<img
							src={thumbnail}
							className={cx('react-player-thumbnail', {
								'display-none': playing,
							})}
							alt="thumbnail from video"
						/>
					</div>
				}
				{isSafari &&
					<div className="react-player-safari-play-wrapper">
						<img
							src={playing ? pauseIcon : playIcon}
							className="react-player-safari-play-btn"
							alt="play btn"
						/>
					</div>
				}
				<ReactPlayer
					className="react-player"
					ref={ref}
					playing={playing}
					muted={muted}
					volume={volume}
					controls={controls}
					url={url}
					width="100%"
					height="100%"
					onSeek={e => {
						console.log('onSeek', e);
					}}
					onEnded={() => {
						if (watched === false) {
							if (updateProgressViaApi) {
								finishWatchProgress(id);
							}
							changeWatched(true);

							updateProgressHandler(0);
						}
					}}
					onProgress={handleProgress}
					onDuration={handleDuration}
					vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
				/>
				<div className="creators-feed-card-tip clearfix">
					{avatar && <img src={avatar || defaultAvatar} alt="" className="round-avatar creators-feed-card-author-avatar" />}
					<div className="creators-feed-card-author-name">{name}</div>
					<div className="video-volume-icon float-right">
						<img onClick={() => changeMute(!muted)} src={muted ? volumeMuteIcon : volumeIcon} alt="Mute" />
					</div>
					<div className="creators-feed-card-duration float-right">
						{playing && playedState ? mathDuration(Math.round(playedState.playedSeconds)) : duration}
					</div>
				</div>
			</div>
		</Col>
	);
};

VideoCell.defaultProps = {};

VideoCell.propTypes = {};

export default VideoCell;
