/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

// import ReactTooltip from 'react-tooltip';

import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	UncontrolledDropdown,
} from 'reactstrap';
import cx from 'classnames';

import { Scrollbars } from 'react-custom-scrollbars';

import { getSocialIcon } from '../../../common/getSocialIcon';

import AlertModal from '../../../common/components/AlertModal/AlertModal';
import RenderUserAccount from '../DropdownSelectAccount/RenderUserAccount/RenderUserAccount';

import sortAccountsByTypes from '../../../helpers/sortAccountsByTypes';

import group from '../../../images/icon-color-group.svg';
import page from '../../../images/icon-color-page.svg';
import channel from '../../../images/channel.svg';
import { SocialIntegrationType } from '../../../constants/mediaTypes';

import './DropdownSelectAccount.css';

const defaultAvatar = 'https://goskrep.ru/upload/info/default-avatar.png';

export default class DropdownSelectAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openTwitterModal: false,
			accounts: [],
			facebookAccounts: [],
			twitterAccounts: [],
			linkedInAccounts: [],
			instagramAccounts: [],
			tikTokAccounts: [],
			snapchatAccounts: [],
			pinterestAccounts: [],
			gmbAccounts: [],
			youtubeAccounts: [],
			alexaAccounts: [],
			wordpressStoriesAccounts: [],
			shopifyStoriesAccounts: [],
			whatsAppAccounts: [],
			fireTVAppAccounts: [],
			selectedOptions: [],
			threadsAppAccounts: [],
		};
	}

	componentDidMount() {
		const { accounts, selectedOptions = [] } = this.props;
		if (accounts && accounts.length) {
			this.setUserEnableAccounts(accounts);
		}
		if (selectedOptions.length) {
			this.setStateSelectedOptions(selectedOptions, accounts);
		}
	}

	componentDidUpdate(prevProp) {
		const { selectedOptions, accounts } = this.props;
		if (accounts.length !== prevProp.accounts.length) {
			this.setUserEnableAccounts(accounts);
		}
		if (
			selectedOptions.length !== prevProp.selectedOptions.length &&
      accounts.length
		) {
			this.setStateSelectedOptions(selectedOptions, accounts);
		}
	}

	setUserEnableAccounts = (listOfAccounts) => {
		const facebookAccounts = listOfAccounts.filter((account) =>
			account.socialMedia === 'facebook' &&
			account.type !== SocialIntegrationType.INSTAGRAM_BUSINESS);
		const twitterAccounts = listOfAccounts.filter((account) => account.socialMedia === 'twitter');
		const linkedInAccounts = listOfAccounts.filter((account) => account.socialMedia === 'linkedin');
		const instagramAccounts = listOfAccounts.filter((account) => account.socialMedia === 'instagram');
		const tikTokAccounts = listOfAccounts.filter((account) => account.socialMedia === 'tikTok');
		const snapchatAccounts = listOfAccounts.filter((account) => account.socialMedia === 'snapchat');
		const gmbAccounts = listOfAccounts.filter((account) => account.socialMedia === 'googleMB');
		let alexaAccounts = listOfAccounts.filter((account) => account.socialMedia === 'alexaDevice');
		const fireTVAppAccounts = listOfAccounts.filter((account) => account.socialMedia === 'fireTVApp');
		const pinterestAccounts = listOfAccounts.filter((account) => account.socialMedia === 'pinterest');
		const wordpressStoriesAccounts = listOfAccounts.filter((account) =>
			account.socialMedia === 'wordpress' && account.type === 'story');
		const shopifyStoriesAccounts = listOfAccounts.filter((account) => account.socialMedia === 'shopify' && account.type === 'story');
		const whatsAppAccounts = listOfAccounts.filter((account) => account.socialMedia === 'whatsApp');
		const youtubeAccounts = listOfAccounts.filter(account => account.socialMedia === 'youtube' && !account.disabled);
		const threadsAppAccounts = listOfAccounts.filter(account => account.socialMedia === 'threads' && !account.disabled);

		wordpressStoriesAccounts.forEach((storyAcc) => {
			const wpMainAcc = listOfAccounts.find((acc) => storyAcc.parentAccountId === acc._id);
			if (!wpMainAcc) return;
			const alreadyAddedParentAcc = wordpressStoriesAccounts.find((acc) => acc._id === wpMainAcc._id);
			if (wpMainAcc && !alreadyAddedParentAcc) {
				wordpressStoriesAccounts.push(wpMainAcc);
			}
		});

		shopifyStoriesAccounts.forEach((storyAcc) => {
			const shopifyMainAcc = listOfAccounts.find((acc) => storyAcc.parentAccountId === acc._id);
			if (!shopifyMainAcc) return;
			const alreadyAddedParentAcc = shopifyStoriesAccounts.find((acc) => acc._id === shopifyMainAcc._id);
			if (shopifyMainAcc && !alreadyAddedParentAcc) {
				shopifyStoriesAccounts.push(shopifyMainAcc);
			}
		});
		alexaAccounts = alexaAccounts.concat(listOfAccounts.filter((account) => account.socialMedia === 'alexaWhiteLabelSkill'));

		this.setState({
			accounts: listOfAccounts,
			facebookAccounts,
			twitterAccounts,
			linkedInAccounts,
			instagramAccounts,
			tikTokAccounts,
			snapchatAccounts,
			gmbAccounts,
			alexaAccounts,
			fireTVAppAccounts,
			pinterestAccounts,
			wordpressStoriesAccounts,
			shopifyStoriesAccounts,
			whatsAppAccounts,
			youtubeAccounts,
			threadsAppAccounts,
		});
	};

	setStateSelectedOptions = (selectedOptions, accounts) => {
  	this.setState({ selectedOptions }, () =>
  		this.setUserEnableAccounts(accounts));
	};

	getTypeIcon = (type) => {
	  if (type === 'page') return page;
	  if (type === 'group') return group;
	  if (type === 'channel') return channel;
	  return null;
	};

	writeCategory = () => {
  	const { selectedOptions } = this.state;
  	if (selectedOptions.length === 0) {
  		return 'Choose your account';
  	}
  	// TODO: just slice(0, 5) it
  	return selectedOptions.map((item, index, arr) => {
  		const account = this.props.accounts.find((element) => element._id === item._id);
  		if (
  			arr.length &&
        account &&
        !(account.hootsuiteId && account.tokenExpired) &&
        index < 5
  		) {
  			return (
  				<React.Fragment
  					key={
  						account.hootsuiteId
  							? `${account.hootsuiteId}-${account._id}`
  							: account._id
  					}
  				>
  					{!!account.hootsuiteId && (
  					// TODO: use css backgrounds for all icons?
  						<i className="hootsuite-icon" />
  					)}
  					<img
  						src={account.avatar || defaultAvatar}
  						alt="avatar"
  						className={cx('avatar-preview', {
  							'hootsuite-account': !!account.hootsuiteId,
  						})}
  					/>
  					{account.socialMedia && (
  						<img
  							src={getSocialIcon(account.socialMedia, account.type)}
  							alt="avatar soc"
  							className="avatar-social-icon-preview"
  						/>
  					)}
  				</React.Fragment>
  			);
  		}
  		return null;
  	});
	};

	toggleSecondTwitterModal = () => {
  	this.setState({ openTwitterModal: !this.state.openTwitterModal });
	};

	profileAccount = (accountData) => (
  	<RenderUserAccount
  		key={
  			accountData.hootsuiteId
  				? `${accountData.hootsuiteId}-${accountData._id}`
  				: accountData._id
  		}
  		accountData={accountData}
  		account={accountData}
  		selectedOptions={this.state.selectedOptions}
  		onSelectOption={this.props.onSelectOption}
  		toggleSecondTwitterModal={this.toggleSecondTwitterModal}
  		accountsMetadata={this.props.accountsMetadata}
  		onAccountMetadataSelect={this.props.onAccountMetadataSelect}
  	/>
	);

	renderAccounts = (accounts) => {
  	const sortedByTypesArray = sortAccountsByTypes(accounts);
  	return sortedByTypesArray.map((account) => this.profileAccount(account));
	};

	renderGMBAccounts = (gmbAccounts) => this.renderAccounts(gmbAccounts);

	render() {
		const {
			accounts,
			facebookAccounts,
			twitterAccounts,
			linkedInAccounts,
			instagramAccounts,
			tikTokAccounts,
			snapchatAccounts,
			alexaAccounts,
			pinterestAccounts,
			wordpressStoriesAccounts,
			shopifyStoriesAccounts,
			whatsAppAccounts,
			selectedOptions,
			gmbAccounts,
			fireTVAppAccounts,
			youtubeAccounts,
			threadsAppAccounts,
		} = this.state;

		return (
			<Fragment>
				<div className="account-picker">
					<label>Select account(s)</label>
					<Dropdown
						isOpen={this.props.dropdownOpen}
						toggle={this.props.toggle}
						id="dropdown-accounts"
					>
						<UncontrolledDropdown>
							<DropdownToggle
								color="drop-down"
								className="dropdown-toggle"
								caret
								style={{
									height: this.props.height,
									width: this.props.width,
									marginTop: this.props.marginTop,
								}}
							>
								<div className="dropdown-toggle-box">
									{this.writeCategory()}
									<span className="after-avatars-count">
										{selectedOptions.length > 5
											? `+ ${selectedOptions.length - 5}`
											: null}
									</span>
								</div>
							</DropdownToggle>
							<DropdownMenu className="drop-down-menu">
								<Scrollbars
									style={
										this.props.scrollSize
											? this.props.scrollSize
											: { width: '100%' }
									}
									autoHeight
									autoHeightMax={363}
								>
									{accounts.length === 0 && (
										<Link
											onClick={this.props.toggle}
											to={{
												pathname: '/settings',
												hash: '#addSocialModal',
											}}
											className="link"
											style={{ padding: '0 20px' }}
											target="_blank"
										>
											Add Account
										</Link>
									)}
									{twitterAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">Twitter</span>
											{this.renderAccounts(twitterAccounts)}
										</Fragment>
									)}
									{facebookAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">Facebook</span>
											{this.renderAccounts(facebookAccounts)}
										</Fragment>
									)}
									{linkedInAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">LinkedIn</span>
											{this.renderAccounts(linkedInAccounts)}
										</Fragment>
									)}
									{pinterestAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">Pinterest</span>
											{this.renderAccounts(pinterestAccounts)}
										</Fragment>
									)}
									{wordpressStoriesAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">Wordpress</span>
											{this.renderAccounts(wordpressStoriesAccounts)}
										</Fragment>
									)}
									{shopifyStoriesAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">Shopify</span>
											{this.renderAccounts(shopifyStoriesAccounts)}
										</Fragment>
									)}
									{instagramAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">Instagram</span>
											{this.renderAccounts(instagramAccounts)}
										</Fragment>
									)}
									{tikTokAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">TikTok</span>
											{this.renderAccounts(tikTokAccounts)}
										</Fragment>
									)}
									{snapchatAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">Snapchat</span>
											{this.renderAccounts(snapchatAccounts)}
										</Fragment>
									)}
									{gmbAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">Google My Business</span>
											{this.renderGMBAccounts(gmbAccounts)}
										</Fragment>
									)}
									{alexaAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">Amazon Alexa</span>
											{this.renderAccounts(alexaAccounts)}
										</Fragment>
									)}
									{youtubeAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">YouTube</span>
											{this.renderAccounts(youtubeAccounts)}
										</Fragment>
									)}
									{fireTVAppAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">Amazon Fire TV</span>
											{this.renderAccounts(fireTVAppAccounts)}
										</Fragment>
									)}
									{whatsAppAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">WhatsApp</span>
											{this.renderAccounts(whatsAppAccounts)}
										</Fragment>
									)}
									{threadsAppAccounts.length > 0 && (
										<Fragment>
											<span className="social-title">Threads</span>
											{this.renderAccounts(threadsAppAccounts)}
										</Fragment>
									)}
								</Scrollbars>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Dropdown>
				</div>

				{this.state.openTwitterModal && (
					<AlertModal
						isOpen={this.state.openTwitterModal}
						toggle={this.toggleSecondTwitterModal}
						message="You can only post to one twitter account at a time"
					/>
				)}
			</Fragment>
		);
	}
}
