/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Fragment } from 'react';
import { Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import ModalLoader from '../../../common/components/ModalLoader/ModalLoader';
import ConfirmModal from '../../../common/components/ConfirmModal/ConfirmModal';
import './ImageEditModal.css';
import { uploadEditedImage } from './ImageEditModal.action';
import ShowingVideoGuideButton from '../../ShowingVideoGuideButton/ShowingVideoGuideButton';
import { woofyText } from '../../../constants/woofyText';
// import headLinerLogo from '../../../images/headliner-logo.webp';
import editGraph from '../../../images/edit-graph.png';

export default class ImageEditModal extends React.Component {
	constructor(props) {
		super(props);
		this.headlinerElementRef = React.createRef(null);

		this.state = {
			imageMaxSize: 5242880,
			isOpen: false,
			// isButtonsLoaded: false,
			loader: false,
			isOpenConfirmModal: false,
			canvaDropdownOpen: false,
		};
	}

	componentDidMount() {
		// Handler for Canva and Crello
		window.addEventListener('message', this.listener);

		// Define handler for Wave.video
		window.onWavePublish = (button, result, error) => {
			if (error) {
				console.log(`Wave video error: ${error}`);
			} else {
				if (result && result.videoUrl) {
					console.log('got video: ', result);
					this.downloadVideo(result.videoUrl, result.name);
				}
				// Sometimes wave.video iframe doesn't close, so manually closing it
				try {
					const iframes = document.getElementsByTagName('iframe');
					for (let i = 0; i < iframes.length; i++) {
						if (iframes[i].src.indexOf('wave.video') >= 0) {
							const waveIframe = iframes[i];
							const wrapperDiv = iframes[i].nextSibling;
							const closeIcon = iframes[i].nextSibling.nextSibling;
							closeIcon.remove();
							wrapperDiv.remove();
							waveIframe.remove();
						}
					}
				} catch (err) {
					console.log('Error trying to remove wave iframe', err);
				}
				this.props.toggle();
			}
		};

		// for headliner
		window.headliner.render(this.headlinerElementRef.current);
	}

	downloadVideo = (videoUrl, name) => {
		const xhr = new XMLHttpRequest();
		console.log(videoUrl);
		xhr.open('GET', videoUrl, true);
		xhr.responseType = 'blob';
		xhr.onload = function () {
			const urlCreator = window.URL || window.webkitURL;
			const imageUrl = urlCreator.createObjectURL(this.response);
			const tag = document.createElement('a');
			tag.href = imageUrl;
			tag.target = '_blank';
			tag.download = name;
			document.body.appendChild(tag);
			tag.click();
			document.body.removeChild(tag);
		};
		xhr.onerror = (err) => {
			console.log('Failed to download video', err);
		};
		console.log('video_successfull');
		xhr.send();
	};

	componentWillUnmount() {
		window.removeEventListener('message', this.listener);
	}

	onToggle = () => {
		this.setState({
			isOpenConfirmModal: false,
		});
		this.props.toggle(this.state.isOpen);
	};

	openModalConfirm = () => {
		this.setState({
			isOpenConfirmModal: true,
		});
	};

	listener = async (e) => {
		const {
			imgUrl, imgSource, isButtonsLoaded, needToSetHeaders = true,
		} = e.data;
		const { imageMaxSize } = this.state;
		if (e.origin === window.location.origin && isButtonsLoaded) {
			return;
		} else if (e.origin !== window.location.origin && imgSource !== 'imgEditApi') {
			return;
		}
		if (imgUrl) {
			this.setState({ loader: true });
			try {
				const imageResponse = await fetch(`https://cors-anywhere.hellowoofy.com/${imgUrl}`);
				const blobObj = await imageResponse.blob();
				if (blobObj.size >= imageMaxSize) {
					this.setState({ loader: false });
					this.props.setErrorMessage(`Images should be less than ${imageMaxSize / 1048576}Mb`);
					return this.props.transferImageToAddContent('');
				}
				return uploadEditedImage(imgUrl, needToSetHeaders)
					.then(url => {
						this.setState({ loader: false });
						return this.props.transferImageToAddContent(url.image);
					})
					.catch(err => console.log(err));
			} catch (err) {
				console.error('error : ', err);
			}
		}
	};

	crelloApi = () => {
		window.CrelloButton.init({
			apiKey: '8U3QXgysgNXpFAwVCeAjgbubQfCHZ0',
			designType: 'socialMediaSM',
			onPublishAction(publishedData) {
				window.parent.postMessage({ imgUrl: publishedData.url, imgSource: 'imgEditApi', needToSetHeaders: false }, window.location.origin);
			},
		});
	};

	showCanvaDropDown = () => {
		document.getElementById('canva-dropdown').classList.toggle('show');
	};

	handleCanvaDropdown = () => {
		this.setState(prevState => ({
			canvaDropdownOpen: !prevState.canvaDropdownOpen,
		}));
	};

	openCanvaModal = (designType) => {
		window.canvaApi.createDesign({
			design: {
				type: designType,
			},
			onDesignPublish: ({ exportUrl, designId }) => {
				window.parent.postMessage({ imgUrl: exportUrl, designId, imgSource: 'canva' }, window.location.origin);
			},
		});
	};

	render() {
		return (
			<Fragment>
				<Modal
					isOpen={this.props.isOpen}
					toggle={this.openModalConfirm}
					centered
					className="edit-modal graphics-editor"
				>
					<ModalHeader toggle={this.openModalConfirm} >
						<div>Please Choose Your Graphic Design Tool</div>
						<div className="showing-video-guide-button"><ShowingVideoGuideButton url="https://player.vimeo.com/video/393782778" /></div>
					</ModalHeader>
					<ModalBody className="edit-modal-body">
						<div className="img-editors">
							<div className="img-editor-button-section" >
								<Container>
									<Row>
										<Col xl={12} lg={12} md={12} sm={12} xs={12}>
											<div className="design-tool-item-title">Available Tools:</div>
										</Col>
									</Row>
									<Row>
										<Col xl={6} lg={6} md={12} sm={12} xs={12} >
											<div className="left-sec" >
												<div className="design-tool-button" >
													<button
														className="design-with-crello integration-btn"
														onClick={this.crelloApi}
													>
													Create in Crello
													</button>
												</div>

												<Dropdown isOpen={this.state.canvaDropdownOpen} toggle={this.handleCanvaDropdown}>
													<DropdownToggle>
														<div className="design-with-canva integration-btn" >
															<span id="canva-button" className="canva-btn canva-btn-theme-default canva-btn-size-s">
																<span className="canva-btn-i" />
                        										Design on Canva
															</span>
														</div>
													</DropdownToggle>
													<DropdownMenu right>
														<DropdownItem>
															<a id="canva-button1" onClick={() => this.openCanvaModal('FacebookPost')}><span className="canva-drp-title">Facebook Post</span> <span className="canva-drp-pixels">(940px x 788px) </span></a>
														</DropdownItem>
														<DropdownItem>
															<a id="canva-button2" onClick={() => this.openCanvaModal('InstagramStory')}><span className="canva-drp-title">Facebook Story</span> <span className="canva-drp-pixels">(1080px × 1920px)</span></a>
														</DropdownItem>
														<DropdownItem>
															<a id="canva-button3" onClick={() => this.openCanvaModal('InstagramPost')}><span className="canva-drp-title">Instagram Post</span> <span className="canva-drp-pixels">(1080px × 1080px)</span></a>
														</DropdownItem>
														<DropdownItem>
															<a id="canva-button4" onClick={() => this.openCanvaModal('InstagramStory')}><span className="canva-drp-title">Instagram Story</span> <span className="canva-drp-pixels">(1080px × 1920px)</span></a>
														</DropdownItem>
														<DropdownItem>
															<a id="canva-button5" onClick={() => this.openCanvaModal('TwitterPost')}><span className="canva-drp-title">Twitter Post</span> <span className="canva-drp-pixels">(1042px × 512px)</span></a>
														</DropdownItem>
														<DropdownItem>
															<a id="canva-button6" onClick={() => this.openCanvaModal('SocialMedia')}><span className="canva-drp-title">Google My Business Image</span> <span className="canva-drp-pixels">(800px x 800px)</span></a>
														</DropdownItem>
														<DropdownItem>
															<a id="canva-button7" onClick={() => this.openCanvaModal('InstagramStory')}><span className="canva-drp-title">Pinterest Pin</span> <span className="canva-drp-pixels">(1080px × 1920px)</span></a>
														</DropdownItem>
														<DropdownItem>
															<a id="canva-button8" onClick={() => this.openCanvaModal('PinterestGraphic')}><span className="canva-drp-title">Pinterest Graphic</span> <span className="canva-drp-pixels">(735px × 1102px)</span></a>
														</DropdownItem>
														<DropdownItem>
															<a id="canva-button9" onClick={() => this.openCanvaModal('InstagramPost')}><span className="canva-drp-title">LinkedIn Post Square</span> <span className="canva-drp-pixels">(1080px × 1080px)</span></a>
														</DropdownItem>
														<DropdownItem>
															<a id="canva-button10" onClick={() => this.openCanvaModal('DesktopWallpaper')} ><span className="canva-drp-title">LinkedIn Post Portrait</span> <span className="canva-drp-pixels">(1920px × 1080px)</span></a>
														</DropdownItem>
														<DropdownItem>
															<a id="canva-button11" onClick={() => this.openCanvaModal('YouTubeThumbnail')}><span className="canva-drp-title">YouTube Thumbnail</span> <span className="canva-drp-pixels">(1280px × 720px)</span></a>
														</DropdownItem>
													</DropdownMenu>
												</Dropdown>
											</div>
										</Col>
										<Col xl={6} lg={6} md={12} sm={12} xs={12} >
											<div className="right-sec" >
												<div className="design-tool-button" >
													<button
														className="design-with-wave integration-btn wave-video-button large default"
														onClick={(function (d, w, e, c, a) {
														// eslint-disable-next-line no-multi-assign
															const cf = (w[c] = w[c] || {});
															cf.apiKey = a;
															const sc = d.createElement(e);
														  const s = d.getElementsByTagName(e)[0];
															sc.async = !0;
															sc.src = cf.embed || 'https://wave.video/editor/embed.js';
															s.parentNode.insertBefore(sc, s);
													  }(
															document,
															window,
															'script',
															'waveconfig',
															'2281285ff49c77050b61ec75',
													  ))}
													  data-publish-callback="onWavePublish"
													>
													Wave.video
													</button>
												</div>
												<div className="design-tool-button" >
													<div className="headliner-btn" ref={this.headlinerElementRef} />
												</div>
											</div>
										</Col>
									</Row>
									<Row>
										<Col xs="12" >
											<div className="graphic-tool-footer" >
												<img className="edit-graph-img" src={editGraph} alt="edit-graph" />
											</div>
										</Col>
									</Row>
								</Container>
							</div>
							<ModalLoader message="The image is uploading. Please wait" isOpen={this.state.loader} />
						</div>
					</ModalBody>
				</Modal>
				<ConfirmModal
					isOpen={this.state.isOpenConfirmModal}
					onConfirm={() => { this.onToggle(); }}
					onClose={() => { this.setState({ isOpenConfirmModal: false }); }}
					message={woofyText.unsavedChanges}
				/>
			</Fragment>

		);
	}
}
