/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

const title = 'LOGGED_TOKEN';
let savedToken;

export const clearAuthToken = () => {
	localStorage.removeItem(title);
	sessionStorage.removeItem(title);
	savedToken = null;
};

export const getToken = () => {
	const localStorageItem = localStorage.getItem(title);
	const sessionStorageItem = sessionStorage.getItem(title);
	const token = localStorageItem || sessionStorageItem;
	if (!savedToken) {
		savedToken = token;
	} else if (savedToken !== token) {
		window.location.href = window.location.origin;
	}
	return token;
};

export const isAuthorized = () => localStorage.getItem(title) || sessionStorage.getItem(title);

export const setAuthToken = (token, remember) => {
	if (remember) {
		localStorage.setItem(title, token);
	} else {
		sessionStorage.setItem(title, token);
	}
};
