/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

export const getCurrentUser = state => state.user.currentUser;
export const checkDiscount = state => state.discount.get;
export const getCalendarStatistic = state => state.calendar.statisticById;
export const getCalendars = state => state.calendar.getAll;
export const getSettings = state => state.settings.getAll;
export const getNotifications = state => state.notification.getAll;
export const getAccounts = state => state.accounts.getAll;
export const getConfig = state => state.config.getAll;
export const getByCampaignId = state => state.post.getByCampaignId;
export const getExtensionSettings = state => state.extensionSettings.getAll;
export const getAllLanguages = state => state.googleService.getAll;
export const getAllUserJournalPosts = state => state.journal.getUserJournalPosts;
export const getAllUserJournalFolders = state => state.journal.getUserJournalFolders;
export const getAllUserJournalAccounts = state => state.journal.getUserJournalAccount;
