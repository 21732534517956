/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, May 2020
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { clickable } from '../../../helpers/makeElementClicable';
import { woofyText } from '../../../constants/woofyText';

import helloWoofyExclusiveProDealWithDog from '../../../images/helloWoofyExclusiveProDealWithDog.png';

import { getCalendar } from '../../calendarHelper';

import './HelloWoofyExclusiveDeal.css';

const HelloWoofyExclusiveDeal = ({ msg, history }) =>
	(
		<div id="hello-woofy-exclusive-pro-deal">
			<img
				src={helloWoofyExclusiveProDealWithDog}
				{...clickable(() => history.push(`/${getCalendar()}/${'upgrade-profile'}`))}
				alt="woofy-icon"
			/>
			<span>{msg || woofyText.helloWoofyExclusiveDealText}</span>
		</div>
	);

HelloWoofyExclusiveDeal.defaultProps = {
	msg: woofyText.helloWoofyExclusiveDealText,
};

HelloWoofyExclusiveDeal.propTypes = {
	msg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	history: PropTypes.shape({}).isRequired,
};

export default withRouter(HelloWoofyExclusiveDeal);
