/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, January 2021
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './CalendarContentPickerHashtagItem.css';

const CalendarContentPickerHashtagItem = ({
	hashtag, selectedTrendingHashtags, newClass, onSelect,
}) => {
	const [isMouseOverHashtag, changeIsMouseOverHashtagValue] = useState(false);

	const setTitle = (title) => (title.includes('#') ? `${title}` : `#${title}`);

	const checkCurrentClass = () => {
		const includesHashtag = selectedTrendingHashtags.includes(hashtag);
		if (newClass) {
			return includesHashtag ? 'new-hashtag current-tag' : 'new-hashtag';
		}
		return includesHashtag ? 'hashtag-item current-tag' : 'hashtag-item';
	};

	return (
		<div
			className="calendar-content-picker-hashtag-item-container"
			onMouseEnter={() => changeIsMouseOverHashtagValue(true)}
			onMouseLeave={() => changeIsMouseOverHashtagValue(false)}
		>
			<div
				key={hashtag}
				onClick={() => onSelect(hashtag)}
				className={checkCurrentClass(hashtag)}
			>
				{setTitle(hashtag)}
			</div>
			{isMouseOverHashtag && <div className="hashtag-item-full-text">{hashtag}</div>}
		</div>);
};

CalendarContentPickerHashtagItem.defaultProps = {
	newClass: false,
};

CalendarContentPickerHashtagItem.propTypes = {
	hashtag: PropTypes.string.isRequired,
	selectedTrendingHashtags: PropTypes.arrayOf(PropTypes.string).isRequired,
	newClass: PropTypes.bool,
	onSelect: PropTypes.func.isRequired,
};

export default CalendarContentPickerHashtagItem;
