/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, February 2021
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import store from 'store';

import './RedirectToPageModal.css';

const RedirectToPageModal = ({
	isModalOpen, changedModalVisibility, redirectLink, history,
}) => {
	const handleApplyButtonClick = () => {
		const calendar = store.get('currentCalendar');
		history.push(`/${calendar}/${redirectLink}`);
		changedModalVisibility(false);
	};
	return (
		<Modal
			isOpen={isModalOpen}
			centered
			id="redirect-to-page-modal"
		>
			<ModalHeader
				className="redirect-to-page-modal-header"
				toggle={() => changedModalVisibility(false)}
			/>
			<ModalBody>
				<div>We noticed that you have not added any Google Web Stories account yet, do you want to add one now?</div>
			</ModalBody>
			<ModalFooter className="redirect-to-page-modal-footer">
				<Button
					color="light"
					onClick={() => changedModalVisibility(false)}
					className="redirect-to-page-modal-footer-button"
				>
					NO
				</Button>
				<Button
					color="primary"
					onClick={handleApplyButtonClick}
					className="redirect-to-page-modal-footer-button"
				>
					YES
				</Button>
			</ModalFooter>
		</Modal>
	);
};

RedirectToPageModal.propTypes = {
	isModalOpen: PropTypes.bool.isRequired,
	changedModalVisibility: PropTypes.func.isRequired,
	redirectLink: PropTypes.string.isRequired,
	history: PropTypes.shape({}).isRequired,
};

export default withRouter(RedirectToPageModal);
