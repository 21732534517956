import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import styles from './UpgradePlanModal.module.css';

const UpgradeNowButton = (props) => {
	const { onClose, inNewTab } = props;

	const history = useHistory();

	const goToUpgradeSubscription = () => {
		if (inNewTab) {
			return window.open('/upgrade-profile', '_blank');
		}
		history.push({
			pathname: 'upgrade-profile',
			state: { isUpgrade: true },
		});

		if (onClose) { onClose(); }
	};

	return (
		<Button
			color="primary"
			className={styles.upgradeButton}
			onClick={goToUpgradeSubscription}
		>
			UPGRADE NOW
		</Button>
	);
};

export default React.memo(UpgradeNowButton);
