export const subsActions = {
	calendars: 'calendars',
	socialMediaAccounts: 'socialMediaAccounts',
	hashTagList: 'hashtagPlaylist',
	importContent: 'importContent',
	importRSS: 'importCSV',
	importCSV: 'importRSS',
	importImages: 'importImages',
	instagramAutoPostCarousel: 'instagramAutoPostCarousel',
	addingVideoToLinked: 'addingVideoToLinked',
	advancedArticleSummarizer: 'advancedArticleSummarizer',
	advancedDiscovery: 'advancedDiscovery',
	journalArticlePosts: 'journalArticlePosts',
	basicDiscovery: 'basicDiscovery',
	autocompleteAPI: 'autocompleteAPI',
	pinterestBoard: 'pinterestBoard',
	googleMB: 'googleMB',
	pinterest: 'pinterest',
	snapchat: 'snapchat',
	wix: 'wix',
	squarespace: 'squarespace',
	whatsapp: 'whatsapp',
	mailchimp: 'mailchimp',
	youtube: 'youtube',
	libraryPosts: 'libraryPosts',
	bannerBearImagesPerMonth: 'bannerBearImagesPerMonth',
	threads: 'threads',
};

export const subsErrorReasons = {
	limit_exceed: 'limit_exceed',
	no_access: 'no_access',
	no_subscription: 'no_subscription',
};
