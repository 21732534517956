/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, July 2020
 */

import React from 'react';

import { clickable } from '../../helpers/makeElementClicable';

import scrollToTopArrow from '../../images/scrollToTopArrow.svg';

import './AppScrollToTopButton.css';

const AppScrollToTopButton = () => {
	const scrollToTop = () => window.scroll(0, 0);

	return (
		<div
			id="app-scroll-to-top-button"
			{...clickable(() => scrollToTop())}
		>
			<img src={scrollToTopArrow} alt="up-button" />
		</div>);
};

export default AppScrollToTopButton;
