/* eslint-disable no-nested-ternary */
/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, June 2020
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import VideoCell from '../../../DiscoverPage/CreatorsTabPage/MediaCell/VideoCell';
import ImageCropModal from '../../../../common/components/ImageCropModal';
import cropImageIcon from '../../../../images/crop_black_24dp.svg';
import postImageDeleteImageIcon from '../../../../images/postImageDeleteImageIcon.svg';
import postImageHashtagIcon from '../../../../images/postImageHashtagIcon.svg';
import postImageEmojiIcon from '../../../../images/postImageEmojiIcon.svg';
import textFromImageIcon from '../../../../images/textFromImageIcon.svg';
import urlToImageIcon from '../../../../images/attachImageMedia.svg';
import viewImagePdf from '../../../../images/viewImagePdf.svg';

import './CalendarImageImageUploaderItem.css';

const CalendarImageImageUploaderItem = ({
	file,
	onHandleRemove,
	addSocialImage,
	isPriorPostedPostView,
	setHashtagsImageUrl,
	getEmojiFromImageURL,
	needToShowAllButtons,
	pdfShowButtons,
	changePostMessageToTextFromImage,
	selectedAccounts,
	socialImages,
	changeOriginalImg,
	onHandleAttachMetadata,
	mediaMetadata = [],
	imgRatioValidate = [],
	showImageMetadata = true,
	toggleVideoThumbnailModal,
}) => {
	const imageUrl = file.thumbnail || file.image || file.file || file;
	const isVideo = (file && file.type && file.type.indexOf('video/') >= 0) || (file && file.url && (file.url.toLowerCase().indexOf('.mp4') >= 0 || file.url.toLowerCase().indexOf('.mov') >= 0));
	const needCropt = imgRatioValidate && imgRatioValidate.some(ratioData => ratioData.url === imageUrl);
	const contStyles = {
		backgroundImage: `url(${imageUrl}), url(../img-not-found.jpg)`,
	};

	if (isPriorPostedPostView && (!mediaMetadata.length || !mediaMetadata.find((data) => data.mediaId === imageUrl))) {
		contStyles.pointerEvents = 'none';
	}

	if (isVideo && isVideo > 0) {
		return (
			<VideoCell
				id={file.id}
				name={file.name}
				url={file.url}
				thumbnail={file.thumbnail}
				duration={file.duration}
				myFeed
				deleteHandler={() => onHandleRemove(file)}
				toggleEditModal={false}
				size={2}
				stopPropagation
				updateProgressViaApi={false}
				hideDeleteBtn={isPriorPostedPostView}
				toggleVideoThumbnailModal={toggleVideoThumbnailModal}
				hideEditBtn={false}
				videoFileObj={file}
			/>
		);
	}

	return (
		<div
			key={file.id || file.url || file || imageUrl}
			className={cx('uploaded-file-preview', {
				'highlighted-warning': needCropt,
			})}
			style={contStyles}
			onClick={e => {
				e.stopPropagation();
			}}
		>
			{!isPriorPostedPostView ? (
				<div className={needToShowAllButtons ? 'images-container' : 'images-container one-item'}>
					{needToShowAllButtons && !isVideo &&
					<Fragment>
						<img
							onClick={() => {
								setHashtagsImageUrl(imageUrl);
							}}
							src={postImageHashtagIcon}
							alt="hashtag-icon"
						/>
						<img
							onClick={() => {
								getEmojiFromImageURL(imageUrl);
							}}
							src={postImageEmojiIcon}
							alt="emoji-icon"
						/>
						<img
							onClick={() => {
								changePostMessageToTextFromImage(imageUrl);
							}}
							src={textFromImageIcon}
							alt="get-text-icon"
						/>

					</Fragment>}

					<img
						onClick={() => onHandleRemove(file)}
						src={postImageDeleteImageIcon}
						alt="remove-file-icon"
						className="remove-file-icon"
					/>

					{showImageMetadata && imageUrl && !isVideo && <img
						onClick={() => onHandleAttachMetadata(imageUrl)}
						src={urlToImageIcon}
						alt="attach-meta-icon"
						className="emoji-icon"
					/>}

					{pdfShowButtons ?
						<a href={file.url} target="_blanck">
							<img
								src={viewImagePdf}
								alt="preview-icon"
							/>
						</a> :
						<ImageCropModal
							iconUrl={cropImageIcon}
							iconClassName="icon-crop-preview-img"
							imageUrl={imageUrl}
							onHandleRemove={() => onHandleRemove(file)}
							selectedAccounts={selectedAccounts}
							addSocialImage={addSocialImage}
							socialImages={socialImages}
							changeOriginalImg={files => changeOriginalImg(files, file)}
						/>
					}
				</div>
			) : (imageUrl &&
				<div className={needToShowAllButtons ? 'images-container' : 'images-container one-item'}>
					<img
						onClick={() => onHandleAttachMetadata(imageUrl)}
						src={urlToImageIcon}
						alt="attach-meta-icon"
						className="emoji-icon"
					/>
				</div>)}
		</div>
	);
};

CalendarImageImageUploaderItem.defaultProps = {
	setHashtagsImageUrl: null,
};

CalendarImageImageUploaderItem.propTypes = {
	file: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
	onHandleRemove: PropTypes.func.isRequired,
	setHashtagsImageUrl: PropTypes.func,
	getEmojiFromImageURL: PropTypes.func.isRequired,
	changePostMessageToTextFromImage: PropTypes.func.isRequired,
	needToShowAllButtons: PropTypes.bool.isRequired,
	onHandleAttachMetadata: PropTypes.func.isRequired,
};

export default CalendarImageImageUploaderItem;
