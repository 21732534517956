/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, December 2020
 */

import React from 'react';

import spinner from '../../../images/spinner-woofly.svg';

import './SwitchSpinner.css';

const SwitchSpinner = () => <img src={spinner} width={30} height={30} className="switch-spinner" alt="spinner" />;

export default SwitchSpinner;
