import React, { Component } from 'react';
import { Modal, ModalBody, Button, ModalFooter } from 'reactstrap';

export default class ComingSoonModal extends Component {
	static wrapWithPrompt = (onAccept) => (...args) => {
		if (ComingSoonModal.__instance) {
			return ComingSoonModal.__instance.__show(() => onAccept(...args));
		}

		throw new Error('There are no any instance of ComingSoonModal. You likely forgot to add it to your layout.');
	};

	static hide() {
		ComingSoonModal.__instance.__hide();
	}

	constructor(props) {
		super(props);
		ComingSoonModal.__instance = this;
	}

	state = {
		open: false,
	};

	onAccept = () => null;

	onToggle = () => this.setState(state => ({
		open: !state.open,
	}));

	__show = (onAccept) => {
		this.onAccept = onAccept;
		this.setState({ open: true });
	};

	__hide = () => {
		this.setState({ open: false });
	};

	render() {
		const { open } = this.state;

		return (
			<Modal isOpen={open} toggle={this.onToggle}>
				<ModalBody>
					This feature is Coming Soon!
				</ModalBody>
				<ModalFooter>
					<Button onClick={this.__hide}>
						Okay
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export const withComingSoonModal = Comp => props => <Comp PromptWrapper={ComingSoonModal} {...props} />;
