/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, November 2020
 */
import { SocialIntegrationType } from '../constants/mediaTypes';

const sortArrayByTypes = account => (account.type === 'page' ? -1 : 1);

const sortHootsuiteAccount = (acc1, acc2) => ((acc1.hootsuiteId || acc2.hootsuiteId) ? -1 : 0);

// TODO: need to refactor after will fixed creating hootsuite's accounts(at this time different account contain different data)
const sortAccountsByTypes = (arrayOfAccounts) => {
	const newMapOfSocialMediaAccounts = new Map();
	const mainAccounts = arrayOfAccounts.filter(account => (!account.type || account.type === SocialIntegrationType.INSTAGRAM_BUSINESS) && !account.hootsuiteId);

	const arrayOfMainAccountsIds = mainAccounts.map(acc => acc._id);
	arrayOfAccounts.forEach(account => {
		const oldValue = newMapOfSocialMediaAccounts.get(account.parentAccountId);
		const newValue = oldValue ? [...oldValue, account] : [account];
		if (account.type && arrayOfMainAccountsIds.includes(account.parentAccountId)) {
			newMapOfSocialMediaAccounts.set(account.parentAccountId, newValue);
		}
		if (account.hootsuiteId) {
			newMapOfSocialMediaAccounts.set(account.parentAccountId, newValue);
		}
	});

	if (!newMapOfSocialMediaAccounts.size) {
		return mainAccounts;
	}

	const arrayFromMap = Array.from(newMapOfSocialMediaAccounts);
	let resultArray = [];
	arrayFromMap.forEach(([parentAccountId, arrayOfChildAccounts]) => {
		const arrayOfSortedChildAccounts = arrayOfChildAccounts.sort(sortArrayByTypes);
		const mainAccount = mainAccounts.find(account => (account._id === parentAccountId));
		if (mainAccount) {
			mainAccount.added = true;
			resultArray = [...resultArray, mainAccount, ...arrayOfSortedChildAccounts];
		} else {
			resultArray = [...resultArray, ...arrayOfSortedChildAccounts];
		}
	});

	resultArray.push(...mainAccounts.filter(mainAccount => !mainAccount.added));

	return resultArray.sort(sortHootsuiteAccount);
};
export default sortAccountsByTypes;
