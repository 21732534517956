/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

import { get } from '../../../httpRequests';
import { GET_IMAGES_BY_URL } from '../../../apiRouting';

export function getImagesByURL(url) {
	return get(`${GET_IMAGES_BY_URL}?url=${url}`, true)
		.then(res => res.json());
}
