/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, November 2020
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DropdownItem, Collapse } from 'reactstrap';
import cx from 'classnames';

import { getSocialIcon } from '../../../../common/getSocialIcon';

import hootsuite from '../../../../images/icon-white-hootsuite.svg';

import { getCalendar } from '../../../../common/calendarHelper';

import checkBoxOn from '../../../../images/check-box-on.svg';
import checkBoxOff from '../../../../images/check-box-off.svg';

import collapseLess from '../../../../images/collapse_less.svg';
import collapseMore from '../../../../images/collapse_more.svg';

import defaultAvatar from '../../../../images/default-avatar.png';

import { SocialIntegrationType } from '../../../../constants/mediaTypes';
import { locationAddressParser } from '../../../../common/parser';
import defaultLinkedinAvatar from '../../../../images/linkedin-default-avatar.png';
import wordpressIcon from '../../../../images/wordpress-logo.svg';
import { hasLocationAddressAccount } from '../../../../helpers/accounts';
import page from '../../../../images/icon-color-page.svg';
import group from '../../../../images/icon-color-group.svg';
import board from '../../../../images/board.svg';
import story from '../../../../images/web-stories-logo.png';
import channel from '../../../../images/channel.svg';

const renderSocialMediaNameInfo = account => {
	if (account.socialMedia === SocialIntegrationType.FACEBOOK && account.type) return '';
	if (account.socialMedia === SocialIntegrationType.WORDPRESS && account.type) return '';
	if (account.socialMedia === SocialIntegrationType.SHOPIFY && account.type) return '';

	const nameInfo = {
		snapchat: '(Feed Only)',
		wordpress: '(For posting to blog, visit journal)',
		shopify: '(For posting to blog, visit journal)',
	};

	const info = nameInfo[account.socialMedia];

	return info;
};

const socialMediaReminders = {
	instagram: ['storyReminder', 'feedReminder', 'reelReminder', 'carouselReminder'],
	'instagram-business': ['autoFeed', 'autoCarousel', 'autoReel', 'autoStory'],
	facebook: ['storyReminder', 'personalTimelineReminder', 'nonAdminGroupsReminder', 'reelReminder'],
	tikTok: ['storyReminder', 'feedReminder'],
};

const smReminderTexts = {
	feedReminder: 'Feed Reminder to Mobile App',
	reelReminder: 'Reel Reminder to Mobile App',
	carouselReminder: 'Carousel Reminder to Mobile App',
	storyReminder: 'Story Reminder to Mobile App',
	personalTimelineReminder: 'Feed (Personal Timeline) Reminder to Mobile App',
	nonAdminGroupsReminder: 'Non-Admin Groups Reminder to Mobile App',
	autoFeed: 'Auto Posting to Feed',
	autoCarousel: 'Auto Posting to Carousel',
	autoReel: 'Auto Posting to Reel (Recommended 9:16)',
	autoStory: 'Auto Posting to Story (Recommended 9:16)',
};

const RenderUserAccount = ({
	accountData,
	account,
	selectedOptions,
	onSelectOption,
	toggleSecondTwitterModal,
   	toggleSecondMailchimpModal = null,
	accountsMetadata,
	onAccountMetadataSelect,
}) => {
	const [selectedTwitterId, changeSelectedTwitterId] = useState('');
	const [selectedMailchimpId, changeSelectedMailchimpId] = useState('');
	const [avatar, setAvatar] = useState(defaultAvatar);
	const [isMouseOverProfile, changeIsMouseOverProfile] = useState(false);
	const isCollapsable = true;
	const accountMeta = accountsMetadata.find(item => item.accountId === account._id);
	if (accountMeta) {
		socialMediaReminders[account.socialMedia].forEach((smTag) => {
			if (accountMeta[smTag]) {
				// isCollapsable = false;
			}
		});
	}

	const detectUserAvatar = (userAccount, isChild) => {
		const { socialMedia, type } = userAccount;
		const accountAvatar = userAccount.avatar;
		let avatarSrc;
		switch (socialMedia) {
		case 'linkedin':
			avatarSrc = accountAvatar || defaultLinkedinAvatar;
			break;
		case 'wordpress':
			if (type === 'story') {
				avatarSrc = accountAvatar;
			} else {
				avatarSrc = !isChild ? wordpressIcon : accountAvatar || defaultAvatar;
			}
			break;
		default: avatarSrc = accountAvatar || defaultAvatar;
		}
		setAvatar(avatarSrc);
	};
	const [collapse, setCollapse] = useState(isCollapsable);

	const onImageLoadError = socialNetwork => {
		let avatarSrc;
		switch (socialNetwork) {
		case 'linkedin':
			avatarSrc = defaultLinkedinAvatar;
			break;
		case 'wordpress':
			avatarSrc = wordpressIcon;
			break;
		default: avatarSrc = defaultAvatar;
		}
		return avatarSrc;
	};

	const detectRawAccountName = (acc) => {
		const {
			name, pageId, url, socialMedia,
		} = acc;
		const defaultAccountName = name || pageId;
		const accountURL = socialMedia === 'wordpress' ? `@${url}` : `@${name}`;

		return url && socialMedia !== 'pinterest' ? accountURL : defaultAccountName;
	};

	useEffect(() => {
		if (selectedOptions && selectedOptions.length) {
			const { _id: twitterId } = selectedOptions.find(option => option.socialMedia === 'twitter') || {};
			const { _id: mailchimpId } = selectedOptions.find(option => option.socialMedia === 'mailchimp') || {};
			changeSelectedTwitterId(twitterId);
			changeSelectedMailchimpId(mailchimpId);
		} else {
			changeSelectedTwitterId('');
			changeSelectedMailchimpId('');
		}
	}, [selectedOptions]);

	useEffect(() => () => {
		changeSelectedTwitterId('');
		changeSelectedMailchimpId('');
	}, []);

	useEffect(() => {
		detectUserAvatar(account, account.type);
	});

	const getActiveCheckbox = (accountId) => (selectedOptions.find(item => item._id === accountId) ? checkBoxOn : checkBoxOff);

	const getActiveSmMetadataCheckbox = (accountId, smTag) => {
		const isSmMetadataSelected = accountsMetadata.find(item => item.accountId === accountId && item[smTag]);
		const isSmSelected = selectedOptions.find(item => item._id === accountId);
		return (isSmSelected && isSmMetadataSelected) ? checkBoxOn : checkBoxOff;
	};
	const getCollapseIcon = () => (collapse ? collapseMore : collapseLess);

	const getCheckClass = (accountId) => (selectedOptions.includes(accountId) ? 'checkBoxOn' : 'checkBoxOff');

	const handleSelect = () => {
		const { socialMedia, _id: accountId } = account;
		if (socialMedia === 'twitter') {
			if (!selectedTwitterId) {
				changeSelectedTwitterId(accountId);
			} else if (accountId !== selectedTwitterId) {
				return toggleSecondTwitterModal();
			} else {
				changeSelectedTwitterId('');
			}
		} else if (socialMedia === 'mailchimp' && toggleSecondMailchimpModal !== null) {
			if (!selectedMailchimpId) {
				changeSelectedMailchimpId(accountId);
			} else if (accountId !== selectedMailchimpId) {
				return toggleSecondMailchimpModal();
			} else {
				changeSelectedMailchimpId('');
			}
		}
		return onSelectOption(account);
	};

	const isFbPersonal = account.socialMedia === 'facebook' && !account.type;
	const isIgPersonal = account.socialMedia === 'instagram' && !account.type;
	const isIgBusiness = account.socialMedia === 'instagram' && account.type;
	const isTiktok = account.socialMedia === 'tikTok';

	const isPinterestParent = accountData.socialMedia === 'pinterest' && !account.type && !account.hootsuiteId;

	const isNoNeedToShowCheckBox = () =>
		(accountData.socialMedia === 'facebook' && !account.type && account.disabled) ||
    (accountData.socialMedia === 'linkedin' && !account.type && (!account.hootsuiteId && account.disabled)) ||
		(accountData.socialMedia === 'googleMB' && !account.type && !account.hootsuiteId || isPinterestParent) ||
		(accountData.socialMedia === 'mailchimp' && !account.type) ||
		(accountData.socialMedia === 'wordpress' && !account.type) ||
		(accountData.socialMedia === 'shopify' && !account.type) ||
		(accountData.socialMedia === 'youtube' && !account.type);

	let fbCollapseIcon = null;
	if (isFbPersonal || isIgPersonal || isTiktok || isIgBusiness) {
		if (account.disabled) {
			fbCollapseIcon = <div className="collapse-icon" style={{ width: 16 }}> </div>;
		} else {
			fbCollapseIcon = (<img
				src={getCollapseIcon()}
				className="collapse-icon"
				alt="Collapse Icon"
			/>);
		}
	}

	let locationAddress = '';
	if (hasLocationAddressAccount(account)) {
		const address = account.address;
		locationAddress = locationAddressParser(address);
	}

	const getTypeIcon = type => {
		if (type === 'page') return page;
		if (type === 'group') return group;
		if (type === 'board') return board;
		if (type === 'story') return story;
		if (type === 'list') return group;
		if (type === 'channel') return channel;
		return null;
	};

	return (
		<div
			key={account._id}
			className={cx({
				child: !!account.type && account.type !== SocialIntegrationType.INSTAGRAM_BUSINESS,
				'is-hootsuite': account.hootsuiteId,
			})}
			onMouseEnter={() => changeIsMouseOverProfile(true)}
			onMouseLeave={() => changeIsMouseOverProfile(false)}
		>
			<DropdownItem
				className={cx('rss-category-checkbox', {
					facebookAccount: isNoNeedToShowCheckBox(),
				})}
				toggle={false}
				onClick={() => {}}
				style={{
					height: isFbPersonal || isIgPersonal || isTiktok || isIgBusiness ? '100%' : 'auto',
				}}
			>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
				}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
						onClick={() => {
							if (isCollapsable && (isIgPersonal || isFbPersonal || isTiktok || isIgBusiness)) return setCollapse(!collapse);
							if ((!isIgPersonal && !isFbPersonal && !isTiktok || !isIgBusiness) && (!account.tokenExpired || account.parentAccountId)) {
								handleSelect();
							}
						}}
					>
						<div style={{ display: 'flex' }}>
							{!isNoNeedToShowCheckBox() && !isFbPersonal && !isIgPersonal && !isTiktok && !isIgBusiness && (
								<img
									className={getCheckClass(account._id)}
									src={getActiveCheckbox(account._id)}
									alt="Select Category"
								/>
							)}

							{fbCollapseIcon}
							<img
								src={avatar}
								alt="avatar"
								onError={(event) => {
									event.currentTarget.onerror = null;
									event.currentTarget.src = onImageLoadError(account.socialMedia);
								}}
								className={cx('avatar', { 'hootsuite-account': !!account.hootsuiteId })}
							/>

							{account.socialMedia && (<img
 									src={(account.type && account.type !== SocialIntegrationType.INSTAGRAM_BUSINESS)
 										? getTypeIcon(account.type)
 										: getSocialIcon(account.socialMedia)
 									}
 									alt="avatar soc"
 									className="avatar-social-icon"
 									style={{
 										width: account.type === 'story' || !account.type ? 25 : 43,
 										height: account.type === 'story' || !account.type ? 25 : 43,
 										left: account.type === 'story' || !account.type ? 73 : 73,
 									}}
							/>)}
							{!!account.hootsuiteId && (
								<div className="avatar-hootsuite-icon">
									<img src={hootsuite} alt="Hootsuite" />
								</div>
							)}

							{(account.socialMedia === 'facebook' || account.type || account.socialMedia === 'instagram') ? (
								<span className="type" style={{ margin: 'auto 0' }}>
									{account.type || 'Personal'}
								</span>
							) : null}
							<span
								className="account-name"
								style={{ margin: 'auto 0' }}
							>
								{isMouseOverProfile && <div className="account-name-full-text">{detectRawAccountName(account)}</div>}
								{account.name ? ` ${detectRawAccountName(account)}` : account[`${account.socialMedia}Id`]}
								{locationAddress.length ?
									<div style={{ fontSize: 12 }}>
										{locationAddress}
									</div> : null
								}
				    </span>
							{account.tokenExpired && (
								<Link
									to={`/${getCalendar()}/settings`}
									className="reconnect"
									style={{
										height: 30,
										margin: 'auto 0',
									}}
								>
                				reconnect
								</Link>
							)}
						</div>
						<div style={{ fontSize: 12, paddingLeft: '4rem' }}><b>{renderSocialMediaNameInfo(account)}</b></div>
					</div>
					{
						isIgBusiness ? <Collapse isOpen={!collapse}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								{
									socialMediaReminders[isIgBusiness].map((smTag) => (<div
										style={{
											marginLeft: 70,
										}}
										onClick={() => {
											if (!account.tokenExpired || account.parentAccountId) {
												onAccountMetadataSelect(account, smTag);
											}
										}}
									>
										<img
											className={getCheckClass(account._id)}
											src={getActiveSmMetadataCheckbox(account._id, smTag)}
											alt="Select Category"
										/>
										{smReminderTexts[smTag]}
									</div>))
								}
							</div>
						</Collapse> : null
					}
					{(isFbPersonal || isIgPersonal || isTiktok) && !account.disabled ?
						<Collapse isOpen={!collapse}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>{socialMediaReminders[account.socialMedia].map((smTag) => (
									<div
										style={{
											marginLeft: 70,
										}}
										onClick={() => {
											if (!account.tokenExpired || account.parentAccountId) {
												onAccountMetadataSelect(account, smTag);
											}
										}}
									>
										<img
											className={getCheckClass(account._id)}
											src={getActiveSmMetadataCheckbox(account._id, smTag)}
											alt="Select Category"
										/>
										{smReminderTexts[smTag]}
									</div>))}
							</div>
						</Collapse>
						: null}
				</div>
			</DropdownItem>
		</div>);
};

RenderUserAccount.propTypes = {
	accountData: PropTypes.shape({}).isRequired,
	account: PropTypes.shape({}).isRequired,
	selectedOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSelectOption: PropTypes.func.isRequired,
	toggleSecondTwitterModal: PropTypes.func.isRequired,
	toggleSecondMailchimpModal: PropTypes.func.isRequired,
};

export default RenderUserAccount;
