/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

const { SocialIntegrationType } = require('../constants/mediaTypes');

exports.getEnabledAccounts = accounts => accounts.filter(account =>
	(!account.disabled ||
	(
		account.socialMedia === 'facebook' ||
		account.socialMedia === 'linkedin' ||
		account.socialMedia === 'googleMB' ||
		account.socialMedia === 'wordpress' ||
		account.socialMedia === 'shopify'
	) && !account.type) ||
	(account.socialMedia === 'hootsuite' && account.type && !account.disabled));

exports.getAccountsWithoutJournalAccounts = accounts => accounts.filter(account => account.socialMedia !== 'medium');

exports.isGMBAccountsWereSelected = accounts => !!accounts.filter(account => account.socialMedia === 'googleMB').length;
exports.isWebstoryAccountsWereSelected = accounts =>
	!!accounts.filter(account => account.socialMedia === 'wordpress' && account.type === 'story').length ||
	!!accounts.filter(account => account.socialMedia === 'shopify' && account.type === 'story').length ||
	!!accounts.filter(account => account.socialMedia === 'youtube' && account.type === 'channel').length;

exports.needToShowModalForInstallMobileApp = accounts =>
	accounts.some(account => (
		account.socialMedia === 'tikTok' ||
		account.socialMedia === 'snapchat' ||
		account.socialMedia === 'whatsApp' ||
		(account.socialMedia === 'instagram' && !account.type) ||
		(account.socialMedia === 'facebook' && !account.type)));

exports.hasLocationAddressAccount = account => account.address &&
		(account.socialMedia === SocialIntegrationType.GOOGLE && account.type === 'page' ||
		account.socialMedia === SocialIntegrationType.FACEBOOK && account.type);
