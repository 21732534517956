/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, March 2020
 */

import { combineReducers } from 'redux';
import * as requestActionTypes from '../constants/requestActionTypes';
import { withLoadable } from './_common/request';

// Requests

const getUserJournalPosts = withLoadable(requestActionTypes.GET_ALL_JOURNAL_POSTS)((state = {}) => state);
const getUserJournalFolders = withLoadable(requestActionTypes.GET_ALL_JOURNAL_FOLDERS)((state = {}) => state);
const getUserJournalAccount = withLoadable(requestActionTypes.GET_ALL_JOURNAL_ACCOUNTS)((state = {}) => state);

const reducers = {
	getUserJournalPosts,
	getUserJournalFolders,
	getUserJournalAccount,
};

export default combineReducers(reducers);
