/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { get, post, put, httpDelete } from '../../../common/httpRequests';
import { LIBRARY_CATEGORIES_URL } from '../../../common/apiRouting';

export function getCategories() {
	return get(`${LIBRARY_CATEGORIES_URL}`, true)
		.then(res => res.json());
}

export function createCategory(title) {
	return post(`${LIBRARY_CATEGORIES_URL}`, title, true)
		.then(res => res.json());
}

export function updateCategory(id, title) {
	return put(`${LIBRARY_CATEGORIES_URL}/${id}`, title, true)
		.then(res => res.json());
}

export function deleteCategory(id) {
	return httpDelete(`${LIBRARY_CATEGORIES_URL}/${id}`, true);
}
