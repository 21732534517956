/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import store from 'store';

export function getCalendar() {
	return store.get('currentCalendar');
}

export function setCalendar(calendar) {
	store.set('currentCalendar', `${calendar}`);
}
