/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { post, getProps, put, httpDelete, get } from '../../../common/httpRequests';
import {
	POST_URL,
	CHAT_GPT_TITLE_GENERATION,
	CHAT_GPT_CAPTION_GENERATION,
	// DELETE_FILES_URL
} from '../../../common/apiRouting';
// import { getToken } from '../../../common/auth';


export function deleteFiles() {
	return Promise.resolve();
	// filesList = filesList.filter(file => file);
	// if (!filesList || filesList.length === 0) return Promise.resolve();
	// return fetch(`${DELETE_FILES_URL}`, {
	// 	method: 'POST',
	// 	headers: {
	// 		Authorization: getToken(),
	// 		'Content-type': 'application/json',
	// 	},
	// 	body: JSON.stringify({
	// 		files: filesList,
	// 	}),
	// }).then(res => res.json()).then(() => Promise.resolve()).catch((err) => Promise.reject(err));
}

export function createPost(newPost) {
	const data = getProps(newPost, [
		'message',
		'postDate',
		'accountId',
		'accountIds',
		'hashtags',
		'imageHashtags',
		'images',
		'calendarId',
		'draft',
		'video',
		'pdf',
		'complianceEngineRules',
		'libraryPostId',
		'postTitle',
		'audio',
		'audioThumbnail',
		'callToAction',
		'accountsMetadata',
		'mediaMetadata',
	]);

	return post(POST_URL, data, true)
		.then(res => res.json());
}

export function updatePost(updatedPost, option = null) {
	const data = getProps(updatedPost, [
		'message',
		'postDate',
		'accountId',
		'accountIds',
		'hashtags',
		'imageHashtags',
		'images',
		'calendarId',
		'draft',
		'video',
		'pdf',
		'postTitle',
		'audio',
		'audioThumbnail',
		'callToAction',
		'accountsMetadata',
		'mediaMetadata',
	]);

	const id = updatedPost._id;
	const type = option && option.type ? `?type=${option.type}` : '';

	return put(`${POST_URL}/${id}${type}`, data, true)
		.then(res => res.json());
}

export function deletePost(deletedPostId) {
	return httpDelete(`${POST_URL}/${deletedPostId}`, true);
}

export function getPostsCount() {
	return get(`${POST_URL}/count`, true)
		.then(res => res.json());
}

export function chatGptTittleGeneration(summary) {
	return post(CHAT_GPT_TITLE_GENERATION, { message: summary }, true)
		.then(res => res.json());
}

export function chatGptCaptionGeneration(formData) {
	return post(CHAT_GPT_CAPTION_GENERATION, formData, true)
		.then(res => res.json());
}
