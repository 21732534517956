/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import '../AlertModal/AlertModal.css';

export default class AlertModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};
		this.onToggle = this.onToggle.bind(this);
	}

	onToggle() {
		this.props.toggle(this.state.isOpen);
	}

	render() {
		const { contentHeader } = this.props;

		return (
			<Modal
				isOpen={this.props.isOpen}
				toggle={this.onToggle}
				className={`modal-alert${contentHeader ? 'alertModalTitle' : ''}`}
			>
				<ModalHeader toggle={this.onToggle}>
					{contentHeader}
				</ModalHeader>
				<ModalBody id="modal-alert">
					<div className="modal-alert-message">{this.props.message}</div>
				</ModalBody>
			</Modal>
		);
	}
}

