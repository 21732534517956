import React, { useState, lazy, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThemeProvider } from '@mui/material';

import store from 'store';
import { Container } from 'reactstrap';
import * as selectors from '../../../selectors';
import NotFoundPage from '../../NotFoundPage/NotFoundPage';
import { hasSubscription } from '../../../common/userHelper';
import { getCurrentUser } from '../../../actions/user';
import { getSettings } from '../../../actions/settings';
import { getAccountsFromDB, syncAccountsFromDB } from '../../../actions/accounts';
import { getConfig } from '../../../actions/config';
import Loader from '../../../common/components/LoaderAnimation/Loader';
import theme from '../../../theme';

const Home = lazy(() => import(/* webpackChunkName: "home" */'../../Home/Home'));
const CalendarPage = lazy(() => import(/* webpackChunkName: "calendar" */'../../Calendar/CalendarPage/CalendarPage'));
const AnalyticsPage = lazy(() => import(/* webpackChunkName: "analytics" */'../../Analytics/AnalyticsPage'));
const Settings = lazy(() => import(/* webpackChunkName: "settings" */'../../Settings/Settings'));
// const UserSubscriptions = lazy(() => import(/* webpackChunkName: "upgrade" */'../../UserSubscriptions/UserSubscriptions'));
const UserSubscription = lazy(() => import(/* webpackChunkName: "upgrade" */'../../../pagesNew/UpgradeSubscription/UpgradeSubscription'));
const DiscoverPage = lazy(() => import(/* webpackChunkName: "discover" */'../../DiscoverPage/DiscoverPage'));
const LibraryPage = lazy(() => import(/* webpackChunkName: "library" */'../../Library/LibraryPage'));
const SavedDraftPage = lazy(() => import(/* webpackChunkName: "save-draft" */'../../SavedDraftPosts/SavedDraftPage'));
const JournalPage = lazy(() => import(/* webpackChunkName: "journal" */'../../JournalPage/JournalPage'));

const RoutesContainer = ({
	getCurrentUserAction,
	user = {},
	settings,
	getSettingsAction,
	accounts,
	getAccountsFromDBAction,
	syncAccountsAction,
	updateUserInfo,
	config: { response: userConfig = {} },
	getConfigAction,
}) => {
	const calendar = store.get('currentCalendar');
	const [filteredCampaignsIds, setFilteredCampaignsIds] = useState([]);

	const HomePageContainer = () => (
		<Home
			user={user.response}
			config={userConfig}
			getConfig={() => { getConfigAction(true); }}
			syncAccounts={syncAccountsAction}
		/>
	);

	const DiscoverPageContainer = () => (
		<DiscoverPage
			user={user.response}
			config={userConfig}
			getConfig={() => getConfigAction()}
		/>
	);

	const LibraryPageContainer = () => (
		<LibraryPage
			user={user.response}
			accounts={accounts.response}
			getAccounts={getAccountsFromDBAction}
			syncAccounts={syncAccountsAction}
		/>
	);

	const SavedDraftPageContainer = () => (
		<SavedDraftPage
			user={user.response}
		/>
	);

	const JournalPageContainer = () => (
		<JournalPage
			user={user.response}
			getAccounts={getAccountsFromDBAction}
			syncAccounts={syncAccountsAction}
		/>
	);

	const CalendarPageContainer = () => (
		<CalendarPage
			user={user.response}
			accountsList={accounts.response}
			updateChosenFiltersCampaignsIds={
				(campaigns) => { setFilteredCampaignsIds(campaigns); return campaigns; }}
			chosenFiltersCampaignsIds={filteredCampaignsIds}
			syncAccounts={syncAccountsAction}
		/>
	);

	const SettingsPageContainer = () => (
		<Settings
			user={user.response}
			accountsList={accounts.response}
			updateUser={() => { getCurrentUserAction(true); }}
			hasSubscription={!hasSubscription(user.response)}
			requireSubscription={() => {}}
			settings={settings.response}
			handleSettingsChange={() => { getSettingsAction(true); }}
			getAccounts={getAccountsFromDBAction}
			syncAccounts={syncAccountsAction}
		/>
	);

	const AnalyticsPageContainer = () => (
		<AnalyticsPage
			user={user.response}
			accountsList={accounts.response}
		/>
	);

	const UpgradePageContainer = () => (
		<ThemeProvider theme={theme}>
			<UserSubscription
				user={user.response}
				config={userConfig}
				getConfig={() => getConfigAction(true)}
				updateUserInfo={updateUserInfo}
			/>
		</ThemeProvider>
	);

	return (
		<Container fluid>
			<Suspense fallback={<Loader />}>
				<Switch>
					<Route exact path={`/${calendar}/`} render={HomePageContainer} />
					<Route path={`/${calendar}/calendar`} render={CalendarPageContainer} />
					<Route path={`/${calendar}/analytics`} render={AnalyticsPageContainer} />
					<Route path={`/${calendar}/settings`} render={SettingsPageContainer} />
					{!user.response.teamId && <Route path={`/${calendar}/upgrade-profile`} render={UpgradePageContainer} />}
					{/* <Route path={`/${calendar}/discover`} render={DiscoverPageContainer} /> */}
					<Route path={`/${calendar}/library`} render={LibraryPageContainer} />
					<Route path={`/${calendar}/drafts`} render={SavedDraftPageContainer} />
					<Route path={`/${calendar}/journal`} render={JournalPageContainer} />
					<Route component={NotFoundPage} />
				</Switch>
			</Suspense>
		</Container>
	);
};

const mapStateToProps = state => ({
	settings: selectors.getSettings(state),
	user: selectors.getCurrentUser(state),
	accounts: selectors.getAccounts(state),
	config: selectors.getConfig(state),
});

const mapDispatchToProps = dispatch => ({
	getCurrentUserAction: bindActionCreators(getCurrentUser, dispatch),
	getSettingsAction: bindActionCreators(getSettings, dispatch),
	getAccountsFromDBAction: bindActionCreators(getAccountsFromDB, dispatch),
	syncAccountsAction: bindActionCreators(syncAccountsFromDB, dispatch),
	getConfigAction: bindActionCreators(getConfig, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoutesContainer));
