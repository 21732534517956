/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

/** USER */

export const GET_USER = { key: 'GET_USER', url: '/api/user' };

/** DISCOUNT */

export const CHECK_DISCOUNT = { key: 'CHECK_DISCOUNT', url: '/api/discount/check' };

/** CALENDAR */

export const GET_CALENDAR_STATS = { key: 'GET_CALENDAR_STATS', url: '/api/stats' };
export const GET_CALENDARS = { key: 'GET_CALENDARS', url: '/api/calendars' };

/** CATEGORIES */

export const GET_CATEGORIES = { key: 'GET_CATEGORIES', url: '/api/library/categories' };

/** SETTINGS */

export const GET_SETTINGS = { key: 'GET_SETTINGS', url: '/api/settings' };

/** NOTIFICATION */

export const GET_NOTIFICATIONS = { key: 'GET_NOTIFICATIONS', url: '/api/notifications' };
export const PUT_UPDATE_NOTIFICATIONS = { key: 'PUT_UPDATE_NOTIFICATIONS', url: '/api/notifications' };

/** ACCOUNTS */

export const GET_ACCOUNTS = { key: 'GET_ACCOUNTS', url: '/api/accounts' };
export const SYNC_ACCOUNTS = { key: 'SYNC_ACCOUNTS', url: '/api/accounts/sync' };

/** CONFIG */

export const GET_CONFIG = { key: 'GET_CONFIG', url: '/api' };

/** POST */

export const GET_POSTS_BY_CAMPAIGN_ID = { key: 'GET_POSTS_BY_CAMPAIGN_ID', url: '/api/posts' };
export const GET_ALL_POSTS = { key: 'GET_ALL_POSTS', url: '/api/posts' };

/** SETTINGS */

export const GET_EXTENSION_SETTINGS = { key: 'GET_EXTENSION_SETTINGS', url: '/api/extension-settings' };


/** TRANSLATION */
export const GET_ALL_LANGUAGES = { key: 'GET_ALL_LANGUAGES', url: '/api/google-service/get-languages' };


/** JOURNAL */
export const GET_ALL_JOURNAL_POSTS = { key: 'GET_ALL_JOURNAL_POSTS', url: '/api/journal/posts' };
export const CREATE_NEW_JOURNAL_POST = { key: 'CREATE_NEW_JOURNAL_POST', url: '/api/journal/posts' };
export const UPDATE_JOURNAL_POST = { key: 'UPDATE_JOURNAL_POST', url: '/api/journal/posts' };
export const DELETE_JOURNAL_POST = { key: 'DELETE_JOURNAL_POST', url: '/api/journal/posts' };
export const GET_ALL_JOURNAL_FOLDERS = { key: 'GET_ALL_JOURNAL_FOLDERS', url: '/api/journal/folders' };
export const GET_ALL_JOURNAL_ACCOUNTS = { key: 'GET_ALL_JOURNAL_ACCOUNTS', url: '/api/accounts' };
export const CREATE_NEW_JOURNAL_FOLDERS = { key: 'CREATE_NEW_JOURNAL_FOLDERS', url: '/api/journal/folders' };

/** MAILCHIMP */
export const GET_MAILCHIMP_LISTS = { key: 'GET_MAILCHIMP_LISTS', url: '/api/mailchimp/lists' };
export const ADD_MEMBER_TO_MASTER_LIST = { key: 'ADD_MEMBER_TO_MASTER_LIST', url: '/api/mailchimp/addMemberToMasterList' };
