/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2021
 */

import moment from 'moment';
import React, { useCallback, useState, useEffect } from 'react';
import {
	Row,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	Button,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCalendar } from '../../../common/calendarHelper';

import './FreeUserSubscriptionModal.css';
import ArjunSvg from '../../../icons/arjun.svg';
import DogenSvg from '../../../icons/dogen.svg';
import BlueWaveSvg from '../../../icons/blue-wave.svg';

const FreeUserSubscriptionModal = () => {
	const [isOpen, setOpen] = useState(false);
	const currentUser = useSelector(({ user }) => user.currentUser && user.currentUser.response);
	const startRemovingFreeUsersDate = useSelector(({ config }) => config.getAll && config.getAll.response && config.getAll.response.startRemovingFreeUsersDate);
	const lastNotifyDay = new Date(moment(startRemovingFreeUsersDate).add(30, 'd')).toDateString();
	const subscriptionPlan = currentUser && currentUser.subscription && currentUser.subscription.plan;
	const history = useHistory();

	useEffect(() => {
		const shouldNotify = JSON.parse(localStorage.getItem('shouldNotifyFreeUser') || '{}');
		const today = new Date();
		const dateStr = today.toDateString();

		if (currentUser && currentUser.subscription && !subscriptionPlan && !currentUser.teamId && !shouldNotify[dateStr] && new Date(dateStr) <= new Date(lastNotifyDay)) {
			setOpen(true);
		}
	});

	const handleModalClose = useCallback(() => {
		const today = new Date();
		const dateStr = today.toDateString();
		localStorage.setItem('shouldNotifyFreeUser', JSON.stringify({ [dateStr]: true }));
	}, []);

	const handleChosePlanBtn = useCallback(() => {
		setOpen(false);
		handleModalClose();
		history.push(`/${getCalendar()}/upgrade-profile`);
	}, [handleModalClose, history]);

	return (
		<Modal
			isOpen={isOpen}
			toggle={() => {
				handleModalClose();
				setOpen(false);
			}}
			onClosed={handleModalClose}
			id="subscription-update-modal"
			centered
			autoFocus={false}
			size="lg"
		>
			<ModalBody>
				<Row>
					<Col>
						<div className="centered"><img className="avatar" src={ArjunSvg} alt="" /></div>
					</Col>
				</Row>
				<Row>
					<Col>
						<Row>
							<Col><h3>Hey, it&apos;s Arjun, Founder at <a href="https://hellowoofy.com">HelloWoofy.com</a> and <a href="https://selftact.com">SelfTact.com</a></h3></Col>
						</Row>
						<Row>
							<Col>
								<h5>
									It seems like you don't have a membership that's active at the moment. How about picking one of these amazing and affordable plans here to get started?
								</h5>
							</Col>
						</Row>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter className="categories-modal-footer">
				<Button
					color="primary"
					onClick={handleChosePlanBtn}
				>Choose new Plan
				</Button>
			</ModalFooter>
			<img className="dogen" src={DogenSvg} alt="" />
			<img className="wave" src={BlueWaveSvg} alt="" />
		</Modal>
	);
};

export default FreeUserSubscriptionModal;
