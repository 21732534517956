/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import './VoiceOver.css';
import * as selectors from '../../../selectors';
import VoiceRecognition from './VoiceRecognition';

import { getCalendar } from '../../calendarHelper';

import homeIcon from '../../../images/home.svg';
import analyticsIcon from '../../../images/analytics.svg';
import calendarIcon from '../../../images/calendar.svg';
import libraryIcon from '../../../images/library.svg';
import discoverIcon from '../../../images/discover.svg';
import settingsIcon from '../../../images/settings.svg';
import singlePostIcon from '../../../images/single-post.svg';
import campaignIcon from '../../../images/campaign.svg';
import libraryContentIcon from '../../../images/library-content.svg';
import manageRssIcon from '../../../images/rss.svg';
import graphicsIcon from '../../../images/graphics.svg';
import importContentIcon from '../../../images/import-content.svg';
import hashtagIcon from '../../../images/postImageHashtagIcon.svg';
import friendIcon from '../../../images/friend.svg';
import Loader from '../LoaderAnimation/Loader';
import AiLoader from '../../../components/Layout/AiLoader';

class VoiceOver extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeVoiceOver: false,
			intents: [
				{ link: '', icon: homeIcon, title: 'Go to Home' },
				{ link: 'analytics', icon: analyticsIcon, title: 'Show me Analytics page' },
				{ link: 'calendar', icon: calendarIcon, title: 'Go to the Calendar' },
				{ link: 'library', icon: libraryIcon, title: 'Show me Library page' },
				{ link: 'discover', icon: discoverIcon, title: 'Go to the Discover' },
				{ link: 'settings', icon: settingsIcon, title: 'Show me Settings page' },
				{ link: 'calendar/#singlePost', icon: singlePostIcon, title: 'Create Single Post' },
				{ link: 'calendar/#campaign', icon: campaignIcon, title: 'Create Campaign' },
				{ link: 'library/#libraryPost', icon: libraryContentIcon, title: 'Create Content' },
				{ link: 'library/#manageRss', icon: manageRssIcon, title: 'Manage RSS' },
				{ link: 'library/#createGraphics', icon: graphicsIcon, title: 'Create Graphics' },
				{ link: 'library/#importContent', icon: importContentIcon, title: 'Import Content' },
				{ link: '#addHashtag', icon: hashtagIcon, title: 'Add hashtags' },
				{ link: 'settings/#referFriend', icon: friendIcon, title: 'Refer a friend' },
			],
		};
	}

	onRedirect = (page) => {
		this.toggleActiveVoiceOver();

		if (this.props.isExtension) {
			return window.open(`/${getCalendar()}/${page}`, '_blank');
		}

		return this.props.history.push(`/${getCalendar()}/${page}`);
	};

	toggleActiveVoiceOver = () => {
		this.setState({ activeVoiceOver: !this.state.activeVoiceOver });
	};

	intentBuilder = intent => (
		<div key={intent.link} className="action" onClick={() => this.onRedirect(intent.link)}>
			<div className="action-image"><img src={intent.icon} alt="intent" /></div>
			<div className="action-title">{intent.title}</div>
		</div>
	);

	render() {
		const { intents } = this.state;
		const {
			user: {
				response: {
					name: userName,
				} = {},
			},
		} = this.props;

		const getRandomIntents = () => {
			const arr = [];
			while (arr.length < 3) {
				const random = Math.floor(Math.random() * intents.length) + 1;
				if (!(arr.indexOf(random) > -1)) {
					arr[arr.length] = random;
				}
			}
			return arr;
		};
		const randomIntents = getRandomIntents();

		return (
			<Fragment>
				<Modal
					isOpen={this.state.activeVoiceOver}
					toggle={this.toggleActiveVoiceOver}
					className="voice-over"
					centered
				>
					<ModalBody>
						<div className="vo-message">
							Hi {userName}! How can I help you?
						</div>
						<div className="example-actions">
							{intents.map((intent, index) =>
								(randomIntents.some(el => el === index + 1) ? this.intentBuilder(intent) : null))}
						</div>

						<VoiceRecognition
							intents={intents}
							onRedirect={this.onRedirect}
						/>

					</ModalBody>
				</Modal>

				<Suspense fallback={<Loader />}>
					<AiLoader
						activeVoiceOver={this.toggleActiveVoiceOver}
						ailLoaderLength={this.props.ailLoaderLength}
						ailLoaderRadius={this.props.ailLoaderRadius}
					/>
				</Suspense>
			</Fragment>
		);
	}
}

const componentWithRouter = withRouter(VoiceOver);

const mapStateToProps = state => ({
	user: selectors.getCurrentUser(state),
});

export default connect(mapStateToProps)(componentWithRouter);
