/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Loader from '../../../common/components/LoaderAnimation/Loader';

import artBoard from '../../../images/artboard.png';

import './ModalLoader.css';

const ModalLoader = (props) => (
	<Modal
		isOpen={props.isOpen}
		centered
		className="modal-loader campaign-confirm"
	>
		<ModalBody>
			<img src={artBoard} width={261} height={131} className="loader-art-board" alt="art-board" />
			<div className="loader-container"><Loader /></div>
			{ props.message && <div className="loader-text">{ props.message }</div> }
		</ModalBody>
	</Modal>
);

export default ModalLoader;
