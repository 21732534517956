/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import iconTwitter from '../images/icon-color-twitter.svg';
import iconFacebook from '../images/icon-color-facebook-by-meta.png';
import iconLinkedin from '../images/icon-color-linkedin.svg';
import iconInstagram from '../images/icon-color-instagram-by-meta.png';
import iconTiktok from '../images/icon-color-tiktok.svg';
import iconSnapchat from '../images/snapchatIcon.svg';
import iconMailchimp from '../images/HelloWoofy-Mailchimp.jpg';
import iconYoutube from '../images/youTubeIconForAccount.svg';
import iconWhatsApp from '../images/whatsappIcon.svg';
import iconFireTVApp from '../images/amazon-fireTVIcon.png';

import wixIcon from '../images/wix-icon.svg';
import weeblyIcon from '../images/weebly-icon.png';
import squareSpaceIcon from '../images/squarespace.png';
import threadsIcon from '../images/threads-logo.svg';
import medium from '../images/mediumIcon.svg';
import wordpress from '../images/wordpress-logo.svg';
import alexaAvatar from '../images/amazon-alexa-logo.png';
import group from '../images/icon-color-group-lg.svg';
import event from '../images/icon-color-event-lg.svg';
import shopifyIcon from '../images/shopifyIcon.svg';
import GMBAccountIcon from '../images/GMBAccountIcon.svg';
import pinterest from '../images/pinterest.png';
import board from '../images/board.svg';
import story from '../images/web-stories-logo.png';
import channel from '../images/channel.svg';

export const getSocialIcon = (socialName, socialType) => {
	switch (socialName) {
	case 'twitter':
		return iconTwitter;
	case 'facebook': {
		switch (socialType) {
		case 'group':
			return group;
		case 'event':
			return event;
		case 'page':
		default:
			return iconFacebook;
		}
	}
	case 'pinterest': {
		switch (socialType) {
		case 'page':
			return board;
		case 'board':
			return board;
		default:
			return pinterest;
		}
	}
	case 'linkedin':
		return iconLinkedin;
	case 'instagram':
		return iconInstagram;
	case 'tikTok':
		return iconTiktok;
	case 'snapchat':
		return iconSnapchat;
	case 'medium':
		return medium;
	case 'wordpress':
		switch (socialType) {
		case 'story':
			return story;
		case 'blog':
			return wordpress;
		default:
			return wordpress;
		}
	case 'shopify':
		switch (socialType) {
		case 'story':
			return story;
		case 'blog':
			return shopifyIcon;
		default:
			return shopifyIcon;
		}
	case 'googleMB':
		return GMBAccountIcon;
	case 'alexaDevice':
		return alexaAvatar;
	case 'alexaWhiteLabelSkill':
		return alexaAvatar;
	case 'mailchimp':
		switch (socialType) {
		case 'list':
			return group;
		default:
			return iconMailchimp;
		}
	case 'youtube':
		switch (socialType) {
		case 'channel':
			return channel;
		default:
			return iconYoutube;
		}
	case 'whatsApp':
		return iconWhatsApp;
	case 'fireTVApp':
		return iconFireTVApp;
	case 'wix':
		return wixIcon;
	case 'weebly':
		return weeblyIcon;
	case 'squarespace':
		return squareSpaceIcon;
	case 'threads':
		return threadsIcon;
	default:
		return '';
	}
};
