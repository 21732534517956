/* eslint-disable */
/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import './UserSocialAccountsBlock.css';

import { getSocialIcon } from '../../getSocialIcon';
import defaultAvatar from '../../../images/default-avatar.png';
import hootsuiteIcon from '../../../images/icon-white-hootsuite.svg';

const UserSocialAccountsBlock = ({
	socialMedia, avatar = defaultAvatar, hootsuite, name, acocuntId, isPosted, isCongratsModal = false, accountData
}) => (
	<div
		className={cx('post-account', { hootsuite })}
		style={{
			backgroundImage: `url(${avatar})`,
			border: isCongratsModal ? '1px solid' : undefined,
			borderColor: isCongratsModal ? (isPosted ? '#22d3a5' : 'red') : undefined,
			width: isCongratsModal ? '40px' : 20,
			height: isCongratsModal ? '40px' : 20,
		}}
		data-tip
		data-for={`icon-name-tooltip-${acocuntId}`}
		onClick={() => {
			if (accountData && accountData.url) {
				return window.open(accountData.url, '_blank');
			}
		}}
	>
		{hootsuite && (
			<div className="icon-hootsuite">
				<img
					src={hootsuiteIcon}
					alt="Hootsuite"
				/>

			</div>
		)}

		<img
			key={socialMedia}
			src={getSocialIcon(socialMedia)}
			style={{
				marginTop: isCongratsModal ? 25 : 5,
				marginLeft: isCongratsModal ? 25 : 10,
			}}
			alt={socialMedia}
			data-tip
			data-for={`icon-name-tooltip-${acocuntId}`}
		/>
		{
			name && <ReactTooltip id={`icon-name-tooltip-${acocuntId}`} type="info" >
				<span>{name}</span>
			</ReactTooltip>
		}
	</div>
);

UserSocialAccountsBlock.propTypes = {
	avatar: PropTypes.string.isRequired,
	socialMedia: PropTypes.string.isRequired,
};

export default UserSocialAccountsBlock;
