/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { get } from '../../httpRequests';
import { WORDS_API_URL } from '../../apiRouting';

export function getWords(highlightWord) {
	let word = highlightWord;

	try {
		word = encodeURIComponent(highlightWord);
	} catch (e) {
		return Promise.reject();
	}

	return get(`${WORDS_API_URL}?word=${word}`, true)
		.then(res => res.json());
}
