/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

export const imagesAcceptedFormats = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/heic'];
export const imagesAcceptedShortFormats = ['image/png', 'image/jpg', 'image/jpeg'];
