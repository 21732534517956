/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { EditorState, SelectionState } from 'draft-js';

import { urlRegexp, hashtagRegexp } from './textHelper';

export const getSelectionRange = () => {
	const selection = window.getSelection();
	if (selection.rangeCount === 0) return null;
	return selection.getRangeAt(0);
};

export const getSelectionCoords = (selectionRange, editorNode) => {
	if (selectionRange.startContainer.childElementCount > 1) {
		return { offsetLeft: -36, offsetTop: 85 };
	}

	const editorBounds = editorNode.getBoundingClientRect();
	let rangeBounds = selectionRange.getBoundingClientRect();
	// when content is copy pasted into an empty editor, selection range is not properly detected
	if (rangeBounds.x === 0 && rangeBounds.y === 0) {
		rangeBounds = editorBounds;
	}
	const rangeWidth = rangeBounds.right - rangeBounds.left;
	const offsetLeft = (rangeBounds.left - editorBounds.left) + (rangeWidth / 2) - 20;
	const offsetTop = Math.abs(rangeBounds.top - editorBounds.top) + 50;
	return { offsetLeft, offsetTop };
};

export const getHighlightWord = (text = '') => {
	const selection = window.getSelection();
	if (selection.rangeCount === 0 || text === ' ' || text.match(urlRegexp) || text.match(hashtagRegexp)) return null;

	let word = text.slice(selection.getRangeAt(0).startOffset, selection.getRangeAt(0).endOffset);
	if (word[0] === ' ') {
		word = word.slice(1, word.length);
	} else if (word[word.length - 1] === ' ') {
		word = word.slice(0, word.length - 1);
	}

	return !word.match(' ') ? word : null;
};

const reverseStr = str => [...str].reverse().join('');

const addSpace = text => (text[0] !== ' ' ? ` ${text}` : text);

export const replaceHighlightWord = (editorState, key, word, hashtagObj) => {
	const content = editorState.getCurrentContent();
	let textBlocks = '';
	content.getBlocksAsArray().forEach((block, index) => {
		const range = {
			start: hashtagObj ? hashtagObj.startPosition : editorState.getSelection().getStartOffset(),
			end: hashtagObj ? hashtagObj.endPosition : editorState.getSelection().getEndOffset(),
		};
		if (block.getKey() === key) {
			const firstPartOfText = block.getText().slice(0, range.start);
			const secondPartOfText = block.getText().slice(range.end, block.getText().length);
			const textBlocksStart = range.start
				? reverseStr(addSpace(reverseStr(firstPartOfText)))
				: firstPartOfText;
			const newWord = block.getText().length ?
				textBlocksStart + word + addSpace(secondPartOfText) : '';
			textBlocks += `${index === 0 ? '' : '\n'}${newWord}`;
		} else {
			textBlocks += `${index === 0 ? '' : '\n'}${block.getText()}` || ' ';
		}
	});
	return textBlocks;
};

const setLineBreak = (elementIndex, arrayLength) => (elementIndex !== arrayLength - 1 ? '\n' : '');

export const replaceTextBlock = (editorState, currentBlockKey, text) => {
	const blocksArray = editorState.getCurrentContent().getBlocksAsArray();
	let textBlocks = '';
	blocksArray.forEach((block, index) => {
		const blockText = block.getText();
		const lineBreak = setLineBreak(index, blocksArray.length);
		if (block.getKey() === currentBlockKey) {
			textBlocks += (blockText + text + lineBreak);
		} else {
			textBlocks += (blockText + lineBreak);
		}
	});
	return textBlocks;
};

export const getCurrentContentBlockKey = editorState => editorState.getSelection().getStartKey();

export const getCurrentContentBlockText = (currentContentBlockKey, contentState) => {
	try {
		return contentState.getBlockForKey(currentContentBlockKey).getText();
	} catch (error) {
		console.log('[getCurrentContentBlockText error]', error);
		return '';
	}
};

export const moveSelectionToEnd = (editorState) => {
	const content = editorState.getCurrentContent();
	const blockMap = content.getBlockMap();

	const key = blockMap.last().getKey();
	const length = blockMap.last().getLength();

	const selection = new SelectionState({
		anchorKey: key,
		anchorOffset: length,
		focusKey: key,
		focusOffset: length,
	});

	return EditorState.forceSelection(editorState, selection);
};

export const getTextHashtagsWithPosition = (text) => {
	// eslint-disable-next-line
	const regexp = /\B\#\w+\b/g;
	const array = text.match(regexp) || [];
	return array.map(hashTag => {
		const startIndex = text.search(hashTag);
		return {
			name: hashTag,
			startPosition: startIndex,
			endPosition: startIndex + hashTag.length,
		};
	});
};

export const anchorOnHashTag = (positionArray, currentPosition) =>
	positionArray.find(({ startPosition, endPosition }) =>
		currentPosition >= startPosition && currentPosition <= endPosition);
