/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, December 2020
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import './ShopifyInstructionModal.css';

const ShopifyInstructionModal = ({ modalOpen, closeShopifyInstruction, currentUrlHash }) => (
	<Modal
		isOpen={modalOpen}
		toggle={closeShopifyInstruction}
		centered
		className="shopify-instruction-modal"
	>
		<ModalHeader
			toggle={closeShopifyInstruction}
			className="shopify-instruction-modal-header"
		>Shopify Setup Instructions
		</ModalHeader>
		<ModalBody
			className="shopify-instruction-modal-body"
		>
			{currentUrlHash === '#shopify-new-user' &&
			<Fragment>
				<p>Create an account at HelloWoofy.com</p>
				<p>Go to the Settings section under the User profile menu(top right corner) and Add Account by clicking on the &apos;Add accounts to this calendar&apos;
					button and select Shopify integration from the Blogging Accounts Column.
				</p>
				<p>Enter your Shopify Shop name in the appearing popup.</p>
			</Fragment>}
			<p>Go to Journal Tab and click Create Blog Post (<a href="https://prnt.sc/ufmqcp" target="_blank" rel="noopener noreferrer">https://prnt.sc/ufmqcp</a>)</p>
			<p>Add Title and Content (<a href="https://prnt.sc/ufmr7x" target="_blank" rel="noopener noreferrer">https://prnt.sc/ufmr7x</a>)</p>
			<p>Chose folder- you can create one (<a href="https://prnt.sc/ufmruk" target="_blank" rel="noopener noreferrer">https://prnt.sc/ufmruk</a>|<a href="https://prnt.sc/ufmsl7" target="_blank" rel="noopener noreferrer">https://prnt.sc/ufmsl7</a>)</p>
			<p>Click the Upload button next to the Folder select menu (<a href="https://prnt.sc/ufm85m" target="_blank" rel="noopener noreferrer">https://prnt.sc/ufm85m</a>)</p>
			<p>Select publishing account- Shopify Account (<a href="https://prnt.sc/ufmtkh" target="_blank" rel="noopener noreferrer">https://prnt.sc/ufmtkh</a>)</p>
			<p>Select New blog and click Post (<a href="https://prnt.sc/ufmuet" target="_blank" rel="noopener noreferrer">https://prnt.sc/ufmuet</a> | <a href="https://prnt.sc/ufmv53" target="_blank" rel="noopener noreferrer">https://prnt.sc/ufmv53</a>)</p>
		</ModalBody>
		<ModalFooter
			className="shopify-instruction-modal-footer"
		>
			<Button color="primary" onClick={closeShopifyInstruction}>Ok</Button>
		</ModalFooter>
	</Modal>
);

ShopifyInstructionModal.propTypes = {
	modalOpen: PropTypes.bool.isRequired,
	closeShopifyInstruction: PropTypes.func.isRequired,
	currentUrlHash: PropTypes.string.isRequired,
};

export default ShopifyInstructionModal;
