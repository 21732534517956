/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, July 2020
 */

export const woofyText = {
	helloWoofyExclusiveDealText: 'Click here to Upgrade to Pro and Redeem this Exclusive Offer',
	helloWoofyExclusiveAlexaDealText: 'Click here to Upgrade to SMB and Redeem this Exclusive Offer',
	unsavedChanges: 'Any unsaved changes will be lost. Are you sure you want to continue?',
	confirmDeleting: 'Are you sure you want to delete it?',
	usedUTM: 'Please, upgrade your subscription plan to start using UTM parameters for your posts.',
	usedJournalPage: 'Please, upgrade your subscription plan to start using Journal page.',
	usedImportContent: 'Please, upgrade your subscription plan to start using Import Content.',
	usedHashtagsPlaylist: 'Please, upgrade your subscription plan to start using Hashtags.',
	usedRSS: 'Please, upgrade your subscription plan to start using RSS.',
	sendPostWithoutMedia: 'Are you sure you want to publish a post without an Image, GIF or Video?',
	emptyCampaignContent: 'Are you sure you want to publish your Campaign using an empty category?',
	instaBusinessGifStatic: 'Are you sure you want to publish a post  with a GIF file (GIF to Instagram Business account will be posted as static image)?',
	instaBusinessMultipleImagesLimit: 'Hey, we see you\'re posting with multiple images to an Instagram Business profile however carousel posting is only supported via mobile app reminders. Are you sure you want to be reminded via a mobile notification for this post instead of auto posting a single image/video to the IG Business profile?',
	pinterestMultipleImagesLimit: 'Are you sure you want to publish a post with multiple image files (multiple images to Pinterest account will be posted only first image)?',
	twitterDailyPostLimit: 'This twitter account can\'t be posted for this day as you have reached your daily limit based on your subscription tier. Schedule for later?',
};
