/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import './NotFoundPage.css';

const NotFoundPage = () => (
	<div id="not-found-page">
		<div className="box">
			<div className="message-404">404</div>
			<h4>OOPS! Forget other social media management tools. When it comes to you,</h4>
			<br />
			<h4>you are mobile, visual and creative...so is Woofy!</h4>
			<div className="btn-area">
				<Button color="info" outline><Link className="btn-link" to="/">BACK TO HOMEPAGE</Link></Button>
			</div>
		</div>
	</div>
);

export default NotFoundPage;
