import emoji from 'node-emoji';

function isFirstAddressValue(locationAddress) {
	return !locationAddress.length;
}

function splitAddress(locationAddress) {
	return isFirstAddressValue(locationAddress) ? ' ' : ', ';
}
export function locationAddressParser(address) {
	let locationAddress = '';

	if (address.addressLines) {
		locationAddress = locationAddress.concat(`${splitAddress(locationAddress)}${address.addressLines[0]}`);
	}

	if (address.locality) {
		locationAddress = locationAddress.concat(`${splitAddress(locationAddress)}${address.locality}`);
	}

	if (address.administrativeArea) {
		locationAddress = locationAddress.concat(`${splitAddress(locationAddress)}${address.administrativeArea}`);
	}

	if (address.postalCode) {
		locationAddress = locationAddress.concat(`${splitAddress(locationAddress)}${address.postalCode}`);
	}

	return `${emoji.emoji.round_pushpin}${locationAddress}`;
}
