import React, { Fragment } from 'react';
import { Button } from 'reactstrap';

import woofyAppError from '../../images/woofy-app-error.svg';

import './AppErrorPage.css';

const AppErrorPage = ({ err }) => {
	const error = err.error;
	console.log(err);
	const errorName = error.name;
	const isChunkLoadError = errorName === 'ChunkLoadError';

	const onReloadClick = () => {
		window.location.reload();
	};

	return (
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			textAlign: 'center',
			paddingTop: '50px',
		}}
		>
			<img src={woofyAppError} alt="Error" style={{ height: 100, width: 200 }} />
			<span style={{
				fontStyle: 'normal',
				fontWeight: 600,
				fontSize: '24px',
				lineHeight: '29px',
				textAlign: 'center',
				color: '#747B95',
				paddingTop: '19.5px',
			}}
			>
				{isChunkLoadError ?
					<Fragment>
						<article>Oops, seems like we have just released a new version of HelloWoofy!</article>
						<article>Click on the button below to update to the latest version.</article>
					</Fragment> :
					<Fragment>
						<article>Oops, something went wrong! Error {error.toString()}</article>
						<article>Please contact us at support@hellowoofy.com if the issue persists.</article>
					</Fragment>
				}
			</span>
			{isChunkLoadError &&
			<Button
				color="primary"
				style={{
					width: '30%',
					alignSelf: 'center',
					margin: '15px 0',
					backgroundColor: '#18d1f9',
					borderRadius: '100px',
					fontWeight: 'bold',
					fontSize: '14px',
					borderColor: '#18d1f9',
					boxShadow: '0 2px 15px 0 rgba(49, 218, 255, 0.41)',
				}}
				onClick={onReloadClick}
			>
				Update
			</Button>
			}
		</div>);
};

export default AppErrorPage;
