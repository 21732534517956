import React from 'react';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import './DailyLimitsModal.css';

import { getSocialIcon } from '../../getSocialIcon';

const defaultAvatar = 'https://goskrep.ru/upload/info/default-avatar.png';

const DailyLimitsModal = ({
	onConfirm, onClose, isOpen, selectedAccounts, postsCount, postDates, postLimit, withReminderText = false, onConfirmText = 'Yes', rejectActionText = 'No',
}) => {
	if (!selectedAccounts.length || !postsCount || !postDates) return false;

	const postsCountInfo = postDates.map((postDate) => {
		const date = new Date(postDate).toDateString();
		if (postsCount[date]) {
			return (
				<div style={{
					paddingBottom: 12,
				}}
				>
					<div>{date}</div>
					{Object.keys(postsCount[date]).map((accountId) => {
						const account = selectedAccounts.find((acc) => acc.instagramId === accountId || acc.twitterId === accountId);
						if (account) {
							const count = postsCount[date][accountId];
							return (
								<>
									<div className="account-name">
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img
												src={account.avatar ? account.avatar : defaultAvatar}
												alt="avatar"
												className="avatar"
											/>
											{account.socialMedia && (
												<img
													src={getSocialIcon(account.socialMedia)}
													alt="avatar soc"
													className="avatar-social-icon"
												/>
											)}
											<span>{account.name ? ` @${account.name}` : account[`${account.socialMedia}Id`]}</span>: {count}
										</div>
									</div>
								</>
							);
						}
						return '';
					})}
				</div>
			);
		}
		return '';
	});
	const message = (
		<div style={{ textAlign: 'left' }}>
			<div style={{ marginBottom: 24 }}>You've reached your daily limit {postLimit} for posting to the following social media account based on your plan and platform rules. {withReminderText ? 'Do you want to continue and use it as a push notification to your phone?' : ''}</div>
			<div style={{ maxHeight: 250, overflow: 'auto' }}>{postsCountInfo}</div>
		</div>
	);
	return (
		<ConfirmModal
			isOpen={isOpen}
			onConfirm={onConfirm}
			onClose={onClose}
			message={message}
			confirmActionText={onConfirmText}
			rejectActionText={rejectActionText}
		/>
	);
};

export default DailyLimitsModal;
