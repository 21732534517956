/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, January 2021
 */

import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import RedirectToPageModal from '../../../common/components/RedirectToPageModal/RedirectToPageModal';

import checkBoxOn from '../../../images/check-box-on.svg';
import checkBoxOff from '../../../images/check-box-off.svg';
import postWarningTooltip from '../../../images/postWarningTooltip.svg';
import chatGptIcon from '../../../icons/chatgpt-icon.svg';

import './AddTitleToGMBPost.css';

const AddTitleToGMBPost = ({
	isAddTitleToGMBPostSelected,
	changeAddTitleToGMBPostSelected,
	titleOfPost = '',
	changeGMBPostTitle,
	userAccounts = [],
	showChatGptIconInTitleSec,
	handleTitleGeneration,
}) => {
	const [gmbPostLength, changeGMBPostLength] = useState(0);
	const [isUserHaveGMBAccount, changedUserHaveGMBAccountValue] = useState(false);
	const [
		isRedirectToSettingsModalOpen,
		changedRedirectToSettingsModalVisibility,
	] = useState(false);
	useEffect(() => {
		changeGMBPostLength(titleOfPost.trim().length);
	}, [titleOfPost]);

	useEffect(() => {
		if (titleOfPost.length) {
			changeAddTitleToGMBPostSelected(true);
			changeGMBPostLength(titleOfPost.trim().length);
		}
		return () => {
			changeGMBPostLength(0);
			changedUserHaveGMBAccountValue(false);
		};
	}, []);

	useEffect(() => {
		const userHaveGMBAccount = userAccounts.some((account) =>
			(account.socialMedia === 'wordpress' && account.type === 'story') ||
        (account.socialMedia === 'shopify' && account.type === 'story') ||
        (account.socialMedia === 'youtube' && account.type === 'channel') ||
        account.socialMedia === 'googleMB');
		changedUserHaveGMBAccountValue(userHaveGMBAccount);
	}, [userAccounts]);

	const handleCheckBoxIconClick = () => {
		if (!isUserHaveGMBAccount) {
			return changedRedirectToSettingsModalVisibility(true);
		}
		changeAddTitleToGMBPostSelected(!isAddTitleToGMBPostSelected);
		changeGMBPostTitle('');
		changeGMBPostLength(0);
	};

	const changePostTitle = (newTitle) => {
		if (newTitle.length > 70) {
			return;
		}
		changeGMBPostTitle(newTitle);
	};

	return (
		<Fragment>
			<div className="add-title-to-gmb-post-container">
				<div>
					<img
						src={isAddTitleToGMBPostSelected ? checkBoxOn : checkBoxOff}
						onClick={handleCheckBoxIconClick}
						alt="checkbox"
						className="add-title-to-gmb-post-checkbox"
					/>
					<span className="add-title-to-gmb-post-title">
            Add a Title for Google Web Stories, GMB Offer or YouTube
					</span>
					<img
						src={postWarningTooltip}
						alt="post-warning-tooltip"
						data-tip="When posting to Google Web Stories, GMB Offer or YouTube, this will be used under Story Title or ignored for other social media platforms"
						data-place="top"
						data-for="add-title-to-GMB-post-tooltip"
					/>
					<ReactTooltip id="add-title-to-GMB-post-tooltip" effect="solid" />
				</div>
				{isAddTitleToGMBPostSelected && (
					<div className="add-title-to-gmb-post-input-container">
						<input
							placeholder="Enter Google Web Stories, GMB Offer or YouTube post title (max length 70)"
							value={titleOfPost}
							onChange={(event) => changePostTitle(event.target.value)}
							className="input-post-title"
						/>
						{showChatGptIconInTitleSec && (
							<div className="chat-gpt-icon" onClick={handleTitleGeneration}>
								<img
									src={chatGptIcon}
									alt="chat-gpt-icon"
									height="30px"
									data-for="chat-gpt-data"
									data-tip=""
								/>

								<ReactTooltip
									id="chat-gpt-data"
									place="bottom"
									effect="solid"
									backgroundColor="#07a3ed"
									getContent={() => (
										<div>Generate Title using AI</div>
									)}
									delayHide={300}
								/>
							</div>
						)}

						<span className="title-length-counter">
              Google Web Stories: {gmbPostLength}
						</span>
					</div>
				)}
			</div>
			{isRedirectToSettingsModalOpen && (
				<RedirectToPageModal
					isModalOpen={isRedirectToSettingsModalOpen}
					changedModalVisibility={changedRedirectToSettingsModalVisibility}
					redirectLink="settings"
				/>
			)}
		</Fragment>
	);
};

AddTitleToGMBPost.propTypes = {
	isAddTitleToGMBPostSelected: PropTypes.bool.isRequired,
	changeAddTitleToGMBPostSelected: PropTypes.func.isRequired,
	titleOfPost: PropTypes.string.isRequired,
	changeGMBPostTitle: PropTypes.func.isRequired,
	userAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AddTitleToGMBPost;
