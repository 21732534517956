/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, January 2021
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
	Button,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { updateNotifications } from '../../../../actions/notification';
import iconNotification from '../../../../images/icon-notification.svg';
import * as selectors from '../../../../selectors';
import { getSuccessData } from '../../../../helpers/store';
import { getCalendar } from '../../../../common/calendarHelper';
import CalendarCongratsModal from '../../../../components/Calendar/CalendarCongratsModal/CalendarCongratsModal';
import './NotificationDropDown.css';
import CalendarPostModal from '../../../Calendar/CalendarPostModal/CalendarPostModal';
import { getPostsById } from '../../../Calendar/CalendarPage/CalendarPage.action';

const redirectToSettingPage = (history) =>
	history.push(`/${getCalendar()}/${'settings'}`);

const getNotificationMessage = (history, message, needToReconnect) => {
	if (needToReconnect) {
		return (
			<div className="notification-message-container">
				{/* eslint-disable-next-line react/no-danger */}
				<p dangerouslySetInnerHTML={{ __html: message }} />
				<Button color="primary" onClick={() => redirectToSettingPage(history)}>
          Reconnect
				</Button>
			</div>
		);
	}
	// eslint-disable-next-line react/no-danger
	return <span dangerouslySetInnerHTML={{ __html: message }} />;
};

const NotificationDropDown = ({
	updateNotificationAction,
	notifications,
	history,
	user,
	accounts,
}) => {
	const [userNotifications, changeUserNotifications] = useState([]);
	const [postModal, setPostModal] = useState(false);
	const [postData, setPostData] = useState({});
	const [notFoundData, setNotFoundData] = useState(false);
	const [postBlockedAccounts, setPostBlockedAccounts] = useState([]);
	const [congratsModalOpen, setCongratsModalOpen] = useState(false);
	const [congratsMessage, setCongratsMessage] = useState('');
	const [postType, setPostType] = useState('');
	const [updatedPostData, setUpdatedPostData] = useState({});
	const [subMessage, setSubMessage] = useState('');
	const appTimezone = user.response.timezone;


	const getCurrentPost = async (id) => {
		try {
			const currentPostData = await getPostsById(id);
			if (currentPostData) {
				setPostData(currentPostData);
			} else {
				console.log('post is not found');
				setPostData(null);
				setNotFoundData(true);
			}
		} catch (err) {
			setNotFoundData(true);
			console.log(err);
		}
	};

	useEffect(() => {
		const { items = [] } = getSuccessData(notifications);
		changeUserNotifications(items);
	}, [notifications]);

	useEffect(() => {
		if (postData && Object.keys(postData).length > 0) {
			const {
				blocked = false, postedInfo = [], accountIds = [],
			} = postData;
			let blockedAccounts = [];
			if (blocked) {
				blockedAccounts = accountIds;
			} else if (!blocked && postedInfo.length) {
				if (postedInfo.length !== accountIds.length) {
					const arrayOfPublishedAccountsIds = postedInfo.map(info => info.accountId);
					blockedAccounts = accountIds.filter(postId => !arrayOfPublishedAccountsIds.includes(postId));
				} else if (postedInfo.length === 1) {
					const blockedPostData = postedInfo.filter(info => !info.id);
					blockedAccounts = blockedPostData.length ? [blockedPostData[0].accountId] : [];
				} else {
					const blockedAccountsIds = postedInfo.filter(info => !info.id);
					if (blockedAccountsIds.length) {
						blockedAccounts = blockedAccountsIds.map(info => info.accountId);
					}
				}
			}

			const arrayOfBlockedAccounts = accounts.response.items.filter(account => blockedAccounts.includes(account._id));
			setPostBlockedAccounts(arrayOfBlockedAccounts);
			setPostModal(true);
		}
	}, [postData]);

	const handleReadNotifications = () => {
		setTimeout(() => {
			if (document.querySelector('.notifications.show')) {
				const unreadNotifications = userNotifications.filter((el) => el.seen === false);
				const readNotifications = unreadNotifications.length
					? unreadNotifications.map((el) => {
						if (el.seen === false) {
							const notification = el;
							notification.seen = true;
							return notification;
						}
						return el;
					})
					: [];

				if (readNotifications.length > 0) {
					readNotifications.forEach((notification) => {
						updateNotificationAction(notification, userNotifications);
					});
				}
			}
			return '';
		}, 2000);
	};

	const openModal = async (id) => {
		if (id) {
			const data = await getCurrentPost(id);
			return data;
		}
	};

	const onPostModalClose = () => {
		setPostModal(false);
	};

	const congratsModalEvent = (postedInfo) => {
		setPostType(postedInfo.postType);
		setCongratsMessage(postedInfo.message);
		setCongratsModalOpen(postedInfo.open);
		setSubMessage(postedInfo.subMessage);
		setUpdatedPostData(postedInfo.postData);
	};

	const onSaveModalClose = (postedInfo) => {
		if (!postedInfo.open) {
			setPostModal(false);
		}
		congratsModalEvent(postedInfo);
	};

	return (
		<>
			<UncontrolledDropdown
				nav
				inNavbar
				className="notifications"
				onClick={handleReadNotifications}
			>
				<DropdownToggle nav caret>
					<img
						id="app-notification-icon"
						className="notification-icon"
						src={iconNotification}
						alt="notification"
					/>
					{userNotifications.length > 0 &&
            userNotifications.some((el) => el.seen === false) && (
						<div className="notification-icon-point" />
					)}
				</DropdownToggle>
				<DropdownMenu right>
					<Scrollbars style={{ width: '100%', height: 422 }}>
						{userNotifications.length ? (
							userNotifications.map(({
								_id,
								message,
								seen,
								needToReconnect,
								updatedAt,
								postId,
							}) => (
								<DropdownItem
									key={_id}
									onClick={() => openModal(postId, message)}
								>
									<div
										className={`notification ${
											seen === true ? 'read' : 'unread'
										}`}
									>
										<div className="notification-header">
											<div className="notification-title-wrap">
												<div className="notification-point">
													<div className="point" />
												</div>
												<div className="notification-message">
													{getNotificationMessage(
														history,
														message,
														needToReconnect,
														updatedAt,
													)}
												</div>
											</div>
										</div>
										<div className="notification-footer">
											<span className="know-more-link">
												{postId && 'Click to know more'}
											</span>
											<span data-tip data-for="full_date">
												{moment(updatedAt)
													.tz(user.response.timezone)
													.fromNow()}
											</span>
										</div>
									</div>
								</DropdownItem>
							))
						) : (
							<div className="no-notifications">No notifications</div>
						)}
					</Scrollbars>
				</DropdownMenu>
			</UncontrolledDropdown>
			{postModal && (
				<CalendarPostModal
					isOpen={postModal}
					toggle={onPostModalClose}
					onSave={onSaveModalClose}
					timezone={user.response.timezone}
					notFoundData={notFoundData}
					blockedPostData={postData}
					blockedAccountList={postBlockedAccounts}
					retry={postData ? !postData.campaignId : true}
					existingPost
				/>
			)}

			{congratsModalOpen && <CalendarCongratsModal
				isOpen={congratsModalOpen}
				toggle={onSaveModalClose}
				message={congratsMessage}
				subMessage={subMessage}
				url={postType}
				postData={updatedPostData}
				timezone={appTimezone}
			/>}
		</>
	);
};

const mapStateToProps = (state) => ({
	notifications: selectors.getNotifications(state),
	user: selectors.getCurrentUser(state),
	accounts: selectors.getAccounts(state),
});

const mapDispatchToProps = (dispatch) => ({
	updateNotificationAction: bindActionCreators(updateNotifications, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(NotificationDropDown));
