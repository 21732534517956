/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, July 2019
 */

export const isPostWasBlocked = post => {
	const { blocked = false, postedInfo = [], accountIds = [] } = post;
	return blocked || postedInfo.length !== accountIds.length || !!postedInfo.filter(info => !info.id).length;
};

export const isAllPostWasPublished = post => {
	const { postedInfo = [], accountIds = [] } = post;
	const notBlockedAccounts = postedInfo.filter(info => info.id);
	return accountIds.length === notBlockedAccounts.length;
};

export const isAllPostWasBlocked = post => {
	const { postedInfo = [], accountIds = [] } = post;
	const blockedAccounts = postedInfo.filter(info => !info.id);
	return accountIds.length === blockedAccounts.length || !postedInfo.length;
};
