/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import { get, getProps, post, put, httpDelete } from '../../../common/httpRequests';
import { CALENDAR_URL } from '../../../common/apiRouting';

export function getCalendars() {
	return get(CALENDAR_URL, true)
		.then(res => res.json());
}

export function createCalendar(calendar) {
	const data = getProps(calendar, ['name']);
	return post(CALENDAR_URL, data, true)
		.then(res => res.json());
}

export function updateCalendar(calendar) {
	const data = getProps(calendar, ['name']);
	return put(`${CALENDAR_URL}/${calendar.id}`, data, true)
		.then(res => res.json());
}

export function deleteCalendar(id) {
	return httpDelete(`${CALENDAR_URL}/${id}`, true);
}
