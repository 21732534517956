/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	FormGroup,
	Label,
	Input,
	FormFeedback,
} from 'reactstrap';

class CalendarEditModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			calendar: '',
			errorMessage: '',
		};

		this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentDidMount() {
		this.setState({ calendar: this.props.calendar.name });
	}

	handleChangeCalendar(e) {
		const calendar = e.target.value;
		this.setState({ calendar, errorMessage: '' });
	}

	handleSave() {
		if (!this.state.calendar.trim().length) {
			this.setState({ errorMessage: 'Name is required', calendar: '' });
			return;
		}

		this.props.updateCalendar(this.state.calendar);
	}

	render() {
		const { isOpen, toggle } = this.props;

		return (
			<Fragment>
				<div>
					<Modal
						isOpen={isOpen}
						toggle={toggle}
						id="calendar-modal"
						centered
						autoFocus={false}
					>
						<ModalHeader
							className="categories-modal-header"
							toggle={toggle}
						>
							<div id="rectangle" />
							{this.props.calendar._id ? 'Edit Calendar' : 'Add new Calendar'}
						</ModalHeader>
						<ModalBody>
							<FormGroup className="add-category">
								<Label>Calendar Name</Label>
								<Input
									type="text"
									value={this.state.calendar}
									onChange={this.handleChangeCalendar}
									invalid={!!this.state.errorMessage}
									autoFocus
								/>
								<FormFeedback className="form-feedback">{this.state.errorMessage}</FormFeedback>
							</FormGroup>
						</ModalBody>
						<ModalFooter className="categories-modal-footer">
							<Button onClick={this.handleSave} className="save-category-btn" color="primary">
								{this.props.calendar._id ? 'Save' : 'Add'}
							</Button>
						</ModalFooter>
					</Modal>
				</div>
			</Fragment>
		);
	}
}

export default CalendarEditModal;
