import { post } from '../actions/_common/request';

const createImage = (url) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.crossOrigin = 'Anonymous';
		image.addEventListener('load', () => resolve(image), false);
		image.addEventListener('error', (error) => reject(error));
		// image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

function getRadianAngle(degreeValue) {
	return (degreeValue * Math.PI) / 180;
}

function getImgKey(imgUrl) {
	const isGStorage = imgUrl.indexOf('https://storage.googleapis.com/woofy-images/') !== -1;
	const imageKey = imgUrl.split('/').pop();
	const imageSrc = isGStorage ? `/api/upload-image/${imageKey}` : `https://cors-anywhere.hellowoofy.com/${imgUrl}`;
	return { imageSrc, imageKey };
}

export async function getImgRatio(originImageSrc) {
	const { imageSrc: url } = getImgKey(originImageSrc);
	return post({
		url,
	})
		.then(response => response && response.data);
}
// export async function getCroppedImg(originImageSrc, pixelCrop, rotation = 0) {
// 	const { imageSrc, imageKey } = getImgKey(originImageSrc);
// 	const image = await createImage(imageSrc);
// 	const canvas = document.createElement('canvas');
// 	const ctx = canvas.getContext('2d');

// 	const maxSize = Math.max(image.width, image.height);
// 	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

// 	// set each dimensions to double largest dimension to allow for a safe area for the
// 	// image to rotate in without being clipped by canvas context
// 	canvas.width = safeArea;
// 	canvas.height = safeArea;

// 	// translate canvas context to a central location on image to allow rotating around the center.
// 	ctx.translate(safeArea / 2, safeArea / 2);
// 	ctx.rotate(getRadianAngle(rotation));
// 	ctx.translate(-safeArea / 2, -safeArea / 2);

// 	// draw rotated image and store data.
// 	ctx.drawImage(
// 		image,
// 		safeArea / 2 - image.width * 0.5,
// 		safeArea / 2 - image.height * 0.5,
// 	);
// 	const data = ctx.getImageData(0, 0, safeArea, safeArea);

// 	// set canvas width to final desired crop size - this will clear existing context
// 	canvas.width = pixelCrop.width;
// 	canvas.height = pixelCrop.height;

// 	// paste generated rotate image with correct offsets for x,y crop values.
// 	ctx.putImageData(
// 		data,
// 		Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
// 		Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
// 	);

// 	// As Base64 string
// 	// return canvas.toDataURL('image/jpeg');

// 	// As a blob
// 	return new Promise((resolve) => {
// 		canvas.toBlob((file) => {
// 			resolve({
// 				uri: URL.createObjectURL(file),
// 				file,
// 				imageKey,
// 			});
// 		}, 'image/png');
// 	});
// }

export function rotateSize(width, height, rotation) {
	const rotRad = getRadianAngle(rotation);

	return {
	  width:
		Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
	  height:
		Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
	};
}

export async function getCroppedImg(
	originImageSrc,
	pixelCrop,
	rotation = 0,
	flip = { horizontal: false, vertical: false },
) {
	const { imageSrc, imageKey } = getImgKey(originImageSrc);
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const maxSize = Math.max(image.width, image.height);
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea;
	canvas.height = safeArea;
	const ctx = canvas.getContext('2d');

	if (!ctx) {
	  return null;
	}

	const rotRad = getRadianAngle(rotation);

	// calculate bounding box of the rotated image
	const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
	  image.width,
	  image.height,
	  rotation,
	);

	// set canvas size to match the bounding box
	canvas.width = bBoxWidth;
	canvas.height = bBoxHeight;

	// translate canvas context to a central location to allow rotating and flipping around the center
	ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
	ctx.rotate(rotRad);
	ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
	ctx.translate(-image.width / 2, -image.height / 2);

	// draw rotated image
	ctx.drawImage(image, 0, 0);

	// croppedAreaPixels values are bounding box relative
	// extract the cropped image using these values
	const data = ctx.getImageData(
	  pixelCrop.x,
	  pixelCrop.y,
	  pixelCrop.width,
	  pixelCrop.height,
	);

	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	// paste generated rotate image at the top left corner
	ctx.putImageData(data, 0, 0);

	// As Base64 string
	// return canvas.toDataURL('image/jpeg');

	// As a blob
	return new Promise((resolve) => {
	  canvas.toBlob((file) => {
			console.log('new file: ', file);
			resolve({
				uri: URL.createObjectURL(file),
				file,
				imageKey,
			});
	  }, 'image/jpeg');
	});
}

export async function getRotatedImage(imageSrc, rotation = 0) {
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const orientationChanged =
		rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
	if (orientationChanged) {
		canvas.width = image.height;
		canvas.height = image.width;
	} else {
		canvas.width = image.width;
		canvas.height = image.height;
	}

	ctx.translate(canvas.width / 2, canvas.height / 2);
	ctx.rotate((rotation * Math.PI) / 180);
	ctx.drawImage(image, -image.width / 2, -image.height / 2);

	return new Promise((resolve) => {
		canvas.toBlob((file) => {
			resolve(URL.createObjectURL(file));
		}, 'image/png');
	});
}

export const parsedImgKey = getImgKey;
