/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment } from 'react';
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';

import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactTooltip from 'react-tooltip';

import CalendarEditModal from './CalendarEditModal';
import { UserSubscriptionLimitsContext, UserSubscriptionService } from '../../UserSubscriptions/UserSubscriptionService';

import { getCalendars, createCalendar, updateCalendar, deleteCalendar } from './CalendarDropDown.action';

import editIcon from '../../../images/edit-icon.svg';
import notPostedPostIcon from '../../../images/notPostedPostIcon.svg';
import removeIcon from '../../../images/remove-icon.svg';
import { getCalendar, setCalendar } from '../../../common/calendarHelper';
import { subsErrorReasons, subsActions } from '../../../constants/subscriptionValidation';
import './CalendarDropDown.css';
import { checkCalendarCreateOrDeleteRights, checkCalendarEditRights } from '../../../helpers/rolesCheck';
import AccessWarningTooltip from '../../AccessWarningTooltip/AccessWarningTooltip';
import postWarningTooltip from '../../../images/postWarningTooltip.svg';

class CalendarDropDown extends Component {
	static contextType = UserSubscriptionLimitsContext;

	constructor(props) {
		super(props);
		this.state = {
			dropdownStatus: false,
			currentDrop: '',
			calendarList: [],
			loading: false,
			colors: [
				'#000000',
				'#0000FF',
				'#8A2BE2',
				'#A52A2A',
				'#7FFF00',
				'#D2691E',
				'#DC143C',
				'#00008B',
				'#9932CC',
				'#8B0000',
				'#483D8B',
				'#9400D3',
				'#1E90FF',
				'#98FB98',
				'#FA8072',
				'#008000',
				'#ADFF2F',
				'#FF69B4',
				'#F0E68C',
				'#800000',
				'#D8BFD8',
				'#FF6347',
				'#40E0D0',
				'#EE82EE',
				'#F5DEB3',
				'#C0C0C0',
			],
			errorMessage: '',
			confirmModal: false,
			confirmRemove: '',
			editModal: false,
			currentCalendar: {},
			calendarSearch: '',
			filterCalendarList: [],
		};

		this.toggle = this.toggle.bind(this);
		this.onClickAddCalendar = this.onClickAddCalendar.bind(this);
	}

	componentDidMount() {
		this.setState({ loading: true });
		this.load();
	}

	onRedirect = (page) => {
		this.props.history.push(`/${getCalendar()}/${page}`);
	};

	onDropClick(value, id) {
		this.setState(() => ({
			currentDrop: value,
			dropdownStatus: false,
		}));
		this.props.onSelect(id);
		this.toggle();
	}


	onClickAddCalendar() {
		const { calendarList } = this.state;
		const { user } = this.props;
		// const isUserHaveSubscription = UserSubscriptionService.isUserHaveSubscription(user);
		const { limits, setIsUpgradeModalShown, setUpgradeModalMetadata } = this.context;
		const calendars = UserSubscriptionService.getPlanItemLimit(user, limits, 'calendars');

		if (calendarList.length >= calendars) {
			// const errorMessage = !isUserHaveSubscription ?
			// 	`Your subscription plan allowed to have ${calendars} Calendars.
			// 	You can upgrade your plan to get more calendars`
			// 	: `You have reached a maximum number of Calendars(${calendars})`;

			setUpgradeModalMetadata({
				action: subsActions.calendars,
				reason: subsErrorReasons.limit_exceed,
				amount: calendarList.length,
			});
			setIsUpgradeModalShown(true);

			return this.setState({
				dropdownStatus: !this.state.dropdownStatus,
			});
		}

		return this.toggleEdit({ _id: '', name: '' });
	}

	load() {
		getCalendars()
			.then(result => {
				const calendarList = result.items.map((element, index) =>
					({ ...element, color: this.state.colors[index] }));

				if (!getCalendar()) {
					setCalendar(calendarList[0]._id);
					this.props.history.push('/');
				}

				let currentDrop = 0;
				calendarList.forEach((element, index) => {
					if (element._id === getCalendar()) {
						currentDrop = index;
					}
				});

				this.setState({
					calendarList,
					filterCalendarList: calendarList,
					currentDrop,
				});
			})
			.then(() => {
				this.setState({ loading: false });
			})
			.catch(error => new Error(error));
	}

	toggle() {
		this.setState(prevState => ({
			dropdownStatus: !prevState.dropdownStatus,
			errorMessage: '',
		}));
	}

	handleUpdateCalendar = (calendar) => {
		const { setIsUpgradeModalShown, setUpgradeModalMetadata } = this.context;

		if (this.state.currentCalendar._id) {
			updateCalendar({ id: this.state.currentCalendar._id, name: calendar })
				.then(() => {
					this.toggleEdit({});
					this.toggle();
					this.load();
				})
				.catch((error) => new Error(error));
		} else {
			createCalendar({ name: calendar })
				.then(() => {
					this.setState(({
						currentDrop: this.state.calendarList.length,
						loading: true,
					}));
					this.toggleEdit({});
					this.toggle();
					this.load();
				})
				.catch(() => {
					setUpgradeModalMetadata({
						action: subsActions.calendars,
						reason: subsErrorReasons.limit_exceed,
						amount: this.state.calendarList.length,
					});
					setIsUpgradeModalShown(true);
					this.setState({
						dropdownStatus: !this.state.dropdownStatus,
					});
				});
		}
	};

	handleDeleteCalendar = (id) => {
		deleteCalendar(id)
			.then(() => {
				this.setState(({
					confirmModal: !this.state.confirmModal,
					confirmRemove: '',
				}));
				this.toggle();
				this.load();
			})
			.then(() => {
				if (getCalendar() === id) {
					setCalendar('');
					this.load();
				}
			});
	};

	confirm = () => {
		this.setState({
			confirmModal: !this.state.confirmModal,
		});
	};

	toggleEdit = (calendar) => {
		this.setState({
			editModal: !this.state.editModal,
			currentCalendar: calendar,
		});
	};

	confirmRemove = (id) => {
		this.setState({
			confirmModal: !this.state.confirmModal,
			confirmRemove: id,
		});
	};

	handleSearchCalendar = (e) => {
		this.setState({
			calendarSearch: e.target.value,
			filterCalendarList: this.state.calendarList.filter((ele) => ele.name.toLowerCase().includes(e.target.value.toLowerCase())),
		});
	};

	render() {
		function addItemClasses(value, currentDrop) {
			return currentDrop === value ? 'current-item select-calendar-dropdown-item' : 'select-calendar-dropdown-item';
		}
		const {
			dropdownStatus, loading, calendarList, currentDrop,
		} = this.state;

		return (
			<Fragment>
				<Dropdown
					id="select-calendar-drop-down"
					isOpen={dropdownStatus}
					toggle={this.toggle}
				>
					<DropdownToggle
						color="drop-down"
						caret
						style={{ position: this.props.position, border: `${calendarList[currentDrop] && calendarList[currentDrop].showReconnect ? '1px solid red' : 'none'}` }}
					>
						<div className="drop-down-title-main">
							<span
								className="item"
								style={!loading && calendarList.length ?
									{ backgroundColor: `${calendarList[currentDrop].color}` } : null}
							/>
							{!loading && calendarList.length ?
								calendarList[currentDrop].name : null}
						</div>
					</DropdownToggle>
					<DropdownMenu
						modifiers={{
							setMaxHeight: {
								enabled: true,
								order: 890,
								fn: (data) => ({
									...data,
									styles: {
										// ...data.styles,
										overflow: 'auto',
										maxHeight: 200,
									},
								}),
							},
						}}
					>
						<Scrollbars style={{ width: '100%' }} autoHeight>
							<div className="search-calendar" >
								<input className="search-input" type="text" placeholder="Search Calendar" value={this.state.calendarSearch} onChange={e => this.handleSearchCalendar(e)} />
							</div>
							{!this.state.loading ? this.state.filterCalendarList.map((calendar, index) => (
								<div
									key={calendar._id}
									className={addItemClasses(index, this.state.currentDrop)}
								>
									<div className="drop-down-title" onClick={() => this.onDropClick(index, calendar._id)}>
										<span
											className="item"
											style={{
												backgroundColor: `${this.state.calendarList[index].color}`,
											}}
										/>
										{calendar.name}
									</div>
									<div className="drop-down-actions">
										{
											calendar.showReconnect && <React.Fragment>
												<img
													src={notPostedPostIcon}
													alt={notPostedPostIcon}
													className="account-reconnect-warning"
													data-tip
													data-for="warning-tooltip"
												/>
												<ReactTooltip id="warning-tooltip" type="error" >
													<span>Some of your accounts have expired. <br /> Please reconnect your social media account on the Settings page.</span>
												</ReactTooltip>
											</React.Fragment>
										}
										<img
											onClick={() => checkCalendarEditRights(this.props.user) && this.toggleEdit(calendar)}
											src={editIcon}
											alt={editIcon}
											className="edit-btn"
											data-tip
											data-for="access-warning-tooltip-edit"
										/>
										{
											!checkCalendarEditRights(this.props.user) && <ReactTooltip id="access-warning-tooltip-edit" type="warning" >
												<span>Contact your admin</span>
											</ReactTooltip>
										}
										{this.state.calendarList.length !== 1 ?
											<img
												onClick={() => checkCalendarCreateOrDeleteRights(this.props.user) && this.confirmRemove(calendar._id)}
												src={removeIcon}
												alt={removeIcon}
												className="remove-btn"
												data-tip
												data-for="access-warning-tooltip"
											/>
											: <div className="empty-area" />}
										{
											!checkCalendarCreateOrDeleteRights(this.props.user) && <AccessWarningTooltip />
										}
									</div>
								</div>
							)) : null}
							<div style={{ display: 'flex' }}>
								<img
									style={{ marginLeft: '1rem' }}
									src={postWarningTooltip}
									alt="more-access-tooltip"
									data-tip="Sniffing around for more categories or calendars? Contact your billing admin for access."
									data-place="top"
									data-for="more-access-tooltip"
								/>
								<ReactTooltip
									id="more-access-tooltip"
									effect="solid"
								/>
								<span style={{ marginLeft: '10px', fontSize: '16px', color: '#747b95' }}>More Access?</span>
							</div>
							<div className="add-calendar-dropdown-item">
								<div
									onClick={checkCalendarCreateOrDeleteRights(this.props.user) && this.onClickAddCalendar}
									className={this.state.errorMessage ? 'error' : ''}
									data-tip
									data-for="access-warning-tooltip"
								>
									+ ADD NEW CALENDAR
								</div>
								{
									!checkCalendarCreateOrDeleteRights(this.props.user) && <AccessWarningTooltip />
								}

								<div className="error-message">{this.state.errorMessage}</div>
							</div>
						</Scrollbars>
					</DropdownMenu>
				</Dropdown>
				{
					this.state.confirmModal ?
						<Confirm
							isOpen={this.state.confirmModal}
							toggle={this.confirm}
							calendarId={this.state.confirmRemove}
							removeCalendar={this.handleDeleteCalendar}
						/> : null
				}
				{
					this.state.editModal ?
						<CalendarEditModal
							isOpen={this.state.editModal}
							toggle={this.toggleEdit}
							calendar={this.state.currentCalendar}
							updateCalendar={this.handleUpdateCalendar}
						/> : null
				}
			</Fragment>
		);
	}
}

const Confirm = ({
	isOpen, toggle, calendarId, removeCalendar,
}) => (
	<Modal isOpen={isOpen} toggle={toggle} className="confirm-modal">
		<ModalHeader toggle={toggle} />
		<ModalBody>
			Are you sure? If you will remove this calendar - you will remove all records tied to it
			(Social Media Accounts, Posts, Scheduled Posts, Campaigns, Library content etc.)
		</ModalBody>
		<ModalFooter>
			<Button color="light" onClick={toggle} className="confirm-no">No</Button>
			<Button color="primary" onClick={() => removeCalendar(calendarId)} className="confirm-yes">Yes</Button>
		</ModalFooter>
	</Modal>
);

export default withRouter(CalendarDropDown);
