/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';

import NotPostedPostWarning from '../../../common/components/NotPostedPostWarning/NotPostedPostWarning';

import { getCalendar } from '../../../common/calendarHelper';
import {
	isPostWasBlocked,
	// isAllPostWasPublished,
	isAllPostWasBlocked,
} from '../../../helpers/isPostWasBlocked';

import checkmark from '../../../images/checkmark.svg';
import congrat from '../../../images/congrat-icon.svg';
import errorCrossIcon from '../../../images/errorCrossIcon.svg';

import './CalendarCongratsModal.css';
import UserSocialAccountsBlock from '../../../common/components/UserSocialAccountsBlock/UserSocialAccountsBlock';
import * as selectors from '../../../selectors';

const CalendarCongratsModal = ({
	isOpen,
	headerMessage = '',
	message = '',
	subMessage = '',
	congratsMessage = 'Congrats',
	withHeaderIcon = true,
	url,
	toggle,
	postData = {},
	timezone = '',
	reloadCalendarEvents,
	footerButtons,
	errMessage,
	accounts: { response: { items: accounts } = [] },
}) => {
	let isPostWasNotPublished = false;
	// let isAllPublished = true;
	let isAllBlocked = false;
	const accountsPostedDetails = [];
	if (Object.keys(postData).length) {
		const { postDate, draft } = postData;
		const isDraftOrScheduledPost = draft || moment(postDate).utc().isAfter(moment().utc(), 'minutes');
		if (!isDraftOrScheduledPost) {
			accounts.forEach(({ _id, ...rest }) => {
				const accInPost = postData.accountIds.find((accId) => accId === _id);
				if (accInPost) {
					const accInfo = postData.postedInfo.find((acc) => acc.accountId === _id);
					const isPosted = accInfo ? !!accInfo.id : false;
					if (!(postData.hootsuiteId && accInfo.tokenExpired)) {
						accountsPostedDetails.push({
							_id, ...rest, isPosted,
						});
					}
				}
			});
			isPostWasNotPublished = isPostWasBlocked(postData);
			// isAllPublished = isAllPostWasPublished(postData);
			isAllBlocked = isAllPostWasBlocked(postData);
		}
	}

	const getMessage = () => {
		if (isAllBlocked) {
			return (
				<div className="not-published-message">
					<div className="not-published-message-text">{message}</div> &nbsp;
					<NotPostedPostWarning
						needToShowButton
						postData={postData}
						timezone={timezone}
						closePreviousModal={toggle}
						reloadCalendarEvents={reloadCalendarEvents}
					/>
				</div>);
		}

		const gridTemplateColumns = accountsPostedDetails.length < 5 ? accountsPostedDetails.length % 5 : 5;
		return (<div style={{
			display: 'grid',
			gridTemplateColumns: `repeat(${gridTemplateColumns}, 1fr)`,
			gridAutoRows: 40,
			gridRowGap: 8,
			gridColumnGap: 8,
		}}
		>
			{accountsPostedDetails.map(({
				socialMedia, avatar, hootsuiteId, name, _id, isPosted,
			}) => (
				<div>
					<UserSocialAccountsBlock
						key={`${socialMedia} ${avatar}`}
						socialMedia={socialMedia}
						avatar={avatar || ''}
						hootsuite={!!hootsuiteId}
						name={name}
						acocuntId={_id}
						isCongratsModal
						isPosted={isPosted}
					/>
				</div>
			))}
		</div>);
		// return <p>{message}</p>;
	};

	const footerButton = (<div className="btn-link">
		<Link to={`/${getCalendar()}/${url || 'library'}`}>
			<Button onClick={() => toggle({ open: false })} color="primary">
				{`Go to ${url || 'library'}`}
			</Button>
		</Link>
	</div>);

	useEffect(() => {
		if (!isPostWasNotPublished && isOpen) {
			setTimeout(() => toggle({ open: false }), 5000);
		}
	});

	return (
		<Modal
			isOpen={isOpen}
			toggle={() => toggle({ open: false })}
			centered
			size="md"
		>
			<ModalHeader toggle={() => toggle({ open: false })} id="congrats-modal-header">
				<div>
					{withHeaderIcon && <img src={congrat} alt="congrats-modal-pick" />}
					{headerMessage}
				</div>
			</ModalHeader>
			<ModalBody id="congrats-modal">
				<img
					src={isAllBlocked ? errorCrossIcon : checkmark}
					className="congrats-icon"
					alt="congrats-modal-icon"
				/>
				<div className="congrats-message">{isAllBlocked ? '' : congratsMessage}</div>
				<div
					style={isAllBlocked ? {} : {
						maxHeight: 200,
						minHeight: 60,
						overflowX: 'hidden',
						overflowY: 'auto',
					}}
				>{getMessage()}</div>
				<p>{errMessage && errMessage}</p>
				<p>{subMessage}</p>
			</ModalBody>
			<ModalFooter id="congrats-modal-footer" style={{ justifyContent: footerButtons ? 'center' : 'flex-end' }}>
				{footerButtons || footerButton}
			</ModalFooter>
		</Modal>
	);
};

const mapStateToProps = state => ({
	accounts: selectors.getAccounts(state),
});

export default compose(
	connect(mapStateToProps, null),
	withRouter,
)(CalendarCongratsModal);
