/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
	Dropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import * as selectors from '../../../selectors';
import checkBoxOff from '../../../images/check-box-off.svg';
import checkBoxOn from '../../../images/check-box-on.svg';
import CategoriesModal from '../../Library/Categories/CategoriesModal';

import { createCategory, getCategories } from '../Categories/LibraryCategories.action';

import './DropdownSelectCategory.css';
import { checkLibraryCategoryCreateOrDeleteRights } from '../../../helpers/rolesCheck';
import AccessWarningTooltip from '../../AccessWarningTooltip/AccessWarningTooltip';

class DropdownSelectCategory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			existingCategories: [],
			selectedOptions: [],
			permissionError: '',
		};

		this.toggle = this.toggle.bind(this);
	}

	componentDidMount() {
		if (this.props.selectedOptions) {
			this.setState({
				selectedOptions: this.props.selectedOptions,
			});
		}
		getCategories()
			.then(res => {
				if (this.isCancelled) {
					return;
				}

				const categories = res.map(el => ({
					id: el._id, text: el.title, count: el.count,
				}));
				this.setState({ existingCategories: categories });
			});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedOptions) {
			this.setState({
				selectedOptions: nextProps.selectedOptions,
			});
		}
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	getActiveCheckbox = (categoryId) =>
		(this.state.selectedOptions.includes(categoryId) ? checkBoxOn : checkBoxOff);

	setTitle = (title) => {
		if (title && title.length > 20) {
			return `${title.slice(0, 20)}...`;
		}
		return title;
	};

	getCheckClass = categoryId => (this.state.selectedOptions.includes(categoryId) ? 'checkBoxOn' : 'checkBoxOff');

	writeCategory = () => {
		const { selectedOptions = [], existingCategories = [] } = this.state;
		const { placeholder = '' } = this.props;
		if (!selectedOptions.length) {
			return placeholder || 'Choose category';
		}

		return selectedOptions.map((item, index, options) => {
			const category = existingCategories.find(element => element.id === item);
			if (category) {
				const { text, count } = category;
				const categoryData = `${text}(${count})`;
				return index === options.length - 1 ? categoryData : `${categoryData}, `;
			}
			return null;
		});
	};

	toggle = () => {
		this.setState({
			showModal: !this.state.showModal,
		});
		if (this.state.showModal) {
			this.setState({
				permissionError: '',
			});
		}
	};

	changeCategories = existingCategories => {
		const { onSelectOption } = this.props;
		Promise.all([
			createCategory({ title: existingCategories.text }),
		]).then(res => {
			if (res) {
				const newCategory = { id: res[0]._id, text: res[0].title, count: res[0].count };
				this.setState({
					existingCategories: this.state.existingCategories.concat(newCategory),
				});

				if (this.props.updateExistingCategories) {
					this.props.updateExistingCategories(this.state.existingCategories);
				}
				onSelectOption(newCategory.id);
				if (this.state.showModal) {
					this.toggle();
				}
			}
		})
			.catch(error => {
				if (error.status === 403) {
					this.setState({
						permissionError: error.message,
					});
				}
				console.log('[Category error]:', error);
			});
	};

	render() {
		const {
			className,
			onSelectOption,
			isAddCategory = true,
			dropdownOpen,
			toggle,
			direction,
			height,
			width,
			marginTop,
			scrollSize,
		} = this.props;
		const { showModal, existingCategories } = this.state;

		return (
			<div>
				<Dropdown
					isOpen={dropdownOpen}
					toggle={toggle}
					id="dropdown-categories"
					direction={direction}
				>
					<DropdownToggle
						color="drop-down"
						className="dropdown-toggle"
						caret
						style={{
							height,
							width,
							marginTop,
						}}
					>
						<div
							className="dropdown-toggle-box"
							style={{ width }}
						>
							{this.writeCategory()}
						</div>
					</DropdownToggle>

					<DropdownMenu className={`drop-down-menu ${className && className}`}>
						<Scrollbars
							style={scrollSize || { width: '100%' }}
							autoHeight
							className="drop-down-menu-categories"
						>
							{isAddCategory &&
								<DropdownItem
									className="rss-category-checkbox add-new-category"
									// onClick={this.toggle}
									onClick={checkLibraryCategoryCreateOrDeleteRights(this.props.user.response) && this.toggle}
									data-tip
									data-for="access-warning-tooltip"
								>
									<span>+</span>Add new category
								</DropdownItem>
							}
							{
								!checkLibraryCategoryCreateOrDeleteRights(this.props.user.response) && <AccessWarningTooltip />
							}

							{existingCategories.map(category => (
								<DropdownItem
									key={category.id}
									onClick={() => onSelectOption(category.id)}
									className="rss-category-checkbox"
								>
									<img
										className={this.getCheckClass(category.id)}
										src={this.getActiveCheckbox(category.id)}
										alt="Select Category"
									/>
									<div className="drop-down-select-categories-title">
										{this.setTitle(category.title || category.text)}
									</div>
									<span>{category.count <= 999 ? category.count : '999+'}</span>
								</DropdownItem>
							))}
						</Scrollbars>
					</DropdownMenu>
				</Dropdown>

				<CategoriesModal
					isOpen={showModal}
					toggle={this.toggle}
					addCategory={this.changeCategories}
					deleteCategory={this.deleteCategories}
					currentCategory
					existingCategories={existingCategories}
					permissionError={this.state.permissionError}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: selectors.getCurrentUser(state),
});

export default connect(mapStateToProps)(DropdownSelectCategory);

DropdownSelectCategory.defaultProps = {
	direction: 'down',
	height: '2.75rem',
	width: '20.5rem',
	marginTop: '0',
};

DropdownSelectCategory.propTypes = {
	direction: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	marginTop: PropTypes.string,
};
