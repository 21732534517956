/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SketchPicker } from 'react-color';

import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Input,
	Alert,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';

import './AttachImageMetadataModal.css';

const fontsList = [
	'auto',
	'Glyphicons Halflings',
	'cursive',
	'emoji',
	'fangsong',
	'fantasy',
	'inherit',
	'initial',
	'math',
	'monospace',
	'none',
	'revert',
	'sans-serif',
	'serif',
	'system-ui',
	'ui-monospace',
	'ui-rounded',
	'ui-sans-serif',
	'ui-serif',
	'unset',
	'-webkit-body',
];

const AttachImageMetadataModal = ({
	imageUrl,
	imageMetadata,
	isAttachImageMetadataModalOpen,
	changeAttachImageMetadataModalVisibility,
	handleAttachMetadata,
	isPriorPostedPostView,
}) => {
	const closeModal = () => {
		changeAttachImageMetadataModalVisibility(false);
	};

	const [errorMessage, setErrorMessage] = useState('');

	const [buttonUrl, setButtonUrl] = useState(imageMetadata && imageMetadata.buttonUrl || '');

	const [buttonColor, setButtonColor] = useState(imageMetadata && imageMetadata.buttonColor || '#18d1f9');
	const [showButtonColorPicker, setShowButtonColorPicker] = useState(false);

	const [buttonText, setButtonText] = useState(imageMetadata && imageMetadata.buttonText || '');

	const [buttonTextColor, setButtonTextColor] = useState(imageMetadata && imageMetadata.buttonTextColor || '#FFFFFF');
	const [showButtonTextColorPicker, setShowButtonTextColorPicker] = useState(false);

	const [titleText, setTitleText] = useState(imageMetadata && imageMetadata.titleText || '');

	const [titleTextColor, setTitleTextColor] = useState(imageMetadata && imageMetadata.titleTextColor || '#FFFFFF');
	const [showTitleTextColorPicker, setShowTitleTextColorPicker] = useState(false);

	const [selectTitleFontDropDownOpen, setSelectTitleFontDropDownOpen] = useState(false);
	const [selectedTitleFont, setSelectedTitleFont] = useState(imageMetadata && imageMetadata.titleTextFont || fontsList[0]);

	const handleAddButtonClick = () => {
		if (buttonUrl && !buttonUrl.match(/(https:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)) {
			return setErrorMessage('Please correct Button URL (add https:// and make sure it is a valid URL)');
		}

		if (buttonText && !buttonUrl) {
			return setErrorMessage('Please add Link for Button');
		}

		if (buttonUrl && !buttonText) {
			return setErrorMessage('Please add Button text');
		}

		if (titleText.length > 26) {
			return setErrorMessage('You can place text on image up to 26 characters');
		}

		if (handleAttachMetadata) {
			handleAttachMetadata({
				mediaId: imageUrl,
				buttonText,
				buttonTextColor,
				buttonUrl,
				buttonColor,
				titleText,
				titleTextColor,
				titleTextFont: selectedTitleFont,
			});
		}

		closeModal();
	};

	return (
		<Modal
			isOpen={isAttachImageMetadataModalOpen}
			toggle={closeModal}
			className="get-text-from-url-modal"
		>
			<ModalHeader
				toggle={closeModal}
				className="get-text-from-url-modal-header"
			/>
			<ModalBody className="get-text-from-url-modal-body">
				{errorMessage &&
					<Alert
						color="danger"
						className="get-text-from-url-modal-body-alert"
					>{errorMessage}
					</Alert>}
				{isPriorPostedPostView ? null : <div>Enter a link and text for this image to be added when scheduling content to your google web stories</div>}
				<div className="content-container-attach">
					<div style={{ marginTop: '1rem' }}>
						<Input
							type="url"
							value={buttonUrl}
							placeholder={isPriorPostedPostView ? '' : 'Enter Link for Button'}
							className="input-text"
							required
							disabled={isPriorPostedPostView}
							onChange={(e) => {
								const newValue = e.target.value;
								setButtonUrl(newValue);
							}}
							invalid={buttonUrl && !buttonUrl.match(/(https:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)}
						/>
						<div style={{ display: 'flex' }}>
							<Input
								type="url"
								value={buttonText}
								placeholder={isPriorPostedPostView ? '' : 'Enter Text for Button'}
								className="input-text"
								required
								disabled={isPriorPostedPostView}
								onChange={(e) => {
									const newValue = e.target.value;
									if (newValue.length <= 12) {
										setButtonText(newValue);
									}
								}}
							/>
							<div>
								<div
									className="picker-swatch"
									style={{ cursor: isPriorPostedPostView ? 'auto' : 'pointer' }}
									onClick={() => (isPriorPostedPostView ? false : setShowButtonTextColorPicker(true))}
								>
									<div className="picker-color" style={{ backgroundColor: buttonTextColor }} />
								</div>
								{showButtonTextColorPicker ? <div className="picker-popover">
									<div className="picker-cover" onClick={() => setShowButtonTextColorPicker(false)} />
									<SketchPicker
										color={buttonColor}
										onChange={(color) => setButtonTextColor(color.hex)}
									/>
								</div> : null}
							</div>
						</div>
						<div style={{ display: 'flex' }}>
							<div style={{ flex: 1, textAlign: 'start', marginLeft: 4 }}>Button Color:</div>
							<div
								className="picker-swatch"
								onClick={() => (isPriorPostedPostView ? false : setShowButtonColorPicker(true))}
								style={{ cursor: isPriorPostedPostView ? 'auto' : 'pointer' }}
							>
								<div className="picker-color" style={{ backgroundColor: buttonColor }} />
							</div>
							{showButtonColorPicker ? <div className="picker-popover">
								<div className="picker-cover" onClick={() => setShowButtonColorPicker(false)} />
								<SketchPicker color={buttonColor} onChange={(color) => setButtonColor(color.hex)} />
							</div> : null}
						</div>
						<hr style={{ width: '50%' }} />
						<div style={{ display: 'flex' }}>
							<Input
								type="textarea"
								value={titleText}
								placeholder={isPriorPostedPostView ? '' : 'Enter Text to place on Image (max length 26)'}
								className="input-text"
								required
								disabled={isPriorPostedPostView}
								onChange={(e) => {
									const newValue = e.target.value;
									if (newValue.length <= 26) {
										setTitleText(newValue);
									}
								}}
								// invalid={true}
							/>
							<div>
								<div
									className="picker-swatch"
									onClick={() => (isPriorPostedPostView ? false : setShowTitleTextColorPicker(true))}
									style={{ cursor: isPriorPostedPostView ? 'auto' : 'pointer' }}
								>
									<div className="picker-color" style={{ backgroundColor: titleTextColor }} />
								</div>
								{showTitleTextColorPicker ? <div className="picker-popover">
									<div className="picker-cover" onClick={() => setShowTitleTextColorPicker(false)} />
									<SketchPicker color={buttonColor} onChange={(color) => setTitleTextColor(color.hex)} />
								</div> : null}
							</div>
						</div>
						<div style={{
							color: '#747B95',
							marginLeft: 8,
							fontSize: 16,
							textAlign: 'left',
						}}
						>
							{isPriorPostedPostView ? 'Selected' : 'Select'} Font Family
						</div>
						{isPriorPostedPostView ? <Input
							type="url"
							value={selectedTitleFont}
							className="input-text"
							disabled
						/> : <Dropdown
							isOpen={selectTitleFontDropDownOpen}
							toggle={() => setSelectTitleFontDropDownOpen(!selectTitleFontDropDownOpen)}
						>
							<DropdownToggle color="drop-down" caret className="select-wp-category-toggle">
								{selectedTitleFont}
							</DropdownToggle>
							<DropdownMenu onClick={() => setSelectTitleFontDropDownOpen(false)} className="select-wp-category-menu">
								<Scrollbars autoHeight>
									{fontsList.map(fontName =>
										(<div
											style={{
												margin: 'auto 0',
												paddingLeft: 8,
												color: '#747B95',
												textAlign: 'left',
												cursor: 'pointer',
											}}
											onClick={() => setSelectedTitleFont(fontName)}
										>
											{fontName}
										</div>))}
								</Scrollbars>
							</DropdownMenu>
						</Dropdown>}
					</div>
					<div
						style={{
							backgroundImage: `url(${imageUrl})`,
							backgroundPosition: 'left',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							width: 250,
							height: 320,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							overflow: 'hidden',
						}}
						className="image-to-text"
					>
						<div
							style={{
								color: titleTextColor,
								width: 200,
								margin: '0 auto',
								fontFamily: selectedTitleFont,
							}}
						>
							{titleText}
						</div>
						{buttonText ?
							<div
								style={{ margin: '10px 0' }}
							>
								<Button
									className="get-text-from-url-modal-footer-button"
									style={{
										backgroundColor: buttonColor,
										borderColor: buttonColor,
										color: buttonTextColor,
										width: 170,
										fontFamily: selectedTitleFont,
									}}
								>
									{buttonText}
								</Button>
							</div> : null}
					</div>
				</div>
			</ModalBody>
			<ModalFooter className="get-text-from-url-modal-footer">
				<Button
					color="light"
					className="get-text-from-url-modal-footer-button"
					onClick={closeModal}
				>
          CANCEL
				</Button>
				{isPriorPostedPostView ? null : <Button
					color="primary"
					className="get-text-from-url-modal-footer-button"
					style={{
						color: 'white',
					}}
					onClick={handleAddButtonClick}
				>
          ADD
				</Button>}
			</ModalFooter>
		</Modal>);
};


AttachImageMetadataModal.propTypes = {
	imageUrl: PropTypes.string.isRequired,
	isAttachImageMetadataModalOpen: PropTypes.bool.isRequired,
	changeAttachImageMetadataModalVisibility: PropTypes.func.isRequired,
};

export default AttachImageMetadataModal;
