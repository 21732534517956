/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';
// import { isTablet, isMobile } from 'react-device-detect';
// import { Scrollbars } from 'react-custom-scrollbars';

import { getEmojiAndHashtags } from './PredictiveLinguistics.action';
import './PredictiveLinguistics.css';
// import { getDifferenceBetweenStrings } from '../../textHelper';
// import { replaceTextBlock } from '../../editorHelper';

// let sendingForSuggestionValue = '';


// LARGE TODO: atm thi component still needed for emojis, in further may be totally removed.
class PredictiveLinguistics extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			suggestion: '',
			suggestionCurrentBlock: '',
			prevText: '',
		};
		this.myElement = React.createRef();
	}

	// componentDidMount() {
	// 	document.addEventListener('keydown', this.handleTabPress, false);
	// }

	componentWillReceiveProps(props) {
		const { suggestion } = this.state;
		const { text, editorState } = props;

		if (text === '' && text !== suggestion) {
			this.setState({ suggestion: '' });
			return;
		}

		if (text !== ' ' && text.trim() !== this.state.prevText.trim()) {
			this.setState({ suggestion: '', prevText: text });
			if (this.suggestionAbortController) {
				this.suggestionAbortController.abort();
			}
			if (this.emojiAndHashtagsAbortController) {
				this.emojiAndHashtagsAbortController.abort();
			}
			this.suggestionAbortController = new window.AbortController();

			// const selectionState = editorState.getSelection();
			// const anchorKey = selectionState.getAnchorKey();
			const startKey = editorState.getSelection().getStartKey();
			const selectedBlockText = editorState
				.getCurrentContent().getBlockForKey(startKey).getText();

			this.setState({
				/* clear last suggestion, it need  for correct work after pasted url */
				suggestionCurrentBlock: '',
			});

			// if (selectedBlockText && sendingForSuggestionValue !== selectedBlockText) {
			// 	this.debouncedGetSuggestion(selectedBlockText, editorState, anchorKey);
			// 	sendingForSuggestionValue = selectedBlockText;
			// }
			this.getEmojiAndHashtags(selectedBlockText);
		}

		if (props.scroll >= 0) {
			// this.setSuggestedTextScroll(props.scroll);
		}
	}

	componentWillUnmount() {
		// if (this.suggestionAbortController) {
		// 	this.suggestionAbortController.abort();
		// }
		if (this.emojiAndHashtagsAbortController) {
			this.emojiAndHashtagsAbortController.abort();
		}
		document.removeEventListener('keydown', this.handleTabPress, false);
	}

	setSuggestedTextScroll = (scroll) => {
		console.log(this.myElement.current);
		this.myElement.current.scrollTop(scroll);
	};

	getEmojiAndHashtags = (text) => {
		const { setEmojiList, emojiCount, updateSuggestionHashtags } = this.props;
		if (!setEmojiList || !text) {
			return;
		}

		setEmojiList([]);
		getEmojiAndHashtags(text, this.emojiAndHashtagsAbortController)
			.then((res) => {
				const { hashtags = {}, emojis = [] } = res;
				const list = emojis.length !== 0 ? emojis.slice(0, emojiCount) : undefined;
				setEmojiList(list);

				if (updateSuggestionHashtags) {
					updateSuggestionHashtags(hashtags, 'Suggested');
				}

				this.emojiAndHashtagsAbortController = null;
			})
			.catch(() => { setEmojiList(undefined); return false; });
	};

	// debouncedGetSuggestion = debounce((selectedBlockText, editorState, anchorKey) => {
	// 	this.suggestionAbortController = new window.AbortController();
	// 	getSuggestion(selectedBlockText, this.suggestionAbortController)
	// 		.then((res) => {
	// 			const suggestionText = getDifferenceBetweenStrings(selectedBlockText, res.suggestion);
	// 			const inputText = replaceTextBlock(editorState, anchorKey, suggestionText);

	// 			this.setState({
	// 				suggestion: inputText,
	// 				suggestionCurrentBlock: res.suggestion,
	// 			}, () => this.setSuggestedTextScroll(this.props.scroll));
	// 			this.suggestionAbortController = null;
	// 		})
	// 		.catch(() => this.setState({
	// 			suggestion: '',
	// 			suggestionCurrentBlock: '',
	// 		}));
	// }, 300);

	handleTabPress = (event) => {
		const { text, onConfirm } = this.props;
		const { suggestionCurrentBlock } = this.state;

		if (event.keyCode === 9 && suggestionCurrentBlock && text !== suggestionCurrentBlock) {
			event.preventDefault();
			onConfirm(suggestionCurrentBlock);
		}
	};

	render() {
		// const { suggestion, suggestionCurrentBlock } = this.state;
		// const { text, isVisible, onConfirm } = this.props;

		return null;

		// 	return (
		// 		<Scrollbars className="predictive-linguistics-scroll" ref={this.myElement} >
		// 			<div className={`predictive-linguistics-wrap ${isVisible ? '' : 'hidden'}`}>
		// 				{text !== '' && suggestion &&
		// 					<div className="predictive-linguistics-content">
		// 						{isMobile || isTablet ?
		// 							<div
		// 								className="predictive-linguistics-mobile"
		// 							>
		// 								<div
		// 									className="mobile-content"
		// 									// use onMouseDown, as we need to work before parent onBlur
		// 									onMouseDown={() => onConfirm(suggestion)}
		// 								>
		// 									{suggestion.substring(text.length)}
		// 								</div>
		// 							</div> :
		// 							<div className="predictive-linguistics-desktop">
		// 								{suggestion}
		// 								{suggestionCurrentBlock.length > 0 ? <span className="predictive-linguistics-tab">tab</span> : null}
		// 							</div>
		// 						}
		// 					</div>
		// 				}
		// 			</div>
		// 		</Scrollbars>
		// 	);
		// }
	}
}

export default PredictiveLinguistics;
