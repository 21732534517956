/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React from 'react';

import loaderDot from '../../../images/loaderDot.svg';
import './Loader.css';

class Loader extends React.Component {
	render() {
		const { style = {} } = this.props;
		return (
			<div className="app-loader-container" style={style}>
				<div className="loader-container">
					<img src={loaderDot} alt="loader-dot" />
					<img src={loaderDot} alt="loader-dot" />
					<img src={loaderDot} alt="loader-dot" />
					<img src={loaderDot} alt="loader-dot" />
				</div>
			</div>
		);
	}
}

export default Loader;
