/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import Loader from '../LoaderAnimation/Loader';

import './TextFromImageConfirmModal.css';

const TextFromImageConfirmModal = ({
	imageUrl,
	textFromUrl,
	isTextFromImageModalOpen,
	changeTextFromImageModalVisibility,
	isDataLoading,
	addTextFromImageToPostMessage,
}) => {
	const closeModal = () => {
		changeTextFromImageModalVisibility(false);
	};

	const handleAddButtonClick = () => {
		addTextFromImageToPostMessage(textFromUrl);
		closeModal();
	};

	return (
		<Modal
			isOpen={isTextFromImageModalOpen}
			toggle={closeModal}
			className="get-text-from-url-modal"
		>
			<ModalHeader
				toggle={closeModal}
				className="get-text-from-url-modal-header"
			/>
			<ModalBody className="get-text-from-url-modal-body">
				{!isDataLoading && !textFromUrl &&
			<Alert
				color="danger"
				className="get-text-from-url-modal-body-alert"
			>There are wasn&apos;t detected any text from this image
			</Alert>}
				<img
					src={imageUrl}
					alt="selected-pick"
					className="image-to-text"
				/>
				<div>Do you want to add this text to previously written post text?</div>
				<div className="get-text-from-url-modal-body-text">
					{isDataLoading ? <Loader /> : textFromUrl}
				</div>
			</ModalBody>
			<ModalFooter className="get-text-from-url-modal-footer">
				<Button
					color="light"
					className="get-text-from-url-modal-footer-button"
					onClick={closeModal}
				>
				CANCEL
				</Button>
				<Button
					color="primary"
					className="get-text-from-url-modal-footer-button"
					onClick={handleAddButtonClick}
					disabled={isDataLoading || !textFromUrl}
				>
				ADD
				</Button>
			</ModalFooter>
		</Modal>);
};


TextFromImageConfirmModal.propTypes = {
	imageUrl: PropTypes.string.isRequired,
	textFromUrl: PropTypes.string.isRequired,
	isTextFromImageModalOpen: PropTypes.bool.isRequired,
	changeTextFromImageModalVisibility: PropTypes.func.isRequired,
	isDataLoading: PropTypes.bool.isRequired,
};

export default TextFromImageConfirmModal;
