/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import moment from 'moment-timezone';

const getRoundedMinutes = (minutes, hours) => {
	const isNextHour = +minutes > 50;
	const roundedMinutes = (Math.round(minutes / 15) * 15) % 60;
	const minutesToShow = roundedMinutes === 0 ? '00' : roundedMinutes;
	const hoursToShow = (minutesToShow === 0 && isNextHour) ? +hours + 1 : hours;
	return `${hoursToShow}:${minutesToShow}`;
};

export function getUTCDateWithSettingTimezone(date, time, timezone, format) {
	const dateString = date && time ? `${date} ${time}` : date;

	if (format) {
		return moment.tz(dateString, timezone).format(format);
	}
	console.log(dateString, 'dateString');
	return new Date(moment.tz(dateString, timezone).format()).toISOString();
}

export function getDateWithSpecialTimezone(date, time, timezone) {
	return moment(`${date} ${time}`).tz(timezone).format('YYYY-MM-DD HH:mm');
}

export function getNewDateWithoutSettingTimezone(date, timezone = moment.tz.guess()) {
	return {
		date: moment(date).tz(timezone).format('YYYY-MM-DD'),
		time: moment(date).tz(timezone).format('HH:mm'),
	};
}

export function getLocalDate(timezone, date = moment()) {
	const currentUtcOffset = moment().utcOffset();
	const userUtcOffset = moment().tz(timezone).utcOffset();
	return moment(date).subtract(currentUtcOffset - userUtcOffset, 'minutes');
}

export const getDefaultTimeForSchedule = () => {
	const hourForSchedulePosts = moment().subtract(2, 'hour').format('HH');
	const minutesForSchedulePosts = moment().subtract(2, 'hour').format('mm');
	return getRoundedMinutes(minutesForSchedulePosts, hourForSchedulePosts);
};

export const getRangeCampaignDates = (startDate, endDate) => {
	const dates = [];
	let tempStartDate = startDate;

	while (new Date(tempStartDate) <= new Date(endDate)) {
		dates.push(new Date(tempStartDate).toDateString());
		tempStartDate = moment(tempStartDate).add(1, 'days');
	}

	return dates;
};
