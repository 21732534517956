import { admin, viewer } from '../constants/rolesAndAuth';

export const checkSocialMediaRights = (person, currentCalendar) => {
	if (person.role === viewer) {
		return false;
	} else if (person.role === admin || person.role === undefined) {
		return true;
	}
	const hasAccess = person.access && person.access.calendars.includes(currentCalendar);
	return hasAccess;
};

export const checkLibraryCategoryCreateOrDeleteRights = (person) => {
	if (person.role === undefined || person.role === 'admin') {
		return true;
	}
	return false;
};


export const checkLibraryCategoryEditRights = (person) => {
	if (person.role === undefined || person.role === 'admin' || person.role === 'editor') {
		return true;
	}
	return false;
};

export const checkCalendarCreateOrDeleteRights = (person) => {
	if (person.role === undefined || person.role === 'admin') {
		return true;
	}
	return false;
};

export const checkCalendarEditRights = (person) => {
	if (person.role === undefined || person.role === 'admin' || person.role === 'editor') {
		return true;
	}
	return false;
};
