/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Fragment /* useState, useEffect, useRef */ } from 'react';
/* import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'; */
import ReactTooltip from 'react-tooltip';

/* import * as selectors from '../../../selectors';

import { getAllLanguages } from '../../../actions/translator';

import { getTranslatedText, getCurrentLanguage } from './LanguageTranslator.actions';

import { useDebounce } from '../../../helpers/debounce'; */

import './LanguageTranslator.css';

const LanguageTranslatorPlug = () => (
	<Fragment>
		<div className="language-translator-container-plug">
			<div
				data-tip="Coming soon"
				data-for="translate-coming-soon"
			>
				Translator
			</div>
		</div>
		<ReactTooltip
			id="translate-coming-soon"
			effect="solid"
			place="left"
		/>
	</Fragment>
);

/* const LanguageTranslator = ({
	text, translationData = [], getAllLanguagesAction, setTranslatedText,
}) => {
	useEffect(() => {
		getAllLanguagesAction();
	}, []);
	const [isPopoverOpen, setPopoverOpen] = useState(false);
	const [currentLanguageForTranslate, changeLanguageForTranslate] = useState('English');
	const [currentLanguage, changeCurrentLanguage] = useState('English');

	const arrayOfLanguages = translationData.response ? translationData.response.result[0] : [];

	const abortControllerRef = useRef(new window.AbortController());

	useDebounce(() => {
		if (text && text.length >= 3) {
			// TODO add logic for aborting request with abortControllerRef
			getCurrentLanguage(text, abortControllerRef.current)
				.then(languageData => {
					const languageCode = languageData[0].language;
					const detectedLanguage = arrayOfLanguages.find(language => language.code === languageCode);
					if (detectedLanguage) {
						changeCurrentLanguage(detectedLanguage.name);
					}
				})
				.catch(error => console.log(error));
		}
	}, 300, [text]);

	const textToTranslate = languageCode => {
		if (text) {
			getTranslatedText(text, languageCode)
				.then(translatedText => setTranslatedText(translatedText));
		}
	};

	return (
		<div className="language-translator-container">
			<div className="current-language">{currentLanguage}</div>
			<div id="languageSelectPopover" onClick={() => setPopoverOpen(!isPopoverOpen)}>{currentLanguageForTranslate}</div>
			{isPopoverOpen &&
			<div className="all-languages">
				{arrayOfLanguages.map(language => (
					<div
						className="all-languages-item"
						key={language.code}
						onClick={() => {
							textToTranslate(language.code);
							changeLanguageForTranslate(language.name);
							setPopoverOpen(false);
						}}
					>{language.name}
					</div>))}
			</div>
			}
		</div>
	);
};

LanguageTranslator.propTypes = {
	text: PropTypes.string.isRequired,
	translationData: PropTypes.shape({
		allLanguages: PropTypes.arrayOf(PropTypes.string),
	}).isRequired,
	getAllLanguagesAction: PropTypes.func.isRequired,
	setTranslatedText: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	translationData: selectors.getAllLanguages(state),
});

const mapDispatchToProps = dispatch => ({
	getAllLanguagesAction: bindActionCreators(getAllLanguages, dispatch),
}); */

// export default connect(mapStateToProps, mapDispatchToProps)(LanguageTranslator);
export default LanguageTranslatorPlug;
