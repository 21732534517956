/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component, Fragment } from 'react';
import * as Sentry from '@sentry/react';

import AppPage from './components/AppPage/AppPage';
import AppErrorPage from './components/AppErrorPage/AppErrorPage';
import AppMaintenancePage from './components/AppMaintenancePage/AppMaintenancePage';

class App extends Component {
	constructor() {
		super();
		this.state = {
			underMaintenance: false,
			isNeedShowCopyright: true,
		};
	}

	componentDidMount() {
		 const hide = () => {
			window.zE('webWidget', 'hide');
		};
		hide();
		fetch('/maintenance').then(r => r.json()).then(response => {
			this.setState({ underMaintenance: response.underMaintenance });
		});
	}

	setShowCopyright = (show) => {
		this.setState({
			isNeedShowCopyright: show,
		});
	};
	render() {
		let content = (
			<Fragment>
				<Sentry.ErrorBoundary fallback={(err) => <AppErrorPage err={err} />}>
					<AppPage setShowCopyright={this.setShowCopyright} />
				</Sentry.ErrorBoundary>
				{this.state.isNeedShowCopyright && <footer style={{ width: '100%', textAlign: 'center' }}>
					Copyright {new Date().getFullYear()} © Woofy, Inc.
				</footer>}
			</Fragment>);
		if (this.state.underMaintenance) {
			content = (
				<Fragment>
					<AppMaintenancePage />
					<footer style={{ width: '100%', textAlign: 'center' }}>
						Copyright {new Date().getFullYear()} © Woofy, Inc.
					</footer>
				</Fragment>);
		}
		return content;
	}
}

export default App;
