import React, { Fragment } from 'react';

import woofyAppError from '../../images/woofy-app-error.svg';

const AppMaintenancePage = () => (
	<div style={{
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'center',
		padding: '50px',
	}}
	>
		<img src={woofyAppError} alt="Error" style={{ height: 100, width: 200 }} />
		<span style={{
			fontStyle: 'normal',
			fontWeight: 600,
			fontSize: '24px',
			lineHeight: '29px',
			textAlign: 'center',
			color: '#747B95',
			paddingTop: '19.5px',
		}}
		>
			<Fragment>
				<article>HelloWoofy is under Maintenance. Please check back in some time.</article>
			</Fragment>
		</span>
	</div>);

export default AppMaintenancePage;
