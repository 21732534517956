/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

export const SESSION_LOGIN = '/api/session/login';
export const SESSION_REGISTER = '/api/session/register';
export const SESSION_RECOVER_PASSWORD = '/api/session/recover-password';
export const SESSION_CHANGE_PASSWORD = '/api/session/change-password';
export const SESSION_LOGOUT = '/api/session/logout';

export const CAMPAIGN_URL = '/api/campaigns';
export const POST_URL = '/api/posts';
export const JOURNAL_POSTS_URL = '/api/journal';
export const CALENDAR_URL = '/api/calendars';

// export const MEDIA_SERVICE_URL = 'https://woofy-demo.fluvius.co/media-uploader';
// export const IMAGE_UPLOADER_URL = `${MEDIA_SERVICE_URL}/api/media/image/upload`;
// export const VIDEO_UPLOADER_URL = `${MEDIA_SERVICE_URL}/api/media/video/upload`;
// export const AUDIO_UPLOADER_URL = `${MEDIA_SERVICE_URL}/api/media/audio/upload`;

export const GENERATE_PRESIGNED_URL = '/api/files/generate-signed-url';
export const DELETE_FILES_URL = '/api/files/delete-files';
export const IMAGE_UPLOADER_URL = '/api/upload-image';
export const VIDEO_UPLOADER_URL = '/api/upload-video';
export const AUDIO_UPLOADER_URL = '/api/upload-audio';

export const IMAGE_UPLOADER_BY_URL = '/api/upload-image-url';
export const ACCOUNT_URL = '/api/accounts';
export const ACCOUNTS_COUNT = '/api/accounts/count';
export const USER_URL = '/api/user';

export const DISCOVER_URL = '/api/discover';

export const ARTICLE_URL = '/api/articles';
export const LIBRARY_POSTS_URL = '/api/library/posts';
export const LIBRARY_POSTS_BULK_DELETE_URL = '/api/library/bulk-delete';
export const LIBRARY_POSTS_BULK_CHANGE_CATEGORY_URL = '/api/library/change-category';
export const LIBRARY_CATEGORIES_URL = '/api/library/categories';
export const LIBRARY_RSS_URL = '/api/library/rss-feeds';
export const LIBRARY_RSS_PAUSED_URL = '/api/library/rss-feeds-paused';
export const LOAD_RSS_URL = '/api/library/rss-load';
export const LIBRARY_CSV_URL = '/api/library/import-csv';
export const LIBRARY_XLSX_URL = '/api/library/import-xlsx';
export const LIBRARY_POST_BATCH_URL = '/api/library/post-batch';
export const COMPLIANCE_LIBRARY_URL = '/api/compliance-engine/library-post';
export const WORDS_API_URL = '/api/words-api';
export const PREDICTIVE_SUGGESTION_API_URL = '/api/predictive';
export const PREDICTIVE_EMOJI_HASHTAGS_API_URL = '/api/predictive/emoji-hashtags';
export const PREDICTIVE_SUMMARIZE_TEXT = '/api/predictive/summarize-text';
export const IMAGE_SUGGESTIONS_URL = '/api/image-suggestions';

export const STATS_URL = '/api/stats';
export const TEAM_MEMBERS_URL = '/api/team/members';
export const HASHTAG_GROUPS_URL = '/api/hashtag-groups';
export const HASHTAGS_URL = '/api/hashtags';
export const CONTENT_ENGINES_URL = '/api/content-engines';

export const CONFIG_URL = '/api';
export const SENTRY_CONFIG_URL = '/api/sentry-config';
export const SETTINGS_URL = '/api/settings';
export const NOTIFICATIONS_URL = '/api/notifications';
export const SEND_REFERRAL_EMAIL_URL = '/api/user/send-referral-email';

export const FACEBOOK_URL = '/api/facebook';
export const INSTAGRAM_BUSINESS_URL = '/api/instagram-business';
export const LINKEDIN_URL = '/api/linkedin';
export const HOOTSUITE_URL = '/api/hootsuite';
export const GOOGLE_MB_URL = '/api/googleMB';
export const YOUTUBE_URL = '/api/youtube';
export const PINTEREST_URL = '/api/pinterest';
export const MAILCHIMP_URL = '/api/mailchimp';

export const STRIPE_CHECKOUT_URL = '/api/checkout/stripe';
export const PAYPAL_SUCCESS_URL = '/api/checkout/paypal/success';
export const RECURLY_CANCEL__URL = '/api/payment/recurly/cancel';
export const RECURLY_CHANGE_SUBSCRIPTION_URL = '/api/payment/recurly/change';
export const RECURLY_ACTIVATE_SUBSCRIPTION_URL = '/api/payment/recurly/activate';
export const RECURLY_PREVIEW_SUBSCRIPTION_URL = '/api/payment/recurly/preview';
export const RECURLY_COUPON_CODE_URL = '/api/payment/recurly/coupon-code';
export const RECURLY_GET_PLANS = '/api/payment/plans';
export const RECURLY_CREATE_SUBSCRIPTION = '/api/payment/subscription';
export const RECURLY_CHECK_PLAN_LIMITS = '/api/payment/check-plan-limits';
export const RECURLY_GET_LIMITS = '/api/payment/limits';

export const DISCOUNT_URL = '/api/discount';

export const DIALOGFLOW_AUDIO_URL = '/api/dialog-flow/audio';

export const EXTENSION_SETTINGS_URL = '/api/extension-settings';

export const TRANSLATION_URL = '/api/google-service/translate';
export const DETECT_LANGUAGE_URL = '/api/google-service/detect-language';
export const DETECT_ANNOTATION_FROM_IMAGE_URL = '/api/google-service/detect-annotation-from-image-url';
export const GET_TEXT_FROM_IMAGE_URL = '/api/google-service/get-text-from-image-url';

export const GET_HOME_PAGE_BANNER = '/api/home-page-banner';

export const GET_HOME_PAGE_VIDEO_BANNER = '/api/home-page-banner/video';

export const DETECT_TEXT_READING_TIME = '/api/detect-text-reading-time/detect-time';

export const CREATE_TIKTOK_ACCOUNT_URL = '/api/create-tiktok-account';

export const CREATE_WIX_ACCOUNT_URL = '/api/wix';
export const CREATE_WEEBLY_ACCOUNT_URL = '/api/weebly';
export const CREATE_SQUARESPACE_ACCOUNT_URL = '/api/squarespace';
export const CREATE_THREADS_ACCOUNT_URL = '/api/threads';

export const SNAPCHAT_URL = '/api/snapchat';

export const WORDPRESS_URL = '/api/wordpress';
export const SHOPIFY_URL = '/api/shopify';

export const GENERATE_CAMPAIGNS_DATES_URL = '/api/generate-campaigns-dates';

export const GET_IMAGES_BY_URL = '/api/get-images-by-url';

export const GET_SHOPIFY_INSTALL_DATA = '/api/shopify/install/data';

export const ACTIVATE_SHOPIFY_INSTALL = '/api/shopify/install/activate';

export const ACTIVATE_CREATOR_ACCOUNT_URL = '/api/smart-device/random-code';
export const DISCOVER_CREATORS = '/api/creators';
export const DISCOVER_MY_CREATOR_DATA = '/api/creators/my';
export const DISCOVER_CREATORS_SOCIALS = '/api/creators/socials';
export const DISCOVER_CREATORS_BIOGRAPHY = '/api/creators/biography';
export const DISCOVER_CREATORS_BRIEFINGS = '/api/creators/briefings';
export const DISCOVER_CREATORS_WATCH_PROGRESS = '/api/creators/watch';
export const DISCOVER_CREATORS_SUBSRIPTIONS = '/api/creators/sub';
export const DISCOVER_CREATORS_FOLLOW = '/api/creators/follow';
export const DISCOVER_CREATORS_UNFOLLOW = '/api/creators/unfollow';
export const DISCOVER_CREATORS_WL_CREATE_SKILL = '/api/smart-device/create-wl';
export const DISCOVER_CREATORS_WL_UPLOAD_CODE = '/api/smart-device/skill/s3';
export const DISCOVER_CREATORS_WL_CREATE_LAMBDA = '/api/smart-device/skill/lambda';
export const DISCOVER_CREATORS_WL_CERTIFICATION = '/api/smart-device/skill/certification';
export const DISCOVER_CREATORS_WL_VALIDATION = '/api/smart-device/skill/validation';
export const DISCOVER_CREATORS_WL_UPDATE_SKILL = '/api/smart-device/skill';
export const DISCOVER_CREATORS_WL_DELETE_SKILL = '/api/smart-device/skill';

export const GET_USER_BILLING_INFORMATION_LINK = '/api/user/billing-information-link';
export const GET_ALL_SHOP_PRODUCTS = '/api/products';

export const CREATE_WHATSAPP_GROUP_URL = '/api/create-whatsapp-group';

export const GET_ALL_BANNER_BEAR_TEMPLATES = '/api/bannerbear/all-template';
export const GET_IMAGE_FROM_BANNER_BEAR = '/api/bannerbear/get-image';
export const POST_CRETAE_IMAGE_FROM_BANNER_BEAR_TEMPLATE = '/api/bannerbear/create-image';
export const GET_BANNER_BEAR_USAGE = '/api/bannerbear/usage';

export const CHAT_GPT_TITLE_GENERATION = '/api/chat-gpt/title-generation';
export const CHAT_GPT_CAPTION_GENERATION = '/api/chat-gpt/caption-generation';
export const CHAT_GPT_SUMMARY_GENERATION = '/api/chat-gpt/summary-generation';
export const CHAT_GPT_POST_FROM_VIDEO_GENERATION = '/api/chat-gpt/video-post-generation';
export const CHAT_GTP_USAGE = '/api/chat-gpt/usage';
