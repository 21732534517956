/* eslint-disable no-unused-expressions */
/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

// import { getToken } from '../../common/auth';
import { get, getProps, post, put, httpDelete } from '../../common/httpRequests';
import {
	LIBRARY_POSTS_URL,
	COMPLIANCE_LIBRARY_URL,
	LIBRARY_POSTS_BULK_DELETE_URL,
	LIBRARY_POSTS_BULK_CHANGE_CATEGORY_URL,
	// DELETE_FILES_URL
} from '../../common/apiRouting';
import { getCalendar } from '../../common/calendarHelper';

export function deleteFiles() {
	return Promise.resolve();
	// filesList = filesList.filter(file => file);
	// if (!filesList || filesList.length === 0) return Promise.resolve();
	// return fetch(`${DELETE_FILES_URL}`, {
	// 	method: 'POST',
	// 	headers: {
	// 		Authorization: getToken(),
	// 		'Content-type': 'application/json',
	// 	},
	// 	body: JSON.stringify({
	// 		files: filesList,
	// 	}),
	// }).then(res => res.json()).then(() => Promise.resolve()).catch((err) => Promise.reject(err));
}

export function getLibraryPosts(options) {
	let params = '?';
	Object.keys(options).forEach((key) => {
		if (options[key]) {
			if (options[key].length || typeof options[key] === 'number') {
				params = `${params + key}=${options[key]}&`;
			}
		}
	});

	return get(`${LIBRARY_POSTS_URL + params}calendarId=${getCalendar()}`, true)
		.then(res => res.json());
}

export function createLibraryPost(currentPost) {
	const newPost = currentPost;
	newPost.variations = newPost.variations.filter(el => el.message !== '');
	newPost.calendarId = getCalendar();
	newPost.images = newPost.images.filter(img => img);

	const data = getProps(newPost, [
		'calendarId',
		'message',
		'variations',
		'categories',
		'hashtags',
		'imageHashtags',
		'images',
		'draft',
		'video',
		'complianceEngineRules',
		'postTitle',
		'callToAction',
		'mediaMetadata',
	]);

	// eslint-disable-next-line no-unused-expressions
	data.video && Object.keys(data.video).length === 0 && delete data.video;
	data.images && Object.keys(data.images).length === 0 && delete data.images;

	return post(LIBRARY_POSTS_URL, data, true)
		.then(res => res.json());
}

export function updateLibraryPost(currentPost) {
	const updatedPost = currentPost;
	updatedPost.variations = updatedPost.variations.filter(el => el.message !== '');
	updatedPost.calendarId = getCalendar();
	updatedPost.images = updatedPost.images.filter(img => img);

	const data = getProps(updatedPost, [
		'calendarId',
		'message',
		'variations',
		'categories',
		'hashtags',
		'imageHashtags',
		'images',
		'pending',
		'rejected',
		'draft',
		'expirationDate',
		'video',
		'complianceEngineRules',
		'postTitle',
		'callToAction',
		'mediaMetadata',
	]);

	return put(`${LIBRARY_POSTS_URL}/${updatedPost._id}`, {
		...data,
	}, true)
		.then(res => res.json());
}

export function deleteLibraryPost(id) {
	return httpDelete(`${LIBRARY_POSTS_URL}/${id}`, true);
}

export function bulkDeleteLibraryPosts(arrayOfIds) {
	return post(`${LIBRARY_POSTS_BULK_DELETE_URL}`, { arrayOfIds }, {})
		.then(res => res.json());
}

export function getLibraryPostSimilarity(libraryPostId) {
	return get(`${COMPLIANCE_LIBRARY_URL}?libraryPostId=${libraryPostId}`, true)
		.then(res => res.json());
}

export function bulkChangeLibraryPostsCategory(arrayOfLibraryPostsIds, arrayOfLibraryCategoriesIds) {
	return post(`${LIBRARY_POSTS_BULK_CHANGE_CATEGORY_URL}`, { arrayOfLibraryPostsIds, arrayOfLibraryCategoriesIds }, {})
		.then(res => res.json());
}

export function getLibraryPostById(postId) {
	return get(`${LIBRARY_POSTS_URL}/${postId}?calendarId=${getCalendar()}`, true)
		.then(res => res.json()).then(data => data.post);
}
