import { useRef, useLayoutEffect } from 'react';
import _throttle from 'lodash.throttle';

let supportsPassive = false;
try {
	const opts = Object.defineProperty({}, 'passive', {
		get() {
			supportsPassive = true;
			return true;
		},
	});
	window.addEventListener('testPassive', null, opts);
	window.removeEventListener('testPassive', null, opts);
} catch (e) {
	console.info('This browser does not support passive events');
}
const getPosition = () => ({
	x: window.pageXOffset,
	y: window.pageYOffset,
});
export default function useWindowScrollPosition(effect = () => {}, { throttle = 100 }, deps = []) {
	const position = useRef(getPosition());
	useLayoutEffect(() => {
		const handleScroll = _throttle(() => {
			position.current = getPosition();
			effect(position.current);
		}, throttle);
		window.addEventListener(
			'scroll',
			handleScroll,
			supportsPassive ? { passive: true } : false,
		);
		return () => {
			handleScroll.cancel();
			window.removeEventListener('scroll', handleScroll);
		};
	}, deps);
	return position;
}
