/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment-timezone';

import {
	Dropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
	Input,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { Scrollbars } from 'react-custom-scrollbars';
import './ManageCTA.css';
import postWarningTooltip from '../../../images/postWarningTooltip.svg';
import { urlRegexp } from '../../../common/textHelper';


// import { getHashtagGroups, getHashtags } from './ManageCTA.action';

const callToActionTypes = {
	LEARN_MORE: 'Learn More',
	BOOK: 'Book',
	ORDER: 'Order',
	SHOP: 'Shop',
	SIGN_UP: 'Sign Up',
	CALL: 'Call Us',
	OFFER: 'Offer',
};

function isOfferType(type) {
	return type === 'OFFER';
}

function ManageCTA(props) {
	const [isOpen, toggleOpen] = useState(false);
	const [isDurationToggleOpen, setDurationToggleOpen] = useState(false);
	const [isUnitToggleOpen, setUnitToggleOpen] = useState(false);

	const [callToActionType, setCallToActionType] = useState('default');
	const [callToActionUrl, setCallToActionUrl] = useState('');
	const [urlValid, setUrlValid] = useState(false);
	const [offerRedeemUrl, setOfferRedeemUrl] = useState('');
	const [urlRedeemValid, setRedeemUrlValid] = useState(false);
	const [offerCouponCode, setOfferCouponCode] = useState('');
	const [offerTermAndCond, setOfferTermAndCond] = useState('');

	const [durationValue, setDurationValue] = useState(1);
	const [unitValue, setUnitValue] = useState('hours');

	useEffect(() => {
		if (
			props.isEditPost &&
			props.isEditPost.event &&
			props.isEditPost.event.post &&
			props.isEditPost.event.post.callToAction &&
			props.isEditPost.event.post.callToAction.actionType !== 'default'
		) {
			const callToActionData = props.isEditPost ? props.isEditPost.event.post.callToAction : {};
			const offerData = callToActionData.offer || {};
			console.log('CTA for edit: ', callToActionData);
			setCallToActionUrl(callToActionData.url);
			setUrlValid(true);
			setCallToActionType(callToActionData.actionType);
			setOfferRedeemUrl(offerData.redeemOnlineUrl || '');
			setOfferCouponCode(offerData.couponCode || '');
			setOfferTermAndCond(offerData.termsConditions || '');
			setUnitValue(offerData.unit || 'hours');
			setDurationValue(offerData.duration || 1);
		} else {
			setCallToActionUrl('');
			setUrlValid(false);
			setCallToActionType('default');
		}
	}, []);

	let mainInputPlaceHolder = 'Enter Action URL';
	switch (callToActionType) {
	case callToActionTypes.CALL.toUpperCase():
		mainInputPlaceHolder = 'Phone number in your GMB account will be used.';
		break;
	case callToActionTypes.OFFER.toUpperCase():
		mainInputPlaceHolder = 'Terms and conditions';
		break;
	default:
		mainInputPlaceHolder = 'Enter Action URL';
		break;
	}

	return (
		<Fragment>
			<div className="d-flex justify-space-between">
				<div className="w-100 cta-url-div">
					<Input
						type="url"
						value={isOfferType(callToActionType) ? offerTermAndCond : callToActionUrl}
						disabled={callToActionType === 'CALL'}
						placeholder={mainInputPlaceHolder}
						style={{ backgroundColor: '#f2f3f7', border: 'none' }}
						required={() => callToActionType !== 'default' || 'CALL'}
						onChange={(e) => {
							const newValue = e.target.value;
							let ctaData = {};
							if (isOfferType(callToActionType)) {
								setOfferTermAndCond(newValue);
								ctaData = {
									actionType: callToActionType,
									offer: {
										unit: unitValue,
										duration: durationValue,
										redeemOnlineUrl: offerRedeemUrl,
										couponCode: offerCouponCode,
										termsConditions: newValue,
									},
								};
							} else {
								setCallToActionUrl(newValue);
								ctaData = {
									url: newValue,
									actionType: callToActionType,
								};
								const isValid = !!newValue.match(urlRegexp);
								setUrlValid(isValid);
							}

							props.setCallToAction(ctaData);
						}}
						valid={isOfferType(callToActionType) ? undefined : urlValid}
						invalid={callToActionUrl ? !urlValid : false}
					/>
					{isOfferType(callToActionType) && <div style={{ display: 'flex', marginTop: 6 }}>
						<Input
							type="url"
							value={offerRedeemUrl}
							placeholder="Redeem online url"
							className="gmb-offer-redeem-online-url-input"
							required={false}
							onChange={(e) => {
								const newValue = e.target.value;
								setOfferRedeemUrl(newValue);
								props.setCallToAction({
									actionType: callToActionType,
									offer: {
										unit: unitValue,
										duration: durationValue,
										redeemOnlineUrl: newValue,
										couponCode: offerCouponCode,
										termsConditions: offerTermAndCond,
									},
								});

								const isValid = !!newValue.match(/^(?:(?:https?|ftp|www):\/\/)?[\w/\-?=%.]+$/g);
								setRedeemUrlValid(isValid);
							}}
							valid={!isOfferType(callToActionType) ? undefined : urlRedeemValid}
							invalid={offerRedeemUrl ? !urlRedeemValid : false}
						/>
						<Input
							type="url"
							value={offerCouponCode}
							placeholder="Coupon code"
							style={{ backgroundColor: '#f2f3f7', border: 'none', marginLeft: 6 }}
							required={false}
							onChange={(e) => {
								const newValue = e.target.value;
								setOfferCouponCode(newValue);
								props.setCallToAction({
									actionType: callToActionType,
									offer: {
										unit: unitValue,
										duration: durationValue,
										redeemOnlineUrl: offerRedeemUrl,
										couponCode: newValue,
										termsConditions: offerTermAndCond,
									},
								});
							}}
						/>
					</div>}
				</div>
				<div className="w-100 cta-drop-down-div">
					<div>
						<Dropdown
							isOpen={isOpen}
							toggle={() => toggleOpen(!isOpen)}
							id="call-to-action-dropdown"
							className="w-100"
						>
							<DropdownToggle color="drop-down" caret>
								<div className="drop-down-toggle">
									{callToActionType === 'default' ? 'Select Action Type' : callToActionTypes[callToActionType]}
								</div>
							</DropdownToggle>
							<DropdownMenu className="drop-down-menu" value={callToActionType}>
								<Scrollbars style={{ width: '100%' }} autoHeight>
									{callToActionType !== 'default' && (
										<DropdownItem
											onClick={() => {
												setCallToActionType('default');
												setCallToActionUrl('');
												setUrlValid(false);
												props.setCallToAction({
													url: '',
													actionType: 'default',
												});
											}}
										>
									Select Action Type
										</DropdownItem>)}
									{Object.keys(callToActionTypes).map(ctaType => (
										<DropdownItem
											key={ctaType}
											onClick={() => {
												setCallToActionType(ctaType);
												setOfferCouponCode('');
												setOfferRedeemUrl('');
												setOfferTermAndCond('');
												if (ctaType === 'CALL' || isOfferType(ctaType)) {
													setCallToActionUrl('');
													setUrlValid(false);
												}
												props.setCallToAction({
													url: ctaType === 'CALL' || isOfferType(ctaType) ? '' : callToActionUrl,
													actionType: ctaType,
												});
											}}
										>
											{callToActionTypes[ctaType]}
										</DropdownItem>
									))}
								</Scrollbars>
							</DropdownMenu>
							<div
								id="cta-warning-tooltip"
								data-tip=""
								data-for="cta-warning"
								style={{
									margin: 'auto 0',
									marginLeft: 10,
								}}
							>
								<img
									src={postWarningTooltip}
									alt="cta-warning-tooltip"
									className="warning-tooltip-icon"
								/>
							</div>
						</Dropdown>
					</div>
					{isOfferType(callToActionType) &&
						<div>
							<div style={{ display: 'flex', marginTop: 8, marginLeft: 16 }}>
								<div style={{ margin: 'auto 0' }} >Duration</div>
								<Dropdown
									isOpen={isDurationToggleOpen}
									toggle={() => setDurationToggleOpen(!isDurationToggleOpen)}
									id="call-to-action-dropdown"
									className="call-to-action-dropdown-duration"
								>
									<DropdownToggle color="drop-down" caret>
										<div style={{ paddingRight: 8, width: '100%' }} className="drop-down-toggle">
											{durationValue}
										</div>
									</DropdownToggle>
									<DropdownMenu className="drop-down-menu" value={durationValue}>
										<Scrollbars style={{ width: '100%' }} autoHeight>
											{[...new Array(30)].map((value, index) => (
												<DropdownItem
													key={value + 1}
													onClick={() => {
														setDurationValue(index + 1);
														props.setCallToAction({
															actionType: callToActionType,
															offer: {
																duration: index + 1,
																unit: unitValue,
																redeemOnlineUrl: offerRedeemUrl,
																couponCode: offerCouponCode,
																termsConditions: offerTermAndCond,
															},
														});
													}}
												>
													{index + 1}
												</DropdownItem>
											))}
										</Scrollbars>
									</DropdownMenu>
								</Dropdown>
								<Dropdown
									isOpen={isUnitToggleOpen}
									toggle={() => setUnitToggleOpen(!isUnitToggleOpen)}
									id="call-to-action-dropdown"
									className="call-to-action-dropdown-unit"
								>
									<DropdownToggle color="drop-down" caret>
										<div style={{ paddingRight: 8, width: '100%' }} className="drop-down-toggle">
											{unitValue}
										</div>
									</DropdownToggle>
									<DropdownMenu className="drop-down-menu" value={unitValue}>
										<Scrollbars style={{ width: '100%' }} autoHeight>
											{['hours', 'days', 'weeks'].map((value) => (
												<DropdownItem
													key={value}
													onClick={() => {
														setUnitValue(value);
														props.setCallToAction({
															actionType: callToActionType,
															offer: {
																duration: durationValue,
																unit: value,
																redeemOnlineUrl: offerRedeemUrl,
																couponCode: offerCouponCode,
																termsConditions: offerTermAndCond,
															},
														});
													}}
												>
													{value}
												</DropdownItem>
											))}
										</Scrollbars>
									</DropdownMenu>
									<div
										id="cta-warning-tooltip"
										data-tip=""
										data-for="cta-warning-duration"
										style={{
											margin: 'auto 0',
											marginLeft: 10,
										}}
									>
										<img
											src={postWarningTooltip}
											alt="cta-warning-tooltip"
											className="warning-tooltip-icon"
										/>
										<ReactTooltip
											id="cta-warning-duration"
											place="bottom"
											effect="solid"
											backgroundColor="#07a3ed"
											getContent={() => {
												const offerEndDate = moment(props.postDate).tz(props.timezone).add(durationValue, unitValue).format('YYYY-MM-DD hh:mm A');
												return <div>Offer will start when the post is sent to GMB. If sent out today, your offer will end on {offerEndDate}.</div>;
											}}
											delayHide={300}
											clickable
										/>
									</div>
								</Dropdown>
							</div>
						</div>
					}
				</div>
			</div>
			<ReactTooltip
				id="cta-warning"
				className="post-campaign-warning-tooltip"
				place="bottom"
				effect="solid"
				backgroundColor="#07a3ed"
				getContent={() => <div>Action type works with GMB, Alexa and Pinterest only.</div>}
				delayHide={300}
				clickable
			/>
		</Fragment>
	);
}

export default ManageCTA;

