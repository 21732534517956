/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

export const urlRegexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/g;
export const hashtagRegexp = /#(\w*[0-9a-zA-Z]+\w*[0-9a-zA-Z])/g;
export const idRegexp = /\[a-z]|[0-9].*\//g;
export const textInTagsRegexp = /<.*?>.*?<\/.*?>/g;
export const tagsRegexp = /<.*?>/g;

export function getLinksFromText(text) {
	if (text && text.length) {
		const links = text.match(urlRegexp);
		if (links && links.length) {
			return links.map(el => el.replace(' ', ''));
		}
	}
	return [];
}

export function getHashtagsFromText(text) {
	if (text && text.length) {
		const hashtags = text.match(hashtagRegexp);
		if (hashtags && hashtags.length) {
			return hashtags.map(el => el.replace(' ', ''));
		}
	}
	return [];
}

export function addValueToText(value, text) {
	let textValue = text;
	const match = text.match(value);
	if (!match) {
		textValue = `${textValue} ${value}`;
	}
	return textValue;
}

export function removeValueFromText(value, text) {
	let textValue = text;
	const match = text.match(value);
	if (match && match.length) {
		textValue = textValue.replace(` ${value}`, '');
	}
	return textValue;
}

function findWord(word, str) {
	return str.split(' ').some((w) => w === word);
}

export function addOrRemoveValueFromText(value, text) {
	const textValue = text;
	if (findWord(value, textValue)) {
		if (value[0] === '#') {
			const regexValue = value.replace('#', '');

			// eslint-disable-next-line
			return textValue.replace(new RegExp(`[#]\\b${regexValue}\\b[\\s]?`, 'g'), '');
		}
		return textValue.replace(` ${value} `, '').replace(`${value} `, '').replace(value, '');
	}

	const isSpaceNeeded = textValue[textValue.length - 1] !== ' ';
	return textValue ? `${textValue}${isSpaceNeeded ? ' ' : ''}${value} ` : `${value} `;
}

export function clearTextFromTags(text) {
	return text.replace(textInTagsRegexp, (str) => str.replace(tagsRegexp, ''));
}

export function getDifferenceBetweenStrings(originString, comparedString) {
	let i = 0;
	let j = 0;
	let result = '';

	while (j < comparedString.length) {
		if (originString[i] !== comparedString[j] || i === originString.length) {
			result += comparedString[j];
		} else {
			i++;
		}
		j++;
	}
	return result;
}

export const getTextWithUTMParams = (existingTextValue, existingLink, linkWithUTM) => existingTextValue.replaceAll(existingLink, linkWithUTM);
