/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Scrollbars } from 'react-custom-scrollbars';
import './SimilarityBlock.css';
import iconColorTwitter from '../../../images/icon-color-twitter.svg';
import iconColorFacebook from '../../../images/icon-color-facebook-by-meta.png';
import iconColorLinkedin from '../../../images/icon-color-linkedin.svg';
import iconColorInstagram from '../../../images/icon-color-instagram-by-meta.png';
import iconColorAlexa from '../../../images/amazon-alexa-logo.png';
import iconColorGoogleMB from '../../../images/googleMBIcon.svg';
import iconColorTikTok from '../../../images/tiktokRoundIcon.svg';

class SimilarityBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeSocialMedia: '',
		};
	}

	getSocialMediaAccount = (socialMedias) =>
		socialMedias.find((media) => media.socialMedia === this.state.activeSocialMedia);

	setActiveSocialMedia = (socialMedia) => this.setState({ activeSocialMedia: socialMedia });

	convertToString = (value) => (value && !Number.isNaN(value) ? parseFloat(value).toFixed(0).toString() : '0');

	getSocialMediaIconMap = () => ({
		twitter: {
			icon: iconColorTwitter,
			label: 'Twitter',
		},
		facebook: {
			icon: iconColorFacebook,
			label: 'Facebook by Meta',
		},
		linkedin: {
			icon: iconColorLinkedin,
			label: 'LinkedIn',
		},
		instagram: {
			icon: iconColorInstagram,
			label: 'Instagram by Meta',
		},
		googleMB: {
			icon: iconColorGoogleMB,
			label: 'Google My Business',
		},
		tikTok: {
			icon: iconColorTikTok,
			label: 'TikTok',
		},
		alexaDevice: {
			icon: iconColorAlexa,
			label: 'Alexa',
		},
	});

	render() {
		const { similar: { socialMedias } = {} } = this.props;
		const { activeSocialMedia } = this.state;
		return (
			<div className="similarity-block">
				<p className="similarity-block-label">
					{/* {isLibraryPost &&
					<span>This post is similar to what you have posted or scheduled to post within the last 30 days. </span>} */}
					The similarity is:
				</p>
				<div className="similarity-block-medias">
					{/* <div
						className="similarity-block-media"
						data-tip="Library"
						data-place="bottom"
						data-for="library-icon-tooltip"
					>
						<div className="similarity-block-media-name"><span role="img" aria-label="books" style={{ fontSize: 24 }}>📚</span></div>
						<div className="similarity-block-media-score">{this.convertToString(library)}%</div>
						<ReactTooltip
							id="library-icon-tooltip"
							effect="solid"
						/>
					</div> */}
					{socialMedias && socialMedias.length && socialMedias.map((media) => (<div
						key={media.socialMedia}
						className={`similarity-block-media
							${activeSocialMedia === media.socialMedia ? 'active-account' : ''}
							${media.maxScore >= 60 && 'cursor-pointer'}
							`}
						onClick={() => media.maxScore >= 60 && this.setActiveSocialMedia(media.socialMedia)}
						data-tip={this.getSocialMediaIconMap()[media.socialMedia].label}
						data-place="bottom"
						data-for={`${media.socialMedia}-tooltip`}
					>
						<div className="similarity-block-media-name">
							<img
								style={{ width: 24, height: 24 }}
								src={this.getSocialMediaIconMap()[media.socialMedia].icon}
								alt={`${media.socialMedia}-icon`}
							/>
						</div>
						<div className="similarity-block-media-score">{this.convertToString(media.maxScore)}%</div>
						<ReactTooltip
							id={`${media.socialMedia}-tooltip`}
							effect="solid"
						/>
					</div>
					))}
				</div>
				{activeSocialMedia && socialMedias && socialMedias.length &&
					<Scrollbars style={{ width: '100%' }} autoHeight autoHeightMax={80}>
						<div className="similarity-block-media-accounts">
							{this.getSocialMediaAccount(socialMedias).accounts.map((account) => (
								<div key={account.id} className="similarity-block-media-account">
									<div className="similarity-block-account-name">@{account.accountName}</div>
									<div className="similarity-block-account-score">{this.convertToString(account.score)}% similar</div>
								</div>
							))}
						</div>
					</Scrollbars>}
			</div>
		);
	}
}

export default SimilarityBlock;
